import { useSlate } from "slate-react";

import { deserialize, serialize } from "@smart/itops-serialisation-basic";

export type TestEditableProps = {
  dataTestId?: string;
  disabled?: boolean;
  onKeyDown?: (e: React.KeyboardEvent<any>) => void;
  value?: string;
  placeholder?: string;
};

export const TestEditable = ({
  dataTestId,
  disabled,
  onKeyDown,
  value,
  placeholder,
}: TestEditableProps) => {
  const editor = useSlate();

  return (
    <div>
      <textarea
        placeholder={placeholder}
        data-testid={dataTestId}
        defaultValue={serialize(editor.children)}
        disabled={disabled}
        aria-label={value}
        onKeyDown={onKeyDown}
        onChange={(e) => {
          const v = e.currentTarget.value;
          editor.delete({ at: [0, 0], distance: v.length });
          editor.insertNodes(deserialize(v), { at: [0, 0] });
        }}
      />
    </div>
  );
};
