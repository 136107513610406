import styled from "@emotion/styled";
import {
  autoUpdate,
  flip,
  offset,
  shift,
  useFloating,
} from "@floating-ui/react";
import { useSelected } from "slate-react";

import { ElementRendererProps } from "./paragraph";
import { Variant, variantStyle } from "../../../theme";
import { Button } from "../../button";
import { useEditorContext } from "../hooks";

const LinkWrapper = styled.span<{ variant?: Variant }>`
  display: inline-block;
  a {
    ${variantStyle}
    background-color: transparent;
    cursor: pointer;

    svg {
      margin-left: 0.4rem;
      display: inline-block;
      vertical-align: middle;
    }
  }

  button {
    display: inline-flex;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
  }
`;

export const Link = ({
  attributes,
  children,
  element,
  readOnly,
  isLinkEnabled,
}: ElementRendererProps<"link">) => {
  const { setModal } = useEditorContext();
  const selected = useSelected();
  const open = !readOnly && selected;
  const popup = useFloating({
    placement: "right",
    open,
    middleware: [offset(5), shift({ padding: 10 }), flip()],
    whileElementsMounted: autoUpdate,
  });

  return (
    <LinkWrapper variant="link" ref={popup.refs.setReference}>
      <a
        {...attributes}
        href={isLinkEnabled ? element.url : undefined}
        target="_blank"
        rel="noopener noreferrer"
      >
        <span {...attributes}>{children}</span>
      </a>
      {open && (
        <span ref={popup.refs.setFloating} style={popup.floatingStyles}>
          <Button
            kind="borderless"
            variant="action"
            text="Edit Link"
            size="small"
            icon={{ library: "lucide", name: "Pencil", size: 14 }}
            onMouseDown={() => setModal({ option: "link", element })}
          />
        </span>
      )}
    </LinkWrapper>
  );
};
