import { useMemo } from "react";

import { deserialize } from "@smart/itops-serialisation-basic";

import { DisplayerContextProvider, DisplayerContextValue } from "./context";
import { DisplayChildren } from "./elements";

export type DisplayerProps = {
  value?: string | null;
  fallback?: string;
} & DisplayerContextValue;

export const Displayer = ({
  value,
  fallback,
  paragraphTag,
  placeholders,
  onLinkClick,
  onPlaceholderClick,
}: DisplayerProps) => {
  const parsed = useMemo(() => {
    const trimmed = value?.trim();
    if (trimmed) return deserialize(trimmed);
    if (fallback) return deserialize(fallback);

    return null;
  }, [value, fallback]);

  return parsed ? (
    <DisplayerContextProvider
      value={{ paragraphTag, placeholders, onLinkClick, onPlaceholderClick }}
    >
      <DisplayChildren items={parsed} />
    </DisplayerContextProvider>
  ) : null;
};
