import { ReactNode } from "react";

import {
  ParagraphTag,
  PlaceholderData,
} from "@smart/itops-serialisation-basic";

import { Children } from "../displayers";
import { useParsed } from "../hooks";

type DisplayerProps = {
  paragraphTag?: ParagraphTag;
  hintElement?: ReactNode;
  value?: string | null;
  fallback?: string;
  placeholderData?: Partial<PlaceholderData>;
};

const Displayer = ({
  paragraphTag,
  hintElement,
  value,
  fallback,
  placeholderData,
}: DisplayerProps) => {
  const parsed = useParsed(value?.trim() || fallback);
  return parsed ? (
    <Children
      paragraphTag={paragraphTag}
      hintElement={hintElement}
      items={parsed}
      placeholderData={placeholderData}
    />
  ) : null;
};

export { Displayer, DisplayerProps };
