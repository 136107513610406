import { createContext, useContext } from "react";
import { Editor } from "slate";

export type EditorContext = {
  editor: Editor;
};

const editorContext = createContext<EditorContext | undefined>(undefined);

export const useEditorContext = () => {
  const context = useContext(editorContext);
  if (!context) throw new Error(`Editor context not available`);

  return context;
};

export const EditorContextProvider = editorContext.Provider;
