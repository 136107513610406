import styled from "@emotion/styled";
import {
  arrow,
  FloatingArrow,
  useFloating,
  autoUpdate,
  offset,
  shift,
  Placement,
} from "@floating-ui/react";
import { ReactNode, useRef } from "react";
import { z } from "zod";

import { useStorage } from "@smart/itops-hooks-dom";

import { Button } from "../button";

const dismissedSchema = z.boolean().optional();

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 35rem;
  padding: 2rem;
  border-radius: 1rem;
  z-index: 1000;
  color: white;
  background: ${(props) => props.theme.scheme.orange.r80};

  .feature-info-actions {
    display: flex;
    justify-content: flex-end;
  }

  .feature-info-arrow {
    fill: ${(props) => props.theme.scheme.orange.r80};
  }
`;

type FeatureInfoProps = {
  featureName: string;
  info: string | ReactNode;
  children: ReactNode;
  placement?: Placement;
};

export const FeatureInfo = ({
  children,
  info,
  featureName,
  placement,
}: FeatureInfoProps) => {
  const [isDismissed, setIsDismissed] = useStorage({
    defaultValue: undefined,
    key: featureName,
    schema: dismissedSchema,
    storage: "local",
  });

  const arrowRef = useRef(null);
  const { refs, floatingStyles, context } = useFloating({
    open: !isDismissed,
    placement,
    whileElementsMounted: autoUpdate,
    middleware: [
      shift({ padding: 10 }),
      arrow({
        element: arrowRef,
      }),
      offset(15),
    ],
  });

  return (
    <div ref={refs.setReference}>
      {!isDismissed && (
        <InfoWrapper ref={refs.setFloating} style={floatingStyles}>
          <div>{info}</div>
          <div className="feature-info-actions">
            <Button
              variant="highlightOrange"
              text="Got it"
              onClick={() => setIsDismissed(true)}
              size="small"
            />
          </div>
          <FloatingArrow
            className="feature-info-arrow"
            ref={arrowRef}
            context={context}
            width={20}
            height={10}
          />
        </InfoWrapper>
      )}
      {children}
    </div>
  );
};
