import { z } from "zod";

export const phoneNumberResponse = z.object(
  {
    country: z.string().nullable(),
    countryCallingCode: z.string().nullable(),
    formattedNumber: z.string().nullable(),
    number: z.string().nullable(),
  },
  { required_error: "Please enter a phone number" },
);

export type PhoneNumberResponse = z.infer<typeof phoneNumberResponse>;

export const isOfPhoneNumberResponseType = (
  value: any,
): value is PhoneNumberResponse => !!value?.formattedNumber;
