export type DynamoPermission = "READ" | "WRITE" | null;

export type SNSPermission = "PUBLISH" | null;

export type S3Permission = "READ" | "WRITE" | null;

export type AdditionalPermission = "ACCESS" | null;

export type PermissionList<K extends string, V extends string | null> = {
  default: V;
} & { [k in K]?: V };

export type PermissionsDef<
  L extends string,
  DB extends string,
  SNS extends string,
  BU extends string,
  AR extends string,
> = {
  lambdaKeys: L[];
  tableKeys: DB[];
  transportKeys: SNS[];
  bucketKeys: BU[];
  additionalResources: AR[];
  permissions: {
    [k in L]: {
      dynamo: PermissionList<DB, DynamoPermission>;
      sns: PermissionList<SNS, SNSPermission>;
      s3: PermissionList<BU, S3Permission>;
      additionalResources: PermissionList<AR, AdditionalPermission>;
    };
  };
};

export const buildPermissionsDef = <
  L extends string,
  DB extends string,
  SNS extends string,
  BU extends string,
  AR extends string,
>(
  lambdaKeys: L[],
  tableKeys: DB[],
  transportKeys: SNS[],
  bucketKeys: BU[],
  additionalResources: AR[],
  permissions: PermissionsDef<L, DB, SNS, BU, AR>["permissions"],
): PermissionsDef<L, DB, SNS, BU, AR> => ({
  lambdaKeys,
  tableKeys,
  transportKeys,
  bucketKeys,
  additionalResources,
  permissions,
});

export const loadPermission = <K extends string, V extends string | null>(
  list: PermissionList<K, V>,
  item: K,
) => (list[item] === undefined ? list.default : list[item]);
