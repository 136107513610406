import { z } from "zod";

import { buildNamespace } from "@smart/itops-types-basic";

import { system } from "./base";
import { TeamNS } from "./team";

export const UserNS = buildNamespace({
  system,
  entity: "User",
} as const);

export const UserSchema = z.object({
  uri: UserNS.schema,
  operationId: z.string(),

  teamUri: TeamNS.schema,
  source: z.string(),
  email: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  roles: z.string().optional(),
  initials: z.string().optional(),
  picture: z.string().optional(),

  latestLoginAt: z.string().optional(),
});
