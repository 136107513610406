import styled from "@emotion/styled";

const PillWrapper = styled.button<{
  color?: string;
  backgroundColor?: string;
  borderColor?: string;
  fontWeight?: number;
  size: number;
}>`
  min-width: ${(props) => props.size * 1.5}rem;
  padding: ${(props) => props.size * 0.08}rem ${(props) => props.size * 0.16}rem;
  border-radius: ${(props) => props.size * 2}rem;

  display: flex;
  align-items: center;
  justify-content: center;

  color: ${(props) => props.color || props.theme.palette.primary.base};
  background: ${(props) =>
    props.backgroundColor || props.theme.palette.background.base};
  border: 1.6px solid
    ${(props) => props.borderColor || props.theme.palette.primary.base};
  font-weight: ${(props) => props.fontWeight || "inherit"};
`;

type PillProps = {
  text: string;
  styles?: {
    color?: string;
    backgroundColor?: string;
    borderColor?: string;
    fontWeight?: number;
    size?: number;
  };
};

const Pill = ({ text, styles }: PillProps) => (
  <PillWrapper {...{ size: 5, ...styles }}>{text}</PillWrapper>
);

export { PillProps, Pill };
