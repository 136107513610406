import { useMemo } from "react";
import { Params, useParams, useSearchParams } from "react-router-dom";

export const useParamsWithDefault = <T>(
  fn: (params: Readonly<Params<string>>) => T,
) => {
  const params = useParams();
  return useMemo(() => fn(params), [params]);
};

export const useSearchWithDefault = <T>(fn: (params: URLSearchParams) => T) => {
  const [params] = useSearchParams();
  return useMemo(() => fn(params), [params]);
};
