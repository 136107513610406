import { useEffect } from "react";

const defaultEvent = "keydown";

export type GlobalKeyboardShortcutOptions = {
  key: string;
  modifiers?: {
    shift?: boolean;
    ctrlOrMeta?: boolean;
  };
  event?: "keydown" | "keyup";
  fn?: () => void;
};

export const useGlobalKeyboardShortcut = ({
  key,
  modifiers,
  fn,
  event = defaultEvent,
}: GlobalKeyboardShortcutOptions) =>
  useEffect(() => {
    const listener = (ev: KeyboardEvent) => {
      if (
        ev.key === key &&
        ev.shiftKey === !!modifiers?.shift &&
        (ev.metaKey === !!modifiers?.ctrlOrMeta ||
          ev.ctrlKey === !!modifiers?.ctrlOrMeta)
      )
        fn?.();
    };

    document.addEventListener(event, listener);
    return () => document.removeEventListener(event, listener);
  }, [fn]);
