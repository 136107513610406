import { EditorElement } from "@smart/itops-serialisation-basic";

import { useDisplayerContext } from "../context";

export type DisplayPlaceholderProps = {
  element: EditorElement<"placeholder">;
};

export const DisplayPlaceholder = ({ element }: DisplayPlaceholderProps) => {
  const { placeholders, onPlaceholderClick } = useDisplayerContext();

  return element.isLink ? (
    <a
      href={placeholders?.data[element.name] || element.name}
      target="_blank"
      rel="noreferrer"
      onClick={(e) =>
        onPlaceholderClick?.(
          e,
          element,
          placeholders?.data[element.name] || element.name,
        )
      }
    >
      {element.displayText || element.name}
    </a>
  ) : (
    <span>
      {placeholders?.data[element.name] || element.displayText || element.name}
    </span>
  );
};
