import { ReactNode } from "react";

import { EditorElement } from "@smart/itops-serialisation-basic";

import { useDisplayerContext } from "../context";

export type DisplayLinkProps = {
  element: EditorElement<"link">;
  children: ReactNode;
};

export const DisplayLink = ({ children, element }: DisplayLinkProps) => {
  const { onLinkClick } = useDisplayerContext();

  return (
    <a
      href={element.url}
      target="_blank"
      rel="noreferrer"
      onClick={(e) => onLinkClick?.(e, element)}
    >
      {children}
    </a>
  );
};
