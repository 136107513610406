import styled from "@emotion/styled";
import { position as getPositionStyle, transparentize } from "polished";
import { ReactNode } from "react";

import { Button, ButtonRow, IconButton } from "./buttons";

export const ModalWrapper = styled.div<{
  position: "absolute" | "fixed";
  marginBottom?: number;
}>`
  ${(props) => getPositionStyle(props.position, 0)}
  background: ${(props) =>
    transparentize(0.3, props.theme.palette.foreground.base)};
  z-index: 5;

  display: flex;
  align-items: center;
  justify-content: center;

  .modal {
    position: relative;
    background: var(--background);
    max-width: 60rem;
    width: 94%;
    border-radius: 3px;
    text-align: center;
    padding: 1rem 2rem;

    @media (min-height: ${(props) => props.theme.breakPoints.medium}px) {
      margin-bottom: ${(props) =>
        props.marginBottom === undefined
          ? "50rem"
          : `${props.marginBottom}rem`};
    }

    @media (max-width: ${(props) => props.theme.breakPoints.tablet}px) {
      height: 94%;
      margin-bottom: 0;
    }

    h1 {
      font-size: ${(props) => props.theme.fontSize.heading};
      font-weight: bold;
      margin: 6rem 0 4rem;
    }

    h2 {
      color: ${(props) => props.theme.palette.foreground.accent};
      font-size: ${(props) => props.theme.fontSize.subHeading};
      font-weight: normal;
      margin: 3rem 0;
    }

    & > p {
      color: ${(props) => props.theme.palette.foreground.accent};
      font-size: ${(props) => props.theme.fontSize.base};
      font-weight: normal;
      margin: 3rem 0;
    }

    .buttons {
      margin: 2rem 0 6rem;
    }
  }
`;

const CloseButton = styled(IconButton)`
  ${getPositionStyle("absolute", "0", "0", null, null)}
  color: ${(props) => props.theme.palette.foreground.accent};
`;

type ModalProps = {
  heading?: string;
  subHeading?: string;
  text?: string;
  children?: ReactNode;
  buttons?: { text: string; onClick: () => void; autoFocus?: boolean }[];
  showCloseButton?: boolean;
  onClose?: () => void;
  position?: "absolute" | "fixed";
  marginBottom?: number;
};

const Modal = ({
  heading,
  subHeading,
  text,
  children,
  buttons,
  showCloseButton = false,
  position = "absolute",
  marginBottom,
  onClose,
}: ModalProps) => (
  <ModalWrapper position={position} marginBottom={marginBottom}>
    <div className="modal">
      {showCloseButton && <CloseButton name="cancel" onClick={onClose} />}
      {heading && <h1>{heading}</h1>}
      {subHeading && <h2>{subHeading}</h2>}
      {text && <p>{text}</p>}
      {children}
      {buttons && (
        <ButtonRow className="buttons">
          {buttons.map((b) => (
            <Button
              type="button"
              onClick={b.onClick}
              autoFocus={b.autoFocus}
              key={b.text}
            >
              {b.text}
            </Button>
          ))}
        </ButtonRow>
      )}
    </div>
  </ModalWrapper>
);

export { ModalProps, Modal };
