import styled from "@emotion/styled";
import { ButtonHTMLAttributes, DetailedHTMLProps, ReactNode } from "react";

import {
  Icon,
  IconLibrary,
  IconLibraryName,
  IconName,
} from "@smart/itops-icons-dom";

import {
  ButtonProps,
  activeButtonColor,
  buttonColor,
  buttonDot,
} from "./utils";

const Button = styled.button<ButtonProps>`
  margin: ${(props) => props.theme.baseUnit}rem;
  padding: ${(props) => props.theme.baseUnit}rem;
  border: 0;
  border-radius: ${(props) => props.theme.baseUnit * 2}rem;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${activeButtonColor};
  color: ${buttonColor};

  ${buttonDot}

  &:active,
  &:focus {
    outline: none;
    background-color: ${(props) =>
      activeButtonColor({ ...props, active: true })};
  }

  .text {
    margin-left: ${(props) => props.theme.baseUnit}rem;
    font-size: ${(props) => props.theme.fontSize.small};
    font-weight: 500;
  }

  .right-icon {
    margin-left: ${(props) => props.theme.baseUnit * 0.4}rem;
  }
`;

type BaseIconButtonProps<L extends IconLibrary> = {
  icon?: ReactNode;
  library?: L;
  name?: IconLibraryName<L>;
  onClick?: () => void;
  onPress?: () => void;
  className?: string;
  text?: string;
  size?: number;
  rotate?: number;
  spin?: boolean;
  spinDirection?: "cw" | "ccw";
  rightIcon?: { name: IconName; size?: number };
  transformSVG?: string;
};

type IconButtonProps<L extends IconLibrary> = BaseIconButtonProps<L> &
  ButtonProps &
  DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

type DefaultIconButtonProps<L extends IconLibrary> = Omit<
  BaseIconButtonProps<L>,
  "onClick"
> &
  ButtonProps &
  DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

const IconButton = <L extends IconLibrary = "fontastic">({
  icon,
  library,
  name,
  text,
  size,
  spin,
  spinDirection,
  rotate,
  rightIcon,
  transformSVG,
  onPress,
  ...props
}: IconButtonProps<L> | DefaultIconButtonProps<L>) => (
  <Button type="button" onMouseDown={onPress} onTouchStart={onPress} {...props}>
    {icon || (
      <Icon
        className="icon"
        library={library}
        name={name!}
        size={size}
        spin={spin}
        spinDirection={spinDirection}
        rotate={rotate}
        transformSVG={transformSVG}
      />
    )}
    {text && <div className="text">{text}</div>}
    {rightIcon && (
      <Icon
        className="right-icon"
        name={rightIcon.name}
        size={rightIcon.size}
      />
    )}
  </Button>
);

export { IconButtonProps, IconButton, DefaultIconButtonProps };
