export type BuiltName = {
  parts: string[];
  delimiter: string;
  prefix?: string;
  suffix?: string;
};

export type NameParts = {
  region: string;
  env: string;
  system: string;
  entity: string;
};

export type BuiltNameFactory = (
  info: Pick<NameParts, "region" | "env">,
) => BuiltName;

export const buildName = ({
  parts: { region, env, system, entity },
  prefix,
  suffix,
  delimiter,
  reversed,
}: {
  parts: NameParts;
  prefix?: string;
  suffix?: string;
  delimiter?: string;
  reversed?: boolean;
}): BuiltName => ({
  parts: reversed
    ? [env, region, system.toLocaleLowerCase(), entity.toLocaleLowerCase()]
    : [entity.toLocaleLowerCase(), system.toLocaleLowerCase(), env, region],
  delimiter: delimiter || "-",
  prefix,
  suffix,
});

export const buildRegionalName = ({
  parts,
  info,
  prefix,
  suffix,
  delimiter,
  reversed,
}: {
  parts: Pick<NameParts, "system" | "entity">;
  info: Pick<NameParts, "region" | "env">;
  prefix?: string;
  suffix?: string;
  delimiter?: string;
  reversed?: boolean;
}) =>
  buildName({
    parts: { ...info, ...parts },
    prefix,
    suffix,
    delimiter: delimiter || "-",
    reversed,
  });

export const joinRegionalName = ({
  parts,
  delimiter,
  prefix,
  suffix,
}: BuiltName) => `${prefix || ""}${parts.join(delimiter)}${suffix || ""}`;
