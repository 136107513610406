import { LocalAWSError } from "./local-aws";
import { ValidationError } from "./validation";
import { keysOf } from "../array";
import { displayName } from "../constants";

export type UserErrorLevel = "SUCCESS" | "INFO" | "WARN" | "DEFAULT";

export type UserError<C extends string> = {
  code: C;
  level: UserErrorLevel;
  message: string;
};

export const unknownUserErrorCode = "ERR-1000" as const;

export const userErrorsRaw = {
  [unknownUserErrorCode]: {
    code: unknownUserErrorCode,
    level: "WARN",
    message:
      "There was a problem loading this page. Please close the window and try again. If the issue persists, please contact support.",
  },
  "ERR-1399": {
    code: "ERR-1399",
    level: "WARN",
    message: `${displayName} received unexpected input. Please refresh and try again.`,
  },
  "ERR-1400": {
    code: "ERR-1400",
    level: "WARN",
    message: `${displayName} received unexpected input. Please refresh and try again.`,
  },
  "ERR-1404": {
    code: "ERR-1404",
    level: "WARN",
    message: `${displayName} could not connect to the server, there may be network issues. Please try again.`,
  },
  "ERR-2024": {
    code: "ERR-2024",
    level: "WARN",
    message: `The email or password you entered was incorrect.`,
  },
  "ERR-2025": {
    code: "ERR-2025",
    level: "WARN",
    message:
      "The multi-factor authentication code you entered was incorrect. It should be a 6 digit number.",
  },
  "ERR-2026": {
    code: "ERR-2026",
    level: "WARN",
    message: `Your login has expired. Please sign in again.`,
  },
  "ERR-2027": {
    code: "ERR-2027",
    level: "WARN",
    message: "Could not load user information. Please close and try again.",
  },
  "ERR-3031": {
    code: "ERR-3031",
    level: "WARN",
    message:
      "Generating From Existing Form Failed. Please close and try again.",
  },
  "INF-2030": {
    code: "INF-2030",
    level: "SUCCESS",
    message: "Signed out successfully.",
  },
  "INF-2031": {
    code: "INF-2031",
    level: "SUCCESS",
    message: "Signed in successfully.",
  },
} as const;

export type UserErrorCode = keyof typeof userErrorsRaw;

export const userErrors = userErrorsRaw as {
  [k in UserErrorCode]: UserError<k>;
};

export const userErrorCodes = keysOf(userErrorsRaw);

export const checkErrorCode = (
  input: string | undefined,
): UserError<UserErrorCode> => {
  let error;
  const code = input as UserErrorCode;
  if (userErrorCodes.indexOf(code)) {
    error = userErrors[code];
  }

  return error || userErrors[unknownUserErrorCode];
};

export const buildErrorCode = (error: unknown) => {
  if (error instanceof ValidationError) {
    return error.displayCode;
  }

  if (error instanceof LocalAWSError) {
    return error.displayCode || error.message;
  }

  if (error instanceof Error) {
    if (
      error.name === "TypeError" &&
      error.message.startsWith("NetworkError")
    ) {
      return "ERR-1404";
    }

    return error.message;
  }

  return `${error}`;
};
