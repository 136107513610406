import {
  convertSimplifiedHtmlToMarkdown,
  PlaceholderDetails,
} from "@smart/itops-serialisation-basic";

const basePlaceholders = ["firmName", "firmPhone", "matterCategory"] as const;

const responsePlaceholders = ["downloadFormLink"] as const;

const messagePlaceholders = [
  "clientName",
  "clientEmail",
  "formLink",
  "formTitle",
  "matterType",
] as const;

const familyProPlaceholders = [
  "first_name",
  "link",
  "email",
  "form_name",
] as const;

export const templateMessagePlaceholders = [
  ...basePlaceholders,
  ...messagePlaceholders,
];

export type TemplateMessageData = Record<
  (typeof templateMessagePlaceholders)[number],
  string
>;

export const templateResponsePlaceholders = [
  ...basePlaceholders,
  ...responsePlaceholders,
];

export type TemplateResponseData = Record<
  (typeof templateResponsePlaceholders)[number],
  string
>;

export const templateAllPlaceholders = [
  ...basePlaceholders,
  ...responsePlaceholders,
  ...messagePlaceholders,
];

export type TemplatePlaceholders = (typeof templateAllPlaceholders)[number];

export const templatePlaceholderDetails: PlaceholderDetails<TemplatePlaceholders> =
  {
    firmName: { label: "Firm Name" },
    firmPhone: { label: "Firm Phone" },
    matterCategory: { label: "Matter Category" },
    downloadFormLink: {
      label: "Download Form Link",
      isLink: true,
      defaultValue: "Click here to save a copy of your form",
    },
    clientName: { label: "Client Name" },
    clientEmail: { label: "Client Email" },
    formLink: { label: "Form Link", isLink: true, defaultValue: "Open Form" },
    formTitle: { label: "Form Title" },
    matterType: { label: "Matter Type", lowerable: true },
  };

export type FamilyProTemplatePlaceholders =
  (typeof familyProPlaceholders)[number];

export const familyProTemplatePlaceholdeMapping: Record<
  FamilyProTemplatePlaceholders,
  TemplatePlaceholders
> = {
  first_name: "clientName",
  form_name: "formTitle",
  email: "clientEmail",
  link: "formLink",
};

export const convertFamilyProTemplate = (template: string): string => {
  const placeholderRegex = /{{\s*(\w+)\s*}}/g;

  const markdownTemplate = convertSimplifiedHtmlToMarkdown(template);

  return markdownTemplate
    .replaceAll("[{{link}}]({{link}})", "{{link}}")
    .replace(placeholderRegex, (match, placeholderName) => {
      const intakePlaceholdeName =
        familyProTemplatePlaceholdeMapping[
          placeholderName as FamilyProTemplatePlaceholders
        ];

      if (!intakePlaceholdeName) return match;

      const intakePlaceholder =
        templatePlaceholderDetails[intakePlaceholdeName];
      if (!intakePlaceholder) return match;

      return `{${intakePlaceholdeName}|${intakePlaceholder.label}}`;
    });
};
