import { isNullOrUndefined } from "./guard";

export type Operator =
  | "equalTo"
  | "notEqualTo"
  | "greaterThan"
  | "greaterThanOrEqualTo"
  | "lessThan"
  | "lessThanOrEqualTo"
  | "in"
  | "contain";

export const arrayComparer = (
  leftValue: (string | number)[],
  operator: Operator,
  rightValue: (string | number)[],
): boolean => {
  switch (operator) {
    case "equalTo": {
      if (leftValue.length !== rightValue.length) return false;

      leftValue.sort();
      rightValue.sort();

      return leftValue.every((item, index) => item === rightValue[index]);
    }
    case "in":
      return rightValue.some((item) => leftValue.includes(item));
    case "contain":
      return rightValue.every((item) => leftValue.includes(item));
    default:
      return false;
  }
};

export const singleToArrayComparer = (
  singleValue: string | number,
  operator: Operator,
  array: (string | number)[],
): boolean => {
  switch (operator) {
    case "in":
      return array.includes(singleValue);
    default:
      return false;
  }
};

export const stringOrNumberComparer = (
  leftValue: string | number,
  operator: Operator,
  rightValue: string | number,
): boolean => {
  if (isNullOrUndefined(leftValue)) return false;

  const isOfNumberType =
    typeof leftValue === "number" || typeof rightValue === "number";

  // If any of the values is number, convert both before comparing
  const formattedLeftValue = isOfNumberType
    ? Number(leftValue)
    : leftValue.toLocaleLowerCase();
  const formattedRightValue = isOfNumberType
    ? Number(rightValue)
    : rightValue.toLocaleLowerCase();

  switch (operator) {
    case "equalTo":
      return formattedLeftValue === formattedRightValue;
    case "notEqualTo":
      return formattedLeftValue !== formattedRightValue;
    case "contain":
      return (
        !isOfNumberType &&
        (formattedLeftValue as string).includes(formattedRightValue as string)
      );
    case "in":
      return (
        !isOfNumberType &&
        (formattedRightValue as string).includes(formattedLeftValue as string)
      );
    case "greaterThan":
      return formattedLeftValue > formattedRightValue;
    case "greaterThanOrEqualTo":
      return formattedLeftValue >= formattedRightValue;
    case "lessThan":
      return formattedLeftValue < formattedRightValue;
    case "lessThanOrEqualTo":
      return formattedLeftValue <= formattedRightValue;
    default:
      return false;
  }
};
