import { css, Theme } from "@emotion/react";
import { transparentize } from "polished";

export type ButtonProps = {
  color?: string;
  palette?: keyof Theme["palette"];
  active?: boolean;
  disabled?: boolean;
  transparent?: boolean;
  dot?: keyof Theme["palette"];
};

export const buttonColor = (props: ButtonProps & { theme: Theme }): string => {
  if (props.disabled) return props.theme.palette.disabled.base;
  if (props.color) return props.color;
  if (props.palette) return props.theme.palette[props.palette].base;

  return props.theme.palette.primary.base;
};

export const activeButtonColor = (
  props: ButtonProps & { theme: Theme },
): string => {
  if (props.active) return transparentize(0.9, buttonColor(props));
  if (props.transparent) return "transparent";

  return `var(--background)`;
};

export const buttonDot = (props: ButtonProps & { theme: Theme }) =>
  props.dot
    ? css`
        &::after {
          content: "";
          background: ${props.theme.palette[props.dot].base};
          width: 1rem;
          height: 1rem;
          border-radius: 1rem;

          position: absolute;
          bottom: 0.5rem;
          right: 0.5rem;
        }
      `
    : undefined;

export const buttonStyle = (props: ButtonProps & { theme: Theme }) => css`
  background-color: ${activeButtonColor(props)};
  border-radius: 100px;
  margin: 0.6rem;
  padding: 1rem 2.4rem;
  cursor: pointer;
  font-size: ${props.theme.fontSize.small};
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-family: ${props.theme.fonts.body};

  img {
    display: block;
    height: 3rem;
    margin: 0.2rem 2rem;
  }

  &::after {
    content: "";
    width: 1rem;
    height: 1rem;
    background: red;
  }
`;
