import { Global, css, Theme } from "@emotion/react";

const globalCss = (theme: Theme) => css`
  html {
    box-sizing: border-box;
    font-family: ${theme?.fonts.body || "sans-serif"};
    font-size: ${theme?.baseFontSize || "62.5%"};
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
    font-family: inherit;
    color: inherit;
  }

  body {
    font-family: ${theme?.fonts.body};
    color: ${theme?.palette?.foreground.base};
    margin: 0;
    padding: 0;
  }

  :root {
    --background: ${theme?.palette?.background.base};
  }

  h1 {
    font-family: ${theme?.fonts.title};
  }

  h2 {
    font-size: ${theme?.fontSize.subHeading};
  }

  h3 {
    font-size: ${theme?.fontSize.emphasis};
    font-weight: 600;
  }

  p {
    font-size: ${theme?.fontSize.base};
  }
`;

const GlobalStyles = () => <Global styles={[globalCss]} />;

export { GlobalStyles };
