import { useCallback, useEffect, useMemo, useState } from "react";

import { isTestEnv } from "@smart/itops-utils-basic";

const debounce = <A extends any[]>(fn: (...args: A) => void, delay: number) => {
  let timerId: NodeJS.Timeout;

  const debounced: typeof fn =
    isTestEnv() && delay > 0
      ? fn
      : (...args: A) => {
          clearTimeout(timerId);
          timerId = setTimeout(() => fn(...args), delay);
        };
  return debounced;
};

export const useDebounce = <A extends any[]>(
  fn: (...args: A) => void,
  delay: number,
) => {
  const debouncedFn = useMemo(() => debounce(fn, delay), [fn, delay]);
  const memoizedFn = useCallback(
    (...args: A) => debouncedFn(...args),
    [debouncedFn],
  );

  return memoizedFn;
};

export const useDebouncedValue = <V>(value: V, delay: number) => {
  const [debounced, setDebounced] = useState(value);

  useEffect(() => {
    const timerId = setTimeout(() => setDebounced(value), delay);
    return () => clearTimeout(timerId);
  }, [value, debounce]);

  return debounced;
};

export const useDefinedValue = <V>(value: V | undefined) => {
  const [defined, setDefined] = useState(value);
  useEffect(() => {
    if (value) setDefined(value);
  }, [value]);

  return defined;
};
