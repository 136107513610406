import { ReactNode } from "react";

import { ParagraphTag } from "@smart/itops-serialisation-basic";

export type DisplayParagraphProps = {
  Tag?: ParagraphTag;
  children: ReactNode;
};

export const DisplayParagraph = ({
  children,
  Tag = "p",
}: DisplayParagraphProps) => <Tag>{children}</Tag>;
