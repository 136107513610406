import { createContext, useContext } from "react";

type ProviderInfoFields = { logo: string; icon: string; label: string };

const providerInfoContext = createContext<ProviderInfoFields | undefined>(
  undefined,
);

export const useProviderInfo = () => useContext(providerInfoContext);

export const ProviderInfo = providerInfoContext.Provider;
