import {
  buildRegionalName,
  BuiltNameFactory,
  Namespace,
} from "@smart/itops-types-basic";
import { Guard, PickByKeyValue } from "@smart/itops-utils-basic";

import { LambdaDef } from "./lambda";

export type TableAble = Record<string, any>;

export type TableKeyFields<E extends TableAble> = keyof E extends string
  ? readonly [keyof E] | readonly [keyof E, keyof E]
  : never;

export type TableKey<
  E extends TableAble,
  K extends TableKeyFields<E>,
> = K[1] extends keyof E ? Pick<E, K[0] | K[1]> : Pick<E, K[0]>;

export type TableSchemaValue<V> = V extends string
  ? "string"
  : V extends number
    ? "number"
    : never;

export type TableKeySchema<
  E extends TableAble,
  K extends TableKeyFields<E>,
  KE extends TableKey<E, K>,
> = { [k in keyof KE]: TableSchemaValue<KE[k]> };

export type TableTTLField<E extends TableAble> = keyof PickByKeyValue<
  E,
  string,
  number | undefined
>;

export type TableTableDef<
  T extends string,
  E extends TableAble,
  K extends TableKeyFields<E>,
> = {
  namespace: Namespace<string, T>;
  guard: Guard<E>;
  keyFields: K;
  keySchema: TableKeySchema<E, K, TableKey<E, K>>;
  ttlField?: TableTTLField<E>;
};

export type TableIndexDef<
  I extends string,
  E extends TableAble,
  K extends TableKeyFields<E>,
> = {
  indexName: I;
  keyFields: K;
  keySchema: TableKeySchema<E, K, TableKey<E, K>>;
};

export type TableIndexKeyFields<E extends TableAble, I extends string> = {
  [k in I]: { keyFields: TableKeyFields<E> };
};

export type TableIndexes<
  E extends TableAble,
  I extends string,
  K extends TableIndexKeyFields<E, I>,
> = { [k in I]: TableIndexDef<k, E, K[k]["keyFields"]> };

export type TableDef<
  T extends string,
  E extends TableAble,
  K extends TableKeyFields<E>,
  I extends string,
  IN extends TableIndexKeyFields<E, I>,
> = {
  buildName: BuiltNameFactory;
  indexes: TableIndexes<E, I, IN>;
} & TableTableDef<T, E, K>;

export const buildTableDef = <
  T extends string,
  E extends TableAble,
  K extends TableKeyFields<E>,
  I extends string,
  IN extends TableIndexKeyFields<E, I>,
>(
  def: TableTableDef<T, E, K>,
  indexes: IN & TableIndexes<E, I, IN>,
): TableDef<T, E, K, I, IN> => ({
  ...def,
  buildName: (info) => buildRegionalName({ parts: def.namespace, info }),
  indexes,
});

export const overrideTableGuard = <
  T extends string,
  E extends TableAble,
  E2 extends E,
  K extends TableKeyFields<E>,
  I extends string,
  IN extends TableIndexKeyFields<E, I>,
>(
  def: TableDef<T, E & E2, K, I, IN>,
  guard: Guard<E2>,
): TableDef<T, E & E2, K, I, IN> => ({
  ...def,
  guard,
});

export type TableType<D> =
  D extends TableDef<any, infer E, any, any, any> ? E : never;

export type TableTypes<D> = {
  [k in keyof D]: TableType<D[k]>;
};

export type TableKeys<D> = {
  [k in keyof D]: D[k] extends TableDef<any, infer E, infer K, any, any>
    ? TableKey<E, K>
    : never;
};

export type TableIndexKeys<D> = {
  [k in keyof D]: D[k] extends TableDef<any, TableAble, any, infer I, any>
    ? I
    : never;
};

export type TableIndexKeyValues<D> = {
  [k in keyof D]: D[k] extends TableDef<any, TableAble, any, infer I, infer IN>
    ? { [i in I]: IN[i]["keyFields"] }
    : never;
};

export type DynamoStreamLambdaDef<
  D extends string,
  E extends string,
  T extends string,
  TD extends { [k in T]: TableDef<k, TableAble, any, any, any> },
> = LambdaDef<D, E> & {
  tableKeys: T[];
  tables: TD;
};

export const buildDynamoStreamHandlerDef = <
  D extends string,
  E extends string,
  T extends string,
  L extends LambdaDef<D, E>,
  TD extends { [k in T]: TableDef<k, TableAble, any, any, any> },
>({
  handler,
  tableKeys,
  tables,
}: {
  envKeys: readonly E[];
  handler: L;
  tableKeys: T[];
  tables: TD;
}): DynamoStreamLambdaDef<D, E, T, TD> & { pkg: L["pkg"] } => ({
  ...handler,
  tableKeys,
  tables,
});
