import type { SmithyException, MetadataBearer } from "@aws-sdk/types";

export type OriginalAWSError = SmithyException &
  MetadataBearer & { [key: string]: any };

export const isOriginalAWSError = (e: any): e is OriginalAWSError =>
  typeof e === "object" && typeof e.$metadata === "object";

export type AWSRequestDetails = {
  service: string;
  operation: string;
  params: any;
};

export class AWSError extends Error {
  public service?: string;

  public operation?: string;

  public params?: any;

  public statusCode?: number;

  public code: string;

  public awsStack: string;

  constructor(error: OriginalAWSError, details?: AWSRequestDetails) {
    const code = error.name;

    const detailed = details
      ? `${code} in ${details.service}.${details.operation}`
      : code;
    super(`${detailed}: ${error.message}`);

    this.service = details?.service;
    this.operation = details?.operation;
    this.params = details?.params;
    this.code = code;
    this.statusCode = error.$metadata.httpStatusCode;
    this.awsStack = error.stack;
  }
}
