/* eslint-disable react/no-array-index-key */

import { ReactNode } from "react";
import { Descendant } from "slate";

import {
  PlaceholderData,
  isEditorElementType,
  isEditorText,
  ParagraphTag,
} from "@smart/itops-serialisation-basic";

import { DisplayLink } from "./display-link";
import { DisplayParagraph } from "./display-paragraph";

type ChildrenProps = {
  paragraphTag?: ParagraphTag;
  hintElement?: ReactNode;
  items: Descendant[];
  placeholderData?: Partial<PlaceholderData>;
};

const Children = ({
  paragraphTag,
  hintElement,
  items,
  placeholderData,
}: ChildrenProps) => (
  <>
    {items.map((it, index) => {
      if (isEditorElementType("paragraph")(it)) {
        return (
          <DisplayParagraph Tag={paragraphTag} key={index}>
            <Children items={it.children} placeholderData={placeholderData} />
            {index === 0 && hintElement}
          </DisplayParagraph>
        );
      }

      if (isEditorElementType("link")(it)) {
        return (
          <DisplayLink element={it} key={index}>
            <Children items={it.children} placeholderData={placeholderData} />
          </DisplayLink>
        );
      }

      if (isEditorElementType("placeholder")(it)) {
        return <span key={index}>{placeholderData?.[it.name]}</span>;
      }

      if (isEditorText(it)) {
        let display: string | JSX.Element = it.text;
        if (it.bold) display = <strong>{display}</strong>;
        if (it.italic) display = <em>{display}</em>;

        return <span key={index}>{display}</span>;
      }
      return null;
    })}
  </>
);

export { Children, ChildrenProps };
