import { ReactNode } from "react";

import { useDisplayerContext } from "../context";

export type DisplayParagraphProps = {
  children: ReactNode;
};

export const DisplayParagraph = ({ children }: DisplayParagraphProps) => {
  const { paragraphTag: Tag = "p" } = useDisplayerContext();
  return <Tag>{children}</Tag>;
};
