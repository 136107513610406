import styled from "@emotion/styled";
import { position } from "polished";
import { ButtonHTMLAttributes, ReactNode } from "react";

import { ColourKey } from "@smart/itops-components-dom";
import { Icon, IconName } from "@smart/itops-icons-dom";

const ButtonWrapper = styled.button<{
  outline?: boolean;
  small?: boolean;
  stretch?: number;
  palette: ColourKey;
}>`
  padding: ${(props) => (props.small ? 0.3 : 0.6) * props.theme.baseUnit}rem
    ${(props) =>
      (props.stretch || (props.small ? 0.6 : 1.2)) * props.theme.baseUnit}rem;
  border-radius: 4px;
  cursor: pointer;
  position: relative;

  background: ${(props) =>
    props.outline
      ? props.theme.palette.background.base
      : props.theme.palette[props.palette].base};
  border: 1px solid ${(props) => props.theme.palette[props.palette].accent};
  color: ${(props) =>
    props.outline
      ? props.theme.palette[props.palette].base
      : props.theme.palette[props.palette].overlay};

  display: flex;
  align-items: center;

  .left-icon + .text {
    margin-left: ${(props) => props.theme.baseUnit * 0.8}rem;
  }

  .right-icon {
    margin-left: ${(props) => props.theme.baseUnit}rem;
  }

  .text {
    font-weight: ${(props) => (props.small ? 400 : 600)};
    font-size: ${(props) =>
      props.small ? props.theme.fontSize.small : props.theme.fontSize.base};
    text-transform: uppercase;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:focus,
  &:active {
    outline: none;

    &::after {
      content: "";
      ${position("absolute", 1)}
      border: 2px dotted ${(props) =>
        props.theme.palette[props.palette].accent};
      border-radius: 4px;
    }
  }
`;

const TextButtonWrapper = styled(ButtonWrapper)<{
  active?: boolean;
  hidden?: boolean;
}>`
  display: ${(props) => (props.hidden ? "none" : "default")};
  background: transparent;

  border-style: solid;
  border-color: ${(props) => props.theme.palette.foreground.highlight};
  border-width: ${(props) => (props.active ? "2px" : "0")};

  &:focus,
  &:active {
    &::after {
      border: none;
    }
  }

  &:hover {
    background: ${(props) =>
      props.active ? "transparent" : props.theme.palette.background.base};
  }
`;

type ButtonProps = {
  palette?: ColourKey;
  icon?: IconName;
  rightIcon?: IconName;
  text?: string;
  stretch?: number;
  outline?: boolean;
  disabled?: boolean;
  className?: string;
  small?: boolean;
} & Pick<
  ButtonHTMLAttributes<HTMLButtonElement>,
  "name" | "type" | "value" | "onClick"
>;

const Button = ({
  palette = "default",
  type = "button",
  stretch,
  icon,
  rightIcon,
  text,
  outline,
  disabled,
  onClick,
  small,
  ...props
}: ButtonProps) => (
  <ButtonWrapper
    palette={palette}
    stretch={stretch}
    onClick={onClick}
    type={type}
    outline={outline}
    disabled={disabled}
    small={small}
    {...props}
  >
    {icon && <Icon className="left-icon" size={12} name={icon} />}
    {text && <span className="text">{text}</span>}
    {rightIcon && <Icon className="right-icon" size={12} name={rightIcon} />}
  </ButtonWrapper>
);

type TextButtonProps = {
  icon?: IconName;
  rightIcon?: IconName;
  text?: string | ReactNode;
  stretch?: number;
  outline?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  active?: boolean;
  hidden?: boolean;
};

const TextButton = ({
  stretch,
  icon,
  rightIcon,
  text,
  outline,
  disabled,
  active,
  hidden,
  onClick,
}: TextButtonProps) => (
  <TextButtonWrapper
    palette="default"
    stretch={stretch}
    onClick={onClick}
    type="button"
    outline={outline}
    disabled={disabled}
    active={active}
    hidden={hidden}
  >
    {icon && <Icon className="left-icon" size={12} name={icon} />}
    {text && <span className="text">{text}</span>}
    {rightIcon && <Icon className="right-icon" size={10} name={rightIcon} />}
  </TextButtonWrapper>
);

export { ButtonProps, Button, TextButtonProps, TextButton };
