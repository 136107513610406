import { EditorText } from "@smart/itops-serialisation-basic";

export type DisplayTextProps = {
  element: EditorText;
};

export const DisplayText = ({ element }: DisplayTextProps) => {
  let display: string | JSX.Element = element.text;
  if (element.bold) display = <strong>{display}</strong>;
  if (element.italic) display = <em>{display}</em>;

  return <span>{display}</span>;
};
