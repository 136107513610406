import { useCallback, useState } from "react";

import { v4 } from "@smart/itops-utils-basic";

export const useRefresh = () => {
  const [seed, setSeed] = useState(v4());
  const refreshSeed = useCallback(() => setSeed(v4()), []);

  return [seed, refreshSeed] as const;
};
