export type FontFacesOptions = {
  family: string;
  format: string;
  formats: {
    style: string;
    weight: string;
    path: string;
    local?: string;
    settings?: string;
  }[];
};

export const fontFaces = ({ family, format, formats }: FontFacesOptions) =>
  formats
    .map(
      ({ style, weight, path, local, settings }) => `
@font-face {
  font-family: ${family};
  src: ${local ? `local("${local}"),` : ""} url(${path}) format("${format}");
  font-weight: ${weight};
  font-style: ${style};
  ${settings ? `font-variation-settings: ${settings};` : ""}
}
`,
    )
    .join("\n");
