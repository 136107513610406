import { ZodError } from "zod";

import { FieldError } from "./field";

export type ValidationIssue = {
  code: string;
  fullPath: string;
  path: (string | number)[];
  message: string;
};

export class ValidationError extends Error {
  public action: string;

  public object: any;

  public issues: ValidationIssue[];

  public statusCode: number;

  public displayCode: string;

  constructor(
    error: unknown,
    displayCode: string,
    action: string,
    object: any,
  ) {
    super(`Error ${action}`);
    if (error instanceof ZodError) {
      this.message = `Invalid ${action}`;
      this.issues = error.issues.map((i) => ({
        ...i,
        fullPath: i.path.join("."),
      }));
    } else if (error instanceof FieldError) {
      this.message = `Invalid ${error.fieldName} on ${action}`;
      this.issues = [
        {
          code: "FieldError",
          fullPath: error.fieldName,
          path: [error.fieldName],
          message: error.fieldError,
        },
      ];
    } else if (error instanceof Error) {
      this.message = `${error.name} ${action}`;
      this.issues = [
        {
          code: "Error",
          fullPath: "",
          path: [],
          message: error.message,
        },
      ];
    } else {
      this.issues = [
        {
          code: "Error",
          fullPath: "",
          path: [],
          message: `${error}`,
        },
      ];
    }

    this.displayCode = displayCode;
    this.action = action;
    this.statusCode = action === "request" ? 400 : 500;
    this.object = object;
  }
}
