import {
  buildRegionalName,
  BuiltNameFactory,
  Namespace,
} from "@smart/itops-types-basic";
import { Guard, mapEntries } from "@smart/itops-utils-basic";

import { LambdaDef } from "./lambda";

export type SNSSQSAble = Record<string, any>;

export type SNSSQSDef<
  T extends string,
  E extends SNSSQSAble,
  Q extends boolean,
> = {
  namespace: Namespace<string, T>;
  buildName: BuiltNameFactory;
  guard: Guard<E>;
  maxAttempts?: number;
  visibilityTimeout?: number;
  deliveryDelay?: number;
  sqsOnly: Q;
  groupKey: Q extends true ? undefined : keyof E | undefined;
  idKey: Q extends true ? undefined : keyof E | undefined;
};

export type SNSSQSLambdaDef<
  D extends string,
  E extends string,
  S extends SNSSQSAble,
  Q extends boolean,
> = LambdaDef<D, E> & {
  queue: SNSSQSDef<D, S, Q>;
};

export const buildSNSSQSDef = <
  T extends string,
  E extends SNSSQSAble,
  Q extends boolean,
>(
  def: Omit<SNSSQSDef<T, E, Q>, "buildName"> & { prefix?: string },
): SNSSQSDef<T, E, Q> => {
  const { prefix, ...rest } = def;

  return {
    ...rest,
    buildName: (info) =>
      buildRegionalName({
        parts: def.namespace,
        info,
        prefix,
        suffix: def.groupKey ? ".fifo" : undefined,
      }),
  };
};

export type SNSSQSType<D> = D extends SNSSQSDef<any, infer E, any> ? E : never;

export type SNSSQSQueueOnly<D> =
  D extends SNSSQSDef<any, any, infer Q> ? Q : never;

export type SNSSQSTypes<D> = {
  [k in keyof D]: SNSSQSType<D[k]>;
};

export type SNSSQSHandlersDef<
  E extends string,
  L extends string,
  SD extends { [k in L]: SNSSQSDef<k, any, any> },
  LD extends { [k in L]: LambdaDef<k, E> },
> = {
  envKeys: readonly E[];
  lambdaKeys: L[];
  queueDefs: SD;
  lambdaDefs: LD;
  lambdas: Readonly<{
    [k in L]: SNSSQSLambdaDef<k, E, SNSSQSType<SD[k]>, SNSSQSQueueOnly<SD[k]>>;
  }>;
};

export type SNSSQSDLQHandlerDef<
  D extends string,
  E extends string,
  L extends string,
  SD extends { [k in L]: SNSSQSDef<k, any, any> },
> = {
  envKeys: readonly E[];
  queueDefs: SD;
  lambdaDef: LambdaDef<D, E>;
};

export const buildSNSSQSHandlerDef = <
  D extends string,
  E extends string,
  S extends SNSSQSAble,
  Q extends boolean,
>(
  handler: LambdaDef<D, E>,
  queue: SNSSQSDef<D, S, Q>,
): SNSSQSLambdaDef<D, E, S, Q> => ({
  ...handler,
  queue,
});

export const buildSNSSQSHandlersDef = <
  E extends string,
  L extends string,
  SD extends { [k in L]: SNSSQSDef<k, any, any> },
  LD extends { [k in L]: LambdaDef<k, E> },
>(
  def: Omit<SNSSQSHandlersDef<E, L, SD, LD>, "lambdas">,
): SNSSQSHandlersDef<E, L, SD, LD> => ({
  ...def,
  lambdas: mapEntries(
    def.lambdaDefs,
    (handler, key) =>
      buildSNSSQSHandlerDef(
        handler as LD[L],
        def.queueDefs[key as L],
      ) as SNSSQSLambdaDef<L, E, SNSSQSType<SD[L]>, SNSSQSQueueOnly<SD[L]>>,
  ),
});

export const buildSNSSQSDLQHandlerDef = <
  D extends string,
  E extends string,
  L extends string,
  SD extends { [k in L]: SNSSQSDef<k, any, any> },
>(
  def: SNSSQSDLQHandlerDef<D, E, L, SD>,
) => def;
