import type { RootContent } from "mdast";
import { fromMarkdown } from "mdast-util-from-markdown";
import { Descendant } from "slate";

import { specialChars, isNotNullOrUndefined } from "@smart/itops-utils-basic";

import { EditorElement } from "./elements";
import {
  placeholderAstExtension,
  placeholderSyntaxExtension,
} from "./placeholder";

declare module "mdast" {
  export interface PhrasingContentMap {
    placeholder: EditorElement<"placeholder">;
  }

  export interface RootContentMap {
    placeholder: EditorElement<"placeholder">;
  }
}

const convertChild = (child: RootContent): Descendant | undefined => {
  if (child.type === "link") {
    return {
      type: "link",
      url: child.url,
      children: child.children.map(convertChild).filter(isNotNullOrUndefined),
    };
  }

  if (child.type === "placeholder") {
    return {
      type: "placeholder",
      displayText: child.displayText,
      name: child.name,
      isLink: child.isLink,
      casing: child.casing,
      children: [{ text: "" }],
    };
  }

  if (child.type === "paragraph") {
    if (
      child.children.length === 1 &&
      child.children[0].type === "text" &&
      child.children[0].value === specialChars.nbsp
    ) {
      return { type: "paragraph", children: [{ text: "" }] };
    }

    if (
      child.children.length === 1 &&
      child.children[0].type === "placeholder"
    ) {
      const converted = convertChild(child.children[0]);
      if (converted)
        return {
          type: "paragraph",
          children: [{ text: "" }, converted, { text: "" }],
        };
    }

    return {
      type: "paragraph",
      children: child.children.map(convertChild).filter(isNotNullOrUndefined),
    };
  }

  if (child.type === "text") {
    const text = child.value.replace(
      new RegExp(specialChars.invisible, "g"),
      "",
    );
    if (text.length === 0) return undefined;

    return { text };
  }

  if (child.type === "strong") {
    const converted = convertChild(child.children[0]) || { text: "" };
    return { ...converted, bold: true };
  }

  if (child.type === "emphasis") {
    const converted = convertChild(child.children[0]) || { text: "" };
    return { ...converted, italic: true };
  }

  return { text: "" };
};

export const deserialize = (md?: string): Descendant[] => {
  const parsed = md
    ? fromMarkdown(md, {
        extensions: [placeholderSyntaxExtension],
        mdastExtensions: [placeholderAstExtension],
      }).children
    : [];

  if (!parsed.length) return [{ type: "paragraph", children: [{ text: "" }] }];

  return parsed.map(convertChild).filter(isNotNullOrUndefined);
};
