/* eslint-disable no-underscore-dangle */
import { CompileContext } from "mdast-util-from-markdown";
import { Token } from "micromark-util-types";

import { EditorElement } from "../elements";

export const placeholderAstExtension = {
  enter: {
    placeholder(this: CompileContext, token: Token) {
      const enterItem: EditorElement<"placeholder"> = {
        type: "placeholder",
        name: token._placeholderName || "",
        displayText: token._displayText,
        isLink: token._isLink,
        casing: token._casing,
        children: [],
      };
      this.enter(
        enterItem as unknown as Parameters<CompileContext["enter"]>[0],
        token,
      );
    },
  },
  exit: {
    placeholder(this: CompileContext, token: Token) {
      this.exit(token);
    },
  },
};
