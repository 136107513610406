import styled from "@emotion/styled";

import { FontSizeKey, UITheme } from "../../theme";
import { skeleton } from "../../utils";

export type EditableFontProps = {
  fontSize: FontSizeKey;
  bold: boolean;
};

export const fontProps = (props: EditableFontProps & { theme: UITheme }) => `
  font-size: ${props.theme.fontSize[props.fontSize]};
  font-weight: ${props.bold ? 600 : 400};
  line-height: ${props.theme.fontSize[props.fontSize]};
`;

export const EditableContent = styled.div<
  { background?: boolean } & EditableFontProps
>`
  background-color: ${(props) =>
    props.background ? props.theme.scheme.grey.r15 : "transparent"};
  border-radius: 0.8rem;
  display: inline-grid;
  align-items: center;
  position: relative;
  padding: 0.4rem 0.6rem;
  max-width: 100%;

  ${fontProps}

  input,
  &::after {
    width: auto;
    min-width: 1em;
    grid-area: 1/2;
    font: inherit;
    box-sizing: content-box;
    height: ${(props) => props.theme.fontSize[props.fontSize]};
    padding: 0.6rem 0;
    margin: 0;
    background: none;
    appearance: none;
    outline: none;
    border: none;
  }

  &::after {
    content: attr(data-value) " ";
    visibility: hidden;
    white-space: pre-wrap;
  }
`;

export const TextContent = styled.div`
  padding: 0.4rem 0;
  margin: 0.2rem 0;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Placeholder = styled.div`
  font-style: italic;
  margin: 0.6rem 0;
  opacity: 0.7;
`;

export const FieldQuestion = styled.div<
  EditableFontProps & {
    backgroundOnHover?: boolean;
  }
>`
  display: flex;
  max-width: 100%;
  width: max-content;
  align-items: center;
  transition: background-color 0.2s ease;
  border-radius: 0.8rem;
  cursor: default;
  ${fontProps}

  .pencil {
    margin-left: 0.6rem;
    padding: 0.4rem;
    opacity: 0;
    transition: opacity 0.2s ease;
  }

  &:not([aria-disabled="true"]):hover {
    background-color: ${(props) =>
      props.backgroundOnHover ? props.theme.scheme.grey.r15 : "transparent"};
    cursor: pointer;

    .pencil {
      opacity: 1;
    }
  }
`;

export const FieldLoading = styled(FieldQuestion)`
  ${skeleton(6)}
`;
