import { z } from "zod";

import { buildNamespace } from "@smart/itops-types-basic";
import { buildSlug, SlugOptions } from "@smart/itops-utils-basic";

import { system } from "./base";
import { FormNS } from "./form";
import { globalTeamUri } from "./team";
import { UserNS } from "./user";

export const SlugNS = buildNamespace({
  system,
  entity: "Slug",
} as const);

export const SlugSchema = z.object({
  uri: SlugNS.schema,
  operationId: z.string(),

  active: z.boolean(),
  formUri: FormNS.schema,

  updatedBy: UserNS.schema,
  updatedAt: z.string(),

  deleted: z.boolean().optional(),
  deletedAtSec: z.number().optional(),
});

export const buildTeamFormSlug = buildSlug({
  components: ["teamName", "formTitle"],
  words: { teamName: 1, formTitle: 2 },
});

export const buildGlobalFormSlug = buildSlug({
  components: ["formTitle"],
  words: { formTitle: 3 },
});

export const buildFormSlug = ({
  teamUri,
  teamName,
  formTitle,
  checkExisting,
}: {
  teamName?: string;
  teamUri: string;
  formTitle: string;
  checkExisting: SlugOptions<string>["checkExisting"];
}) =>
  teamUri === globalTeamUri
    ? buildGlobalFormSlug({ checkExisting, values: { formTitle } })
    : buildTeamFormSlug({ checkExisting, values: { formTitle, teamName } });
