import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useReducer,
} from "react";
import { Location, useLocation } from "react-router-dom";

type HistoryState = { index: number; current?: Location; previous?: Location };
type HistoryAction = { location: Location };

const historyReducer = (
  state: HistoryState,
  action: HistoryAction,
): HistoryState => {
  if (window.history?.state) {
    const { idx } = window.history.state;
    if (idx > state.index) {
      return {
        index: idx,
        current: action.location,
        previous: state.current,
      };
    }
  }

  return {
    ...state,
    current: action.location,
  };
};

const HistoryContext = createContext<HistoryState>({ index: -1 });

export const setupHistory = () => {
  const location = useLocation();
  const [history, dispatch] = useReducer(historyReducer, { index: -1 });

  useEffect(() => {
    dispatch({ location });
  }, [location]);

  return history;
};

export const useHistory = () => useContext(HistoryContext);

export type HistoryProviderProps = { children: ReactNode };

export const HistoryProvider = ({ children }: HistoryProviderProps) => {
  const value = setupHistory();

  return (
    <HistoryContext.Provider value={value}>{children}</HistoryContext.Provider>
  );
};
