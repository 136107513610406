import styled from "@emotion/styled";

import { FontSizeKey, PartColors, Variant, variantStyle } from "../../theme";

const standard = styled.button<{
  size?: FontSizeKey;
  partColors?: PartColors;
  variant?: Variant;
  eventCaptureAttributes?: Record<string, string | boolean | undefined>;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.6rem;

  border-radius: 0.8rem;
  border: 2px solid transparent;
  cursor: pointer;
  padding: 1rem 2.4rem;
  font-size: ${(props) =>
    props.size ? props.theme.fontSize[props.size] : "inherit"};
  font-weight: 600;
  ${variantStyle}

  transition: background-color 0.2s, border-color 0.2s, color 0.2s;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const buttonKinds = {
  standard,
  round: styled(standard)`
    border-width: 1px;
    border-radius: 100px;
    padding: 1.4rem 2rem;
  `,
  borderless: styled(standard)`
    border-color: transparent;
    border-radius: 100px;
    padding: 0.6rem;
  `,
  input: styled(standard)`
    border-width: 1px;
    border-radius: 100px;
    padding: 0.4rem;
  `,
} satisfies Record<string, typeof standard>;

export type ButtonKind = keyof typeof buttonKinds;

export const StyledButton = (
  props: Parameters<typeof standard>[0] & {
    kind?: ButtonKind;
  },
) => {
  const Styled = (props.kind && buttonKinds[props.kind]) || standard;
  return <Styled {...props} {...props.eventCaptureAttributes} />;
};
