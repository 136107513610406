import styled from "@emotion/styled";
import { FC } from "react";

import { EditorElement } from "@smart/itops-serialisation-basic";

import { LinkPopup } from "./link";
import { PlaceholderPopup } from "./placeholder";
import { EditorModal, ModalOption } from "../hooks";

export const PopupWrapper = styled.span`
  background: white;
  border-radius: 0.8rem;
  box-shadow:
    0px 2px 4px -2px rgba(0, 0, 0, 0.05),
    0px 4px 6px -1px rgba(0, 0, 0, 0.1);
  padding: 1rem 1.4rem;

  overflow: hidden;
  z-index: 1;
`;

const popups: Record<ModalOption, FC<{ element?: EditorElement }>> = {
  link: LinkPopup,
  placeholder: PlaceholderPopup,
};

export const Popup = ({ option, element }: EditorModal) => {
  const PopupComponent = popups[option];

  return <PopupComponent element={element} />;
};
