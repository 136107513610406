import styled from "@emotion/styled";
import { AutocompleteGetTagProps } from "@mui/base/useAutocomplete";

import { Icon } from "@smart/itops-icons-dom";

import { MultipleTextCount } from "../multiple-text-label";
import { Tooltip } from "../tooltip";

const TagWrapper = styled.div`
  background: ${(props) => props.theme.scheme.grey.r10};
  border: 1px solid ${(props) => props.theme.scheme.grey.r30};
  border-radius: 100px;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
  font-size: 0.875em;
  padding: 0.4rem 1rem;
  margin: 0.2rem;
  white-space: nowrap;
  outline: none;

  &:focus {
    border-color: ${(props) => props.theme.scheme.blue.r40};
    box-shadow: 0 0 0 3px ${(props) => props.theme.scheme.blue.r10};
  }

  .tag-button {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    background: 0;
    padding: 0;
    opacity: 0.6;
  }
`;

const ComboboxTag = ({
  text,
  onDelete,
  ...props
}: { text: string } & ReturnType<AutocompleteGetTagProps>) => (
  <TagWrapper {...props}>
    {text}
    <span
      role="button"
      onClick={onDelete}
      onKeyUp={(e) => {
        if (e.key === "Backspace") onDelete(e);
      }}
      className="tag-button"
      tabIndex={-1}
      data-testid={`tag-button-${text}`}
    >
      <Icon library="lucide" name="X" size={14} stroke={3} />
    </span>
  </TagWrapper>
);

export const ComboboxTags = <V extends any>({
  value,
  maxItems,
  optionLabel,
  getTagProps,
}: {
  value: Array<V>;
  maxItems?: number;
  optionLabel: (option: V) => string;
  getTagProps: AutocompleteGetTagProps;
}) => (
  <>
    {value.slice(0, maxItems).map((option, index) => (
      <ComboboxTag text={optionLabel(option)} {...getTagProps({ index })} />
    ))}
    {maxItems && value.length > maxItems && (
      <Tooltip
        placement="top"
        tooltipText={value.slice(maxItems).map(optionLabel).join(", ")}
      >
        <MultipleTextCount>+{value.length - maxItems}</MultipleTextCount>
      </Tooltip>
    )}
  </>
);
