import { Ref } from "react";

export type BaseIconProps = {
  size?: number;
  stroke?: number;
  rotate?: number;
  className?: string;
  spin?: boolean;
  spinDirection?: "cw" | "ccw";
  transformSVG?: string;
  color?: string;
  ref?: Ref<SVGSVGElement>;
  onClick?: () => void;
  dataTestId?: string;
};

export const defaultSize = 20;
