import { z } from "zod";

import { buildNamespace } from "@smart/itops-types-basic";

import { system } from "./base";
import { SubmissionNS } from "./submission";
import { UserNS } from "./user";

export const TrackerNS = buildNamespace({
  system,
  entity: "Tracker",
} as const);

export const trackerAction = [
  "saved-to-matter",
  "matter-created",
  "submission-created",
  "submission-sent",
  "submission-closed",
  "submission-completed",
  "submission-modified",
  "submission-link-copied",
  "intake-form-opened",
] as const;
export type TrackerActionType = (typeof trackerAction)[number];
export const trackerActionSchema = z.enum(trackerAction);
export const TrackerSchema = z.object({
  uri: TrackerNS.schema,
  operationId: z.string(),

  submissionUri: SubmissionNS.schema,

  method: z.string().optional(),
  sentAt: z.string().optional(),

  userUri: UserNS.schema.optional(),
  action: trackerActionSchema.optional(),
});
