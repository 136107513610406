import styled from "@emotion/styled";

export const Field = styled.div`
  display: flex;
  flex-flow: column;
  gap: 0.4rem;
  margin: 1rem 0;

  label {
    color: ${(props) => props.theme.scheme.grey.r80};
    font-size: ${(props) => props.theme.fontSize.small};
    font-weight: 600;
  }
  input,
  select {
    border: 1px solid ${(props) => props.theme.scheme.grey.r35};
    border-radius: 0.8rem;
    background: white;
    font-size: ${(props) => props.theme.fontSize.base};
    padding: 0.8rem 1.2rem;
    outline: none;
    width: 30rem;
    max-width: 100%;

    &:hover,
    &:focus {
      border-color: ${(props) => props.theme.scheme.blue.r60};
    }

    &:focus {
      box-shadow: 0 0 0 3px ${(props) => props.theme.scheme.blue.r10};
    }
  }
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid ${(props) => props.theme.scheme.grey.r25};
  padding-top: 1rem;
  margin-top: 1.6rem;
`;
