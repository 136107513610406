import { Namespace } from "@smart/itops-types-basic";
import { InfraInfo, isNotNullOrUndefined } from "@smart/itops-utils-basic";

import { ZoneDef } from "./zone";

export type CloudfrontDef<C extends string, Z extends string> = {
  namespace: Namespace<string, C>;
  subdomain: string | undefined;
  fallbackRoute: string | undefined;
  zone: ZoneDef<Z>;
};

export const buildCloudfrontDef = <C extends string, Z extends string>(
  def: CloudfrontDef<C, Z>,
) => def;

export const cdnDomain = (
  { cdn }: { cdn: CloudfrontDef<string, string> },
  regionInfo: InfraInfo,
) =>
  [cdn.subdomain, cdn.zone.domains[regionInfo.env]?.[regionInfo.region]]
    .filter(isNotNullOrUndefined)
    .join(".");

export const cdnSubdomain = (
  {
    cdn,
    subdomain,
    path,
  }: {
    cdn: CloudfrontDef<string, string>;
    subdomain?: string;
    path: string[];
  },
  regionInfo: InfraInfo,
) => {
  const domain = [
    subdomain,
    cdn.zone.domains[regionInfo.env][regionInfo.region],
  ]
    .filter(isNotNullOrUndefined)
    .join(".");
  return `https://${[domain, ...path].join("/")}`;
};
