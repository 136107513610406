import { Namespace } from "@smart/itops-types-basic";
import { InfraInfo, Schema } from "@smart/itops-utils-basic";

import { cdnDomain, CloudfrontDef } from "./cloudfront";
import { LambdaDef } from "./lambda";

export type ApiLambdaMethod = "GET" | "POST";

export type ApiLambdaDef<
  D extends string,
  E extends string,
  A extends boolean,
> = LambdaDef<D, E> & {
  authenticated: A;
  method: ApiLambdaMethod;
};

export type ApiGatewayDef<
  G extends string,
  C extends string,
  E extends string,
  A extends boolean,
  L extends string,
  LD extends Readonly<{
    [k in L]: ApiLambdaDef<k, E, A extends true ? boolean : false>;
  }>,
> = {
  namespace: Namespace<string, G>;
  cdn: CloudfrontDef<C, string>;
  prefix: string;
  authorized: A;
  authorizer: A extends true ? LambdaDef<"authorizer", string> : undefined;
  envKeys: readonly E[];
  lambdaKeys: L[];
  lambdas: LD;
  port: number;
};

export const buildApiGatewayDef = <
  G extends string,
  C extends string,
  E extends string,
  A extends boolean,
  L extends string,
  LD extends Readonly<{
    [k in L]: ApiLambdaDef<k, E, A extends true ? boolean : false>;
  }>,
>(
  def: ApiGatewayDef<G, C, E, A, L, LD>,
) => def;

export type ApiGatewayHandlerPackages<AG> =
  AG extends ApiGatewayDef<string, string, string, infer A, infer L, any>
    ? Record<L | (A extends true ? "authorizer" : never), string>
    : never;

export const buildApiGatewaySchemas = <
  L extends string,
  S extends Record<L, Readonly<{ input: Schema<any>; output: Schema<any> }>>,
>(
  def: ApiGatewayDef<string, string, string, boolean, L, any>,
  schemas: S,
) => schemas;

export const apiGatewayUrl =
  <
    L extends string,
    LD extends Readonly<{
      [k in L]: ApiLambdaDef<k, any, any>;
    }>,
  >(
    api: ApiGatewayDef<string, string, string, boolean, L, LD>,
    infraInfo: InfraInfo,
  ) =>
  (key?: L) => {
    const domain = cdnDomain(api, infraInfo);
    if (!domain) return "";

    return `https://${domain}/${api.prefix}${
      key ? `/${api.lambdas[key].deployable}` : ""
    }`;
  };
