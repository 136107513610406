import { css } from "@emotion/react";

import { SchemeColor, fromSchemeColor } from "./scheme";
import { UITheme } from "./theme";

export type PartColors = {
  background?: SchemeColor;
  border?: SchemeColor;
  color?: SchemeColor;
  decoration?: "underline";
  icon?: SchemeColor;
};

export const partStyle = (props: {
  partColors?: PartColors;
  theme: UITheme;
}) => css`
  background-color: ${fromSchemeColor(
    props.partColors?.background,
    "var(--background)",
  )(props)};
  border-color: ${fromSchemeColor(
    props.partColors?.border,
    "transparent",
  )(props)};
  color: ${fromSchemeColor(props.partColors?.color, "inherit")(props)};
  text-decoration: ${props.partColors?.decoration || "none"};

  svg {
    color: ${fromSchemeColor(props.partColors?.icon, "inherit")(props)};
  }
`;

export const variants = {
  action: {
    background: ["blue", "r10"],
    border: ["blue", "r20"],
    color: ["blue", "r100"],
  },
  cancel: {
    background: ["grey", "r0"],
    border: ["grey", "r40"],
    color: ["grey", "r80"],
  },
  default: {
    background: ["grey", "r10"],
    border: ["grey", "r30"],
    color: ["grey", "r70"],
  },
  error: {
    background: ["red", "r10"],
    border: ["red", "r40"],
    color: ["red", "r100"],
  },
  header: {
    background: ["grey", "r0"],
    border: ["grey", "r30"],
    color: ["grey", "r100"],
    icon: ["blue", "r100"],
  },
  link: {
    color: ["blue", "r100"],
    decoration: "underline",
  },
  navigation: {
    color: ["blue", "r100"],
  },
  plain: {},
  remove: {
    color: ["red", "r100"],
  },
  save: {
    background: ["blue", "r100"],
    border: ["blue", "r100"],
    color: ["grey", "r0"],
  },
  success: {
    background: ["green", "r10"],
    border: ["green", "r40"],
    color: ["green", "r100"],
  },
  brandOrange: {
    background: ["orange", "r100"],
    color: ["grey", "r0"],
  },
  highlightOrange: {
    background: ["orange", "r10"],
    border: ["orange", "r40"],
    color: ["orange", "r100"],
  },
  highlightPurple: {
    background: ["purple", "r10"],
    border: ["purple", "r40"],
    color: ["purple", "r100"],
  },
  highlightYellow: {
    background: ["yellow", "r10"],
    border: ["yellow", "r40"],
    color: ["yellow", "r100"],
  },
} satisfies Record<string, PartColors>;

export type Variant = keyof typeof variants;

export const variantStyle = (props: {
  partColors?: PartColors;
  variant?: Variant;
  theme: UITheme;
}) =>
  partStyle({
    partColors: props.variant ? variants[props.variant] : props.partColors,
    theme: props.theme,
  });
