import styled from "@emotion/styled";

import { IconDetail } from "@smart/itops-icons-dom";

import { Variant } from "../../theme";
import { Button } from "../button";
import { Pill } from "../pill";

const ModalHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.6rem 2rem;
  border-bottom: 1px solid ${(props) => props.theme.scheme.grey.r25};
  gap: 1rem;

  .modal-header-icon {
    padding: 0.8rem;
  }

  .modal-title {
    font-size: ${(props) => props.theme.fontSize.subHeading};
    font-weight: 600;
    flex: 1;
    margin: 0;
  }

  .modal-close {
    color: ${(props) => props.theme.scheme.grey.r50};
  }
`;

export type ModalHeaderProps = {
  icon?: IconDetail & { variant?: Variant };
  text?: string;
  onClose?: () => void;
};

export const ModalHeader = ({ icon, text, onClose }: ModalHeaderProps) => (
  <ModalHeaderWrapper>
    {icon && (
      <Pill className="modal-header-icon" icon={icon} variant={icon.variant} />
    )}
    {text && <h2 className="modal-title">{text}</h2>}
    {onClose && (
      <Button
        className="modal-close"
        icon={{ library: "lucide", name: "X" }}
        kind="borderless"
        aria-label="Close Modal - X"
        variant="plain"
        onClick={() => onClose()}
      />
    )}
  </ModalHeaderWrapper>
);
