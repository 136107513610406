import { z } from "zod";

import { v4 } from "@smart/itops-utils-basic";

export const bytesPerMegaByte = 1048576;

export const uploadedFile = z.object(
  {
    fileName: z.string(),
    downloadUrl: z.string(),
    uploadStatus: z.enum([
      "notUploaded",
      "uploading",
      "uploaded",
      "failedToUpload",
      "exceedSizeLimit",
    ]),
    key: z.string().optional(),
  },
  { required_error: "Please upload a file" },
);

export type UploadedFile = z.infer<typeof uploadedFile>;

export const buildIntakeFileObjectKey = ({
  formUri,
  submissionUri,
  fieldUri,
  fileName,
}: {
  formUri: string;
  submissionUri: string;
  fieldUri: string;
  fileName: string;
}) => `${formUri}/${submissionUri}/${fieldUri}/${v4()}-${fileName}`;
