import { useCallback, useState } from "react";

import { Guard, logError, buildErrorCode } from "@smart/itops-utils-basic";

import { useAsync } from "./async";
import { jsonFetchHelper, FetchProps } from "./fetch";
import { useRefresh } from "./refresh";
import { useStatus, Status } from "./status";

export type GetOptions<A, I, O> = {
  authenticated: A;
  method: "GET";
  input: Guard<I>;
  output: Guard<O>;
};

export type GetProps<I> = {
  required: keyof I;
};

export type GetHook<A, I, O> = (
  props: FetchProps<A, I>,
  options?: GetProps<I>,
) => FetchProps<A, I> & {
  status: Status;
  result?: O;
  error?: string;
  fetch: () => Promise<O>;
  refetch: () => void;
};

export const useGet =
  <A, I, O>(
    url: string,
    { method, authenticated, input, output }: GetOptions<A, I, O>,
  ): GetHook<A, I, O> =>
  (props, options) => {
    const [seed, refetch] = useRefresh();
    const [status, setStatus] = useStatus();
    const [errorCode, setErrorCode] = useState<string>();
    const [result, setResult] = useState<O>();

    const fetch = useCallback(
      () =>
        jsonFetchHelper({ authenticated, method, input, output }, url, props),
      [
        authenticated,
        method,
        input,
        output,
        url,
        JSON.stringify(props.body),
        props.token,
      ],
    );

    useAsync(
      async (info) => {
        if (options?.required && !props.body[options.required]) return;

        try {
          if (info.mounted) setStatus("loading");
          const fetched = await fetch();

          if (info.mounted) {
            setStatus("success");
            setResult(fetched);
          }
        } catch (err) {
          console.warn("Error occurred while getting:", url);
          logError(err);
          if (info.mounted) {
            setStatus("error");
            setErrorCode(buildErrorCode(err));
          }
        }
      },
      [fetch, seed, options?.required],
    );

    return {
      ...props,
      status,
      result,
      errorCode,
      fetch,
      refetch,
    };
  };
