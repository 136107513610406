import styled from "@emotion/styled";

export const TooltipContent = styled.div`
  background: ${(props) => props.theme.scheme.grey.r90};
  border-radius: 5px;
  box-shadow:
    0px 2px 4px -2px rgba(0, 0, 0, 0.05),
    0px 4px 6px -1px rgba(0, 0, 0, 0.1);
  color: ${(props) => props.theme.scheme.grey.r5};
  font-weight: 400;
  line-height: 1.4;
  max-width: min(30rem, 96%);
  padding: ${(props) => props.theme.baseUnit * 0.4}rem
    ${(props) => props.theme.baseUnit * 0.8}rem;
  z-index: 1;

  svg {
    fill: ${(props) => props.theme.scheme.grey.r90};
  }

  p {
    margin: ${(props) => props.theme.baseUnit * 0.4}rem 0;
  }
`;
