import { useEffect, DependencyList } from "react";

export type AsyncInfo = {
  mounted: boolean;
};

export const useAsync = (
  fn: (info: AsyncInfo) => Promise<void>,
  deps?: DependencyList,
) =>
  useEffect(() => {
    const info: AsyncInfo = { mounted: true };
    fn(info).catch((err) => console.error(err));

    return () => {
      info.mounted = false;
    };
  }, deps);
