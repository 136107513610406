import styled from "@emotion/styled";
import { autoUpdate, offset, useFloating } from "@floating-ui/react";
import { useCallback, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Editor, Transforms } from "slate";
import { Editable, RenderPlaceholderProps, Slate } from "slate-react";

import { deserialize, serialize } from "@smart/itops-serialisation-basic";
import { isTestComponent } from "@smart/itops-utils-basic";

import { EditableFontProps, fontProps } from "./elements";
import { FontSizeKey } from "../../theme";
import { Block, Leaf, TestEditable } from "../editor/elements";
import { EditorContextProvider, useEditorState } from "../editor/hooks";
import { Toolbar } from "../editor/toolbar";

const EditableInputWrapper = styled.div`
  .editable {
    outline: none;

    .button-text {
      caret-color: transparent;
    }
  }
`;

const EditableText = styled.div<
  {
    background?: boolean;
    isPreview: boolean;
    isEditor: boolean;
  } & EditableFontProps
>`
  padding: ${(props) => (props.isPreview ? 0 : "0.4rem 0.6rem")};
  background-color: ${(props) =>
    props.background ? props.theme.scheme.grey.r15 : "transparent"};
  border-radius: 0.8rem;
  display: inline-grid;
  align-items: center;
  position: relative;
  padding: 0.4rem 0.6rem;
  max-width: 100%;
  min-width: ${(props) => (props.isEditor ? "10rem" : "auto")};

  ${fontProps}
`;

type EditableInputProps = {
  text?: string;
  dataTestId?: string;
  disabled?: boolean;
  onChange?: (v: string) => void;
  onSubmit?: () => Promise<void>;
  backgroundOnHover?: boolean;
  fontSize: FontSizeKey;
  bold?: boolean;
  isEditor?: boolean;
  placeholder?: string;
  isPreview?: boolean;
};

export const EditableInput = ({
  text,
  dataTestId,
  disabled,
  onChange: onValueChange,
  onSubmit,
  fontSize,
  bold,
  backgroundOnHover,
  isEditor,
  placeholder,
  isPreview,
}: EditableInputProps) => {
  const [isTextSelected, setIsTextSelected] = useState(false);

  const { editor, initialValue, onChange, modal, setModal, onKeyDown } =
    useEditorState({
      initialTextValue: text,
      onValueChange,
      autoFocus: true,
    });

  const handleSelect = () => {
    setIsTextSelected(!!window.getSelection()?.toString().length);
  };

  const popup = useFloating({
    placement: "top-start",
    open: isTextSelected || !!modal,
    middleware: [offset(5)],
    whileElementsMounted: autoUpdate,
  });

  const updateEditorState = useCallback(() => {
    const currentContent = serialize(editor.children);
    if (currentContent !== text) {
      Transforms.deselect(editor);

      Editor.withoutNormalizing(editor, () => {
        editor.children = deserialize(text);
        editor.history = { undos: [], redos: [] };
      });

      Transforms.select(editor, Editor.end(editor, []));
    }
  }, [text, editor]);

  useEffect(() => {
    updateEditorState();
  }, [updateEditorState]);

  const renderPlaceholderText = ({ children }: RenderPlaceholderProps) => (
    <span
      data-slate-placeholder
      style={{
        opacity: 0.7,
        fontStyle: "italic",
      }}
    >
      {children}
    </span>
  );

  return (
    <EditableInputWrapper
      onBlur={async () => {
        if (!modal && onSubmit && !isTestComponent()) await onSubmit();
      }}
      ref={popup.refs.setReference}
      onMouseDown={() => window.getSelection()?.removeAllRanges()}
    >
      <Slate
        editor={editor}
        initialValue={initialValue}
        onValueChange={onChange}
      >
        <EditorContextProvider
          value={{
            modal,
            setModal,
          }}
        >
          {(isTextSelected || !!modal) &&
            isEditor &&
            ReactDOM.createPortal(
              <div ref={popup.refs.setFloating} style={popup.floatingStyles}>
                <Toolbar />
              </div>,
              document.querySelector('[data-testid="edit-questions"]') ||
                document.body,
            )}
          <EditableText
            background={backgroundOnHover}
            data-value={text}
            fontSize={fontSize}
            bold={!!bold}
            isPreview={!!isPreview}
            isEditor={!!isEditor}
          >
            <Editable
              className="editable"
              data-testid={`${dataTestId || "editable-input"}-editable`}
              readOnly={disabled}
              renderElement={(props) => (
                <Block
                  {...props}
                  readOnly={!!disabled}
                  isLinkEnabled={isPreview}
                />
              )}
              renderLeaf={Leaf}
              renderPlaceholder={renderPlaceholderText}
              placeholder={placeholder}
              onMouseUp={handleSelect}
              onKeyUp={handleSelect}
              onKeyDown={async (e) => {
                onKeyDown(e);
                if (!onSubmit) return;
                if (
                  e.key === "Enter" &&
                  (!isEditor || (!e.shiftKey && !e.ctrlKey && !e.altKey))
                ) {
                  await onSubmit();
                }
              }}
              disabled={disabled}
              spellCheck
              autoFocus
            />
            {!!isTestComponent() && (
              <TestEditable
                placeholder={placeholder}
                disabled={disabled}
                dataTestId={`${dataTestId || "editable-input"}-textarea`}
                value={text}
                onKeyDown={async (e) => {
                  onKeyDown(e);
                  if (!onSubmit) return;
                  if (
                    e.key === "Enter" &&
                    (!isEditor || (!e.shiftKey && !e.ctrlKey && !e.altKey))
                  ) {
                    await onSubmit();
                  }
                }}
              />
            )}
          </EditableText>
        </EditorContextProvider>
      </Slate>
    </EditableInputWrapper>
  );
};
