import { AWSError } from "./aws";
import { FetchError } from "./fetch";
import { GQLError } from "./gql";
import { LocalAWSError } from "./local-aws";
import { ProcessorError } from "./processor";
import { SDKError } from "./sdk";
import { ValidationError } from "./validation";

export const buildLogProps = (error: unknown) => {
  let errorProps;
  let stack;
  let logMessage;
  let secretProps;
  let statusCode = 500;

  if (error instanceof ProcessorError) {
    errorProps = {
      message: error.message,
      step: error.step,
      args: error.args,
      ...(error.processError
        ? {
            action: error.processError.action,
            issues: error.processError.issues,
            displayCode: error.processError.displayCode,
            object: error.processError.object,
          }
        : {}),
    };
    stack = error.stack;
    logMessage = "Processor error occurred";
  } else if (error instanceof ValidationError) {
    errorProps = {
      message: error.message,
      action: error.action,
      issues: error.issues,
      displayCode: error.displayCode,
      object: error.object,
    };
    stack = error.stack;
    statusCode = error.statusCode;
    logMessage = "Validation error occurred";
  } else if (error instanceof FetchError) {
    errorProps = {
      name: error.name,
      message: error.message,
      action: error.action,
      url: error.url,
      input: error.input,
      body: error.body,
    };
    stack = error.stack;
    statusCode = error.statusCode || 500;
    logMessage = "Fetch error occurred";
  } else if (error instanceof SDKError) {
    errorProps = {
      name: error.name,
      message: error.message,
      action: error.action,
      input: error.input,
    };
    stack = error.stack;
    logMessage = "SDK error occurred";
  } else if (error instanceof AWSError) {
    secretProps = { params: error.params };
    errorProps = {
      message: error.message,
      service: error.service,
      operation: error.operation,
      code: error.code,
    };
    stack = [error.params, error.stack, error.awsStack].join("\n");
    statusCode = error.statusCode || 500;
    logMessage = "AWS error occurred";
  } else if (error instanceof LocalAWSError) {
    errorProps = {
      __type: error.type,
      message: error.message,
    };
    stack = error.stack;
    statusCode = error.statusCode;
    logMessage = "Local AWS Error occurred";
  } else if (error instanceof GQLError) {
    errorProps = {
      message: error.message,
      gqlErrors: error.gqlErrors,
    };
    stack = error.stack;
    statusCode = error.statusCode;
    logMessage = "GQL Error occurred";
  } else if (error instanceof Error) {
    errorProps = {
      message: error.message,
    };
    stack = error.stack;
    logMessage = "Error occurred";
  } else {
    errorProps = {
      message: `${error}`,
    };
    logMessage = "Unknown error occurred";
  }

  return { errorProps, secretProps, stack, logMessage, statusCode };
};

export type LogProps = ReturnType<typeof buildLogProps>;

export const logError = (error: unknown, parent?: any) => {
  const { errorProps, secretProps, stack, logMessage, statusCode } =
    buildLogProps(error);

  console.error(
    JSON.stringify({
      ...errorProps,
      ...secretProps,
      stack,
      object: JSON.stringify(errorProps.object),
      parent: typeof parent === "string" ? parent : JSON.stringify(parent),
      reason: errorProps.message,
      message: logMessage,
    }),
  );

  return {
    errorProps,
    stack,
    logMessage,
    statusCode,
  };
};
