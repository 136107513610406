import styled from "@emotion/styled";
import { ReactNode } from "react";

import { ProgressOverlay } from "./progress";

const NavWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0.6rem 2rem;
  --background: ${(props) => props.theme.scheme.grey.r0};
  background: var(--background);
  border-bottom: 1px solid ${(props) => props.theme.scheme.grey.r25};
  z-index: 1;
  min-height: 5rem;
`;

const HeadingWrapper = styled.div`
  --background: ${(props) => props.theme.scheme.grey.r0};
  background: var(--background);

  display: flex;
  align-items: center;
  padding: 1rem 2rem;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.15);
  position: relative;
  z-index: 2;

  .heading-left,
  .heading-right {
    display: flex;
    flex: 1 1;
    width: 30rem;
  }

  .heading-left {
    justify-content: flex-start;
  }
  .heading-right {
    justify-content: flex-end;
  }

  .heading-center {
    display: flex;
    flex: 1;
    justify-content: center;
  }
`;

type HeadingProps = {
  nav?: ReactNode;
  left?: ReactNode;
  center?: ReactNode;
  right?: ReactNode;
  loading?: boolean;
  className?: string;
};

const Heading = ({
  nav,
  left,
  center,
  right,
  loading,
  className,
}: HeadingProps) => (
  <div className={className}>
    {nav && <NavWrapper>{nav}</NavWrapper>}
    <HeadingWrapper className="heading-wrapper">
      <div className="heading-left">{left}</div>
      {center && <div className="heading-center">{center}</div>}
      <div className="heading-right">{right}</div>
      {loading && (
        <ProgressOverlay position="bottom" size={0.3} indeterminate />
      )}
    </HeadingWrapper>
  </div>
);

export { Heading, HeadingProps };
