import {
  buildRegionalName,
  BuiltNameFactory,
  Namespace,
} from "@smart/itops-types-basic";

import { CloudfrontDef } from "./cloudfront";

export type AssetBucketDef<B extends string, C extends string | false> = {
  namespace: Namespace<string, B>;
  buildName: BuiltNameFactory;
  cdn: C extends string ? CloudfrontDef<C, string> : C;
  prefix: C extends string ? string : undefined;
  lifecycleRules?: {
    expirationDays: number;
    tagFilters?: Record<string, string>;
  }[];
};

export const buildAssetBucketDef = <B extends string, C extends string | false>(
  def: Omit<AssetBucketDef<B, C>, "buildName">,
): AssetBucketDef<B, C> => ({
  ...def,
  buildName: (info) => buildRegionalName({ parts: def.namespace, info }),
});

export type AppBucketDef<
  B extends string,
  C extends string,
  P extends string,
> = {
  namespace: Namespace<string, B>;
  cdn: CloudfrontDef<C, string>;
  prefix: string | undefined;
  pkg: P;
  port: number;
};

export const buildAppBucketDef = <
  B extends string,
  C extends string,
  P extends string,
>(
  def: AppBucketDef<B, C, P>,
) => def;
