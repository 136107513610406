export const add =
  "M480 277l-224 0 0 224c0 6-4 11-10 11 0 0 0 0 0 0-6 0-11-5-11-11l0-224-224 0c-6 0-11-4-11-10 0-6 5-11 11-11l224 0 0-224c0-6 4-11 10-11 6 0 11 5 11 11l0 224 224 0c6 0 11 5 11 11 0 6-5 10-11 10z";
export const addCircle =
  "M245 512c-135 0-245-110-245-245 0-65 25-127 72-173 46-47 107-73 173-73 0 0 0 0 0 0 135 0 245 110 246 245 0 135-110 246-246 246z m0-469l0-11 0 11c-124 0-224 101-224 224 1 123 101 224 225 224 123-1 224-101 223-225 0-123-100-223-224-223z m128 234l-117 0 0 118c0 6-5 10-11 10-6 0-10-4-10-10l0-118-120 0c0 0 0 0 0 0-6 0-10-4-10-10 0-6 4-11 10-11l120 0 0-117c0-6 4-11 10-11 6 0 11 5 11 11l0 117 117 0c6 0 11 5 11 10 0 6-5 11-11 11z";
export const addHollow =
  "M501 341l-160 0 0 160c0 6-4 11-10 11l-150 0c0 0 0 0 0 0-2 0-5-1-7-3-2-2-3-5-3-8l0-160-160 0c-6 0-11-4-11-10l0-150c0-6 5-10 11-10l160 0 0-160c0-6 4-11 10-11l150 0c6 0 10 5 10 11l0 160 160 0c6 0 11 4 11 10l0 150c0 6-5 10-11 10z m-10-149l-160 0c-6 0-11-5-11-11l0-160-128 0 0 160c0 6-5 11-11 11l-160 0 0 128 160 0c6 0 11 5 11 11l0 160 128 0 0-160c0-6 5-11 11-11l160 0z";
export const alert =
  "M235 317l0-163c0-6 4-11 10-11 6 0 11 5 11 11l0 163c0 6-5 10-11 10-6 0-10-4-10-10z m31-235c0-11-9-20-21-20-11 0-20 9-20 20 0 11 9 20 20 20 12 0 21-9 21-20z m223-65l-234 468c-4 7-16 7-19 0l-235-470c-2-3-1-7 1-10 2-3 5-5 9-5l469 0c0 0 0 0 0 0 6 0 11 5 11 11 0 2-1 4-2 6z m-461 4l217 435 218-435z";
export const angleDown =
  "M508 404c-4 4-11 4-15-1l-237-271-237 271c-4 5-11 5-15 1-5-4-5-10-1-15l245-280c2-3 5-4 8-4 3 0 6 1 8 4l245 280c4 5 4 11-1 15z";
export const angleLeft =
  "M403 19l-271 237 271 237c5 4 5 11 1 15-4 5-10 5-15 1l-280-245c-3-2-4-5-4-8 0-3 1-6 4-8l280-245c2-2 5-3 7-3 3 0 6 1 8 4 4 4 4 11-1 15z";
export const angleRight =
  "M403 264l-280 245c-5 4-11 4-15-1-4-4-4-11 1-15l271-237-271-237c-5-4-5-11-1-15 2-3 5-4 8-4 2 0 5 1 7 3l280 245c3 2 4 5 4 8 0 3-1 6-4 8z";
export const angleRightBold =
  "M430 264l-287 245c-2 2-4 3-7 3-3 0-6-2-8-4l-47-55c-4-4-3-11 1-15l213-182-213-182c-4-4-5-11-1-15l47-55c2-3 5-4 8-4 3 0 5 1 7 3l287 245c2 2 3 5 3 8 0 3-1 6-3 8z";
export const angleUp =
  "M509 123l-245 280c-4 5-12 5-16 0l-245-280c-4-5-4-11 1-15 4-4 11-4 15 1l237 271 237-271c2-3 5-4 8-4 3 0 5 1 7 3 5 4 5 10 1 15z";
export const arrowLeft =
  "M491 277l-415 0 216 198c8 8 9 21 1 30-8 9-21 9-30 1l-256-234c0 0 0-1 0-1-1 0-2-1-2-2 0 0-1 0-1-1-1-1-1-2-2-2 0-1 0-1 0-2-1-1-1-2-1-3 0 0 0 0-1-1 0-1 0-2 0-4 0-2 0-3 0-4 1-1 1-1 1-1 0-1 0-2 1-3 0-1 0-1 0-2 1 0 1-1 2-2 0-1 1-1 1-1 0-1 1-2 2-2 0 0 0-1 0-1l256-234c4-4 9-6 14-6 6 0 12 2 16 7 8 9 7 22-1 30l-216 198 415 0c11 0 21 9 21 21 0 12-10 21-21 21z";
export const arrowRight =
  "M512 260c-1 1-1 1-1 1 0 1 0 2-1 3 0 1 0 1 0 2-1 0-1 1-2 2 0 1-1 1-1 1 0 1-1 2-2 2 0 0 0 1 0 1l-256 234c-9 8-22 8-30-1-8-9-7-22 1-30l216-198-415 0c-11 0-21-9-21-21 0-12 10-21 21-21l415 0-216-198c-8-8-9-21-1-30 4-5 10-7 16-7 5 0 10 2 14 6l256 234c0 0 0 0 0 1 1 0 2 1 2 2 0 0 1 0 1 1 1 1 1 2 2 2 0 1 0 1 0 2 1 1 1 2 1 3 0 0 0 0 1 1 0 1 0 2 0 4 0 2 0 3 0 4z";
export const bin =
  "M491 448l-139 0 0 53c0 6-5 11-11 11l-170 0c-6 0-11-5-11-11l0-53-139 0c-6 0-10-5-10-11 0-6 4-10 10-10l54 0 0-416c0-6 4-11 10-11l342 0c6 0 10 5 10 11l0 416 54 0c6 0 10 4 10 10 0 6-4 11-10 11z m-310 43l150 0 0-43-150 0z m235-470l-320 0 0 406 320 0z m-245 342c-6 0-11-5-11-11l0-245c0-6 5-11 11-11 6 0 10 5 10 11l0 245c0 6-4 11-10 11z m85 0c-6 0-11-5-11-11l0-245c0-6 5-11 11-11 6 0 11 5 11 11l0 245c0 6-5 11-11 11z m75-11l0-245c0-6 4-11 10-11 6 0 11 5 11 11l0 245c0 6-5 11-11 11-6 0-10-5-10-11z";
export const bold =
  "M377 278c30 26 50 64 50 106 0 74-59 128-139 128l-203 0c-11 0-21-10-21-21 0-12 10-22 21-22l43 0 0-426-43 0c-11 0-21-10-21-22 0-11 10-21 21-21l235 0c82 0 149 67 149 149 0 60-37 109-92 129z m-206 191l117 0c57 0 96-35 96-85 0-53-43-96-96-96l-117 0z m149-426l-149 0 0 202 149 0c62 0 107-40 107-96 0-58-48-106-107-106z";
export const book =
  "M309 405l-128 0c-6 0-10-4-10-10l0-86c0-6 4-10 10-10l128 0c6 0 11 4 11 10l0 86c0 6-5 10-11 10z m-10-85l-107 0 0 64 107 0z m160 128l-54 0 0 32c0 6-4 11-10 11l-320 0c-6 0-11-5-11-11l0-53-11 0c-6 0-10-5-10-11 0-6 4-11 10-11l11 0 0-42-11 0c-6 0-10-5-10-11 0-6 4-11 10-11l11 0 0-42-11 0c-6 0-10-5-10-11 0-6 4-11 10-11l11 0 0-42-11 0c-6 0-10-5-10-11 0-6 4-11 10-11l11 0 0-42-11 0c-6 0-10-5-10-11 0-6 4-11 10-11l11 0 0-42-11 0c-6 0-10-5-10-11 0-6 4-11 10-11l11 0 0-53c0-6 5-11 11-11l320 0c6 0 10 5 10 11l0 53 54 0c6 0 10 5 10 11l0 341c0 6-4 11-10 11z m-11-171l-43 0 0 64 43 0z m-43-21l43 0 0-64-43 0z m43 171l0-64-43 0 0 64z m-64-384l-299 0 0 42 11 0c6 0 11 5 11 11 0 6-5 11-11 11l-11 0 0 42 11 0c6 0 11 5 11 11 0 6-5 11-11 11l-11 0 0 42 11 0c6 0 11 5 11 11 0 6-5 11-11 11l-11 0 0 42 11 0c6 0 11 5 11 11 0 6-5 11-11 11l-11 0 0 42 11 0c6 0 11 5 11 11 0 6-5 11-11 11l-11 0 0 42 11 0c6 0 11 5 11 11 0 6-5 11-11 11l-11 0 0 42 299 0z m64 64l-43 0 0 64 43 0z";
export const brokenLink =
  "M131 407c2-2 5-3 7-3 3 0 6 1 8 3 4 4 4 11 0 15l-43 43c-4 4-11 4-15 0-4-5-4-11 0-15z m71 20c6 0 10 4 10 10l0 54c0 6-4 10-10 10-6 0-11-4-11-10l0-54c0-6 5-10 11-10z m-139-86l53 0c6 0 11 5 11 11 0 6-5 11-11 11l-53 0c-6 0-11-5-11-11 0-6 5-11 11-11z m164-218l-73-72c-25-25-65-25-90 0l-23 22c-25 25-25 66 0 91l73 72c4 5 4 11 0 15-5 5-11 5-15 0l-73-72c-33-33-33-88 0-121l23-22c16-17 38-25 60-25 22 0 44 8 60 25l73 72c4 4 4 11 0 15-4 4-11 4-15 0z m199 240l-96 0c-6 0-11-5-11-11 0-6 5-11 11-11l96 0c34 0 64-30 64-65l0-43c0-34-29-62-65-62l-95 0c-6 0-11-5-11-11 0-6 5-11 11-11l95 0c48 0 86 38 86 84l0 43c0 47-39 87-85 87z";
export const building =
  "M448 459c0 1 0 2-1 3 0 0 0 1 0 1-1 1-1 2-2 3 0 0 0 0 0 0l-43 43c-2 2-4 3-7 3l-278 0c-3 0-5-1-7-3l-43-43c0 0 0 0 0 0-1-1-1-2-2-3 0 0 0-1 0-1-1-1-1-2-1-3 0 0 0 0 0 0l0-448c0-6 5-11 11-11l362 0c6 0 11 5 11 11l0 448c0 0 0 0 0 0z m-326 32l268 0 22-22-312 0z m113-470l0 64 42 0 0-64z m192 0l-128 0 0 75c0 6-5 11-11 11l-64 0c-6 0-11-5-11-11l0-75-128 0 0 427 342 0z m-203 107l64 0c6 0 11 5 11 11l0 64c0 6-5 10-11 10l-64 0c-6 0-11-4-11-10l0-64c0-6 5-11 11-11z m11 64l42 0 0-43-42 0z m96-64l64 0c6 0 10 5 10 11l0 64c0 6-4 10-10 10l-64 0c-6 0-11-4-11-10l0-64c0-6 5-11 11-11z m10 64l43 0 0-43-43 0z m-224-64l64 0c6 0 11 5 11 11l0 64c0 6-5 10-11 10l-64 0c-6 0-10-4-10-10l0-64c0-6 4-11 10-11z m11 64l43 0 0-43-43 0z m96 43l64 0c6 0 11 4 11 10l0 64c0 6-5 11-11 11l-64 0c-6 0-11-5-11-11l0-64c0-6 5-10 11-10z m11 64l42 0 0-43-42 0z m96-64l64 0c6 0 10 4 10 10l0 64c0 6-4 11-10 11l-64 0c-6 0-11-5-11-11l0-64c0-6 5-10 11-10z m10 64l43 0 0-43-43 0z m-224-64l64 0c6 0 11 4 11 10l0 64c0 6-5 11-11 11l-64 0c-6 0-10-5-10-11l0-64c0-6 4-10 10-10z m11 64l43 0 0-43-43 0z m96 42l64 0c6 0 11 5 11 11l0 64c0 6-5 11-11 11l-64 0c-6 0-11-5-11-11l0-64c0-6 5-11 11-11z m11 64l42 0 0-42-42 0z m96-64l64 0c6 0 10 5 10 11l0 64c0 6-4 11-10 11l-64 0c-6 0-11-5-11-11l0-64c0-6 5-11 11-11z m10 64l43 0 0-42-43 0z m-224-64l64 0c6 0 11 5 11 11l0 64c0 6-5 11-11 11l-64 0c-6 0-10-5-10-11l0-64c0-6 4-11 10-11z m11 64l43 0 0-42-43 0z";
export const businessChart =
  "M501 21l-10 0 0 310c0 6-5 10-11 10l-64 0c-6 0-11-4-11-10l0-310-42 0 0 160c0 6-5 11-11 11l-64 0c-6 0-11-5-11-11l0-160-42 0 0 203c0 6-5 11-11 11l-64 0c-6 0-11-5-11-11l0-203-42 0 0 96c0 6-5 11-11 11l-64 0c-6 0-11-5-11-11l0-96-10 0c-6 0-11-4-11-10 0-6 5-11 11-11l490 0c6 0 11 5 11 11 0 6-5 10-11 10z m-458 0l0 86 42 0 0-86z m128 0l0 192 42 0 0-192z m128 0l0 150 42 0 0-150z m128 0l0 299 42 0 0-299z";
export const calendar =
  "M501 469l-74 0 0 32c0 6-5 11-11 11l-64 0c-6 0-11-5-11-11l0-32-170 0 0 32c0 6-5 11-11 11l-64 0c-6 0-11-5-11-11l0-32-74 0c-6 0-11-4-11-10l0-448c0-6 5-11 11-11l490 0c6 0 11 5 11 11l0 448c0 6-5 10-11 10z m-138 22l42 0 0-64-42 0z m-256 0l42 0 0-64-42 0z m-22-43l0-32c0-6 5-11 11-11l64 0c6 0 11 5 11 11l0 32 170 0 0-32c0-6 5-11 11-11l64 0c6 0 11 5 11 11l0 32 64 0 0-85-470 0 0 85z m-64-427l0 320 470 0 0-320z m438 235c6 0 10 5 10 11 0 6-4 10-10 10l-75 0 0 32c0 6-5 11-11 11-6 0-10-5-10-11l0-32-96 0 0 32c0 6-5 11-11 11-6 0-11-5-11-11l0-32-96 0 0 32c0 6-4 11-10 11-6 0-11-5-11-11l0-32-75 0c-6 0-10-4-10-10 0-6 4-11 10-11l75 0 0-64-75 0c-6 0-10-5-10-11 0-6 4-10 10-10l75 0 0-64-75 0c-6 0-10-5-10-11 0-6 4-11 10-11l75 0 0-32c0-6 5-10 11-10 6 0 10 4 10 10l0 32 96 0 0-32c0-6 5-10 11-10 6 0 11 4 11 10l0 32 96 0 0-32c0-6 4-10 10-10 6 0 11 4 11 10l0 32 75 0c6 0 10 5 10 11 0 6-4 11-10 11l-75 0 0 64 75 0c6 0 10 4 10 10 0 6-4 11-10 11l-75 0 0 64z m-310 0l96 0 0-64-96 0z m0-149l0 64 96 0 0-64z m214 0l-96 0 0 64 96 0z m0 85l-96 0 0 64 96 0z";
export const cancel =
  "M286 256l220 220c8 8 8 21 0 30-9 8-22 8-30 0l-220-219-219 219c-8 9-22 9-30 0-9-8-9-22 0-30l219-220-220-219c-8-8-8-21 0-30 4-4 10-6 15-6 6 0 11 2 15 6l220 219 220-220c4-4 9-6 15-6 5 0 11 2 15 6 8 9 8 22 0 30z";
export const cancelCircle =
  "M490 256c0 129-105 234-234 234-129 0-234-105-234-234 0-129 105-234 234-234 129 0 234 105 234 234z m22 0c0-141-115-256-256-256-141 0-256 115-256 256 0 141 115 256 256 256 141 0 256-115 256-256z m-169 102c4 5 11 5 15 0 5-4 5-11 0-15l-189-189c-4-5-11-5-15 0-5 4-5 11 0 15z m15-189c5-4 5-11 0-15-4-5-11-5-15 0l-189 189c-5 4-5 11 0 15 4 5 11 5 15 0z";
export const check =
  "M509 360l-75 74c-4 4-11 4-15 0l-259-259-67 67c-4 4-11 4-15 0l-75-74c-4-5-4-11 0-16l149-149c3-2 5-3 8-3 3 0 5 1 8 3l341 341c4 5 4 11 0 16z m-349-334l-134 134 59 60 67-68c5-4 11-4 16 0l259 260 59-60z";
export const checkCircle =
  "M355 338l-152-142-46 46c-4 4-11 4-15 0-4-4-4-11 0-15l53-53c2-2 5-3 8-3 2 0 5 1 7 3l160 149c4 4 4 11 0 15-4 4-10 4-15 0z m-99 174c-141 0-256-115-256-256 0-141 115-256 256-256 141 0 256 115 256 256 0 141-115 256-256 256z m0-491c-129 0-235 106-235 235 0 129 106 235 235 235 129 0 235-106 235-235 0-129-106-235-235-235z";
export const checkCircleFilled =
  "M512 256c0-141-115-256-256-256-141 0-256 115-256 256 0 141 115 256 256 256 141 0 256-115 256-256z m-136 115l-189-172-50 49c-11 11-29 11-40 0-11-11-11-28 0-38l70-68c10-11 27-11 38-1l209 190c11 11 12 28 1 39-10 11-28 11-39 1z";
export const clock =
  "M256 512c-141 0-256-115-256-256 0-141 115-256 256-256 141 0 256 115 256 256 0 141-115 256-256 256z m0-491c-129 0-235 106-235 235 0 129 106 235 235 235 129 0 235-106 235-235 0-129-106-235-235-235z m0 240l0 112c0 6-5 11-11 11-6 0-10-5-10-11l0-117c0-3 1-6 3-8l128-117c2-2 5-3 7-3 3 0 6 1 8 3 4 5 4 12 0 16z";
export const cog =
  "M424 164l43-44c4-4 4-11 0-15l-60-60c-4-4-11-4-15 0l-44 43c-12-6-28-11-49-16l0-61c0-6-5-11-11-11l-64 0c-6 0-11 5-11 11l0 61c-21 5-37 10-49 16l-44-43c-4-4-11-4-15 0l-60 60c-4 4-4 11 0 15l43 44c-6 12-11 28-16 49l-61 0c-6 0-11 5-11 11l0 64c0 6 5 11 11 11l61 0c5 21 10 37 16 49l-43 44c-4 4-4 11 0 15l60 60c4 4 11 4 15 0l44-43c12 6 28 11 49 16l0 61c0 6 5 11 11 11l64 0c6 0 11-5 11-11l0-61c21-5 37-10 49-16l44 43c4 4 11 4 15 0l60-60c4-4 4-11 0-15l-43-44c6-12 11-28 16-49l61 0c6 0 11-5 11-11l0-64c0-6-5-11-11-11l-61 0c-5-21-10-37-16-49z m-21-10c-3 4-4 9-1 13 7 12 13 31 20 60 1 4 5 8 10 8l59 0 0 42-59 0c-5 0-9 4-10 8-7 28-13 48-20 60-3 4-2 9 1 13l42 41-46 46-41-42c-4-3-9-4-13-1-12 7-32 13-59 20-5 1-9 5-9 10l0 59-42 0 0-59c0-5-4-9-9-10-27-7-47-13-59-20-4-3-9-2-13 1l-41 42-46-46 42-41c3-4 4-9 1-13-7-12-13-31-20-60-1-4-5-8-10-8l-59 0 0-42 59 0c5 0 9-4 10-8 7-29 13-48 20-60 3-4 2-9-1-13l-42-41 46-46 41 42c4 3 9 4 13 1 12-7 31-13 60-20 4-1 8-5 8-10l0-59 42 0 0 59c0 5 4 9 8 10 29 7 48 13 60 20 4 3 9 2 13-1l41-42 46 46z m-62 102c0 47-38 85-85 85-47 0-85-38-85-85 0-47 38-85 85-85 47 0 85 38 85 85z m22 0c0-59-48-107-107-107-59 0-107 48-107 107 0 59 48 107 107 107 59 0 107-48 107-107z";
export const contextMenu =
  "M256 395c-29 0-53 24-53 53 0 29 24 53 53 53 29 0 53-24 53-53 0-29-24-53-53-53z m0-192c-29 0-53 24-53 53 0 29 24 53 53 53 29 0 53-24 53-53 0-29-24-53-53-53z m0-192c-29 0-53 24-53 53 0 29 24 53 53 53 29 0 53-24 53-53 0-29-24-53-53-53z";
export const copy =
  "M491 363l-118 0c-6 0-10 4-10 10 0 6 4 11 10 11l128 0c6 0 11-5 11-11l0-362c0-6-5-11-11-11l-362 0c-6 0-11 5-11 11l0 128c0 6 5 10 11 10 6 0 10-4 10-10l0-118 342 0z m-480-235c-6 0-11 5-11 11l0 362c0 6 5 11 11 11l362 0c6 0 11-5 11-11l0-362c0-6-5-11-11-11z m10 21l342 0 0 342-342 0z";
export const database =
  "M256 491c-120 0-213-43-213-96l0-278c0-54 93-96 213-96 120 0 213 42 213 96l0 278c0 53-93 96-213 96z m192-278c0-44-101-74-192-74-91 0-192 30-192 74l0 54c34-32 106-54 192-54 86 0 158 22 192 54z m0 96c0-44-101-74-192-74-91 0-192 30-192 74l0 43c34-32 106-53 192-53 86 0 158 21 192 53z m-192 160c91 0 192-30 192-74 0-44-101-75-192-75-91 0-192 31-192 75 0 44 101 74 192 74z m0-426c-91 0-192 30-192 74l0 54c34-32 106-54 192-54 86 0 158 22 192 54l0-54c0-44-101-74-192-74z";
export const document =
  "M447 377c0 2-1 3-2 4l-128 128c-1 1-2 2-4 2-1 1-2 1-4 1l-234 0c-6 0-11-5-11-11l0-490c0-6 5-11 11-11l362 0c6 0 11 5 11 11l0 362c0 2 0 3-1 4z m-127 99l92-92-92 0z m-235-455l0 470 214 0 0-118c0-6 4-10 10-10l118 0 0-342z m75 320l96 0c6 0 11 5 11 11 0 6-5 11-11 11l-96 0c-6 0-11-5-11-11 0-6 5-11 11-11z m192-42l-192 0c-6 0-11-5-11-11 0-6 5-11 11-11l192 0c6 0 11 5 11 11 0 6-5 11-11 11z m0-64l-192 0c-6 0-11-5-11-11 0-6 5-11 11-11l192 0c6 0 11 5 11 11 0 6-5 11-11 11z m0-64l-192 0c-6 0-11-5-11-11 0-6 5-11 11-11l192 0c6 0 11 5 11 11 0 6-5 11-11 11z m0-64l-192 0c-6 0-11-5-11-11 0-6 5-11 11-11l192 0c6 0 11 5 11 11 0 6-5 11-11 11z";
export const dollar =
  "M256 512c-141 0-256-115-256-256 0-141 115-256 256-256 141 0 256 115 256 256 0 141-115 256-256 256z m0-491c-129 0-235 106-235 235 0 129 106 235 235 235 129 0 235-106 235-235 0-129-106-235-235-235z m43 288c0-6 4-10 10-10 6 0 11 4 11 10 0 32-23 58-53 63l0 23c0 6-5 10-11 10-6 0-11-4-11-10l0-23c-30-5-53-31-53-63 0-31 23-58 53-63l0-84c-18 4-32 21-32 41 0 6-4 10-10 10-6 0-11-4-11-10 0-32 23-58 53-63l0-23c0-6 5-10 11-10 6 0 11 4 11 10l0 23c30 5 53 31 53 63 0 31-23 58-53 63l0 84c18-4 32-21 32-41z m-86 0c0 20 14 37 32 41l0-82c-18 5-32 22-32 41z m86-106c0-20-14-37-32-41l0 82c18-5 32-22 32-41z";
export const download =
  "M456 318c-20 19-47 30-75 31-27 50-79 82-136 82-77 0-141-58-152-133-22 2-43-5-61-19-20-17-32-42-32-68 0-25 8-46 23-61 26-25 64-26 69-26 0 0 0 0 0 0l41 0c6 0 11 5 11 10 0 6-5 11-11 11l-41 0c0 0-33 0-54 20-11 11-17 27-17 46 0 20 9 39 25 51 15 13 35 18 55 13l12-2 1 12c4 70 62 125 131 125 51 0 98-30 120-76l3-7 7 1c25 1 48-8 66-26 18-17 28-40 28-65 0-77-73-91-82-92l-30 0c-6 0-11-5-11-11 0-5 5-10 11-10l31 0 2 0c1 0 101 14 101 113 0 30-13 59-35 81z m-154-225l-46-46 0 188c0 6-5 10-11 10-6 0-10-4-10-10l0-188-46 46c-4 4-11 4-15 0-4-4-4-11 0-15l64-64c1-1 2-2 3-3 2 0 3 0 4 0 2 0 3 0 4 0 2 1 3 2 4 3l64 64c4 4 4 11 0 15-4 4-11 4-15 0z";
export const exclamationCircleFilled =
  "M504 266c2-139-113-253-252-255-139-3-250 108-252 246-2 138 113 253 252 255 139 2 249-108 252-246z m-205-46l0 200c0 15-19 28-43 28-24 0-43-13-43-28l0-200c0-15 19-28 43-28 24 0 43 13 43 28z m0-113c0 23-19 42-43 42-24 0-43-19-43-42 0-24 19-43 43-43 24 0 43 19 43 43z";
export const fileAdd =
  "M21 491l214 0 0-96c0-6 4-11 10-11l96 0 0-75 22 0 0 86c0 1-1 2-1 4-1 1-1 2-2 3l-107 107c-1 1-2 2-4 2-1 1-2 1-4 1l-234 0c-6 0-11-5-11-11l0-448c0-6 5-10 11-10l213 0 0 21-203 0z m235-15l70-71-70 0z m117-199c-76 0-138-62-138-138 0-77 62-139 138-139 77 0 139 62 139 139 0 76-62 138-139 138z m0-256c-64 0-117 53-117 118 0 64 53 117 117 117 65 0 118-53 118-117 0-65-53-118-118-118z m59 128l-48 0 0 48c0 6-5 11-11 11-6 0-10-5-10-11l0-48-48 0c-6 0-11-4-11-10 0-6 5-11 11-11l48 0 0-48c0-5 4-10 10-10 6 0 11 5 11 10l0 48 48 0c5 0 10 5 10 11 0 6-5 10-10 10z";
export const fileFolder =
  "M178.285 266.422H358.285C364.285 266.422 368.285 271.422 368.285 277.422V482.422C368.285 487.422 364.285 492.422 358.285 492.422H10.2852C4.28516 492.422 0.285156 487.422 0.285156 482.422V236.422C0.285156 230.422 4.28516 226.422 10.2852 226.422H133.285C136.285 226.422 138.285 227.422 140.285 229.422L178.285 266.422ZM348.285 287.422H174.285C171.285 287.422 168.285 286.422 167.285 284.422L129.285 246.422H20.2852V471.422H348.285V287.422ZM123.285 21.4219V236.422C123.285 241.422 118.285 246.422 112.285 246.422C107.285 246.422 102.285 241.422 102.285 236.422V10.4219C102.285 5.42188 107.285 0.421875 112.285 0.421875H338.285C341.285 0.421875 344.285 2.42188 346.285 4.42188L448.285 127.422C449.285 129.422 450.285 131.422 450.285 133.422V441.422C450.285 446.422 446.285 451.422 440.285 451.422H358.285C353.285 451.422 348.285 446.422 348.285 441.422C348.285 435.422 353.285 430.422 358.285 430.422H430.285V137.422L333.285 21.4219H123.285ZM348.285 123.422H440.285C446.285 123.422 450.285 128.422 450.285 133.422C450.285 139.422 446.285 144.422 440.285 144.422H338.285C332.285 144.422 327.285 139.422 327.285 133.422V10.4219C327.285 5.42188 332.285 0.421875 338.285 0.421875C343.285 0.421875 348.285 5.42188 348.285 10.4219V123.422ZM174.285 205.422C168.285 205.422 164.285 200.422 164.285 195.422C164.285 189.422 168.285 185.422 174.285 185.422H379.285C384.285 185.422 389.285 189.422 389.285 195.422C389.285 200.422 384.285 205.422 379.285 205.422H174.285ZM174.285 144.422C168.285 144.422 164.285 139.422 164.285 133.422C164.285 128.422 168.285 123.422 174.285 123.422H297.285C302.285 123.422 307.285 128.422 307.285 133.422C307.285 139.422 302.285 144.422 297.285 144.422H174.285ZM174.285 82.4219C168.285 82.4219 164.285 78.4219 164.285 72.4219C164.285 66.4219 168.285 62.4219 174.285 62.4219H297.285C302.285 62.4219 307.285 66.4219 307.285 72.4219C307.285 78.4219 302.285 82.4219 297.285 82.4219H174.285Z";
export const form =
  "M86 256l42-42 112 112-42 42z m-10-22l-12-43 42 13z m55-47l-81-24c-9-2-17 6-15 15l24 81c0 0 0 0 0 0 0 2 1 4 3 5l174 175c5 4 12 4 17 0l58-58c4-5 4-12 0-17l-175-174c-1-2-3-3-5-3l0 0c-3-1-11 5-25 17z m83 197l42-42 30 30-42 42z m-98-12c0-12-10-23-23-23-13 0-23 11-23 23 0 13 10 24 23 24 13 0 23-11 23-24z m0-256c0-12-10-23-23-23-13 0-23 11-23 23 0 13 10 24 23 24 13 0 23-11 23-24z m-35 0l24 0 0-93 360 0c7 0 12-5 12-11 0-7-5-12-12-12l-372 0c-6 0-12 5-12 12z m0 256l0 128c0 7 6 12 12 12l256 0c3 0 6-1 8-3l117-117c2-2 3-5 3-8l0-369c0-7-5-12-12-12-6 0-11 5-11 12l0 364-110 110-239 0 0-117z m280 24l104 0c7 0 12-6 12-12 0-6-5-12-12-12l-116 0c-6 0-12 6-12 12l0 116c0 7 6 12 12 12 7 0 12-5 12-12z m-140-187l0-23 186 0 0 23z m0 24l186 0c13 0 24-11 24-24l0-23c0-13-11-23-24-23l-186 0c-13 0-23 10-23 23l0 23c0 13 10 24 23 24z m0-117l0-23 186 0 0 23z m0 24l186 0c13 0 24-11 24-24l0-23c0-13-11-23-24-23l-186 0c-13 0-23 10-23 23l0 23c0 13 10 24 23 24z";
export const handle =
  "M217 453c0-33-27-59-59-59-33 0-60 26-60 59 0 33 27 59 60 59 32 0 59-26 59-59z m0-197c0-33-27-59-59-59-33 0-60 26-60 59 0 33 27 59 60 59 32 0 59-26 59-59z m0-197c0-33-27-59-59-59-33 0-60 26-60 59 0 33 27 59 60 59 32 0 59-26 59-59z m197 394c0-33-27-59-60-59-32 0-59 26-59 59 0 33 27 59 59 59 33 0 60-26 60-59z m0-197c0-33-27-59-60-59-32 0-59 26-59 59 0 33 27 59 59 59 33 0 60-26 60-59z m0-197c0-33-27-59-60-59-32 0-59 26-59 59 0 33 27 59 59 59 33 0 60-26 60-59z";
export const home =
  "M438 224c-6 0-11-5-11-11l0-192-106 0 0 150-128 0 0-150-107 0 0 203c0 6-5 11-11 11-6 0-10-5-10-11l0-224 149 0 0 149 85 0 0-149 150 0 0 213c0 6-5 11-11 11z m72 18l-246 246c-4 4-11 4-15 0l-245-246c-4-4-4-11 0-15 4-4 11-4 15 0l238 238 237-238c3-2 5-3 8-3 3 0 5 1 8 3 4 4 4 11 0 15z m-168 206l64 0 0-64c0-6 5-11 11-11 6 0 10 5 10 11l0 85-85 0c-6 0-11-4-11-10 0-6 5-11 11-11z";
export const icons =
  "M44 44l143 0c8 0 15-6 15-14 0-9-7-15-15-15l-157 0c-9 0-15 6-15 15l0 157c0 8 6 15 15 15 8 0 14-7 14-15z m0 424l0-143c0-8-6-15-14-15-9 0-15 7-15 15l0 157c0 9 6 15 15 15l157 0c8 0 15-6 15-15 0-8-7-14-15-14z m424-424l0 143c0 8 6 15 14 15 9 0 15-7 15-15l0-157c0-9-6-15-15-15l-157 0c-8 0-15 6-15 15 0 8 7 14 15 14z m0 424l-143 0c-8 0-15 6-15 14 0 9 7 15 15 15l157 0c9 0 15-6 15-15l0-157c0-8-6-15-15-15-8 0-14 7-14 15z m-148-143c0 35-29 64-64 64-35 0-64-29-64-64 0-35 29-64 64-64 35 0 64 29 64 64z m30 0c0-52-42-94-94-94-52 0-94 42-94 94 0 52 42 93 94 93 52 0 94-41 94-93z m-94-94c-67 0-115-34-122-88l244 0c-7 54-55 88-122 88z m138-118l-276 0c-8 0-15 7-15 15 0 81 65 133 153 133 88 0 153-52 153-133 0-8-7-15-15-15z";
export const imageFile =
  "M459 0l-406 0c-6 0-10 4-10 11l0 490c0 7 4 11 10 11l278 0c2 0 6-2 8-2l128-128c0-2 2-7 2-9l0-362c0-7-4-11-10-11z m-395 21l384 0 0 348-122 122-262 0z m395 342l-128 0c-7 0-11 4-11 10l0 128c0 7 4 11 11 11 6 0 10-4 10-11l0-117 118 0c6 0 10-4 10-11 0-6-4-10-10-10z m-86-278l-213 0c-4 0-6 2-9 5-2 2-2 6 0 10l54 107c0 4 4 6 6 6 4 0 7 0 9-2l32-32 57 105c2 4 7 6 11 6 4 0 9-4 9-8l53-182c0-4 0-6-2-8-2-2-2-7-7-7z m-196 22l181 0-42 143-51-94c-3-2-5-5-9-7-4 0-6 0-9 2l-32 32z m15 170c-23 0-43 20-43 43 0 23 20 43 43 43 23 0 43-20 43-43 0-23-20-43-43-43z m0 64c-13 0-21-8-21-21 0-13 8-21 21-21 13 0 21 8 21 21 0 13-8 21-21 21z";
export const information =
  "M245 512c-135 0-245-110-245-245 0-136 110-246 245-246 136 0 246 110 246 246 0 135-110 245-246 245z m0-469c-123 0-224 100-224 224 0 123 101 224 224 224 124 0 224-101 224-224 0-124-100-224-224-224z m64 85l-53 0 0 181c0 6-5 11-11 11l-42 0c-6 0-11-5-11-11 0-6 5-10 11-10l32 0 0-171-54 0c-6 0-10-5-10-11 0-6 4-10 10-10l128 0c6 0 11 4 11 10 0 6-5 11-11 11z m-85 256c12 0 21 10 21 21 0 12-9 22-21 22-12 0-21-10-21-22 0-11 9-21 21-21z m0 21l0-10 0 10z";
export const insertField =
  "M11 245c-6 0-11 5-11 11 0 6 5 11 11 11l334 0c6 0 11-5 11-11 0-6-5-11-11-11z m237 92c-4 5-4 12 0 16 4 4 12 4 16 0l89-89c4-4 4-12 0-16l-89-89c-4-4-12-4-16 0-4 4-4 11 0 16l81 81z m-205 16c-2-6-9-8-15-6-5 3-8 9-5 15 41 91 132 150 233 150 141 0 256-115 256-256 0-141-115-256-256-256-102 0-193 60-234 151-2 6 0 13 6 15 6 3 12 0 15-6 37-83 120-138 213-138 129 0 234 105 234 234 0 129-105 234-234 234-93 0-175-55-213-137z";
export const italic =
  "M491 512l-171 0c-12 0-21-10-21-21 0-12 9-22 21-22l46 0-271-426-74 0c-11 0-21-10-21-22 0-11 10-21 21-21l171 0c12 0 21 10 21 21 0 12-9 22-21 22l-46 0 271 426 74 0c11 0 21 10 21 22 0 11-10 21-21 21z";
export const justify =
  "M373 43l-352 0c-11 0-21-10-21-22 0-11 10-21 21-21l352 0c12 0 22 10 22 21 0 12-10 22-22 22z m-352 405l470 0c11 0 21 10 21 21 0 12-10 22-21 22l-470 0c-11 0-21-10-21-22 0-11 10-21 21-21z m470-21l-470 0c-11 0-21-10-21-22 0-11 10-21 21-21l470 0c11 0 21 10 21 21 0 12-10 22-21 22z m0-64l-470 0c-11 0-21-10-21-22 0-11 10-21 21-21l470 0c11 0 21 10 21 21 0 12-10 22-21 22z m0-64l-470 0c-11 0-21-10-21-22 0-11 10-21 21-21l470 0c11 0 21 10 21 21 0 12-10 22-21 22z m0-64l-470 0c-11 0-21-10-21-22 0-11 10-21 21-21l470 0c11 0 21 10 21 21 0 12-10 22-21 22z m0-64l-470 0c-11 0-21-10-21-22 0-11 10-21 21-21l470 0c11 0 21 10 21 21 0 12-10 22-21 22z m0-64l-470 0c-11 0-21-10-21-22 0-11 10-21 21-21l470 0c11 0 21 10 21 21 0 12-10 22-21 22z";
export const lead =
  "M427 384l0 21-267 0c-6 0-11 5-11 11l0 32-128 0 0-341c0-24 19-43 43-43 24 0 43 19 43 43l0 266c0 6 4 11 10 11z m21 0l53 0c6 0 11-5 11-11l0-256c0-41-33-74-75-74l-373 0c-35 0-64 28-64 64l0 352c0 6 5 10 11 10l149 0c6 0 11-4 11-10l0-32 266 0c6 0 11-5 11-11z m-11-320c30 0 54 24 54 53l0 246-363 0 0-256c0-17-6-32-16-43z m-67 85c6 0 10-4 10-10 0-6-4-11-10-11l-103 0c-6 0-11 5-11 11l0 149c0 6 5 11 11 11 6 0 10-5 10-11l0-139z";
export const library =
  "M480 459c-100 0-202-22-235-60-32 38-135 60-234 60-6 0-11-5-11-11l0-352c0-6 5-11 11-11 126 0 224-34 224-64 0-6 4-10 10-10 6 0 11 4 11 10 0 30 98 64 224 64 6 0 11 5 11 11l0 352c0 6-5 11-11 11z m-459-352l0 330c121-2 214-35 214-64l0-316c-39 30-127 48-214 50z m448 0c-87-2-174-20-213-50l0 316c0 29 92 62 213 64z";
export const link =
  "M252 147l-68-67c-10-10-23-16-37-16-15 0-28 6-38 16l-15 15c-21 20-21 54 0 75l98 98c10 10 23 16 37 16 15 0 28-6 38-16l15-15c4-4 11-4 15 0 5 4 5 11 0 15l-15 15c-14 14-33 22-53 22-19 0-38-8-52-22l-98-98c-29-29-29-76 0-105l15-15c14-15 33-22 53-22 19 0 38 7 52 22l68 67c4 5 4 11 0 15-4 5-11 5-15 0z m174 249l-15 15c-15 15-33 22-53 22-20 0-39-7-53-22l-68-67c-4-5-4-11 0-15 4-5 11-5 15 0l68 67c10 10 23 16 38 16 14 0 27-6 37-16l16-15c10-10 15-23 15-37 0-15-5-28-15-38l-99-98c-10-10-23-16-37-16-14 0-28 6-38 16-4 4-11 4-15 0-4-4-4-11 0-15 14-14 33-22 53-22 20 0 38 8 53 22l98 98c14 14 21 33 21 53 0 20-7 38-21 52z";
export const list =
  "M58 468c-29 0-53-24-53-54 0-29 24-53 53-53 29 0 53 24 53 53 0 30-24 54-53 54z m129-84l304 0c11 0 21 10 21 21 0 12-10 22-21 22l-304 0c-11 0-21-10-21-22 0-11 10-21 21-21z m-129-87c-29 0-53-24-53-53 0-30 24-54 53-54 29 0 53 24 53 54 0 29-24 53-53 53z m433-41l-304 0c-11 0-21-10-21-21 0-12 10-22 21-22l304 0c11 0 21 10 21 22 0 11-10 21-21 21z m-433-130c-29 0-53-23-53-53 0-29 24-53 53-53 29 0 53 24 53 53 0 30-24 53-53 53z m433-41l-304 0c-11 0-21-9-21-21 0-12 10-21 21-21l304 0c11 0 21 9 21 21 0 12-10 21-21 21z";
export const listTree =
  "M364 377c7 0 13 6 13 14l0 108c0 7-6 13-13 13l-351 0c-7 0-13-6-13-13l0-108c0-8 6-14 13-14z m-14 27l-323 0 0 81 323 0z m149-215c7 0 13 6 13 13l0 108c0 7-6 13-13 13l-351 0c-7 0-13-6-13-13l0-108c0-7 6-13 13-13z m-14 27l-323 0 0 80 323 0z m14-216c7 0 13 6 13 13l0 108c0 8-6 14-13 14l-351 0c-7 0-13-6-13-14l0-108c0-7 6-13 13-13z m-14 27l-323 0 0 81 323 0z m-428 364l-27 0 0-324c0-7 6-13 13-13l108 0 0 27-94 0z m91-148l0 26-100 0 0-26z";
export const location =
  "M277 512c-94 0-170-77-170-171 0-90 155-326 161-336 2-3 6-5 9-5 4 0 7 2 9 5 7 10 162 246 162 336 0 94-77 171-171 171z m0-482c-33 53-149 239-149 311 0 83 67 150 149 150 83 0 150-67 150-150 0-72-116-258-150-311z m0 386c-41 0-74-33-74-75 0-41 33-74 74-74 41 0 75 33 75 74 0 42-34 75-75 75z m0-128c-29 0-53 24-53 53 0 30 24 54 53 54 30 0 54-24 54-54 0-29-24-53-54-53z";
export const magnifier =
  "M509 18l-192 192c0 0 0 0 0 0 28 32 46 74 46 121 0 100-82 181-182 181-100 0-181-81-181-181 0-100 81-182 181-182 47 0 89 18 121 46 0 0 0 0 0 0l192-192c2-2 5-3 7-3 3 0 6 1 8 3 4 4 4 11 0 15z m-328 153c-88 0-160 71-160 160 0 88 72 160 160 160 89 0 160-72 160-160 0-89-71-160-160-160z";
export const mail =
  "M448 405l-384 0c-24 0-43-19-43-42l0-235c0-24 19-43 43-43l384 0c24 0 43 19 43 43l0 235c0 23-19 42-43 42z m0-21c1 0 3 0 4 0l-196-157-196 157c1 0 3 0 4 0z m21-256c0-12-9-21-21-21l-384 0c-12 0-21 9-21 21l0 235c0 2 0 4 1 6l205-164c2-2 5-2 7-2 2 0 5 0 7 2l205 164c1-2 1-4 1-6z";
export const matter =
  "M437 32l-373 0c-36 0-64 28-64 64l0 352c0 6 4 11 11 11l149 0c6 0 11-5 11-11l0-32 266 0c7 0 11-4 11-11l0-32 53 0c7 0 11-4 11-10l0-256c0-41-34-75-75-75z m-326 21l326 0c30 0 54 24 54 54l0 245-363 0 0-256c0-17-6-32-17-43z m-90 384l0-341c0-23 20-43 43-43 23 0 43 20 43 43l0 267c0 6 4 10 10 10l310 0 0 22-267 0c-6 0-11 4-11 10l0 32z m374-320c-7 0-11 5-11 11l0 128-68-51c-5-2-9-2-13 0l-68 51 0-128c0-6-5-11-11-11-6 0-11 5-11 11l0 149c0 5 2 9 7 9 4 2 8 2 10 0l79-60 79 60c5 2 9 2 11 0 4 0 6-4 6-9l0-149c0-6-4-11-10-11z";
export const menu =
  "M501 491l-490 0c-6 0-11-5-11-11l0-107c0-6 5-10 11-10l490 0c6 0 11 4 11 10l0 107c0 6-5 11-11 11z m-10-107l-470 0 0 85 470 0z m10-64l-490 0c-6 0-11-5-11-11l0-106c0-6 5-11 11-11l490 0c6 0 11 5 11 11l0 106c0 6-5 11-11 11z m-10-107l-470 0 0 86 470 0z m10-64l-490 0c-6 0-11-4-11-10l0-107c0-6 5-11 11-11l490 0c6 0 11 5 11 11l0 107c0 6-5 10-11 10z m-10-106l-470 0 0 85 470 0z";
export const moveOut =
  "M294 130c6-6 2-17-7-17l-267 0c-5 0-10 4-10 10l0 266c0 9 11 14 18 7l62-62c237 72 412-1 412-211l-21 0c0 119-120 144-257 77z m-95 66c-5 5-3 13 3 16 105 56 207 60 261 12-49 115-189 146-373 89-3-1-7 0-10 3l-49 49 0-232 231 0z";
export const multiSelect =
  "M501 491l-320 0c-6 0-10-5-10-11l0-107c0-6 4-10 10-10l320 0c6 0 11 4 11 10l0 107c0 6-5 11-11 11z m-10-107l-299 0 0 85 299 0z m10-64l-320 0c-6 0-10-5-10-11l0-106c0-6 4-11 10-11l320 0c6 0 11 5 11 11l0 106c0 6-5 11-11 11z m-10-107l-299 0 0 86 299 0z m10-64l-320 0c-6 0-10-4-10-10l0-107c0-6 4-11 10-11l320 0c6 0 11 5 11 11l0 107c0 6-5 10-11 10z m-10-106l-299 0 0 85 299 0z m-374 448l-106 0c-6 0-11-5-11-11l0-107c0-6 5-10 11-10l106 0c6 0 11 4 11 10l0 107c0 6-5 11-11 11z m-10-107l-86 0 0 85 86 0z m10-64l-106 0c-6 0-11-5-11-11l0-106c0-6 5-11 11-11l106 0c6 0 11 5 11 11l0 106c0 6-5 11-11 11z m-10-107l-86 0 0 86 86 0z m10-64l-106 0c-6 0-11-4-11-10l0-107c0-6 5-11 11-11l106 0c6 0 11 5 11 11l0 107c0 6-5 10-11 10z m-10-106l-86 0 0 85 86 0z";
export const outbox =
  "M511 163c0 0 0 1 0 2 0 0 0 0 0 0l-75 128c-2 4-6 6-9 6l-118 0c-6 0-10-5-10-11 0-6 4-11 10-11l112 0 62-106-131 0c-6 0-11-5-11-11l0-21c0-18-14-32-32-32l-96 0c-17 0-32 14-32 32l0 21c0 6-4 11-10 11l-142 0 62 106 48 0c6 0 10 5 10 11 0 6-4 11-10 11l-54 0c-3 0-7-2-9-6l-75-128c0 0 0 0 0 0 0-1 0-2 0-2-1-1-1-2-1-2 0-1 0-1 0-1l0-149c0-6 5-11 11-11l490 0c6 0 11 5 11 11l0 149c0 0 0 0 0 1 0 0 0 1-1 2z m-20-142l-470 0 0 128 139 0 0-10c0-30 24-54 53-54l96 0c30 0 54 24 54 54l0 10 128 0z m-311 182c1 0 1 0 1 0 5 0 10 3 11 8 1 6 31 146 192 151l0-31c0-4 2-8 6-10 4-2 8-1 11 1l107 86c3 2 4 5 4 8 0 3-1 6-4 8l-107 86c-3 2-7 3-11 1-4-2-6-6-6-10l0-32c-211-8-213-253-213-256 0-5 4-10 9-10z m38 129c34 77 93 116 177 116 6 0 10 5 10 11l0 20 79-63-79-63 0 20c0 6-4 11-10 11-105 0-162-52-193-98 4 15 9 31 16 46z";
export const page =
  "M447 377c0 2-1 3-2 4l-128 128c-1 1-2 2-4 2-1 1-2 1-4 1l-170 0c-6 0-11-5-11-11l0-53-53 0c-6 0-11-5-11-11l0-426c0-6 5-11 11-11l298 0c6 0 11 5 11 11l0 53 53 0c6 0 11 5 11 11l0 298c0 2 0 3-1 4z m-127 99l92-92-92 0z m43-455l-278 0 0 406 43 0 0-352c0-6 5-11 11-11l224 0z m-214 64l0 406 150 0 0-118c0-6 4-10 10-10l118 0 0-278z";
export const palette =
  "M437 416c-48 48-113 75-181 75-68 0-133-27-181-75-100-100-100-263 0-362 10-10 23-16 38-16 14 0 27 6 37 16 21 20 21 54 0 75-2 2-3 5-3 8 0 2 1 5 3 7 4 4 11 4 15 0l91-90c24-25 56-38 91-38 34 0 66 13 90 38 100 99 100 262 0 362z m-15-347c-20-20-47-32-75-32-29 0-56 12-76 32l-90 90c-6 6-14 10-23 10-9 0-17-4-23-10-6-6-9-14-9-22 0-9 3-17 9-23 13-12 13-33 0-45-12-12-33-12-45 0-91 91-91 240 0 332 44 44 103 68 166 68 63 0 122-24 166-68 91-92 91-241 0-332z m-75 229c11 0 22 4 30 12 8 8 12 19 12 30 0 12-4 22-12 30-16 17-45 17-61 0-8-8-12-18-12-30 0-11 4-22 12-30 8-8 19-12 31-12z m-16 57c4 4 10 7 16 7 5 0 11-3 15-7 4-4 6-9 6-15 0-5-2-11-6-15-8-8-23-8-31 0-4 4-6 10-6 15 0 6 2 11 6 15z m97-98c-12 12-33 12-45 0-13-12-13-32 0-45 6-6 14-9 22-9 9 0 17 3 23 9 6 6 9 14 9 23 0 8-3 16-9 22z m-15-30c-4-4-11-4-15 0-4 4-4 11 0 15 2 2 4 3 7 3 3 0 6-1 8-3 2-2 3-4 3-7 0-3-1-6-3-8z m-67-45c-14 0-27-5-37-15-10-10-16-24-16-38 0-14 6-28 16-38 10-10 23-15 38-15 14 0 27 5 37 15 10 10 16 24 16 38 0 14-6 28-16 38-10 10-23 15-38 15z m23-76c-12-12-33-12-45 0-6 6-9 15-9 23 0 9 3 17 9 23 6 6 14 9 23 9 8 0 16-3 22-9 6-6 10-14 10-23 0-8-4-17-10-23z m-181 287c-8-8-12-19-12-30 0-12 4-22 12-30 8-8 19-13 30-13 12 0 22 5 30 13 9 8 13 19 13 30 0 11-5 22-13 30-16 16-44 16-60 0z m45-45c-8-8-22-8-30 0-4 4-6 9-6 15 0 6 2 11 6 15 4 4 10 6 15 6 6 0 11-2 15-6 4-4 7-9 7-15 0-6-3-11-7-15z";
export const paperPlane =
  "M512 501c0 0 0 1 0 1 0 1 0 2-1 3 0 0 0 0 0 1-1 1-1 2-2 3 0 0 0 0 0 0 0 0 0 0 0 0-1 1-2 1-3 2-1 0-1 0-1 0-1 1-2 1-3 1 0 0-1 0-1 0-1 0-2 0-3-1 0 0-1 0-1 0 0 0 0 0 0 0l-491-213c-3-2-6-6-6-10 0-4 2-8 6-9l178-95 95-178c1-4 5-6 9-6 0 0 0 0 0 0 5 0 8 3 10 6l213 491c0 0 0 0 0 0 0 0 0 1 0 1 1 1 1 2 1 3z m-58-32l-264-264-155 82z m-167-434l-82 155 264 264z";
export const paragraph =
  "M373,31.667 L11,31.667 C5,31.667 0,26.667 0,20.667 C0,14.667 5,9.667 11,9.667 L373,9.667 C379,9.667 384,14.667 384,20.667 C384,26.667 379,31.667 373,31.667 z M314.333,479.667 L501,479.667 C507,479.667 512,484.667 512,490.667 C512,496.667 507,501.667 501,501.667 L314.333,501.667 C308.333,501.667 303.333,496.667 303.333,490.667 C303.333,484.667 308.333,479.667 314.333,479.667 z M501,384.333 L314.333,384.333 C308.333,384.333 303.333,379.333 303.333,373.333 C303.333,367.333 308.333,362.333 314.333,362.333 L501,362.333 C507,362.333 512,367.333 512,373.333 C512,379.333 507,384.333 501,384.333 z M501,267 L314.333,267 C308.333,267 303.333,262 303.333,256 C303.333,250 308.333,245 314.333,245 L501,245 C507,245 512,250 512,256 C512,262 507,267 501,267 z M501,148.333 L11,148.333 C5,148.333 0,143.333 0,137.333 C0,131.333 5,126.333 11,126.333 L501,126.333 C507,126.333 512,131.333 512,137.333 C512,143.333 507,148.333 501,148.333 z M59.237,233.91 C65.094,233.91 69.746,237.98 69.746,243.644 C69.746,249.39 64.983,254.153 59.237,254.153 L50.687,254.153 L77.565,315.899 L206.435,315.899 L233.313,254.153 L224.762,254.153 C219.017,254.153 214.254,249.39 214.254,243.644 C214.254,237.98 218.906,233.91 224.762,233.91 L274.265,233.91 C280.011,233.91 284,237.899 284,243.644 C284,249.501 279.929,254.153 274.265,254.153 L256.241,254.153 L151.57,495.82 L151.499,495.986 L151.399,496.135 C149.315,499.261 145.573,501.667 142,501.667 C138.427,501.667 134.685,499.261 132.601,496.135 L132.501,495.986 L132.43,495.82 L27.758,254.153 L9.735,254.153 C4.071,254.153 0,249.501 0,243.644 C0,237.899 3.989,233.91 9.735,233.91 L59.237,233.91 z M197.742,336.915 L86.258,336.915 L142,466.003 L197.742,336.915 z";
export const pencil =
  "M489 444l-45 45c-10 10-24 16-38 16-14 0-28-6-38-16l-15-15c0 0 0 0 0 0 0 0 0 0 0 0l-30-30-282-282c0 0 0 0 0 0 0 0 0-1 0-1l-6-5c-1-1-2-3-2-5l-33-138c-1-3 1-7 3-10 2-2 5-3 8-3 0 0 1 0 2 0l138 33c2 0 4 1 5 2l285 285c1 1 2 1 3 2 1 1 2 3 3 4l24 24c1 1 2 2 3 3 1 1 2 2 3 3l12 12c21 21 21 55 0 76z m-53-98l-90 90 15 15 90-90z m-282-283l-91 91 268 267 90-90z m-103 73l85-85-111-26z m423 247l-8-7-90 90 7 8c12 12 34 12 46 0l45-45c12-13 12-33 0-46z";
export const personCircle =
  "M308 323c0 31-25 55-56 55-30 0-55-24-55-55 0-31 25-56 55-56 31 0 56 25 56 56z m22 0c0-43-35-78-78-78-43 0-77 35-77 78 0 43 34 78 77 78 43 0 78-35 78-78z m-78-78c-55 0-101-42-107-97l215 0c-6 55-52 97-108 97z m119-119l-237 0c-7 0-12 5-12 11 0 72 59 130 130 130 72 0 130-58 130-130 0-6-5-11-11-11z m-115-126c-141 0-256 115-256 256 0 141 115 256 256 256 141 0 256-115 256-256 0-141-115-256-256-256z m0 22c129 0 234 105 234 234 0 129-105 234-234 234-129 0-234-105-234-234 0-129 105-234 234-234z";
export const popOut =
  "M384 32l0 176c0 8 7 16 16 16 9 0 16-8 16-16l0-192c0-8-7-16-16-16l-384 0c-9 0-16 8-16 16l0 384c0 9 7 16 16 16l192 0c9 0 16-7 16-16 0-8-7-16-16-16l-176 0 0-352z m-261 69c-6-7-16-7-22 0-7 6-7 16 0 22l384 384c6 7 16 7 22 0 7-6 7-16 0-22z m357 379l-176 0c-9 0-16 7-16 16 0 9 7 16 16 16l192 0c9 0 16-7 16-16l0-192c0-9-7-16-16-16-9 0-16 7-16 16z";
export const preview =
  "M510 241c-5 6-115 143-254 143-139 0-249-137-254-143-3-4-3-9 0-13 5-6 115-143 254-143 139 0 249 137 254 143 3 4 3 9 0 13z m-254-134c-112 0-208 101-231 128 23 27 119 128 231 128 112 0 208-101 231-128-23-27-119-128-231-128z m0 224c-53 0-96-43-96-96 0-53 43-96 96-96 53 0 96 43 96 96 0 53-43 96-96 96z m0-171c-41 0-75 33-75 75 0 41 34 74 75 74 41 0 75-33 75-74 0-42-34-75-75-75z m0 128c-6 0-11-5-11-11 0-6 5-10 11-10 18 0 32-15 32-32 0-18-14-32-32-32-18 0-32 14-32 32 0 6-5 10-11 10-6 0-10-4-10-10 0-30 24-54 53-54 29 0 53 24 53 54 0 29-24 53-53 53z";
export const questionCircle =
  "M490 256c0 129-105 234-234 234-129 0-234-105-234-234 0-129 105-234 234-234 129 0 234 105 234 234z m22 0c0-141-115-256-256-256-141 0-256 115-256 256 0 141 115 256 256 256 141 0 256-115 256-256z m-245-78c0-6-5-11-11-11-6 0-11 5-11 11l0 67c0 6 5 11 11 11 31 0 56 25 56 56 0 30-25 55-56 55-31 0-56-25-56-55 0-6-5-11-11-11-6 0-11 5-11 11 0 43 35 78 78 78 43 0 78-35 78-78 0-40-29-72-67-77z m0-56c0-6-5-11-11-11-6 0-11 5-11 11 0 7 5 12 11 12 6 0 11-5 11-12z";
export const redo =
  "M491 512c-12 0-22-10-22-21l0-94c-37 35-85 60-135 70-72 14-143 0-201-41-58-42-96-103-108-173-12-71 5-141 46-199 12-18 27-34 43-47 4-4 9-6 14-6 6 0 12 3 16 8 8 9 7 22-2 30-14 12-26 25-36 40-35 48-49 108-39 167 10 59 42 110 91 145 48 34 107 46 168 34 44-9 85-31 118-62l-103 0c-11 0-21-10-21-22 0-11 10-21 21-21l150 0c11 0 21 10 21 21l0 150c0 11-10 21-21 21z";
export const refresh =
  "M492 329c3 5 10 7 15 3 5-3 7-10 3-15l-67-100c-4-7-12-7-17-2l-78 89c-4 5-4 12 1 16 4 4 12 4 16-1l68-78z m-70-104c15 126-75 227-192 231-111 4-204-82-208-192-4-111 83-204 193-208 82-3 156 43 189 116 3 6 10 8 15 5 6-2 8-9 6-14-38-81-120-133-211-129-122 4-218 107-214 230 5 123 108 219 231 215 130-5 230-118 213-256 0-6-6-11-12-10-6 1-10 6-10 12z";
export const sent =
  "M427 277l-342 0c-23 0-42-19-42-42l0-192c0-24 19-43 42-43l342 0c23 0 42 19 42 43l0 192c0 23-19 42-42 42z m0-21c1 0 2 0 3 0l-174-136-174 136c1 0 2 0 3 0z m21-213c0-12-10-22-21-22l-342 0c-11 0-21 10-21 22l0 192c0 2 0 4 1 7l184-144c2-1 5-2 7-2 2 0 5 1 7 2l184 144c1-3 1-5 1-7z m-280 355l77 78 0-156c0-6 5-11 11-11 6 0 11 5 11 11l0 156 77-78c3-2 5-3 8-3 3 0 5 1 8 3 4 4 4 11 0 15l-96 96c-1 1-3 2-4 2-3 1-5 1-8 0-1 0-3-1-4-2l-96-96c-4-4-4-11 0-15 5-4 11-4 16 0z";
export const smartphone =
  "M373 512l-234 0c-30 0-54-24-54-53l0-406c0-29 24-53 54-53l234 0c30 0 54 24 54 53l0 406c0 29-24 53-54 53z m-266-107l298 0 0-298-298 0z m32 86l234 0c18 0 32-15 32-32l0-32-298 0 0 32c0 17 14 32 32 32z m234-470l-234 0c-18 0-32 15-32 32l0 32 298 0 0-32c0-17-14-32-32-32z m-96 32c0-11-9-21-21-21-12 0-21 10-21 21 0 12 9 22 21 22 12 0 21-10 21-22z m-74 395l106 0c6 0 11 5 11 11 0 6-5 10-11 10l-106 0c-6 0-11-4-11-10 0-6 5-11 11-11z";
export const spanner =
  "M475 430c5 5 14 4 17-2 29-58 19-128-27-174-44-44-111-56-167-31l-204-204c-20-21-54-21-75 0-21 21-21 55 0 75l204 204c-24 56-13 123 32 167 46 46 117 57 174 27 6-3 8-11 3-17l-66-67 0-45 42 0z m-205 20c-40-40-49-100-24-149 2-4 1-9-2-12l-210-210c-12-12-12-33 0-45 13-12 33-12 45 0l210 209c3 4 8 5 12 2 49-25 109-16 149 24 36 36 46 89 29 135l-59-59c-2-2-5-3-8-3l-57 0c-6 0-10 4-10 10l0 60c0 3 1 6 3 8l58 59c-47 18-100 7-136-29z";
export const starFilled =
  "M508 318c-2 4-6 7-10 7l-171 0-60 172c-2 4-6 7-10 7-5 0-9-3-10-7l-61-172-171 0c-4 0-8-3-10-7-1-4 0-9 4-12l141-110-61-182c-1-5 0-9 4-12 4-3 9-3 13 0l151 111 151-111c2-1 4-2 6-2 2 0 4 1 6 2 4 3 5 7 4 12l-61 182 141 110c4 3 5 8 4 12z";
export const sync =
  "M145 287c-4 4-11 3-15-1l-49-60c-6 39 2 77 21 111 24 40 64 69 110 79 62 15 126-6 167-53 4-4 11-5 16-1 4 4 4 11 0 15-46 53-118 76-187 60-52-12-97-45-124-89-20-35-29-74-26-114l-38 58c-4 5-10 6-15 3-5-4-6-10-3-15l59-89c2-3 5-4 8-5 1 0 1 0 1 0 3 0 6 2 8 4l69 82c3 5 3 11-2 15z m356-88l-59 89c-2 3-5 4-9 4-3 1-6-1-8-3l-69-82c-4-5-3-11 1-15 5-4 12-3 15 1l48 57c3-77-50-147-130-166-57-13-117 3-159 45-4 4-11 4-15 0-4-4-4-11 0-15 37-36 85-56 135-56 15 0 29 2 44 5 89 21 149 100 147 186l41-62c2-3 6-5 9-5 2 0 4 1 6 2 5 4 6 10 3 15z";
export const text =
  "M405 512l-341 0c-12 0-21-10-21-21l0-64c0-12 9-22 21-22 12 0 21 10 21 22l0 42 128 0 0-426-53 0c-12 0-21-10-21-22 0-11 9-21 21-21l149 0c12 0 22 10 22 21 0 12-10 22-22 22l-53 0 0 426 128 0 0-42c0-12 10-22 21-22 12 0 22 10 22 22l0 64c0 11-10 21-22 21z";
export const triangleDown =
  "M511 466c-2 3-6 5-10 5l-490 0c-4 0-8-2-10-5-2-4-1-8 1-11l245-409c2-3 5-5 9-5 4 0 7 2 9 5l245 409c2 3 3 7 1 11z";
export const underline =
  "M491 43l-470 0c-11 0-21-10-21-22 0-11 10-21 21-21l470 0c11 0 21 10 21 21 0 12-10 22-21 22z m-459 426l43 0 0-202c0-100 81-182 181-182 100 0 181 82 181 182l0 202 43 0c12 0 21 10 21 22 0 11-9 21-21 21l-128 0c-12 0-21-10-21-21 0-12 9-22 21-22l43 0 0-202c0-77-63-139-139-139-76 0-139 62-139 139l0 202 43 0c12 0 21 10 21 22 0 11-9 21-21 21l-128 0c-12 0-21-10-21-21 0-12 9-22 21-22z";
export const undo =
  "M487 253c-12 70-50 131-108 173-58 41-129 55-201 41-50-10-98-35-135-70l0 94c0 11-10 21-22 21-11 0-21-10-21-21l0-150c0-11 10-21 21-21l150 0c11 0 21 10 21 21 0 12-10 22-21 22l-103 0c33 31 74 53 118 62 61 12 120 0 168-34 49-35 81-86 91-145 10-59-4-119-39-167-10-15-22-28-36-40-9-8-10-21-2-30 4-5 10-8 16-8 5 0 10 2 14 6 16 13 31 29 43 47 41 58 58 128 46 199z";
export const upload =
  "M456 318c-20 19-47 30-75 31-27 50-79 82-136 82-77 0-141-58-152-133-22 2-43-5-61-19-20-17-32-42-32-68 0-25 8-46 23-61 26-25 64-26 69-26 0 0 0 0 0 0l61 0c6 0 11 5 11 10 0 6-5 11-11 11l-61 0c0 0-33 0-54 20-11 11-17 27-17 46 0 20 9 39 25 51 15 13 35 18 55 13l12-2 1 12c4 70 62 125 131 125 51 0 98-30 120-76l3-7 7 1c25 1 48-8 66-26 18-17 28-40 28-65 0-77-73-91-82-92l-50 0c-6 0-11-5-11-11 0-5 5-10 11-10l51 0 2 0c1 0 101 14 101 113 0 30-13 59-35 81z m-203-54c-1 1-2 1-4 2-2 1-5 1-8 0-1-1-2-1-3-2l-64-64c-4-5-4-11 0-16 4-4 11-4 15 0l46 46 0-209c0-6 4-10 10-10 6 0 11 4 11 10l0 209 46-46c2-2 5-3 7-3 3 0 6 1 8 3 4 5 4 11 0 16z";
export const uploadFailed =
  "M34 457c-5 6-5 15 0 21 6 5 16 5 21 0l423-423c5-5 5-15 0-21-6-5-15-5-21 0z m463-231c0-34-12-61-34-81-6-5-15-5-21 1-5 6-5 15 1 21 16 14 25 33 25 59 0 48-39 86-87 84-6 0-11 3-14 8-20 43-63 71-111 71-18 0-36-4-51-11-8-4-17-1-20 7-3 7 0 16 7 19 20 10 42 14 64 14 56 0 107-30 134-79 60-2 107-52 107-113z m-384 94c3 7 12 11 19 8 8-3 12-11 9-19-4-11-7-23-8-36 0-9-9-15-17-13-37 7-72-21-72-58 0-27 12-43 31-52 7-3 15-5 23-6 4-1 8-1 10-1l207 0c8 0 15-7 15-15 0-8-7-15-15-15l-206 0c-13 0-29 3-46 10-30 14-48 40-48 79 0 51 42 90 91 88 1 10 4 20 7 30z";
export const users =
  "M490 102c-16 31-70 51-144 78-8 3-17 7-26 10l0 51c9 8 29 29 32 63 5 4 8 11 11 20 3 15 1 33-9 44 0 1 1 3 2 5 8 20 23 59 17 89-7 37-60 50-102 50-33 0-74-8-86-31-13-2-23-7-30-16-18-25-5-69 3-93 0-1 0-3 1-4-11-10-13-28-10-44 3-9 6-16 11-20 3-34 23-55 32-63l0-51c-9-3-18-7-26-10-74-27-128-47-144-78-22-44-22-89-22-91 0-6 5-11 11-11l490 0c6 0 11 5 11 11 0 2 0 47-22 91z m-468-81c1 15 6 44 20 71 11 24 67 45 131 68 11 4 22 8 33 13 5 1 7 5 7 10l0 64c0 3-1 6-4 8-1 1-28 19-28 56 0 4-3 9-8 10-2 3-5 13-4 23 1 5 3 9 6 10 4 2 6 6 6 10 0 3-1 6-3 14-5 17-18 58-5 74 3 5 10 8 19 8 5 0 9 3 10 8 3 11 29 23 69 23 37 0 77-12 81-33 5-24-9-59-16-77-4-10-5-13-5-17 0-4 2-8 6-10 3-1 5-5 6-10 1-10-2-20-4-23-5-1-8-6-8-10 0-37-27-55-28-56-3-2-4-5-4-8l0-64c0-5 2-9 7-10 11-5 22-9 33-13 64-23 120-44 131-68 14-27 19-56 20-71z";
export const warning =
  "M475 260c-2 121-99 217-219 215-121-2-221-103-219-223 2-121 99-217 219-215 121 2 221 103 219 223z m-219-255c-137-2-248 107-251 247-2 137 112 252 251 255 137 2 248-108 251-247 2-137-112-252-251-255z m16 208c0-9-7-16-16-16-9 0-16 7-16 16l0 150c0 9 7 16 16 16 9 0 16-7 16-16z m5-85c0-12-9-21-21-21-12 0-21 9-21 21 0 12 9 21 21 21 12 0 21-9 21-21z";
