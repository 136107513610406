import styled from "@emotion/styled";
import { position } from "polished";
import { useMemo } from "react";
import { Control, Path, PathValue, useController } from "react-hook-form";

import { IconButton } from "../buttons";

const FileInputWrapper = styled.label<{
  compactView: boolean;
  disabled?: boolean;
}>`
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  padding: 1rem;
  width: 100%;

  .placeholder {
    ${position("absolute", 0)}
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      color: ${(props) => props.theme.palette.disabled.base};
      font-size: ${(props) => props.theme.fontSize.small};
      font-weight: 700;
    }
  }

  .preview {
    display: flex;
    justify-content: start;
    align-items: center;
    position: relative;

    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

    height: ${(props) => (props.compactView ? "3rem" : "25rem")};
    width: 100%;

    overflow: auto;
    font-size: ${(props) => props.theme.fontSize.small};
    line-height: 1.4;

    a {
      text-decoration: none;
      color: ${(props) => props.theme.palette.primary.base};
      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 90%;
    }
  }

  .cancel {
    position: absolute;
    top: ${(props) => (props.compactView ? "0rem" : "1rem")};
    right: ${(props) => (props.compactView ? "0rem" : "1rem")};
  }

  input {
    display: none;
  }
`;

type FileInputProps = {
  file: File | string | undefined;
  name: string;
  onChange: (file: File | undefined) => void;
  placeholder?: string;
  disabled?: boolean;
  fileName?: string;
  downloadUrl?: string;
  compactView?: boolean;
};

const FileInput = ({
  file,
  name,
  onChange,
  placeholder,
  disabled,
  fileName,
  downloadUrl,
  compactView = false,
}: FileInputProps) => {
  const isPreloaded = file instanceof File;
  const previewUrl = useMemo(() => {
    if (!compactView) {
      return isPreloaded ? URL.createObjectURL(file) : file;
    }
    return "";
  }, [file]);

  return (
    <FileInputWrapper compactView={!!compactView} disabled={disabled}>
      {!file && placeholder && (
        <div className="placeholder">
          <p>{placeholder}</p>
        </div>
      )}
      <div
        className="preview"
        data-testid={`file-preview-${name}`}
        style={{ backgroundImage: `url(${previewUrl})` }}
      >
        {fileName && (
          <a target="_blank" href={downloadUrl} rel="noreferrer">
            {fileName}
          </a>
        )}
      </div>
      <input
        data-testid={`file-input-${name}`}
        name={name}
        type="file"
        onChange={(e) => e.target.files && onChange(e.target.files[0])}
        disabled={!!file || disabled}
      />
      {file && (
        <IconButton
          aria-label="Remove Uploaded File"
          disabled={disabled}
          className="cancel"
          name="cancelCircle"
          palette="danger"
          active
          onClick={() => {
            onChange(undefined);
          }}
        />
      )}
    </FileInputWrapper>
  );
};

type FormFileKey<V extends {}> = {
  [K in Path<V>]: PathValue<V, K> extends File | string | undefined ? K : never;
}[Path<V>];

type FileFieldProps<V extends {}> = {
  control: Control<V>;
  name: FormFileKey<V>;
  placeholder?: string;
  disabled?: boolean;
};

const FileField = <V extends {}>({
  control,
  name,
  placeholder,
  disabled,
}: FileFieldProps<V>) => {
  const { field } = useController({ control, name });

  return (
    <FileInput
      name={name}
      file={field.value}
      disabled={disabled}
      placeholder={placeholder}
      onChange={field.onChange}
    />
  );
};

export { FileField, FileFieldProps, FileInput, FileInputProps, FormFileKey };
