import { fetchWithRetry, Guard, jsonParse } from "@smart/itops-utils-basic";

export type FetchOptions<A, I, O> = {
  authenticated: A;
  method: "POST" | "GET" | "PUT";
  retry?: number;
  backoff?: number;
  input?: Guard<I>;
  output?: Guard<O>;
};

export type FetchProps<A, I> = {
  body: I;
  backoff?: number;
  retry?: number;
} & (A extends true ? { token: string } : { token?: undefined });

export const jsonFetchHelper = async <A, I, O>(
  { authenticated, method, input, output }: FetchOptions<A, I, O>,
  url: string,
  { body: inputBody, backoff, retry, token }: FetchProps<A, any>,
): Promise<O> => {
  const asObject = input
    ? await input(inputBody, `fetch ${url} input`)
    : inputBody;
  const headers: HeadersInit = {};
  if (inputBody && (method === "POST" || method === "PUT"))
    headers["Content-Type"] = "application/json";
  if (authenticated) headers.Authorization = `Bearer ${token}`;

  let fetchUrl = url;
  let body;
  if (method === "GET") {
    const query =
      typeof asObject === "object"
        ? new URLSearchParams(asObject).toString()
        : asObject || "";
    fetchUrl = `${url}${query && `?${query}`}`;
  } else {
    body = JSON.stringify(asObject);
  }

  const response = await fetchWithRetry(fetchUrl, {
    backoff,
    retry,
    method,
    headers,
    body,
    credentials: "include",
  });
  const text = await response.text();
  if (response.status !== 200) {
    let message;
    try {
      const parsed = JSON.parse(text);
      message =
        parsed.displayCode || parsed.message || parsed.error?.code || text;
    } catch {
      message = text;
    }
    throw new Error(message || "ERR-1404");
  }

  const parsed = jsonParse(text, "fetch output parse");
  const guarded = output ? await output(parsed, "fetch output") : (parsed as O);

  return guarded;
};
