import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";

const scrolling = keyframes`
  0% { left: -40%; }
  100% { left: 105%; }
`;

export type ProgressProps = {
  size: number;
  percent?: number;
  indeterminate?: boolean;
};

export const Progress = styled.span<ProgressProps>`
  height: ${(props) => props.size}rem;
  width: 100%;

  background: ${(props) => props.theme.scheme.grey.r25};
  display: block;
  overflow: hidden;
  position: relative;

  &::after {
    position: absolute;
    content: "";
    display: block;
    height: 100%;
    background: ${(props) => props.theme.scheme.blue.r60};
    transition: width 0.2s ease;

    animation-name: ${(props) => (props.indeterminate ? scrolling : "none")};
    animation-duration: 2s;
    animation-iteration-count: infinite;
    width: ${(props) =>
      props.indeterminate ? "35%" : `${props.percent || 0}%`};
  }
`;

type OverlayProps = { position: "top" | "bottom" };

const Overlay = styled.span<OverlayProps>`
  display: block;
  overflow: hidden;
  position: absolute;
  ${(props) => props.position}: 0;
  left: 0;
  right: 0;
`;

export type ProgressOverlayProps = { className?: string } & OverlayProps &
  ProgressProps;

export const ProgressOverlay = ({
  className,
  position,
  size,
  percent,
  indeterminate,
}: ProgressOverlayProps) => (
  <Overlay className={className} position={position}>
    <Progress size={size} percent={percent} indeterminate={indeterminate} />
  </Overlay>
);
