import styled from "@emotion/styled";
import { position } from "polished";
import { ChangeEvent, useEffect, useState } from "react";
import { useController, UseControllerProps } from "react-hook-form";

const CheckboxItem = styled.label<{ readOnly?: boolean }>`
  position: relative;
  cursor: ${(props) => (props.readOnly ? "not-allowed" : "pointer")};
  display: flex;
  flex-flow: row;
  align-items: center;

  margin: 0.6rem 0;
  padding: 1rem 0;
  min-width: 30rem;
  max-width: 50rem;

  input {
    display: none;
  }

  .checkmark {
    ${position("absolute", "1rem", "0")}
    height: 2rem;
    width: 2rem;
    background-color: ${(props) => props.theme.palette.background.base};
    border: 1px solid ${(props) => props.theme.palette.disabled.accent};

    &:after {
      content: "";
      ${position("absolute", "0.3rem", 0, 0, "0.6rem")}
      opacity: 0;
      width: 0.6rem;
      height: 1rem;
      border: solid white;
      border-width: 0 0.25rem 0.25rem 0;
      transform: rotate(45deg);
    }
  }

  :hover input ~ .checkmark {
    background-color: ${(props) =>
      props.readOnly ? "inherit" : props.theme.palette.disabled.accent};
  }

  input:checked ~ .checkmark {
    background-color: ${(props) => props.theme.palette.secondary.accent};
    border-color: ${(props) => props.theme.palette.secondary.accent};

    &:after {
      opacity: 1;
    }
  }

  .label {
    position: relative;
    font-size: ${(props) => props.theme.fontSize.base};
    margin-left: 3rem;
  }

  .custom-response-textarea {
    margin-left: 3rem;
  }

  &:hover .label,
  input:checked ~ .label {
    text-decoration: ${(props) =>
      props.readOnly ? "none" : "underline dotted"};
  }
`;

const CustomResponse = styled.div`
  min-width: 30rem;

  textarea {
    width: 100%;
    border-radius: 3px;
    border: 1px solid ${(props) => props.theme.palette.disabled.base};
    outline: none;
    font-size: ${(props) => props.theme.fontSize.base};

    &:focus {
      border-color: ${(props) => props.theme.palette.primary.base};
    }
  }
`;

type CustomResponseItemProps = {
  options: { label: string; value: string }[];
  value: string[];
  readOnly?: boolean;
  onChange: (value: string[]) => void;
  onBlur: () => void;
};

const CustomResponseItem = ({
  options,
  value,
  readOnly,
  onChange,
  onBlur,
}: CustomResponseItemProps) => {
  const customResponseIndex = Array.isArray(value)
    ? value.findIndex((v) => options.every((option) => option.value !== v))
    : -1;

  return (
    <>
      <CheckboxItem readOnly={readOnly}>
        <input
          type="checkbox"
          value="Other"
          checked={customResponseIndex >= 0}
          onChange={(e) => {
            if (readOnly) return;

            if (!e.currentTarget.checked) {
              const newVal = value.slice(0);
              newVal.splice(customResponseIndex, 1);
              onChange(newVal);
              setTimeout(onBlur, 0);
            } else {
              onChange([...value, ""]);
            }
          }}
        />
        <div className="checkmark" />
        <div className="label">Other</div>
      </CheckboxItem>
      {customResponseIndex >= 0 && (
        <CustomResponse>
          <textarea
            aria-label="Other Values"
            placeholder="Please specify"
            value={value[customResponseIndex]}
            onChange={({ target }) => {
              const newVal = value.slice(0);
              newVal[customResponseIndex] = target.value;
              onChange(newVal);
            }}
            onBlur={onBlur}
            readOnly={readOnly}
          />
        </CustomResponse>
      )}
    </>
  );
};

type CheckboxProps = {
  options: { label: string; value: string }[];
  allowCustomResponse?: boolean | null;
  readOnly?: boolean;
} & Pick<UseControllerProps, "name" | "control" | "rules">;

const Checkbox = ({
  options,
  allowCustomResponse,
  readOnly,
  control,
  name,
  rules,
}: CheckboxProps) => {
  const { field } = useController({ control, name, rules });
  const [checkedValues, setCheckedValues] = useState<string[]>([]);

  useEffect(() => {
    setCheckedValues(field.value || []);
  }, [field.value]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>, value: string) => {
    if (readOnly) return;

    const newVal = [...checkedValues];
    if (e.currentTarget.checked) {
      newVal.push(value);
    } else {
      newVal.splice(checkedValues.indexOf(value), 1);
    }
    field.onChange(newVal);
    setTimeout(field.onBlur, 0);
  };

  return (
    <>
      {options.map(({ label, value }) => (
        <CheckboxItem key={value} readOnly={readOnly}>
          <input
            type="checkbox"
            value={value}
            checked={(field.value || []).includes(value)}
            onChange={(e) => handleChange(e, value)}
          />
          <div className="checkmark" />
          <div className="label">{label}</div>
        </CheckboxItem>
      ))}
      {allowCustomResponse && (
        <CustomResponseItem
          options={options}
          value={field.value || []}
          readOnly={readOnly}
          onChange={field.onChange}
          onBlur={field.onBlur}
        />
      )}
    </>
  );
};

export { Checkbox, CheckboxItem };
