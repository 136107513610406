import styled from "@emotion/styled";

import { ButtonProps, buttonColor, buttonStyle } from "./utils";

export const Button = styled.button<ButtonProps>`
  ${buttonStyle}

  color: ${buttonColor};
  border: 1px solid ${buttonColor};
`;

export const LinkButton = styled.a<ButtonProps>`
  ${buttonStyle}

  color: ${buttonColor};
  border: 1px solid ${buttonColor};
  text-decoration: none;
`;

export const ButtonLink = styled.button`
  background: none;
  border: 0;

  margin: 0;
  font-size: inherit;
  text-decoration: underline;
  cursor: pointer;
  color: ${(props) => props.theme.palette.primary.base};

  &:focus {
    outline: none;
  }
`;

export const Clickable = styled.div`
  background: none;
  border: 0;
  padding: 0;

  cursor: ${(props) => (props.onClick ? "pointer" : "initial")};
`;
