import { Theme } from "@emotion/react";
import styled from "@emotion/styled";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

import { IconButton } from "@smart/itops-components-dom";
import { Icon, IconName } from "@smart/itops-icons-dom";

export type Toast = {
  icon: IconName;
  text: string;
  palette?: keyof Theme["palette"];
};

type ToastContext = {
  toasts: Toast[];
  setToasts: (toasts: Toast[]) => void;
};

const toastContext = createContext<ToastContext | undefined>(undefined);

const useToast = () => {
  const context = useContext(toastContext);
  if (!context) throw new Error(`Toast context not available`);

  return context;
};

const ToastContextProvider = toastContext.Provider;

const ToastsContainer = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  bottom: 5rem;
  z-index: 100;
`;

const ToastWrapper = styled.div<{ palette: NonNullable<Toast["palette"]> }>`
  display: flex;
  align-items: center;
  padding: 1.5rem;
  background-color: ${(props) => props.theme.palette[props.palette].base};
  border-radius: 0.5rem;
  font-size: ${(props) => props.theme.fontSize.base};

  .toast-icon {
    margin-right: 0.5rem;
  }

  .close-button {
    margin: 0;
    background: none;
  }
`;

type ToastProviderProps = {
  children: ReactNode;
};

const ToastProvider = ({ children }: ToastProviderProps) => {
  const [toasts, setToasts] = useState<Toast[]>([]);

  useEffect(() => {
    if (!toasts.length) return undefined;

    const timer = setTimeout(() => setToasts([]), 5000);
    return () => clearTimeout(timer);
  }, [toasts]);

  return (
    <ToastContextProvider value={{ toasts, setToasts }}>
      {children}
      {!!toasts.length && (
        <ToastsContainer>
          {toasts.map((toast, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <ToastWrapper key={index} palette={toast.palette || "success"}>
              <Icon className="toast-icon" name={toast.icon} />
              <span>{toast.text}</span>
              <IconButton
                className="close-button"
                name="cancel"
                palette="foreground"
                size={10}
                onClick={() =>
                  setToasts((currentToasts) =>
                    currentToasts.filter((_, i) => i !== index),
                  )
                }
              />
            </ToastWrapper>
          ))}
        </ToastsContainer>
      )}
    </ToastContextProvider>
  );
};

export { ToastProvider, useToast };
