import styled from "@emotion/styled";
import { position } from "polished";

import { Spinner } from "./spinner";

const LoadingWrapper = styled.div`
  ${position("absolute", 0)}
  background-color: rgba(255, 255, 255, 0.6);

  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  z-index: 10000;
`;

type LoadingProps = { title?: string; description?: string; size?: number };

const Loading = ({ title, description, size = 8 }: LoadingProps) => (
  <LoadingWrapper>
    <Spinner data-testid="loading-spinner" size={size} title={title} />
    <p className="loading-description">{description}</p>
  </LoadingWrapper>
);

export { LoadingProps, Loading };
