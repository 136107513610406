/* eslint-disable react/no-array-index-key */

import { Descendant } from "slate";

import {
  isEditorElementType,
  isEditorText,
} from "@smart/itops-serialisation-basic";

import { DisplayLink } from "./link";
import { DisplayParagraph } from "./paragraph";
import { DisplayPlaceholder } from "./placeholder";
import { DisplayText } from "./text";

export type DisplayChildrenProps = {
  items: Descendant[];
};

export const DisplayChildren = ({ items }: DisplayChildrenProps) => (
  <>
    {items.map((element, index) => {
      if (isEditorElementType("paragraph")(element))
        return (
          <DisplayParagraph key={index}>
            <DisplayChildren items={element.children} />
          </DisplayParagraph>
        );

      if (isEditorText(element))
        return <DisplayText element={element} key={index} />;

      if (isEditorElementType("placeholder")(element))
        return <DisplayPlaceholder key={index} element={element} />;

      if (isEditorElementType("link")(element)) {
        return (
          <DisplayLink element={element} key={index}>
            <DisplayChildren items={element.children} />
          </DisplayLink>
        );
      }

      return null;
    })}
  </>
);
