import { NetworkStatus } from "@apollo/client";
import { useState } from "react";

export type Status = "initial" | "loading" | "error" | "success";

export const useStatus = (initial?: Status) =>
  useState<Status>(initial || "initial");

export const statusPriority: Status[] = [
  "loading",
  "error",
  "success",
  "initial",
];

export const combineStatus = (stati: Status[]) =>
  statusPriority.find((status) => stati.indexOf(status) >= 0) || "initial";

export const networkStatusToStatus = (
  networkStatus: NetworkStatus | undefined,
  alternate: { error?: unknown; called?: boolean; loading?: boolean },
): Status => {
  if (networkStatus) {
    switch (networkStatus) {
      case NetworkStatus.loading:
      case NetworkStatus.setVariables:
        return "loading";
      case NetworkStatus.error:
        return "error";
      case NetworkStatus.fetchMore:
      case NetworkStatus.poll:
      case NetworkStatus.refetch:
      case NetworkStatus.ready:
        return "success";
      default:
        return "initial";
    }
  }

  if (alternate.loading) return "loading";
  if (alternate.error) return "error";
  if (alternate.called) return "success";
  return "initial";
};
