import { useEffect, useCallback, useRef } from "react";

export const useIsMounted = () => {
  const mountedRef = useRef(true);
  useEffect(
    () => () => {
      mountedRef.current = false;
    },
    [],
  );
  const isMounted = useCallback(() => mountedRef.current, [mountedRef]);

  return {
    isMounted,
  };
};
