import styled from "@emotion/styled";
import { RenderElementProps } from "slate-react";

import {
  EditorElement,
  EditorElementType,
} from "@smart/itops-serialisation-basic";

const ParagraphWrapper = styled.span`
  display: block;
  line-height: 1.4;
  margin: 0.6rem 0;
`;

export type ElementRendererProps<
  T extends EditorElementType = EditorElementType,
> = RenderElementProps & {
  element: EditorElement<T>;
  readOnly?: boolean;
  isLinkEnabled?: boolean;
};

export const Paragraph = ({
  attributes,
  children,
}: ElementRendererProps<"paragraph">) => (
  <ParagraphWrapper {...attributes}>{children}</ParagraphWrapper>
);
