import { Country } from "@smart/bridge-types-basic";

const domains = {
  AU: [".au", ".dev"],
  US: [".com", ".org"],
  GB: [".uk"],
};

export const isCountryDomain = (hostname: string, country: Country) => {
  const tld = hostname.slice(hostname.lastIndexOf("."));
  return domains[country].includes(tld);
};
