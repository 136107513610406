import { fromEntries, Schema, wrapSchema } from "@smart/itops-utils-basic";

import { GetHook, useGet } from "./get";
import { PostHook, usePost } from "./post";

type EndpointMethod = "GET" | "POST" | "PUT";

type EndpointDef<
  E extends string,
  M extends EndpointMethod,
  A extends boolean,
> = {
  deployable: E;
  method: M;
  authenticated: A;
};
type EndpointSchema<I, O> = { input: Schema<I>; output: Schema<O> };

type Fetcher<D, S> =
  D extends EndpointDef<string, infer M, infer A>
    ? S extends EndpointSchema<infer I, infer O>
      ? M extends "GET"
        ? GetHook<A, I, O>
        : PostHook<A, I, O>
      : never
    : never;

const isGetDef = <E extends string, A extends boolean>(
  def: EndpointDef<E, EndpointMethod, A>,
): def is EndpointDef<E, "GET", A> => def.method === "GET";

const isPostDef = <E extends string, A extends boolean>(
  def: EndpointDef<E, EndpointMethod, A>,
): def is EndpointDef<E, "POST" | "PUT", A> =>
  def.method === "POST" || def.method === "PUT";

const buildHook = <
  E extends string,
  M extends EndpointMethod,
  A extends boolean,
  I,
  O,
  D extends EndpointDef<E, M, A>,
  S extends EndpointSchema<I, O>,
>(
  prefix: string,
  def: D,
  schema: S,
): Fetcher<D, S> => {
  let urlPrefix = prefix;
  if (!prefix.match(/^(http|\/)/)) urlPrefix = `/${urlPrefix}`;
  const url = `${urlPrefix}/${def.deployable}`;
  const input = wrapSchema(schema.input);
  const output = wrapSchema(schema.output);

  if (isGetDef(def)) {
    return useGet(url, {
      input,
      output,
      authenticated: def.authenticated,
      method: def.method,
    }) as Fetcher<D, S>;
  }
  if (isPostDef(def)) {
    return usePost(url, {
      input,
      output,
      authenticated: def.authenticated,
      method: def.method,
    }) as Fetcher<D, S>;
  }

  throw new Error(`Unable to handle ${def.method} for ${def.deployable}`);
};

export const useApi = <
  K extends string,
  D extends { [k in K]: EndpointDef<k, EndpointMethod, boolean> },
  S extends { [k in K]: EndpointSchema<any, any> },
>(
  prefix: string,
  keys: K[],
  defs: D,
  schemas: S,
): { [k in K]: Fetcher<D[k], S[k]> } =>
  fromEntries(
    keys.map((key) => [key, buildHook(prefix, defs[key], schemas[key])]),
  );

export type ApiResult<F> = F extends (...args: any[]) => { result?: infer R }
  ? R
  : never;

export type ApiResults<A> = { [k in keyof A]: ApiResult<A[k]> };
