export const v4 = (): string => {
  if (typeof crypto !== "undefined" && crypto.randomUUID !== undefined)
    return crypto.randomUUID();

  if (typeof require !== "undefined") {
    // eslint-disable-next-line global-require
    const c = require("crypto");
    return c.randomUUID();
  }

  throw new Error(`No crypto available.`);
};
