import styled from "@emotion/styled";
import { position } from "polished";

import { IconDetail } from "@smart/itops-icons-dom";

import { FontSizeKey, PartColors, Variant } from "../../theme";

const Checkboxes = styled.div<{
  direction?: "row" | "column";
  align?: string;
  size?: FontSizeKey;
}>`
  display: flex;
  flex-direction: ${(props) => props.direction || "column"};
  justify-content: ${(props) => props.align || "center"};
  gap: 0.8rem;

  font-size: ${(props) =>
    props.size ? props.theme.fontSize[props.size] : "inherit"};
`;

const CheckboxItemWrapper = styled.label<{ readOnly?: boolean }>`
  position: relative;
  cursor: ${(props) => (props.readOnly ? "not-allowed" : "pointer")};
  display: flex;
  flex-flow: row;
  align-items: center;

  margin: 0.6rem 0;
  padding: 1rem 0;
  max-width: 50rem;

  input {
    appearance: none;
    outline: none;
  }

  .checkmark {
    ${position("absolute", "1rem", "0")}
    height: 2rem;
    width: 2rem;
    border: 1px solid ${(props) => props.theme.scheme.blue.r60};
    border-radius: 0.4rem;

    &:after {
      content: "";
      ${position("absolute", "0.3rem", 0, 0, "0.6rem")}
      opacity: 0;
      width: 0.6rem;
      height: 1rem;
      border: solid ${(props) => props.theme.scheme.grey.r0};
      border-width: 0 0.25rem 0.25rem 0;
      transform: rotate(45deg);
    }
  }

  :hover input ~ .checkmark {
    box-shadow: 1px 1px 1px 0px ${(props) => props.theme.scheme.blue.r60};
  }

  input:checked ~ .checkmark {
    background-color: ${(props) => props.theme.scheme.blue.r60};
    border-color: var(--background);

    &:after {
      opacity: 1;
    }
  }

  .label {
    position: relative;
    font-size: ${(props) => props.theme.fontSize.base};
    margin-left: 3rem;
  }

  .custom-response-textarea {
    margin-left: 3rem;
  }
`;

const CustomResponse = styled.div`
  min-width: 30rem;

  textarea {
    width: 100%;
    border-radius: 3px;
    border: 1px solid var(--background);
    outline: none;
    font-size: ${(props) => props.theme.fontSize.base};

    &:focus {
      border-color: var(--background);
    }
  }
`;

type CustomResponseItemProps = {
  options: { label: string; value: string }[];
  value: string[];
  readOnly?: boolean;
  onChange: (value: string[]) => void;
  onBlur?: () => void;
};

const CustomResponseItem = ({
  options,
  value,
  readOnly,
  onChange,
  onBlur,
}: CustomResponseItemProps) => {
  const customResponseIndex = Array.isArray(value)
    ? value.findIndex((v) => options.every((option) => option.value !== v))
    : -1;

  return (
    <>
      <CheckboxItemWrapper readOnly={readOnly}>
        <input
          type="checkbox"
          value="Other"
          checked={customResponseIndex >= 0}
          onChange={(e) => {
            if (readOnly) return;

            if (!e.currentTarget.checked) {
              const newVal = value.slice(0);
              newVal.splice(customResponseIndex, 1);
              onChange(newVal);
            } else {
              onChange([...value, ""]);
            }
          }}
        />
        <div className="checkmark" />
        <div className="label">Other</div>
      </CheckboxItemWrapper>
      {customResponseIndex >= 0 && (
        <CustomResponse>
          <textarea
            aria-label="Other Values"
            placeholder="Please specify"
            value={value[customResponseIndex]}
            onChange={({ target }) => {
              const newVal = value.slice(0);
              newVal[customResponseIndex] = target.value;
              onChange(newVal);
            }}
            onBlur={onBlur}
            readOnly={readOnly}
          />
        </CustomResponse>
      )}
    </>
  );
};

export type CheckboxOptionItem = {
  label: string;
  subtitle?: string;
  icon?: IconDetail & { partColors?: PartColors; variant?: Variant };
  value: string;
};

type CheckboxItemProps = {
  id?: string;
  value: string;
  label: string;
  checked?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  onChange: (checked: boolean, value: string) => void;
};

const CheckboxItem = ({
  id,
  value,
  label,
  checked,
  disabled,
  readOnly,
  onChange,
}: CheckboxItemProps) => (
  <CheckboxItemWrapper readOnly={readOnly} aria-disabled={disabled}>
    <input
      id={id && `${id}-${value}`}
      type="checkbox"
      value={value}
      checked={checked}
      onChange={(e) => onChange(e.target.checked, value)}
    />
    <div className="checkmark" />
    <div className="label">{label}</div>
  </CheckboxItemWrapper>
);
type CheckboxProps = {
  options: CheckboxOptionItem[];
  values: string[] | undefined;
  onChange: (value: string[]) => void;
  onBlur?: () => void;
  allowCustomResponse?: boolean | null;
  size?: FontSizeKey;
  id?: string;
  disabled?: boolean;
  readOnly?: boolean;
  direction?: "row" | "column";
  align?: string;
};

const Checkbox = ({
  options,
  allowCustomResponse,
  readOnly,
  disabled,
  values,
  id,
  size,
  direction,
  align,
  onChange,
  onBlur,
}: CheckboxProps) => {
  const checkedValues = values || [];
  const handleChange = (checked: boolean, value: string) => {
    if (readOnly) return;

    const updated = [...checkedValues];
    if (checked) {
      updated.push(value);
    } else {
      updated.splice(checkedValues.indexOf(value), 1);
    }
    onChange(updated);
  };

  return (
    <Checkboxes id={id} size={size} align={align} direction={direction}>
      {options.map(({ label, value }) => (
        <CheckboxItem
          value={value}
          label={label}
          checked={checkedValues.includes(value)}
          onChange={handleChange}
          readOnly={readOnly}
          disabled={disabled}
        />
      ))}
      {allowCustomResponse && (
        <CustomResponseItem
          options={options}
          value={checkedValues}
          readOnly={readOnly}
          onChange={onChange}
          onBlur={onBlur}
        />
      )}
    </Checkboxes>
  );
};

export { Checkbox, CheckboxItem };
