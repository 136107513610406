import { FontasticIcon, FontasticIconName } from "./generated";
import { LucideIcon, LucideIconName } from "./lucide";
import { BaseIconProps } from "./types";

export type IconLibrary = "fontastic" | "lucide";

export type IconLibraryName<L extends IconLibrary> = {
  fontastic: FontasticIconName;
  lucide: LucideIconName;
}[L];

export type IconDetail<L = IconLibrary> = L extends IconLibrary
  ? {
      library: L;
      name: IconLibraryName<L>;
      size?: number;
      stroke?: number;
      rotate?: number;
    }
  : never;

export type IconProps<L extends IconLibrary> = BaseIconProps & {
  library?: L;
  name: IconLibraryName<L>;
};

export const Icon = <L extends IconLibrary = "fontastic">({
  name,
  library,
  ...rest
}: IconProps<L>) => {
  switch (library) {
    case "lucide":
      return <LucideIcon name={name as LucideIconName} {...rest} />;
    case "fontastic":
    default:
      return <FontasticIcon name={name as FontasticIconName} {...rest} />;
  }
};
