import {
  Country,
  Locale,
  auLocale,
  ukLocale,
  usLocale,
} from "@smart/bridge-types-basic";
import { isTestEnv } from "@smart/itops-utils-basic";

import { isCountryDomain } from "./domain";

let locale: Locale | undefined;

export const loadLocale = (): Locale => {
  const getLocale = () => {
    if (isTestEnv()) return auLocale;
    if (isCountryDomain(window.location.hostname, "AU")) return auLocale;
    if (isCountryDomain(window.location.hostname, "US")) return usLocale;
    if (isCountryDomain(window.location.host, "GB")) return ukLocale;

    return auLocale;
  };

  if (!locale) locale = getLocale();

  return locale;
};

export const forceLocale = (country: Country) => {
  if (!isTestEnv()) throw new Error("forceLocale only available in test");

  switch (country) {
    case "AU":
      locale = auLocale;
      break;
    case "US":
      locale = usLocale;
      break;
    case "GB":
      locale = ukLocale;
      break;
    default:
      throw new Error(`Unknown country ${country}`);
  }
};

export const loadDefaultCountry = () => loadLocale().country;
