export type Mark = "bold" | "italic";

export type EditorText = { text: string } & Partial<Record<Mark, boolean>>;

export const isEditorText = (e: any): e is EditorText =>
  typeof e === "object" && typeof e.text === "string";

const ELEMENT_TYPE = ["paragraph", "link", "placeholder"] as const;

export type EditorElementType = (typeof ELEMENT_TYPE)[number];

export type EditorElement<T extends EditorElementType = EditorElementType> = {
  type: T;
  children: (EditorText | EditorElement)[];
} & {
  paragraph: {};
  link: { url: string };
  placeholder: {
    name: string;
    isLink?: boolean;
    displayText?: string;
    replacingValue?: string;
    casing?: "lowercase";
  };
}[T];

export type ParagraphTag =
  | "p"
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6"
  | "span";

export const isEditorElement = (e: any): e is EditorElement =>
  typeof e === "object" && ELEMENT_TYPE.includes(e.type);

export const isEditorElementType =
  <T extends EditorElementType>(type: T) =>
  (e: any): e is EditorElement<T> =>
    typeof e === "object" && e.type === type;

export type PlaceholderData = Record<string, string | undefined>;

export type PlaceholderDetails<P extends string> = Record<
  P,
  {
    label: string;
    defaultValue?: string;
    isLink?: boolean;
    lowerable?: boolean;
  }
>;

declare module "slate" {
  interface CustomTypes {
    Element: EditorElement;
    Text: EditorText;
  }
}
