import {
  format,
  formatDistanceToNow,
  differenceInDays,
  isSameDay as isSameDayDateFn,
} from "date-fns";

import { shouldLog } from "./env";
import { specialChars } from "./special";

const convertToDate = (
  input: Date | number | string | undefined,
  parseAsLocal?: boolean,
): Date | number | undefined => {
  let dateValue;
  if (typeof input === "string" && input.match(/^\d+$/)) {
    dateValue = parseInt(input, 10);
  } else if (typeof input === "string") {
    const dateOnlyRegex = /^\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/g;
    if (parseAsLocal && !!input.match(dateOnlyRegex)) {
      const formalised = input
        .split("-")
        .map((p) => p.padStart(2, "0"))
        .join("-");
      // Add time it is treated as local time
      dateValue = new Date(`${formalised}T00:00:00`);
    } else {
      dateValue = new Date(input);
    }
  } else {
    dateValue = input;
  }

  return dateValue;
};

export function formatDate(
  start: Date | number | string | undefined,
  options?: {
    short?: boolean;
    allowUndefined?: boolean;
    parseAsLocal?: boolean;
  },
) {
  const startDate = convertToDate(start, options?.parseAsLocal);
  if (!startDate)
    return options?.allowUndefined ? undefined : specialChars.enDash;

  try {
    return format(startDate, options?.short ? "MMM d" : "MMMM d, y");
  } catch (e) {
    if (shouldLog()) console.error("Failed to format date", start);
    return options?.allowUndefined ? undefined : specialChars.enDash;
  }
}

export function formatDateByDistanceToNow(
  start: Date | number | string | undefined,
  options?: { short?: boolean; daysLimit?: number; preposition?: string },
) {
  const startDate = convertToDate(start);
  if (!startDate) return specialChars.enDash;

  try {
    const daysDifference = differenceInDays(startDate, Date.now());

    if (options?.daysLimit && Math.abs(daysDifference) > options?.daysLimit) {
      const formattedDate = format(
        startDate,
        options?.short ? "MMM d" : "MMMM d, y",
      );
      return options.preposition
        ? `${options.preposition} ${formattedDate}`
        : formattedDate;
    }

    return new Date(startDate).valueOf() < Date.now()
      ? `${formatDistanceToNow(startDate)} ago`
      : formatDistanceToNow(startDate);
  } catch (e) {
    if (shouldLog()) console.error("Failed to format date", start);
    return specialChars.enDash;
  }
}

export function isSameDay(
  first: Date | number | string | undefined,
  second: Date | number | string | undefined,
): boolean {
  try {
    const firstDate = convertToDate(first);
    const secondDate = convertToDate(second);
    if (!firstDate || !secondDate) return false;

    return isSameDayDateFn(firstDate, secondDate);
  } catch (error) {
    if (shouldLog())
      console.error(`Failed to check the same day: ${first} and ${second}`);
    return false;
  }
}

// This is for consistent date string parsing:
// "1992-3-12" and "1992-03-12" should have the same result
export const parseDateString = (dateString: string | undefined | null) => {
  if (!dateString) return new Date("");

  let formatedDate: string;
  try {
    formatedDate = format(new Date(dateString), "yyyy-MM-dd");
  } catch (e) {
    formatedDate = "";
  }
  return new Date(formatedDate);
};
