export const PLACEHOLDER_TOKEN_TYPE = "placeholder";
export const PLACEHOLDER_MARKER_TOKEN_TYPE = "placeholderMarker";
export const PLACEHOLDER_NAME_TOKEN_TYPE = "placeholderName";
export const DISPLAY_TEXT_MARKER_TOKEN_TYPE = "displayTextMarker";
export const DISPLAY_TEXT_TOKEN_TYPE = "displayText";
export const IS_LINK_MARKER_TOKEN_TYPE = "isLinkMarker";
export const CASING_TOKEN_TYPE = "casing";
export const CHUNK_STRING_TOKEN_TYPE = "chunkString";

export const PLACEHOLDER_NAME_KEY = "placeholderName";
export const DISPLAY_TEXT_KEY = "displayText";
export const IS_LINK_KEY = "isLink";
export const CASING_KEY = "casing";

declare module "micromark-util-types" {
  interface TokenTypeMap {
    [PLACEHOLDER_TOKEN_TYPE]: typeof PLACEHOLDER_TOKEN_TYPE;
    [PLACEHOLDER_MARKER_TOKEN_TYPE]: typeof PLACEHOLDER_MARKER_TOKEN_TYPE;
    [PLACEHOLDER_NAME_TOKEN_TYPE]: typeof PLACEHOLDER_NAME_TOKEN_TYPE;
    [DISPLAY_TEXT_MARKER_TOKEN_TYPE]: typeof DISPLAY_TEXT_MARKER_TOKEN_TYPE;
    [DISPLAY_TEXT_TOKEN_TYPE]: typeof DISPLAY_TEXT_TOKEN_TYPE;
    [IS_LINK_MARKER_TOKEN_TYPE]: typeof IS_LINK_MARKER_TOKEN_TYPE;
    [CASING_TOKEN_TYPE]: typeof CASING_TOKEN_TYPE;
  }

  interface Token {
    _placeholderName?: string;
    _displayText?: string;
    _isLink?: boolean;
    _casing?: "lowercase";
  }

  interface CompileData {
    [PLACEHOLDER_NAME_KEY]?: string;
    [DISPLAY_TEXT_KEY]?: string;
    [IS_LINK_KEY]?: boolean;
    [CASING_KEY]?: "lowercase";
  }
}
