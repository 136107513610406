import { css } from "@emotion/react";

import interItalic from "./InterVariable-Italic.woff2";
import inter from "./InterVariable.woff2";
import { fontFaces } from "../util";

const fontFamily = `Inter`;

export const interStack = `${fontFamily}, system-ui, sans-serif`;

export const interCss = css`
  ${fontFaces({
    family: fontFamily,
    format: "woff2",
    formats: [
      { style: "normal", weight: "100 900", path: inter },
      { style: "italic", weight: "100 900", path: interItalic },
    ],
  })}
`;
