import { createContext, useContext } from "react";

import {
  EditorElement,
  PlaceholderDetails,
} from "@smart/itops-serialisation-basic";

import { ModalOption } from "./actions";

export type EditorPlaceholders<P extends string = string> = {
  keys: P[];
  details: PlaceholderDetails<P>;
  data: Partial<Record<P, string>>;
};

export type EditorModal = { option: ModalOption; element?: EditorElement };

export type EditorContextValue = {
  placeholders?: EditorPlaceholders;
  modal: EditorModal | undefined;
  setModal: (m: EditorModal | undefined) => void;
};

const editorContext = createContext<EditorContextValue>({
  modal: undefined,
  setModal: () => {},
});

export const EditorContextProvider = editorContext.Provider;

export const useEditorContext = () => useContext(editorContext);
