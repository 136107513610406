import { Theme, css, keyframes } from "@emotion/react";
import { position } from "polished";

const pulse = keyframes`
  50% {
    opacity: 0.5;
  }
`;

export const skeleton =
  (...positionArgs: number[]) =>
  (props: { theme: Theme }) => css`
    animation: ${pulse} 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
    color: transparent;

    position: relative;
    overflow: hidden;

    &::after {
      content: "";
      ${position("absolute", ...positionArgs)}
      background-color: ${props.theme.scheme.grey.r30};
      border-radius: inherit;
    }
  `;
