import { ActivityType } from "./user-activity-state";

const submissionEventDataTypeValue = [
  "start-sending-submission",
  "send-submission",
  "open-submission",
] as const;

const pageEventDataTypeValue = ["view", "leave"] as const;

const formEventDataTypeValue = ["scroll", "click", "focus", "type"] as const;

const systemGeneratedEventDataTypeValue = [
  "pause",
  "complete",
  "heartbeat",
] as const;

export const activityEventDataTypeValue = [
  ...submissionEventDataTypeValue,
  ...pageEventDataTypeValue,
  ...formEventDataTypeValue,
  ...systemGeneratedEventDataTypeValue,
] as const;

export type SubmissionEventDataType =
  (typeof submissionEventDataTypeValue)[number];

export type PageEventDataType = (typeof pageEventDataTypeValue)[number];

export type FormEventDataType = (typeof formEventDataTypeValue)[number];

export type EventDataType = (typeof activityEventDataTypeValue)[number];

export type ActivityEvent = {
  type: EventDataType;
  activity: "send-submission" | "view-submission";
  userId: string;
  matterId: string;
  submissionId?: string;
  formId?: string;
  fieldId?: string;
  createdAt: string;
  operationId: string;
};

export type ActivityHeartbeat = {
  userId: string;
  createdAt: string;
};

type CustomEventType = "custom_element_event";

export type CapturedEventType =
  | "click"
  | "scroll"
  | "focus"
  | "input"
  | "visibilitychange"
  | CustomEventType;

export type EventCaptureAttributes = {
  "data-enabled-capture": string;
  "data-captured-event-type": CapturedEventType;
  "data-event-data-type": EventDataType;
  "data-activity-type": ActivityType;
  "data-submission-id"?: string | undefined;
  "data-field-id"?: string | undefined;
  "data-form-id": string | undefined;
};
