import styled from "@emotion/styled";
import { position } from "polished";
import { PropsWithChildren } from "react";

import { Icon, IconName } from "@smart/itops-icons-dom";
import { UserErrorLevel } from "@smart/itops-utils-basic";

import { levelColour } from "./theme";

const OverlayWrapper = styled.div<{ level?: UserErrorLevel }>`
  ${position("absolute", 0)}
  background-color: rgba(255, 255, 255, 0.95);
  color: ${levelColour};

  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;

  .contents {
    padding: 2rem;
    max-width: 50rem;
    width: 100%;
    text-align: center;

    img {
      display: block;
      margin: 2rem auto;
      width: 6rem;
    }

    h1 {
      font-size: ${(props) => props.theme.fontSize.heading};
      font-weight: bold;
      margin: 1rem 0;
    }

    p {
      font-size: ${(props) => props.theme.fontSize.base};
      font-weight: 500;
      line-height: 1.4;
      margin: 1rem 0;
    }
  }
`;

type OverlayProps = PropsWithChildren<{
  icon?: IconName;
  image?: string;
  heading?: string;
  subHeading?: string;
  title?: string;
  level?: UserErrorLevel;
}>;

const Overlay = ({
  heading,
  subHeading,
  title,
  icon,
  image,
  level,
  children,
}: OverlayProps) => (
  <OverlayWrapper level={level} title={title}>
    <div className="contents">
      {icon && <Icon size={60} name={icon} />}
      {image && <img src={image} alt={title} />}
      {heading && <h1>{heading}</h1>}
      {subHeading && <p>{subHeading}</p>}
      {children}
    </div>
  </OverlayWrapper>
);

export { OverlayProps, Overlay };
