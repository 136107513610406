import type { Theme } from "@emotion/react";

import { systemStack } from "@smart/itops-fonts-dom";
import { Scheme, scheme } from "@smart/itops-ui-dom";
import { UserErrorLevel } from "@smart/itops-utils-basic";

export type Colour = {
  base: string;
  accent: string;
  highlight: string;
  overlay: string;
};

declare module "@emotion/react" {
  export interface Theme {
    baseUnit: number;
    baseFontSize: string;
    fonts: {
      title: string;
      body: string;
      small: string;
    };
    fontSize: {
      heading: string;
      subHeading: string;
      emphasis: string;
      base: string;
      small: string;
      xSmall: string;
    };
    palette: {
      background: Colour;
      foreground: Colour;

      primary: Colour;
      secondary: Colour;
      brand: Colour;
      active: Colour;
      danger: Colour;
      warning: Colour;
      success: Colour;
      default: Colour;
      disabled: Colour;
    };
    scheme: Scheme;
    breakPoints: {
      mobile: number;
      tablet: number;
      readable: number;
      medium: number;
      maximum: number;
    };
  }
}

export type SmartTheme = Theme;

const bg = "#FEFEFE";
const fg = "#232E41";

export const defaultTheme: Theme = {
  baseUnit: 1,
  baseFontSize: "62.5%",
  fonts: {
    title: systemStack,
    body: systemStack,
    small: systemStack,
  },
  fontSize: {
    heading: "3rem",
    subHeading: "2.2rem",
    emphasis: "1.8rem",
    base: "1.6rem",
    small: "1.4rem",
    xSmall: "1.2rem",
  },
  palette: {
    background: {
      base: bg,
      accent: "#F3F4F6",
      highlight: "#DEDEDE",
      overlay: fg,
    },
    foreground: {
      base: fg,
      accent: "#304860",
      highlight: "#4A637C",
      overlay: bg,
    },
    primary: {
      base: "#0EA5E9",
      accent: "#7DD3FC",
      highlight: "#7DD3FC",
      overlay: bg,
    },
    secondary: {
      base: "#075985",
      accent: "#0284C7",
      highlight: "#0284C7",
      overlay: bg,
    },
    brand: {
      base: "#F2600C",
      accent: "#F2600C",
      highlight: "#F2600C",
      overlay: bg,
    },
    active: {
      base: "#007082",
      accent: "#8CA2AF",
      highlight: "#BFCCD2",
      overlay: bg,
    },
    danger: {
      base: "#BE123C",
      accent: "#F43F5E",
      highlight: "#F43F5E",
      overlay: bg,
    },
    warning: {
      base: "#F2B714",
      accent: "#F0D395",
      highlight: "#F7E4C6",
      overlay: bg,
    },
    success: {
      base: "#15803D",
      accent: "#22C55E",
      highlight: "#22C55E",
      overlay: bg,
    },
    default: {
      base: "#232E41",
      accent: "#304860",
      highlight: "#304860",
      overlay: bg,
    },
    disabled: {
      base: "#BCBCBC",
      accent: "#DEDEDE",
      highlight: "#DEDEDE",
      overlay: "#888888",
    },
  },
  scheme,
  breakPoints: {
    mobile: 400,
    tablet: 680,
    readable: 800,
    medium: 900,
    maximum: 1400,
  },
};

export { ThemeProvider } from "@emotion/react";

export type ColourKey = keyof Theme["palette"];

export type BreakPointKey = keyof Theme["breakPoints"];

export type FontSizeKey = keyof Theme["fontSize"];

export const levelColour = ({
  level,
  theme: { palette },
}: {
  level?: UserErrorLevel;
  theme: Theme;
}): string => {
  switch (level) {
    case "SUCCESS":
      return palette.success.base;
    case "WARN":
      return palette.danger.base;
    case "INFO":
      return palette.primary.base;
    case "DEFAULT":
      return palette.foreground.accent;
    default:
      return palette.foreground.highlight;
  }
};
