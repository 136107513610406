import { logError } from "./errors";

export const delay = (ms: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

export const timeout = async (ms: number, message: string) => {
  await delay(ms);
  throw new Error(message);
};

export const wrapRun =
  <A extends any[], R>({
    run,
    shouldLog,
    shouldCatch,
  }: {
    run: (...args: A) => Promise<R>;
    shouldLog?: string;
    shouldCatch?: boolean;
  }) =>
  async (attempt: number, ...args: A): Promise<R | undefined> => {
    try {
      if (shouldLog)
        console.info(`Running ${shouldLog}... (attempt #${attempt})`);
      const result = await run(...args);
      return result;
    } catch (e) {
      if (shouldLog) logError(e, { shouldLog, attempt });
      if (!shouldCatch) throw e;
    }

    return undefined;
  };

export const backoff =
  <A extends any[], R>({
    run,
    test,
    base,
    attempts,
    shouldLog,
    shouldCatch,
  }: {
    run: (...args: A) => Promise<R>;
    test: (result: R | undefined) => boolean;
    base: number;
    attempts: number;
    shouldLog?: string;
    shouldCatch?: boolean;
  }) =>
  async (...args: A): Promise<R | undefined> => {
    let attempt = 1;
    const wrapped = wrapRun({ run, shouldLog, shouldCatch });

    let result = await wrapped(attempt, ...args);
    while (!test(result) && attempt <= attempts) {
      await delay(base * attempt);
      attempt += 1;
      result = await wrapped(attempt, ...args);
    }

    return result;
  };
