import styled from "@emotion/styled";
import { ReactNode } from "react";

import { ProgressOverlay } from "./progress";
import { BreakPointKey } from "../theme";

type CardWrapperProps = {
  breakPoint: BreakPointKey;
  align?: "center" | "flex-start";
  flow?: "row" | "column";
  current?: boolean;
};

const CardWrapper = styled.div<CardWrapperProps>`
  display: flex;
  flex-flow: ${(props) => props.flow || "column"};
  align-items: ${(props) => props.align || "initial"};
  width: 100%;
  max-width: ${(props) => props.theme.breakPoints[props.breakPoint]}px;
  --background: white;
  background-color: var(--background);
  border-radius: 1rem;
  padding: 1.8rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;

  border: 2px solid
    ${(props) => (props.current ? props.theme.scheme.blue.r80 : "transparent")};

  .card-overlay {
    position: absolute;
    overflow: hidden;
    border-radius: inherit;
    bottom: -2px;
    left: -2px;
    right: -2px;
    height: 2rem;
  }
`;

export type CardProps = {
  children: ReactNode;
  loading?: boolean;
} & CardWrapperProps;

export const Card = ({
  children,
  loading,
  breakPoint,
  align,
  flow,
  current,
}: CardProps) => (
  <CardWrapper
    breakPoint={breakPoint}
    current={current}
    align={align}
    flow={flow}
  >
    {children}
    {loading && (
      <div className="card-overlay">
        <ProgressOverlay position="bottom" size={0.3} indeterminate />
      </div>
    )}
  </CardWrapper>
);
