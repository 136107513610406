import styled from "@emotion/styled";
import { position, transparentize } from "polished";
import { useEffect, useState } from "react";

import { ClickOutsideWrapper, Icon } from "@smart/itops-components-dom";

const DropdownButton = styled.button`
  padding: 0.4rem 0.6rem;
  border-radius: 0.2rem;
  cursor: pointer;
  position: relative;
  min-height: 2.7rem;
  width: 100%;
  outline: none;

  background: ${(props) => props.theme.palette.default.base};
  border: 1px solid ${(props) => props.theme.palette.default.accent};
  color: ${(props) => props.theme.palette.default.overlay};

  display: flex;
  align-items: center;
  justify-content: space-between;

  .right-icon {
    margin-left: 1rem;
  }

  .text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: calc(100% - 2rem);
    text-align: left;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const OptionList = styled.div`
  ${position("absolute", "3rem", 0, 0)}
  background-color: ${(props) => props.theme.palette.background.base};
  border: 1px solid ${(props) => props.theme.palette.disabled.base};
  border-radius: 0.2rem;

  width: 100%;
  height: fit-content;
  z-index: 1;
`;

const OptionWrapper = styled.label`
  position: relative;
  cursor: pointer;
  display: flex;
  flex-flow: row;
  align-items: center;
  width: 100%;

  input {
    display: none;
  }

  .checkmark {
    ${position("absolute", "0.3rem", "0.3rem")}
    height: 1.6rem;
    width: 1.6rem;
    background-color: ${(props) => props.theme.palette.background.base};
    border: 1px solid ${(props) => props.theme.palette.disabled.base};
    border-radius: 0.2rem;

    &:after {
      content: "";
      ${position("absolute", 0, 0, 0, "0.4rem")}
      opacity: 0;
      width: 0.6rem;
      height: 1.2rem;
      border: solid ${(props) => props.theme.palette.primary.base};
      border-width: 0 0.25rem 0.25rem 0;
      transform: rotate(45deg);
    }
  }

  .intermediate {
    ${position("absolute", "0.3rem", "0.3rem")}
    height: 1.6rem;
    width: 1.6rem;
    background-color: ${(props) => props.theme.palette.background.base};
    border: 1px solid ${(props) => props.theme.palette.disabled.base};
    border-radius: 0.2rem;

    &:after {
      content: "";
      ${position("absolute", "-0.3rem", 0, 0, "0.3rem")}
      width: 0.8rem;
      height: 1.2rem;
      border: solid ${(props) => props.theme.palette.primary.base};
      border-width: 0 0 0.25rem 0;
    }
  }

  input:checked ~ .checkmark {
    &:after {
      opacity: 1;
    }
  }

  .label {
    position: relative;
    font-size: ${(props) => props.theme.fontSize.base};
    margin-left: 2.4rem;
  }
`;

const Option = styled.div`
  width: 100%;
  padding: 0.3rem 0;

  :hover {
    background-color: ${(props) =>
      transparentize(0.8, props.theme.palette.primary.base)};
  }
`;

type SelectOption = {
  value: string;
  label: string;
};

type MultipleSelectProps = {
  options: SelectOption[];
  values?: string[];
  placeholder?: string;
  selectAllOption?: boolean;
  disabled?: boolean;
  onChange?: (selectedValues: string[]) => void;
};

const MultipleSelect = ({
  options,
  values,
  placeholder,
  selectAllOption,
  disabled,
  onChange,
}: MultipleSelectProps) => {
  const [optionVisible, setOptionVisible] = useState(false);
  const [selectedValues, setSelectedValues] = useState<string[]>(values || []);

  const updateSelectedValues = (value: string) => {
    const updatedSelectedValues = selectedValues.includes(value)
      ? selectedValues.filter((v) => v !== value)
      : [...selectedValues, value];

    setSelectedValues(updatedSelectedValues);

    if (onChange) onChange(updatedSelectedValues);
  };

  const isAllSelected = options.length === selectedValues.length;

  const isSomeSelected = !isAllSelected && selectedValues.length > 0;

  const onSelectAll = () =>
    selectedValues.length === 0
      ? setSelectedValues(options.map((o) => o.value))
      : setSelectedValues([]);

  const displayLabel = options
    .filter((option) => selectedValues.includes(option.value))
    .map((option) => option.label)
    .join(", ");

  useEffect(() => {
    if (values) {
      setSelectedValues(values);
    }
  }, [values]);

  return (
    <ClickOutsideWrapper onClickOutside={() => setOptionVisible(false)}>
      <div>
        <DropdownButton
          onClick={() => setOptionVisible(!optionVisible)}
          disabled={disabled}
          aria-label="dropdown-button"
        >
          <span className="text">
            {displayLabel.length > 0 ? displayLabel : placeholder || ""}
          </span>
          <Icon className="right-icon" size={8} name="triangleDown" />
        </DropdownButton>
        {optionVisible && (
          <OptionList>
            {selectAllOption && (
              <OptionWrapper key="smart-multiple-select-select-all-option">
                <Option>
                  <input
                    type="checkbox"
                    onChange={onSelectAll}
                    checked={isAllSelected}
                    aria-label="Select all"
                    data-testid="multiple-select-option-select-all"
                  />
                  <div className="checkmark" />
                  {isSomeSelected && <div className="intermediate" />}
                  <div className="label">(Select all)</div>
                </Option>
              </OptionWrapper>
            )}
            {options.map((option) => (
              <OptionWrapper key={option.value}>
                <Option>
                  <input
                    type="checkbox"
                    onChange={() => updateSelectedValues(option.value)}
                    checked={selectedValues.includes(option.value)}
                    aria-label={option.label}
                    data-testid={`multiple-select-option.${option.value}`}
                  />
                  <div className="checkmark" />
                  <div className="label">{option.label}</div>
                </Option>
              </OptionWrapper>
            ))}
          </OptionList>
        )}
      </div>
    </ClickOutsideWrapper>
  );
};

export { MultipleSelect, MultipleSelectProps };
