export const unique = <I>(items: I[]): I[] => [...new Set(items)];

export const uniqueKey = <I extends {}, K extends keyof I>(
  items: I[],
  key: K,
): I[K][] => unique(items.map((i) => i[key]));

export const uniqueMap = <
  I extends {},
  K extends keyof I,
  N extends string | number,
>(
  items: I[],
  key: K,
  newKey: N,
) =>
  uniqueKey(items, key).map(
    (value) => ({ [newKey]: value }) as { [k in N]: I[K] },
  );
