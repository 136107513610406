import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { useCallback, useState } from "react";

import { useTimeout } from "@smart/itops-hooks-dom";
import { IconName } from "@smart/itops-icons-dom";

import { IconButton } from "./icon";
import { Button } from "./standard";
import { buttonColor, ButtonProps } from "./utils";

const shrink = keyframes`
  0% { width: 100% }
  100% { width: 0% }
`;

const Confirm = styled.div<ButtonProps & { delay: number; loading?: boolean }>`
  display: flex;
  flex-flow: row wrap;
  align-items: center;

  opacity: ${(props) => (props.loading ? 0.6 : 1)};
  pointer-events: ${(props) => (props.loading ? "none" : "auto")};

  p {
    font-size: ${(props) => props.theme.fontSize.base};
    font-weight: 600;
    margin: 0;
    margin-right: 1rem;
    padding: 0.6rem 2rem;
    position: relative;

    :after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      height: 2px;
      background: ${buttonColor};

      animation: ${shrink} ${(props) => props.delay}ms 1 linear;
    }
  }

  button {
    margin: 0;
  }
`;

type ConfirmButtonProps = ButtonProps & {
  text?: string;
  icon?: IconName;
  confirmText: string;
  delay?: number;
  loading?: boolean;
  size?: number;
  onClick: () => Promise<void> | void;
};

const ConfirmButton = ({
  text,
  icon,
  confirmText,
  delay = 4000,
  loading,
  size,
  onClick,
  ...rest
}: ConfirmButtonProps) => {
  const [clicked, setClicked] = useState(false);
  useTimeout({ condition: clicked, fn: () => setClicked(false), ms: delay });
  const done = useCallback(
    (success: boolean) => () => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      if (success) onClick();
      setClicked(false);
    },
    [],
  );
  const start = useCallback(() => setClicked(true), []);

  if (clicked)
    return (
      <Confirm {...rest} delay={delay} loading={loading}>
        <p>{confirmText}</p>
        <IconButton
          name="checkCircle"
          size={30}
          onClick={done(true)}
          {...rest}
        />
        <IconButton
          name="cancelCircle"
          palette="foreground"
          size={30}
          onClick={done(false)}
        />
      </Confirm>
    );

  return icon ? (
    <IconButton onClick={start} name={icon} text={text} {...rest} size={size} />
  ) : (
    <Button onClick={start} {...rest}>
      {text}
    </Button>
  );
};

export { ConfirmButton, ConfirmButtonProps };
