export const messageTemplateFormats = [
  "email",
  "communicate",
  "embed",
  "document",
] as const;
export type MessageTemplateFormats = (typeof messageTemplateFormats)[number];

export const templateCategories = ["matter", "lead"] as const;
export type TemplateCategories = (typeof templateCategories)[number];

export type MessageTemplateOptions = {
  template?: string;
  client?: {
    contactName?: string | null;
    person?: { firstName?: string | null; email?: string | null };
    company?: { name?: string | null; email?: string | null };
  };
  team?: {
    name?: string | null;
    phone?:
      | string
      | null
      | {
          areaCode?: string | null;
          number?: string | null;
        };
  };
  category: TemplateCategories;
  matterType: {
    name: string;
  };
  form: {
    title: string;
  };
  submissionCreatedAt?: string | null;
  format: MessageTemplateFormats;
  link: string;
  embedOption?: "button" | "iframe";
  shouldAppendFormLink?: boolean;
};

export type MessageTemplate = {
  link: string;
  text: string;
  html: string;
};

export type Placeholder = {
  name: string;
  label: string;
  defaultValue?: string;
  type: "text" | "link" | "element";
  requiredDisplayText?: boolean;
  lowerable?: boolean;
};

export const customFields: Placeholder[] = [
  {
    name: "clientName",
    label: "Client Name",
    type: "text",
  },
  {
    name: "downloadFormLink",
    label: "Download Form Link",
    defaultValue: "Click here to save a copy of your form",
    type: "element",
    requiredDisplayText: true,
  },
  {
    name: "firmName",
    label: "Firm Name",
    type: "text",
  },
  {
    name: "firmPhone",
    label: "Firm Phone",
    type: "text",
  },
  {
    name: "formLink",
    label: "Form Link",
    defaultValue: "Open Form",
    type: "link",
    requiredDisplayText: true,
  },
  {
    name: "matterCategory",
    label: "Matter Category",
    type: "text",
  },
  {
    name: "matterType",
    label: "Matter Type",
    type: "text",
    lowerable: true,
  },
];

export const placeholderNames = [
  "formLink",
  "clientName",
  "firmName",
  "firmPhone",
  "matterCategory",
  "matterType",
  "downloadFormLink",
] as const;

export type PlaceholderName = (typeof placeholderNames)[number];
export const linkPlaceholderNames: PlaceholderName[] = ["formLink"];
export const requiredDisplayTextPlaceholderNames: PlaceholderName[] =
  customFields
    .filter((f) => f.requiredDisplayText)
    .map((f) => f.name) as PlaceholderName[];

export const defaultTemplate = {
  matter: `Hi {clientName|Client Name},\n
We are happy to be representing you on this matter.\n
In order for us to progress your {_matterType|Matter Type} matter, please follow the link to the intake form and provide as much information as possible.\n
Once we receive this additional information, a member of our firm will be in touch to discuss the next steps.\n
{formLink|Open form}
`,
  lead: `Hi {clientName|Client Name},\n
Thank you for contacting {firmName|Firm Name}.\n
In order for us to assist you further with your {_matterType|Matter Type} enquiry, please follow the link to the intake form and provide as much information as possible.\n
Once we receive this additional information, a member of our firm will be in touch to discuss the next steps.\n
{formLink|Open form}
`,
  reminder: `Hi {clientName|Client Name},\n
This is a reminder to complete the form our office sent {formCreatedAt|Form Created At}.\n
Please follow the link and fill out the form to the best of your ability so that we can move forward with {_matterType|Matter Type} matter.\n
A member of our firm will be in touch to discuss the next steps once we receive your completed form.\n
If you have any questions, please do not hesitate to contact our office at {firmPhone|Firm Phone}\n
{formLink|Open form}
  `,
  response: `**Thank you!**\n
Your responses have been submitted. We will be in contact with you shortly.\n
{downloadFormLink|Click here to save a copy of your form}
`,
};

export type DescriptivePlaceholderName = Record<PlaceholderName, string>;

export const descriptivePlaceholderNames: DescriptivePlaceholderName = {
  formLink: "Form Link",
  clientName: "Client Name",
  firmName: "Firm Name",
  matterCategory: "Matter Category",
  matterType: "Matter Type",
  downloadFormLink: "Download Form Link",
  firmPhone: "Firm Phone",
};
