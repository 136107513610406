import styled from "@emotion/styled";
import { position } from "polished";
import { ReactNode } from "react";

const LandingWrapper = styled.div`
  ${position("absolute", 0)}

  display: flex;
  flex-flow: row;

  .content {
    position: relative;
    flex: 0 1 50rem;
    max-width: 50rem;
    padding: 2rem;

    border-left: 1rem solid ${(props) => props.theme.palette.primary.accent};
    display: grid;
    grid-template-columns: 8rem auto;
    grid-template-rows: 8rem auto min-content;
    grid-template-areas: "icon title" "main main" "footer footer";

    .icon {
      grid-area: icon;
      align-self: stretch;
      justify-self: stretch;
      margin: 1rem;

      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }

    .title {
      grid-area: title;
      align-self: center;

      padding-left: 1rem;
    }

    .main {
      grid-area: main;
      overflow-x: hidden;
      overflow-y: auto;

      padding-top: 1rem;
    }

    .footer {
      grid-area: footer;
    }

    h1 {
      font-size: ${(props) => props.theme.fontSize.heading};
      font-weight: bold;
      margin: 0;
    }

    p {
      font-size: ${(props) => props.theme.fontSize.base};
      line-height: 1.4;
    }

    small {
      font-size: ${(props) => props.theme.fontSize.xSmall};
      line-height: 1.4;
    }
  }

  .hero {
    flex: 1 1 auto;

    background: ${(props) => props.theme.palette.background.accent};
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  @media (max-width: ${(props) => props.theme.breakPoints.tablet}px) {
    .content {
      flex: 1;
      max-width: 100%;
    }

    .hero {
      display: none;
    }
  }
`;

type LandingProps = { children: ReactNode; image: string };

const Landing = ({ children, image }: LandingProps) => (
  <LandingWrapper>
    <div className="content">{children}</div>
    <div className="hero" style={{ backgroundImage: `url(${image})` }} />
  </LandingWrapper>
);

export { LandingProps, Landing };
