import styled from "@emotion/styled";
import { ReactNode } from "react";

import { Icon, IconDetail } from "@smart/itops-icons-dom";

import { FontSizeKey, SchemeColor, fromSchemeColor } from "../../theme";
import { Displayer } from "../displayer";
import { RequiredAsterisk } from "../required-asterisk";

export const FieldList = styled.div<{ size?: FontSizeKey }>`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;

  font-size: ${(props) =>
    props.size ? props.theme.fontSize[props.size] : "inherit"};
`;

export const FieldGroupWrapper = styled.div<{ size?: FontSizeKey }>`
  display: flex;
  font-size: ${(props) =>
    props.size ? props.theme.fontSize[props.size] : "inherit"};
  flex-direction: column;
  gap: 0.3rem;
  width: 100%;
`;

export const Label = styled.label<{
  schemeColor?: SchemeColor;
  size?: FontSizeKey;
}>`
  color: ${(props) =>
    fromSchemeColor(props.schemeColor, props.theme.scheme.grey.r80)(props)};
  font-size: ${(props) =>
    props.size ? props.theme.fontSize[props.size] : "inherit"};
  font-weight: 500;

  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: 0.6rem;
`;

export type FieldGroupProps = {
  id: string;
  label?: string | ReactNode;
  required?: boolean;
  icon?: IconDetail;
  size?: FontSizeKey;
  children: ReactNode;
  error?: string | ReactNode;
  hint?: string;
  className?: string;
};

export const FieldGroup = ({
  id,
  label,
  required,
  icon,
  size,
  children,
  error,
  hint,
  className,
}: FieldGroupProps) => (
  <FieldGroupWrapper size={size} className={className}>
    <Label htmlFor={id}>
      {icon && <Icon className="label-icon" size={16} {...icon} />}
      {label && <span className="label-text">{label}</span>}
      {required && <RequiredAsterisk />}
    </Label>
    {children}
    {error && (
      <Label
        schemeColor={["red", "r100"]}
        htmlFor={id}
        id={`${id}-error`}
        size="small"
      >
        <Icon
          className="label-icon"
          size={16}
          library="lucide"
          name="AlertCircle"
        />
        <span className="label-text">{error}</span>
      </Label>
    )}
    {hint && !error && (
      <Label schemeColor={["grey", "r50"]} htmlFor={id} size="small">
        <Icon className="label-icon" size={16} library="lucide" name="Info" />
        <Displayer value={hint} paragraphTag="span" />
      </Label>
    )}
  </FieldGroupWrapper>
);
