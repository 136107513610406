import { ButtonHTMLAttributes, ReactNode, forwardRef } from "react";

import { Icon, IconDetail } from "@smart/itops-icons-dom";

import { ButtonKind, StyledButton } from "./kinds";
import { FontSizeKey, PartColors, Variant } from "../../theme";

export type ButtonProps = {
  text?: string;
  title?: string;
  icon?: IconDetail;
  left?: ReactNode;
  right?: ReactNode;
  kind?: ButtonKind;
  variant?: Variant;
  partColors?: PartColors;
  size?: FontSizeKey;
  dataTestId?: string;
  eventCaptureAttributes?: Record<string, string | boolean | undefined>;
} & Pick<
  ButtonHTMLAttributes<HTMLButtonElement>,
  | "name"
  | "type"
  | "className"
  | "disabled"
  | "value"
  | "onClick"
  | "onMouseDown"
  | "onTouchStart"
  | "aria-label"
>;

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { text, icon, left, right, dataTestId, type = "button", ...buttonProps },
    ref,
  ) => (
    <StyledButton
      ref={ref}
      type={type}
      data-testid={dataTestId}
      {...buttonProps}
    >
      {left}
      {text && <span className="button-text">{text}</span>}
      {icon && <Icon className="button-icon" {...icon} />}
      {right}
    </StyledButton>
  ),
);

export * from "./wrappers";
