export type TimeZone = {
  label: string;
  tzCode: string;
  name: string;
  utc: string;
  region: string;
};

export const timeZones: TimeZone[] = [
  {
    label: "Pacific/Midway (GMT-11:00)",
    tzCode: "Pacific/Midway",
    name: "Midway (GMT-11:00)",
    utc: "-11:00",
    region: "Pacific",
  },
  {
    label: "Pacific/Niue (GMT-11:00)",
    tzCode: "Pacific/Niue",
    name: "Alofi (GMT-11:00)",
    utc: "-11:00",
    region: "Pacific",
  },
  {
    label: "Pacific/Pago_Pago (GMT-11:00)",
    tzCode: "Pacific/Pago_Pago",
    name: "Taulaga Ta`ū, Tāfuna, Pago, Pago (GMT-11:00)",
    utc: "-11:00",
    region: "Pacific",
  },
  {
    label: "America/Adak (GMT-10:00)",
    tzCode: "America/Adak",
    name: "Adak (GMT-10:00)",
    utc: "-10:00",
    region: "America",
  },
  {
    label: "Pacific/Honolulu (GMT-10:00)",
    tzCode: "Pacific/Honolulu",
    name: "Kailua Hilo, City, Pearl Honolulu, East Honolulu, (GMT-10:00)",
    utc: "-10:00",
    region: "Pacific",
  },
  {
    label: "Pacific/Rarotonga (GMT-10:00)",
    tzCode: "Pacific/Rarotonga",
    name: "Avarua (GMT-10:00)",
    utc: "-10:00",
    region: "Pacific",
  },
  {
    label: "Pacific/Tahiti (GMT-10:00)",
    tzCode: "Pacific/Tahiti",
    name: "Mahina Pirae, Punaauia, Papeete, Faaa, (GMT-10:00)",
    utc: "-10:00",
    region: "Pacific",
  },
  {
    label: "Pacific/Marquesas (GMT-09:30)",
    tzCode: "Pacific/Marquesas",
    name: "Taiohae (GMT-09:30)",
    utc: "-09:30",
    region: "Pacific",
  },
  {
    label: "America/Anchorage (GMT-09:00)",
    tzCode: "America/Anchorage",
    name: "Knik-Fairview Badger, River, Eagle Fairbanks, Anchorage, (GMT-09:00)",
    utc: "-09:00",
    region: "America",
  },
  {
    label: "America/Juneau (GMT-09:00)",
    tzCode: "America/Juneau",
    name: "Juneau (GMT-09:00)",
    utc: "-09:00",
    region: "America",
  },
  {
    label: "America/Metlakatla (GMT-09:00)",
    tzCode: "America/Metlakatla",
    name: "Metlakatla (GMT-09:00)",
    utc: "-09:00",
    region: "America",
  },
  {
    label: "America/Nome (GMT-09:00)",
    tzCode: "America/Nome",
    name: "Nome (GMT-09:00)",
    utc: "-09:00",
    region: "America",
  },
  {
    label: "America/Sitka (GMT-09:00)",
    tzCode: "America/Sitka",
    name: "Ketchikan Sitka, (GMT-09:00)",
    utc: "-09:00",
    region: "America",
  },
  {
    label: "America/Yakutat (GMT-09:00)",
    tzCode: "America/Yakutat",
    name: "Yakutat (GMT-09:00)",
    utc: "-09:00",
    region: "America",
  },
  {
    label: "Pacific/Gambier (GMT-09:00)",
    tzCode: "Pacific/Gambier",
    name: "Gambier (GMT-09:00)",
    utc: "-09:00",
    region: "Pacific",
  },
  {
    label: "America/Los_Angeles (GMT-08:00)",
    tzCode: "America/Los_Angeles",
    name: "Seattle Francisco, San Jose, San Diego, San Angeles, Los (GMT-08:00)",
    utc: "-08:00",
    region: "America",
  },
  {
    label: "America/Tijuana (GMT-08:00)",
    tzCode: "America/Tijuana",
    name: "Tecate Rosarito, Ensenada, Mexicali, Tijuana, (GMT-08:00)",
    utc: "-08:00",
    region: "America",
  },
  {
    label: "America/Vancouver (GMT-08:00)",
    tzCode: "America/Vancouver",
    name: "Burnaby Victoria, Okanagan, Surrey, Vancouver, (GMT-08:00)",
    utc: "-08:00",
    region: "America",
  },
  {
    label: "Pacific/Pitcairn (GMT-08:00)",
    tzCode: "Pacific/Pitcairn",
    name: "Adamstown (GMT-08:00)",
    utc: "-08:00",
    region: "Pacific",
  },
  {
    label: "America/Boise (GMT-07:00)",
    tzCode: "America/Boise",
    name: "Pocatello Falls, Idaho Nampa, Meridian, Boise, (GMT-07:00)",
    utc: "-07:00",
    region: "America",
  },
  {
    label: "America/Cambridge_Bay (GMT-07:00)",
    tzCode: "America/Cambridge_Bay",
    name: "Bay Cambridge (GMT-07:00)",
    utc: "-07:00",
    region: "America",
  },
  {
    label: "America/Chihuahua (GMT-07:00)",
    tzCode: "America/Chihuahua",
    name: "Grandes Casas Nuevo Parral, Cuauhtémoc, Delicias, Ciudad Chihuahua, (GMT-07:00)",
    utc: "-07:00",
    region: "America",
  },
  {
    label: "America/Creston (GMT-07:00)",
    tzCode: "America/Creston",
    name: "Creston (GMT-07:00)",
    utc: "-07:00",
    region: "America",
  },
  {
    label: "America/Dawson (GMT-07:00)",
    tzCode: "America/Dawson",
    name: "Dawson (GMT-07:00)",
    utc: "-07:00",
    region: "America",
  },
  {
    label: "America/Dawson_Creek (GMT-07:00)",
    tzCode: "America/Dawson_Creek",
    name: "Creek Dawson John, St. Fort (GMT-07:00)",
    utc: "-07:00",
    region: "America",
  },
  {
    label: "America/Denver (GMT-07:00)",
    tzCode: "America/Denver",
    name: "Aurora Springs, Colorado Albuquerque, Paso, El Denver, (GMT-07:00)",
    utc: "-07:00",
    region: "America",
  },
  {
    label: "America/Edmonton (GMT-07:00)",
    tzCode: "America/Edmonton",
    name: "Lethbridge Deer, Red McMurray, Fort Edmonton, Calgary, (GMT-07:00)",
    utc: "-07:00",
    region: "America",
  },
  {
    label: "America/Fort_Nelson (GMT-07:00)",
    tzCode: "America/Fort_Nelson",
    name: "Nelson Fort (GMT-07:00)",
    utc: "-07:00",
    region: "America",
  },
  {
    label: "America/Hermosillo (GMT-07:00)",
    tzCode: "America/Hermosillo",
    name: "Navojoa Colorado, Río Luis San Nogales, Obregón, Ciudad Hermosillo, (GMT-07:00)",
    utc: "-07:00",
    region: "America",
  },
  {
    label: "America/Inuvik (GMT-07:00)",
    tzCode: "America/Inuvik",
    name: "Inuvik (GMT-07:00)",
    utc: "-07:00",
    region: "America",
  },
  {
    label: "America/Mazatlan (GMT-07:00)",
    tzCode: "America/Mazatlan",
    name: "Paz La Mochis, Los Tepic, Mazatlán, Culiacán, (GMT-07:00)",
    utc: "-07:00",
    region: "America",
  },
  {
    label: "America/Ojinaga (GMT-07:00)",
    tzCode: "America/Ojinaga",
    name: "Ojinaga Ojinaga, Manuel Juárez, Ciudad (GMT-07:00)",
    utc: "-07:00",
    region: "America",
  },
  {
    label: "America/Phoenix (GMT-07:00)",
    tzCode: "America/Phoenix",
    name: "Gilbert Chandler, Mesa, Tucson, Phoenix, (GMT-07:00)",
    utc: "-07:00",
    region: "America",
  },
  {
    label: "America/Whitehorse (GMT-07:00)",
    tzCode: "America/Whitehorse",
    name: "Whitehorse (GMT-07:00)",
    utc: "-07:00",
    region: "America",
  },
  {
    label: "America/Yellowknife (GMT-07:00)",
    tzCode: "America/Yellowknife",
    name: "Yellowknife (GMT-07:00)",
    utc: "-07:00",
    region: "America",
  },
  {
    label: "America/Bahia_Banderas (GMT-06:00)",
    tzCode: "America/Bahia_Banderas",
    name: "Banderas de Valle Bucerías, Vicente, San Mezcales, (GMT-06:00)",
    utc: "-06:00",
    region: "America",
  },
  {
    label: "America/Belize (GMT-06:00)",
    tzCode: "America/Belize",
    name: "Dangriga Belmopan, Walk, Orange Ignacio, San City, Belize (GMT-06:00)",
    utc: "-06:00",
    region: "America",
  },
  {
    label: "America/Chicago (GMT-06:00)",
    tzCode: "America/Chicago",
    name: "Austin Dallas, Antonio, San Houston, Chicago, (GMT-06:00)",
    utc: "-06:00",
    region: "America",
  },
  {
    label: "America/Costa_Rica (GMT-06:00)",
    tzCode: "America/Costa_Rica",
    name: "Liberia Alajuela, Francisco, San Limón, José, San (GMT-06:00)",
    utc: "-06:00",
    region: "America",
  },
  {
    label: "America/El_Salvador (GMT-06:00)",
    tzCode: "America/El_Salvador",
    name: "Mejicanos Miguel, San Ana, Santa Soyapango, Salvador, San (GMT-06:00)",
    utc: "-06:00",
    region: "America",
  },
  {
    label: "America/Guatemala (GMT-06:00)",
    tzCode: "America/Guatemala",
    name: "Sacatepéquez Juan San Petapa, Nueva, Villa Mixco, City, Guatemala (GMT-06:00)",
    utc: "-06:00",
    region: "America",
  },
  {
    label: "America/Indiana/Knox (GMT-06:00)",
    tzCode: "America/Indiana/Knox",
    name: "Knox (GMT-06:00)",
    utc: "-06:00",
    region: "America",
  },
  {
    label: "America/Indiana/Tell_City (GMT-06:00)",
    tzCode: "America/Indiana/Tell_City",
    name: "City Tell (GMT-06:00)",
    utc: "-06:00",
    region: "America",
  },
  {
    label: "America/Managua (GMT-06:00)",
    tzCode: "America/Managua",
    name: "Matagalpa Chinandega, Masaya, León, Managua, (GMT-06:00)",
    utc: "-06:00",
    region: "America",
  },
  {
    label: "America/Matamoros (GMT-06:00)",
    tzCode: "America/Matamoros",
    name: "Acuña Ciudad Negras, Piedras Laredo, Nuevo Matamoros, Heroica Reynosa, (GMT-06:00)",
    utc: "-06:00",
    region: "America",
  },
  {
    label: "America/Menominee (GMT-06:00)",
    tzCode: "America/Menominee",
    name: "River Iron Ironwood, Kingsford, Mountain, Iron Menominee, (GMT-06:00)",
    utc: "-06:00",
    region: "America",
  },
  {
    label: "America/Merida (GMT-06:00)",
    tzCode: "America/Merida",
    name: "Valladolid Kanasín, Carmen, del Ciudad Campeche, Mérida, (GMT-06:00)",
    utc: "-06:00",
    region: "America",
  },
  {
    label: "America/Mexico_City (GMT-06:00)",
    tzCode: "America/Mexico_City",
    name: "Puebla Guadalajara, Morelos, de Ecatepec Iztapalapa, City, Mexico (GMT-06:00)",
    utc: "-06:00",
    region: "America",
  },
  {
    label: "America/Monterrey (GMT-06:00)",
    tzCode: "America/Monterrey",
    name: "Durango de Victoria Torreón, Guadalupe, Saltillo, Monterrey, (GMT-06:00)",
    utc: "-06:00",
    region: "America",
  },
  {
    label: "America/North_Dakota/Beulah (GMT-06:00)",
    tzCode: "America/North_Dakota/Beulah",
    name: "Beulah (GMT-06:00)",
    utc: "-06:00",
    region: "America",
  },
  {
    label: "America/North_Dakota/Center (GMT-06:00)",
    tzCode: "America/North_Dakota/Center",
    name: "Center (GMT-06:00)",
    utc: "-06:00",
    region: "America",
  },
  {
    label: "America/North_Dakota/New_Salem (GMT-06:00)",
    tzCode: "America/North_Dakota/New_Salem",
    name: "Mandan (GMT-06:00)",
    utc: "-06:00",
    region: "America",
  },
  {
    label: "America/Rainy_River (GMT-06:00)",
    tzCode: "America/Rainy_River",
    name: "River Rainy (GMT-06:00)",
    utc: "-06:00",
    region: "America",
  },
  {
    label: "America/Rankin_Inlet (GMT-06:00)",
    tzCode: "America/Rankin_Inlet",
    name: "Inlet Rankin (GMT-06:00)",
    utc: "-06:00",
    region: "America",
  },
  {
    label: "America/Regina (GMT-06:00)",
    tzCode: "America/Regina",
    name: "Battleford North Jaw, Moose Albert, Prince Regina, Saskatoon, (GMT-06:00)",
    utc: "-06:00",
    region: "America",
  },
  {
    label: "America/Resolute (GMT-06:00)",
    tzCode: "America/Resolute",
    name: "Resolute (GMT-06:00)",
    utc: "-06:00",
    region: "America",
  },
  {
    label: "America/Swift_Current (GMT-06:00)",
    tzCode: "America/Swift_Current",
    name: "Current Swift (GMT-06:00)",
    utc: "-06:00",
    region: "America",
  },
  {
    label: "America/Tegucigalpa (GMT-06:00)",
    tzCode: "America/Tegucigalpa",
    name: "Progreso El Ceiba, La Choloma, Sula, Pedro San Tegucigalpa, (GMT-06:00)",
    utc: "-06:00",
    region: "America",
  },
  {
    label: "America/Winnipeg (GMT-06:00)",
    tzCode: "America/Winnipeg",
    name: "Thompson Prairie, la Portage Kenora, Brandon, Winnipeg, (GMT-06:00)",
    utc: "-06:00",
    region: "America",
  },
  {
    label: "Pacific/Easter (GMT-06:00)",
    tzCode: "Pacific/Easter",
    name: "Easter (GMT-06:00)",
    utc: "-06:00",
    region: "Pacific",
  },
  {
    label: "Pacific/Galapagos (GMT-06:00)",
    tzCode: "Pacific/Galapagos",
    name: "Moreno Baquerizo Puerto Ayora, Puerto (GMT-06:00)",
    utc: "-06:00",
    region: "Pacific",
  },
  {
    label: "America/Atikokan (GMT-05:00)",
    tzCode: "America/Atikokan",
    name: "Atikokan (GMT-05:00)",
    utc: "-05:00",
    region: "America",
  },
  {
    label: "America/Bogota (GMT-05:00)",
    tzCode: "America/Bogota",
    name: "Cartagena Barranquilla, Medellín, Cali, Bogotá, (GMT-05:00)",
    utc: "-05:00",
    region: "America",
  },
  {
    label: "America/Cancun (GMT-05:00)",
    tzCode: "America/Cancun",
    name: "Puerto Carrillo Felipe Cozumel, Carmen, del Playa Chetumal, Cancún, (GMT-05:00)",
    utc: "-05:00",
    region: "America",
  },
  {
    label: "America/Cayman (GMT-05:00)",
    tzCode: "America/Cayman",
    name: "Side North End, East Town, Bodden Bay, West Town, George (GMT-05:00)",
    utc: "-05:00",
    region: "America",
  },
  {
    label: "America/Detroit (GMT-05:00)",
    tzCode: "America/Detroit",
    name: "Arbor Ann Heights, Sterling Warren, Rapids, Grand Detroit, (GMT-05:00)",
    utc: "-05:00",
    region: "America",
  },
  {
    label: "America/Eirunepe (GMT-05:00)",
    tzCode: "America/Eirunepe",
    name: "Envira Constant, Benjamin Eirunepé, (GMT-05:00)",
    utc: "-05:00",
    region: "America",
  },
  {
    label: "America/Grand_Turk (GMT-05:00)",
    tzCode: "America/Grand_Turk",
    name: "Town Cockburn (GMT-05:00)",
    utc: "-05:00",
    region: "America",
  },
  {
    label: "America/Guayaquil (GMT-05:00)",
    tzCode: "America/Guayaquil",
    name: "Machala Colorados, los de Domingo Santo Cuenca, Quito, Guayaquil, (GMT-05:00)",
    utc: "-05:00",
    region: "America",
  },
  {
    label: "America/Havana (GMT-05:00)",
    tzCode: "America/Havana",
    name: "Guantánamo Holguín, Camagüey, Cuba, de Santiago Havana, (GMT-05:00)",
    utc: "-05:00",
    region: "America",
  },
  {
    label: "America/Indiana/Indianapolis (GMT-05:00)",
    tzCode: "America/Indiana/Indianapolis",
    name: "Bloomington Carmel, Bend, South Wayne, Fort Indianapolis, (GMT-05:00)",
    utc: "-05:00",
    region: "America",
  },
  {
    label: "America/Indiana/Marengo (GMT-05:00)",
    tzCode: "America/Indiana/Marengo",
    name: "Marengo (GMT-05:00)",
    utc: "-05:00",
    region: "America",
  },
  {
    label: "America/Indiana/Petersburg (GMT-05:00)",
    tzCode: "America/Indiana/Petersburg",
    name: "Petersburg (GMT-05:00)",
    utc: "-05:00",
    region: "America",
  },
  {
    label: "America/Indiana/Vevay (GMT-05:00)",
    tzCode: "America/Indiana/Vevay",
    name: "Vevay (GMT-05:00)",
    utc: "-05:00",
    region: "America",
  },
  {
    label: "America/Indiana/Vincennes (GMT-05:00)",
    tzCode: "America/Indiana/Vincennes",
    name: "Huntingburg Washington, Jasper, Vincennes, (GMT-05:00)",
    utc: "-05:00",
    region: "America",
  },
  {
    label: "America/Indiana/Winamac (GMT-05:00)",
    tzCode: "America/Indiana/Winamac",
    name: "Winamac (GMT-05:00)",
    utc: "-05:00",
    region: "America",
  },
  {
    label: "America/Iqaluit (GMT-05:00)",
    tzCode: "America/Iqaluit",
    name: "Iqaluit (GMT-05:00)",
    utc: "-05:00",
    region: "America",
  },
  {
    label: "America/Jamaica (GMT-05:00)",
    tzCode: "America/Jamaica",
    name: "Bay Montego Portmore, Town, Spanish Kingston, New Kingston, (GMT-05:00)",
    utc: "-05:00",
    region: "America",
  },
  {
    label: "America/Kentucky/Louisville (GMT-05:00)",
    tzCode: "America/Kentucky/Louisville",
    name: "Park Ridge Pleasure Jeffersontown, Albany, New Jeffersonville, Louisville, (GMT-05:00)",
    utc: "-05:00",
    region: "America",
  },
  {
    label: "America/Kentucky/Monticello (GMT-05:00)",
    tzCode: "America/Kentucky/Monticello",
    name: "Monticello (GMT-05:00)",
    utc: "-05:00",
    region: "America",
  },
  {
    label: "America/Lima (GMT-05:00)",
    tzCode: "America/Lima",
    name: "Chiclayo Trujillo, Callao, Arequipa, Lima, (GMT-05:00)",
    utc: "-05:00",
    region: "America",
  },
  {
    label: "America/Nassau (GMT-05:00)",
    tzCode: "America/Nassau",
    name: "Town Cooper’s End, West Freeport, Lucaya, Nassau, (GMT-05:00)",
    utc: "-05:00",
    region: "America",
  },
  {
    label: "America/New_York (GMT-05:00)",
    tzCode: "America/New_York",
    name: "Manhattan Philadelphia, Queens, Brooklyn, City, York New (GMT-05:00)",
    utc: "-05:00",
    region: "America",
  },
  {
    label: "America/Nipigon (GMT-05:00)",
    tzCode: "America/Nipigon",
    name: "Nipigon (GMT-05:00)",
    utc: "-05:00",
    region: "America",
  },
  {
    label: "America/Panama (GMT-05:00)",
    tzCode: "America/Panama",
    name: "Arraiján David, Díaz, Juan Miguelito, San Panamá, (GMT-05:00)",
    utc: "-05:00",
    region: "America",
  },
  {
    label: "America/Pangnirtung (GMT-05:00)",
    tzCode: "America/Pangnirtung",
    name: "Pangnirtung (GMT-05:00)",
    utc: "-05:00",
    region: "America",
  },
  {
    label: "America/Port-au-Prince (GMT-05:00)",
    tzCode: "America/Port-au-Prince",
    name: "Port-de-Paix Pétionville, 73, Delmas Carrefour, Port-au-Prince, (GMT-05:00)",
    utc: "-05:00",
    region: "America",
  },
  {
    label: "America/Rio_Branco (GMT-05:00)",
    tzCode: "America/Rio_Branco",
    name: "Feijó Tarauacá, Madureira, Sena Sul, do Cruzeiro Branco, Rio (GMT-05:00)",
    utc: "-05:00",
    region: "America",
  },
  {
    label: "America/Thunder_Bay (GMT-05:00)",
    tzCode: "America/Thunder_Bay",
    name: "Bay Thunder (GMT-05:00)",
    utc: "-05:00",
    region: "America",
  },
  {
    label: "America/Toronto (GMT-05:00)",
    tzCode: "America/Toronto",
    name: "Québec Mississauga, Ottawa, Montréal, Toronto, (GMT-05:00)",
    utc: "-05:00",
    region: "America",
  },
  {
    label: "America/Anguilla (GMT-04:00)",
    tzCode: "America/Anguilla",
    name: "Hill Sandy Quarter, The Village, Ground Sandy Village, Point Blowing Valley, The (GMT-04:00)",
    utc: "-04:00",
    region: "America",
  },
  {
    label: "America/Antigua (GMT-04:00)",
    tzCode: "America/Antigua",
    name: "Parham Codrington, Bolands, Piggotts, John’s, Saint (GMT-04:00)",
    utc: "-04:00",
    region: "America",
  },
  {
    label: "America/Aruba (GMT-04:00)",
    tzCode: "America/Aruba",
    name: "Paradera Cruz, Santa Nicolas, San Leendert, Tanki Oranjestad, (GMT-04:00)",
    utc: "-04:00",
    region: "America",
  },
  {
    label: "America/Asuncion (GMT-04:00)",
    tzCode: "America/Asuncion",
    name: "Lambaré Capiatá, Lorenzo, San Este, del Ciudad Asunción, (GMT-04:00)",
    utc: "-04:00",
    region: "America",
  },
  {
    label: "America/Barbados (GMT-04:00)",
    tzCode: "America/Barbados",
    name: "Holetown Bathsheba, Oistins, Speightstown, Bridgetown, (GMT-04:00)",
    utc: "-04:00",
    region: "America",
  },
  {
    label: "America/Blanc-Sablon (GMT-04:00)",
    tzCode: "America/Blanc-Sablon",
    name: "Lévis (GMT-04:00)",
    utc: "-04:00",
    region: "America",
  },
  {
    label: "America/Boa_Vista (GMT-04:00)",
    tzCode: "America/Boa_Vista",
    name: "Vista Boa (GMT-04:00)",
    utc: "-04:00",
    region: "America",
  },
  {
    label: "America/Campo_Grande (GMT-04:00)",
    tzCode: "America/Campo_Grande",
    name: "Porã Ponta Lagoas, Três Corumbá, Dourados, Grande, Campo (GMT-04:00)",
    utc: "-04:00",
    region: "America",
  },
  {
    label: "America/Caracas (GMT-04:00)",
    tzCode: "America/Caracas",
    name: "Barquisimeto Valencia, Maracay, Maracaibo, Caracas, (GMT-04:00)",
    utc: "-04:00",
    region: "America",
  },
  {
    label: "America/Cuiaba (GMT-04:00)",
    tzCode: "America/Cuiaba",
    name: "Garças do Barra Sinop, Rondonópolis, Grande, Várzea Cuiabá, (GMT-04:00)",
    utc: "-04:00",
    region: "America",
  },
  {
    label: "America/Curacao (GMT-04:00)",
    tzCode: "America/Curacao",
    name: "Liber Michiel Sint Willemstad, (GMT-04:00)",
    utc: "-04:00",
    region: "America",
  },
  {
    label: "America/Dominica (GMT-04:00)",
    tzCode: "America/Dominica",
    name: "Wesley Joseph, Saint Berekua, Portsmouth, Roseau, (GMT-04:00)",
    utc: "-04:00",
    region: "America",
  },
  {
    label: "America/Glace_Bay (GMT-04:00)",
    tzCode: "America/Glace_Bay",
    name: "Mines Sydney Bay, Glace Sydney, (GMT-04:00)",
    utc: "-04:00",
    region: "America",
  },
  {
    label: "America/Goose_Bay (GMT-04:00)",
    tzCode: "America/Goose_Bay",
    name: "Bay Valley-Goose Happy City, Labrador (GMT-04:00)",
    utc: "-04:00",
    region: "America",
  },
  {
    label: "America/Grenada (GMT-04:00)",
    tzCode: "America/Grenada",
    name: "David’s Saint Victoria, Grenville, Gouyave, George's, Saint (GMT-04:00)",
    utc: "-04:00",
    region: "America",
  },
  {
    label: "America/Guadeloupe (GMT-04:00)",
    tzCode: "America/Guadeloupe",
    name: "Sainte-Anne Petit-Bourg, Gosier, Le Baie-Mahault, Abymes, Les (GMT-04:00)",
    utc: "-04:00",
    region: "America",
  },
  {
    label: "America/Guyana (GMT-04:00)",
    tzCode: "America/Guyana",
    name: "Bartica Regina, Anna Amsterdam, New Linden, Georgetown, (GMT-04:00)",
    utc: "-04:00",
    region: "America",
  },
  {
    label: "America/Halifax (GMT-04:00)",
    tzCode: "America/Halifax",
    name: "Truro Sackville, Lower Charlottetown, Dartmouth, Halifax, (GMT-04:00)",
    utc: "-04:00",
    region: "America",
  },
  {
    label: "America/Kralendijk (GMT-04:00)",
    tzCode: "America/Kralendijk",
    name: "Bottom The Oranjestad, Kralendijk, (GMT-04:00)",
    utc: "-04:00",
    region: "America",
  },
  {
    label: "America/La_Paz (GMT-04:00)",
    tzCode: "America/La_Paz",
    name: "Oruro Sucre, Paz, La Cochabamba, Sierra, la de Cruz Santa (GMT-04:00)",
    utc: "-04:00",
    region: "America",
  },
  {
    label: "America/Lower_Princes (GMT-04:00)",
    tzCode: "America/Lower_Princes",
    name: "Philipsburg Koolbaai, Quarter, Prince’s Lower Sac, de Cul (GMT-04:00)",
    utc: "-04:00",
    region: "America",
  },
  {
    label: "America/Manaus (GMT-04:00)",
    tzCode: "America/Manaus",
    name: "Coari Manacapuru, Parintins, Itacoatiara, Manaus, (GMT-04:00)",
    utc: "-04:00",
    region: "America",
  },
  {
    label: "America/Marigot (GMT-04:00)",
    tzCode: "America/Marigot",
    name: "Marigot (GMT-04:00)",
    utc: "-04:00",
    region: "America",
  },
  {
    label: "America/Martinique (GMT-04:00)",
    tzCode: "America/Martinique",
    name: "François Le Sainte-Marie, Robert, Le Lamentin, Le Fort-de-France, (GMT-04:00)",
    utc: "-04:00",
    region: "America",
  },
  {
    label: "America/Moncton (GMT-04:00)",
    tzCode: "America/Moncton",
    name: "Miramichi Dieppe, Fredericton, John, Saint Moncton, (GMT-04:00)",
    utc: "-04:00",
    region: "America",
  },
  {
    label: "America/Montserrat (GMT-04:00)",
    tzCode: "America/Montserrat",
    name: "Plymouth Peters, Saint Brades, (GMT-04:00)",
    utc: "-04:00",
    region: "America",
  },
  {
    label: "America/Porto_Velho (GMT-04:00)",
    tzCode: "America/Porto_Velho",
    name: "Cacoal Ariquemes, Vilhena, Paraná, Ji Velho, Porto (GMT-04:00)",
    utc: "-04:00",
    region: "America",
  },
  {
    label: "America/Port_of_Spain (GMT-04:00)",
    tzCode: "America/Port_of_Spain",
    name: "Claro Rio Spain, of Port Fernando, San Repos, Mon Chaguanas, (GMT-04:00)",
    utc: "-04:00",
    region: "America",
  },
  {
    label: "America/Puerto_Rico (GMT-04:00)",
    tzCode: "America/Puerto_Rico",
    name: "Caguas Ponce, Carolina, Bayamón, Juan, San (GMT-04:00)",
    utc: "-04:00",
    region: "America",
  },
  {
    label: "America/Santiago (GMT-04:00)",
    tzCode: "America/Santiago",
    name: "Valparaíso Mar, del Viña Antofagasta, Alto, Puente Santiago, (GMT-04:00)",
    utc: "-04:00",
    region: "America",
  },
  {
    label: "America/Santo_Domingo (GMT-04:00)",
    tzCode: "America/Santo_Domingo",
    name: "Macorís de Pedro San Este, Domingo Santo Oeste, Domingo Santo Caballeros, los de Santiago Domingo, Santo (GMT-04:00)",
    utc: "-04:00",
    region: "America",
  },
  {
    label: "America/St_Barthelemy (GMT-04:00)",
    tzCode: "America/St_Barthelemy",
    name: "Gustavia (GMT-04:00)",
    utc: "-04:00",
    region: "America",
  },
  {
    label: "America/St_Kitts (GMT-04:00)",
    tzCode: "America/St_Kitts",
    name: "Island Middle Paul’s, Saint Shop, Market Tree, Fig Basseterre, (GMT-04:00)",
    utc: "-04:00",
    region: "America",
  },
  {
    label: "America/St_Lucia (GMT-04:00)",
    tzCode: "America/St_Lucia",
    name: "Soufrière Micoud, Fort, Vieux Bisee, Castries, (GMT-04:00)",
    utc: "-04:00",
    region: "America",
  },
  {
    label: "America/St_Thomas (GMT-04:00)",
    tzCode: "America/St_Thomas",
    name: "Bay Cruz Amalie, Charlotte Croix, Saint (GMT-04:00)",
    utc: "-04:00",
    region: "America",
  },
  {
    label: "America/St_Vincent (GMT-04:00)",
    tzCode: "America/St_Vincent",
    name: "Elizabeth Port Barrouallie, Georgetown, Park, Kingstown Kingstown, (GMT-04:00)",
    utc: "-04:00",
    region: "America",
  },
  {
    label: "America/Thule (GMT-04:00)",
    tzCode: "America/Thule",
    name: "Thule (GMT-04:00)",
    utc: "-04:00",
    region: "America",
  },
  {
    label: "America/Tortola (GMT-04:00)",
    tzCode: "America/Tortola",
    name: "Town Road (GMT-04:00)",
    utc: "-04:00",
    region: "America",
  },
  {
    label: "Atlantic/Bermuda (GMT-04:00)",
    tzCode: "Atlantic/Bermuda",
    name: "Hamilton (GMT-04:00)",
    utc: "-04:00",
    region: "Atlantic",
  },
  {
    label: "America/St_Johns (GMT-03:30)",
    tzCode: "America/St_Johns",
    name: "Roberts Bay South, Bay Conception Brook, Corner Pearl, Mount John's, St. (GMT-03:30)",
    utc: "-03:30",
    region: "America",
  },
  {
    label: "America/Araguaina (GMT-03:00)",
    tzCode: "America/Araguaina",
    name: "Franco Porto Tocantins, do Miracema Gurupi, Araguaína, Palmas, (GMT-03:00)",
    utc: "-03:00",
    region: "America",
  },
  {
    label: "America/Argentina/Buenos_Aires (GMT-03:00)",
    tzCode: "America/Argentina/Buenos_Aires",
    name: "Blanca Bahía Morón, Plata, del Mar Plata, La Aires, Buenos (GMT-03:00)",
    utc: "-03:00",
    region: "America",
  },
  {
    label: "America/Argentina/Catamarca (GMT-03:00)",
    tzCode: "America/Argentina/Catamarca",
    name: "Rawson Esquel, Madryn, Puerto Trelew, Catamarca, de Valle del Fernando San (GMT-03:00)",
    utc: "-03:00",
    region: "America",
  },
  {
    label: "America/Argentina/Cordoba (GMT-03:00)",
    tzCode: "America/Argentina/Cordoba",
    name: "Estero del Santiago Resistencia, Fe, Santa Rosario, Córdoba, (GMT-03:00)",
    utc: "-03:00",
    region: "America",
  },
  {
    label: "America/Argentina/Jujuy (GMT-03:00)",
    tzCode: "America/Argentina/Jujuy",
    name: "Quiaca La Palpalá, Martín, San General Libertador Jujuy, de Pedro San Jujuy, de Salvador San (GMT-03:00)",
    utc: "-03:00",
    region: "America",
  },
  {
    label: "America/Argentina/La_Rioja (GMT-03:00)",
    tzCode: "America/Argentina/La_Rioja",
    name: "Chamical Arauco, Chilecito, Rioja, La (GMT-03:00)",
    utc: "-03:00",
    region: "America",
  },
  {
    label: "America/Argentina/Mendoza (GMT-03:00)",
    tzCode: "America/Argentina/Mendoza",
    name: "Martín San Rafael, San Mendoza, (GMT-03:00)",
    utc: "-03:00",
    region: "America",
  },
  {
    label: "America/Argentina/Rio_Gallegos (GMT-03:00)",
    tzCode: "America/Argentina/Rio_Gallegos",
    name: "Deseado Puerto Truncado, Pico Olivia, Caleta Gallegos, Río Rivadavia, Comodoro (GMT-03:00)",
    utc: "-03:00",
    region: "America",
  },
  {
    label: "America/Argentina/Salta (GMT-03:00)",
    tzCode: "America/Argentina/Salta",
    name: "Cipolletti Bariloche, de Carlos San Rosa, Santa Neuquén, Salta, (GMT-03:00)",
    utc: "-03:00",
    region: "America",
  },
  {
    label: "America/Argentina/San_Juan (GMT-03:00)",
    tzCode: "America/Argentina/San_Juan",
    name: "Caucete Pocito, Lucía, Santa Chimbas, Juan, San (GMT-03:00)",
    utc: "-03:00",
    region: "America",
  },
  {
    label: "America/Argentina/San_Luis (GMT-03:00)",
    tzCode: "America/Argentina/San_Luis",
    name: "Daract Justo Merlo, Punta, La Mercedes, Villa Luis, San (GMT-03:00)",
    utc: "-03:00",
    region: "America",
  },
  {
    label: "America/Argentina/Tucuman (GMT-03:00)",
    tzCode: "America/Argentina/Tucuman",
    name: "Aguilares Alderetes, Viejo, Tafí Buena, Yerba Tucumán, de Miguel San (GMT-03:00)",
    utc: "-03:00",
    region: "America",
  },
  {
    label: "America/Argentina/Ushuaia (GMT-03:00)",
    tzCode: "America/Argentina/Ushuaia",
    name: "Grande Río Ushuaia, (GMT-03:00)",
    utc: "-03:00",
    region: "America",
  },
  {
    label: "America/Bahia (GMT-03:00)",
    tzCode: "America/Bahia",
    name: "Camaçari Itabuna, Conquista, da Vitória Santana, de Feira Salvador, (GMT-03:00)",
    utc: "-03:00",
    region: "America",
  },
  {
    label: "America/Belem (GMT-03:00)",
    tzCode: "America/Belem",
    name: "Marabá Parauapebas, Macapá, Ananindeua, Belém, (GMT-03:00)",
    utc: "-03:00",
    region: "America",
  },
  {
    label: "America/Cayenne (GMT-03:00)",
    tzCode: "America/Cayenne",
    name: "Rémire-Montjoly Kourou, Saint-Laurent-du-Maroni, Matoury, Cayenne, (GMT-03:00)",
    utc: "-03:00",
    region: "America",
  },
  {
    label: "America/Fortaleza (GMT-03:00)",
    tzCode: "America/Fortaleza",
    name: "Pessoa João Teresina, Natal, Luís, São Fortaleza, (GMT-03:00)",
    utc: "-03:00",
    region: "America",
  },
  {
    label: "America/Godthab (GMT-03:00)",
    tzCode: "America/Godthab",
    name: "Aasiaat Qaqortoq, Ilulissat, Sisimiut, Nuuk, (GMT-03:00)",
    utc: "-03:00",
    region: "America",
  },
  {
    label: "America/Maceio (GMT-03:00)",
    tzCode: "America/Maceio",
    name: "Cristóvão São Socorro, do Senhora Nossa Arapiraca, Aracaju, Maceió, (GMT-03:00)",
    utc: "-03:00",
    region: "America",
  },
  {
    label: "America/Miquelon (GMT-03:00)",
    tzCode: "America/Miquelon",
    name: "Miquelon Saint-Pierre, (GMT-03:00)",
    utc: "-03:00",
    region: "America",
  },
  {
    label: "America/Montevideo (GMT-03:00)",
    tzCode: "America/Montevideo",
    name: "Rivera Piedras, Las Paysandú, Salto, Montevideo, (GMT-03:00)",
    utc: "-03:00",
    region: "America",
  },
  {
    label: "America/Paramaribo (GMT-03:00)",
    tzCode: "America/Paramaribo",
    name: "Moengo Nickerie, Nieuw Brokopondo, Lelydorp, Paramaribo, (GMT-03:00)",
    utc: "-03:00",
    region: "America",
  },
  {
    label: "America/Punta_Arenas (GMT-03:00)",
    tzCode: "America/Punta_Arenas",
    name: "Natales Puerto Arenas, Punta (GMT-03:00)",
    utc: "-03:00",
    region: "America",
  },
  {
    label: "America/Recife (GMT-03:00)",
    tzCode: "America/Recife",
    name: "Paulista Olinda, Guararapes, dos Jaboatão Jaboatão, Recife, (GMT-03:00)",
    utc: "-03:00",
    region: "America",
  },
  {
    label: "America/Santarem (GMT-03:00)",
    tzCode: "America/Santarem",
    name: "Alenquer Oriximiná, Itaituba, Altamira, Santarém, (GMT-03:00)",
    utc: "-03:00",
    region: "America",
  },
  {
    label: "America/Sao_Paulo (GMT-03:00)",
    tzCode: "America/Sao_Paulo",
    name: "Curitiba Brasília, Horizonte, Belo Janeiro, de Rio Paulo, São (GMT-03:00)",
    utc: "-03:00",
    region: "America",
  },
  {
    label: "Antarctica/Palmer (GMT-03:00)",
    tzCode: "Antarctica/Palmer",
    name: "Palmer (GMT-03:00)",
    utc: "-03:00",
    region: "Antarctica",
  },
  {
    label: "Antarctica/Rothera (GMT-03:00)",
    tzCode: "Antarctica/Rothera",
    name: "Rothera (GMT-03:00)",
    utc: "-03:00",
    region: "Antarctica",
  },
  {
    label: "Atlantic/Stanley (GMT-03:00)",
    tzCode: "Atlantic/Stanley",
    name: "Stanley (GMT-03:00)",
    utc: "-03:00",
    region: "Atlantic",
  },
  {
    label: "America/Noronha (GMT-02:00)",
    tzCode: "America/Noronha",
    name: "Itamaracá (GMT-02:00)",
    utc: "-02:00",
    region: "America",
  },
  {
    label: "Atlantic/South_Georgia (GMT-02:00)",
    tzCode: "Atlantic/South_Georgia",
    name: "Grytviken (GMT-02:00)",
    utc: "-02:00",
    region: "Atlantic",
  },
  {
    label: "America/Scoresbysund (GMT-01:00)",
    tzCode: "America/Scoresbysund",
    name: "Scoresbysund (GMT-01:00)",
    utc: "-01:00",
    region: "America",
  },
  {
    label: "Atlantic/Azores (GMT-01:00)",
    tzCode: "Atlantic/Azores",
    name: "Peixe de Rabo Cão, de Rosto Heroísmo, do Angra Lagoa, Delgada, Ponta (GMT-01:00)",
    utc: "-01:00",
    region: "Atlantic",
  },
  {
    label: "Atlantic/Cape_Verde (GMT-01:00)",
    tzCode: "Atlantic/Cape_Verde",
    name: "Cruz Santa Figueira, Cova Maria, Santa Mindelo, Praia, (GMT-01:00)",
    utc: "-01:00",
    region: "Atlantic",
  },
  {
    label: "Africa/Abidjan (GMT+00:00)",
    tzCode: "Africa/Abidjan",
    name: "San-Pédro Daloa, Bouaké, Abobo, Abidjan, (GMT+00:00)",
    utc: "+00:00",
    region: "Africa",
  },
  {
    label: "Africa/Accra (GMT+00:00)",
    tzCode: "Africa/Accra",
    name: "Atsiaman Takoradi, Tamale, Kumasi, Accra, (GMT+00:00)",
    utc: "+00:00",
    region: "Africa",
  },
  {
    label: "Africa/Bamako (GMT+00:00)",
    tzCode: "Africa/Bamako",
    name: "Ségou Koutiala, Mopti, Sikasso, Bamako, (GMT+00:00)",
    utc: "+00:00",
    region: "Africa",
  },
  {
    label: "Africa/Banjul (GMT+00:00)",
    tzCode: "Africa/Banjul",
    name: "Farafenni Banjul, Bakau, Brikama, Serekunda, (GMT+00:00)",
    utc: "+00:00",
    region: "Africa",
  },
  {
    label: "Africa/Bissau (GMT+00:00)",
    tzCode: "Africa/Bissau",
    name: "Bolama Bissorã, Gabú, Bafatá, Bissau, (GMT+00:00)",
    utc: "+00:00",
    region: "Africa",
  },
  {
    label: "Africa/Casablanca (GMT+00:00)",
    tzCode: "Africa/Casablanca",
    name: "Marrakesh Sale, Fès, Rabat, Casablanca, (GMT+00:00)",
    utc: "+00:00",
    region: "Africa",
  },
  {
    label: "Africa/Conakry (GMT+00:00)",
    tzCode: "Africa/Conakry",
    name: "Kankan Kindia, Nzérékoré, Conakry, Camayenne, (GMT+00:00)",
    utc: "+00:00",
    region: "Africa",
  },
  {
    label: "Africa/Dakar (GMT+00:00)",
    tzCode: "Africa/Dakar",
    name: "Nones Thiès Thiès, Touba, Pikine, Dakar, (GMT+00:00)",
    utc: "+00:00",
    region: "Africa",
  },
  {
    label: "Africa/El_Aaiun (GMT+00:00)",
    tzCode: "Africa/El_Aaiun",
    name: "Plage Laayoune Dakhla, Laayoune, (GMT+00:00)",
    utc: "+00:00",
    region: "Africa",
  },
  {
    label: "Africa/Freetown (GMT+00:00)",
    tzCode: "Africa/Freetown",
    name: "Makeni Koidu, Kenema, Bo, Freetown, (GMT+00:00)",
    utc: "+00:00",
    region: "Africa",
  },
  {
    label: "Africa/Lome (GMT+00:00)",
    tzCode: "Africa/Lome",
    name: "Kpalimé Atakpamé, Kara, Sokodé, Lomé, (GMT+00:00)",
    utc: "+00:00",
    region: "Africa",
  },
  {
    label: "Africa/Monrovia (GMT+00:00)",
    tzCode: "Africa/Monrovia",
    name: "Harper Bensonville, Kakata, Gbarnga, Monrovia, (GMT+00:00)",
    utc: "+00:00",
    region: "Africa",
  },
  {
    label: "Africa/Nouakchott (GMT+00:00)",
    tzCode: "Africa/Nouakchott",
    name: "Rosso Kaédi, Néma, Nouadhibou, Nouakchott, (GMT+00:00)",
    utc: "+00:00",
    region: "Africa",
  },
  {
    label: "Africa/Ouagadougou (GMT+00:00)",
    tzCode: "Africa/Ouagadougou",
    name: "Banfora Ouahigouya, Koudougou, Bobo-Dioulasso, Ouagadougou, (GMT+00:00)",
    utc: "+00:00",
    region: "Africa",
  },
  {
    label: "Africa/Sao_Tome (GMT+00:00)",
    tzCode: "Africa/Sao_Tome",
    name: "António Santo Tomé, São (GMT+00:00)",
    utc: "+00:00",
    region: "Africa",
  },
  {
    label: "America/Danmarkshavn (GMT+00:00)",
    tzCode: "America/Danmarkshavn",
    name: "Danmarkshavn (GMT+00:00)",
    utc: "+00:00",
    region: "America",
  },
  {
    label: "Antarctica/Troll (GMT+00:00)",
    tzCode: "Antarctica/Troll",
    name: "Troll (GMT+00:00)",
    utc: "+00:00",
    region: "Antarctica",
  },
  {
    label: "Atlantic/Canary (GMT+00:00)",
    tzCode: "Atlantic/Canary",
    name: "Arona Telde, Laguna, La Tenerife, de Cruz Santa Canaria, Gran de Palmas Las (GMT+00:00)",
    utc: "+00:00",
    region: "Atlantic",
  },
  {
    label: "Atlantic/Faroe (GMT+00:00)",
    tzCode: "Atlantic/Faroe",
    name: "Miðvágur Tvøroyri, Fuglafjørður, Klaksvík, Tórshavn, (GMT+00:00)",
    utc: "+00:00",
    region: "Atlantic",
  },
  {
    label: "Atlantic/Madeira (GMT+00:00)",
    tzCode: "Atlantic/Madeira",
    name: "Machico Caniço, Martinho, São Lobos, de Câmara Funchal, (GMT+00:00)",
    utc: "+00:00",
    region: "Atlantic",
  },
  {
    label: "Atlantic/Reykjavik (GMT+00:00)",
    tzCode: "Atlantic/Reykjavik",
    name: "Garðabær Akureyri, Hafnarfjörður, Kópavogur, Reykjavík, (GMT+00:00)",
    utc: "+00:00",
    region: "Atlantic",
  },
  {
    label: "Atlantic/St_Helena (GMT+00:00)",
    tzCode: "Atlantic/St_Helena",
    name: "Seas Seven the of Edinburgh Georgetown, Jamestown, (GMT+00:00)",
    utc: "+00:00",
    region: "Atlantic",
  },
  {
    label: "Europe/Dublin (GMT+00:00)",
    tzCode: "Europe/Dublin",
    name: "Tallaght Gaillimh, Luimneach, Cork, Dublin, (GMT+00:00)",
    utc: "+00:00",
    region: "Europe",
  },
  {
    label: "Europe/Guernsey (GMT+00:00)",
    tzCode: "Europe/Guernsey",
    name: "Saviour Saint Anne, St Sampson, Saint Martin, St Port, Peter Saint (GMT+00:00)",
    utc: "+00:00",
    region: "Europe",
  },
  {
    label: "Europe/Isle_of_Man (GMT+00:00)",
    tzCode: "Europe/Isle_of_Man",
    name: "Castletown Erin, Port Peel, Ramsey, Douglas, (GMT+00:00)",
    utc: "+00:00",
    region: "Europe",
  },
  {
    label: "Europe/Jersey (GMT+00:00)",
    tzCode: "Europe/Jersey",
    name: "Hocq Le Helier, Saint (GMT+00:00)",
    utc: "+00:00",
    region: "Europe",
  },
  {
    label: "Europe/Lisbon (GMT+00:00)",
    tzCode: "Europe/Lisbon",
    name: "Setúbal Braga, Amadora, Porto, Lisbon, (GMT+00:00)",
    utc: "+00:00",
    region: "Europe",
  },
  {
    label: "Europe/London (GMT+00:00)",
    tzCode: "Europe/London",
    name: "Bristol Sheffield, Liverpool, Birmingham, London, (GMT+00:00)",
    utc: "+00:00",
    region: "Europe",
  },
  {
    label: "Africa/Algiers (GMT+01:00)",
    tzCode: "Africa/Algiers",
    name: "Constantine Tébessa, Oran, Boumerdas, Algiers, (GMT+01:00)",
    utc: "+01:00",
    region: "Africa",
  },
  {
    label: "Africa/Bangui (GMT+01:00)",
    tzCode: "Africa/Bangui",
    name: "Bandoro Kaga Berbérati, Mbaïki, Bimbo, Bangui, (GMT+01:00)",
    utc: "+01:00",
    region: "Africa",
  },
  {
    label: "Africa/Brazzaville (GMT+01:00)",
    tzCode: "Africa/Brazzaville",
    name: "Owando Kayes, Dolisie, Pointe-Noire, Brazzaville, (GMT+01:00)",
    utc: "+01:00",
    region: "Africa",
  },
  {
    label: "Africa/Ceuta (GMT+01:00)",
    tzCode: "Africa/Ceuta",
    name: "Melilla Ceuta, (GMT+01:00)",
    utc: "+01:00",
    region: "Africa",
  },
  {
    label: "Africa/Douala (GMT+01:00)",
    tzCode: "Africa/Douala",
    name: "Bamenda Kousséri, Garoua, Yaoundé, Douala, (GMT+01:00)",
    utc: "+01:00",
    region: "Africa",
  },
  {
    label: "Africa/Kinshasa (GMT+01:00)",
    tzCode: "Africa/Kinshasa",
    name: "Matadi Mbandaka, Kikwit, Masina, Kinshasa, (GMT+01:00)",
    utc: "+01:00",
    region: "Africa",
  },
  {
    label: "Africa/Lagos (GMT+01:00)",
    tzCode: "Africa/Lagos",
    name: "Harcourt Port Kaduna, Ibadan, Kano, Lagos, (GMT+01:00)",
    utc: "+01:00",
    region: "Africa",
  },
  {
    label: "Africa/Libreville (GMT+01:00)",
    tzCode: "Africa/Libreville",
    name: "Moanda Oyem, Franceville, Port-Gentil, Libreville, (GMT+01:00)",
    utc: "+01:00",
    region: "Africa",
  },
  {
    label: "Africa/Luanda (GMT+01:00)",
    tzCode: "Africa/Luanda",
    name: "Benguela Lobito, Huambo, N’dalatando, Luanda, (GMT+01:00)",
    utc: "+01:00",
    region: "Africa",
  },
  {
    label: "Africa/Malabo (GMT+01:00)",
    tzCode: "Africa/Malabo",
    name: "Añisoc Aconibe, Ebebiyin, Malabo, Bata, (GMT+01:00)",
    utc: "+01:00",
    region: "Africa",
  },
  {
    label: "Africa/Ndjamena (GMT+01:00)",
    tzCode: "Africa/Ndjamena",
    name: "Kelo Abéché, Sarh, Moundou, N'Djamena, (GMT+01:00)",
    utc: "+01:00",
    region: "Africa",
  },
  {
    label: "Africa/Niamey (GMT+01:00)",
    tzCode: "Africa/Niamey",
    name: "Alaghsas Agadez, Maradi, Zinder, Niamey, (GMT+01:00)",
    utc: "+01:00",
    region: "Africa",
  },
  {
    label: "Africa/Porto-Novo (GMT+01:00)",
    tzCode: "Africa/Porto-Novo",
    name: "Parakou Porto-Novo, Djougou, Abomey-Calavi, Cotonou, (GMT+01:00)",
    utc: "+01:00",
    region: "Africa",
  },
  {
    label: "Africa/Tunis (GMT+01:00)",
    tzCode: "Africa/Tunis",
    name: "Bizerte Kairouan, Sousse, Sfax, Tunis, (GMT+01:00)",
    utc: "+01:00",
    region: "Africa",
  },
  {
    label: "Africa/Windhoek (GMT+01:00)",
    tzCode: "Africa/Windhoek",
    name: "Swakopmund Oshakati, Bay, Walvis Rundu, Windhoek, (GMT+01:00)",
    utc: "+01:00",
    region: "Africa",
  },
  {
    label: "Arctic/Longyearbyen (GMT+01:00)",
    tzCode: "Arctic/Longyearbyen",
    name: "Olonkinbyen Longyearbyen, (GMT+01:00)",
    utc: "+01:00",
    region: "Arctic",
  },
  {
    label: "Europe/Amsterdam (GMT+01:00)",
    tzCode: "Europe/Amsterdam",
    name: "Eindhoven Utrecht, Hague, The Rotterdam, Amsterdam, (GMT+01:00)",
    utc: "+01:00",
    region: "Europe",
  },
  {
    label: "Europe/Andorra (GMT+01:00)",
    tzCode: "Europe/Andorra",
    name: "Massana la Lòria, de Julià Sant Encamp, Escaldes, les Vella, la Andorra (GMT+01:00)",
    utc: "+01:00",
    region: "Europe",
  },
  {
    label: "Europe/Belgrade (GMT+01:00)",
    tzCode: "Europe/Belgrade",
    name: "Prizren Sad, Novi Niš, Pristina, Belgrade, (GMT+01:00)",
    utc: "+01:00",
    region: "Europe",
  },
  {
    label: "Europe/Berlin (GMT+01:00)",
    tzCode: "Europe/Berlin",
    name: "Main am Frankfurt Köln, Munich, Hamburg, Berlin, (GMT+01:00)",
    utc: "+01:00",
    region: "Europe",
  },
  {
    label: "Europe/Bratislava (GMT+01:00)",
    tzCode: "Europe/Bratislava",
    name: "Žilina Nitra, Prešov, Košice, Bratislava, (GMT+01:00)",
    utc: "+01:00",
    region: "Europe",
  },
  {
    label: "Europe/Brussels (GMT+01:00)",
    tzCode: "Europe/Brussels",
    name: "Liège Charleroi, Gent, Antwerpen, Brussels, (GMT+01:00)",
    utc: "+01:00",
    region: "Europe",
  },
  {
    label: "Europe/Budapest (GMT+01:00)",
    tzCode: "Europe/Budapest",
    name: "Pécs Szeged, Miskolc, Debrecen, Budapest, (GMT+01:00)",
    utc: "+01:00",
    region: "Europe",
  },
  {
    label: "Europe/Copenhagen (GMT+01:00)",
    tzCode: "Europe/Copenhagen",
    name: "Frederiksberg Aalborg, Odense, Århus, Copenhagen, (GMT+01:00)",
    utc: "+01:00",
    region: "Europe",
  },
  {
    label: "Europe/Gibraltar (GMT+01:00)",
    tzCode: "Europe/Gibraltar",
    name: "Gibraltar (GMT+01:00)",
    utc: "+01:00",
    region: "Europe",
  },
  {
    label: "Europe/Ljubljana (GMT+01:00)",
    tzCode: "Europe/Ljubljana",
    name: "Velenje Kranj, Celje, Maribor, Ljubljana, (GMT+01:00)",
    utc: "+01:00",
    region: "Europe",
  },
  {
    label: "Europe/Luxembourg (GMT+01:00)",
    tzCode: "Europe/Luxembourg",
    name: "Bettembourg Schifflange, Dudelange, Esch-sur-Alzette, Luxembourg, (GMT+01:00)",
    utc: "+01:00",
    region: "Europe",
  },
  {
    label: "Europe/Madrid (GMT+01:00)",
    tzCode: "Europe/Madrid",
    name: "Zaragoza Sevilla, Valencia, Barcelona, Madrid, (GMT+01:00)",
    utc: "+01:00",
    region: "Europe",
  },
  {
    label: "Europe/Malta (GMT+01:00)",
    tzCode: "Europe/Malta",
    name: "il-Baħar Pawl San Żabbar, Mosta, Qormi, Birkirkara, (GMT+01:00)",
    utc: "+01:00",
    region: "Europe",
  },
  {
    label: "Europe/Monaco (GMT+01:00)",
    tzCode: "Europe/Monaco",
    name: "Condamine La Monte-Carlo, Monaco, (GMT+01:00)",
    utc: "+01:00",
    region: "Europe",
  },
  {
    label: "Europe/Oslo (GMT+01:00)",
    tzCode: "Europe/Oslo",
    name: "Drammen Stavanger, Trondheim, Bergen, Oslo, (GMT+01:00)",
    utc: "+01:00",
    region: "Europe",
  },
  {
    label: "Europe/Paris (GMT+01:00)",
    tzCode: "Europe/Paris",
    name: "Nice Toulouse, Lyon, Marseille, Paris, (GMT+01:00)",
    utc: "+01:00",
    region: "Europe",
  },
  {
    label: "Europe/Podgorica (GMT+01:00)",
    tzCode: "Europe/Podgorica",
    name: "Budva Pljevlja, Novi, Herceg Nikšić, Podgorica, (GMT+01:00)",
    utc: "+01:00",
    region: "Europe",
  },
  {
    label: "Europe/Prague (GMT+01:00)",
    tzCode: "Europe/Prague",
    name: "Olomouc Pilsen, Ostrava, Brno, Prague, (GMT+01:00)",
    utc: "+01:00",
    region: "Europe",
  },
  {
    label: "Europe/Rome (GMT+01:00)",
    tzCode: "Europe/Rome",
    name: "Palermo Turin, Naples, Milan, Rome, (GMT+01:00)",
    utc: "+01:00",
    region: "Europe",
  },
  {
    label: "Europe/San_Marino (GMT+01:00)",
    tzCode: "Europe/San_Marino",
    name: "Fiorentino Domagnano, Marino, San Maggiore, Borgo Serravalle, (GMT+01:00)",
    utc: "+01:00",
    region: "Europe",
  },
  {
    label: "Europe/Sarajevo (GMT+01:00)",
    tzCode: "Europe/Sarajevo",
    name: "Mostar Tuzla, Zenica, Luka, Banja Sarajevo, (GMT+01:00)",
    utc: "+01:00",
    region: "Europe",
  },
  {
    label: "Europe/Skopje (GMT+01:00)",
    tzCode: "Europe/Skopje",
    name: "Tetovo Prilep, Kumanovo, Bitola, Skopje, (GMT+01:00)",
    utc: "+01:00",
    region: "Europe",
  },
  {
    label: "Europe/Stockholm (GMT+01:00)",
    tzCode: "Europe/Stockholm",
    name: "Sollentuna Uppsala, Malmö, Göteborg, Stockholm, (GMT+01:00)",
    utc: "+01:00",
    region: "Europe",
  },
  {
    label: "Europe/Tirane (GMT+01:00)",
    tzCode: "Europe/Tirane",
    name: "Shkodër Vlorë, Elbasan, Durrës, Tirana, (GMT+01:00)",
    utc: "+01:00",
    region: "Europe",
  },
  {
    label: "Europe/Vaduz (GMT+01:00)",
    tzCode: "Europe/Vaduz",
    name: "Eschen Balzers, Triesen, Vaduz, Schaan, (GMT+01:00)",
    utc: "+01:00",
    region: "Europe",
  },
  {
    label: "Europe/Vatican (GMT+01:00)",
    tzCode: "Europe/Vatican",
    name: "City Vatican (GMT+01:00)",
    utc: "+01:00",
    region: "Europe",
  },
  {
    label: "Europe/Vienna (GMT+01:00)",
    tzCode: "Europe/Vienna",
    name: "Donaustadt Favoriten, Linz, Graz, Vienna, (GMT+01:00)",
    utc: "+01:00",
    region: "Europe",
  },
  {
    label: "Europe/Warsaw (GMT+01:00)",
    tzCode: "Europe/Warsaw",
    name: "Poznań Wrocław, Kraków, Łódź, Warsaw, (GMT+01:00)",
    utc: "+01:00",
    region: "Europe",
  },
  {
    label: "Europe/Zagreb (GMT+01:00)",
    tzCode: "Europe/Zagreb",
    name: "Zadar Osijek, Rijeka, Split, Zagreb, (GMT+01:00)",
    utc: "+01:00",
    region: "Europe",
  },
  {
    label: "Europe/Zurich (GMT+01:00)",
    tzCode: "Europe/Zurich",
    name: "Bern Lausanne, Basel, Genève, Zürich, (GMT+01:00)",
    utc: "+01:00",
    region: "Europe",
  },
  {
    label: "Africa/Blantyre (GMT+02:00)",
    tzCode: "Africa/Blantyre",
    name: "Kasungu Zomba, Mzuzu, Blantyre, Lilongwe, (GMT+02:00)",
    utc: "+02:00",
    region: "Africa",
  },
  {
    label: "Africa/Bujumbura (GMT+02:00)",
    tzCode: "Africa/Bujumbura",
    name: "Ngozi Ruyigi, Gitega, Muyinga, Bujumbura, (GMT+02:00)",
    utc: "+02:00",
    region: "Africa",
  },
  {
    label: "Africa/Cairo (GMT+02:00)",
    tzCode: "Africa/Cairo",
    name: "Suez Said, Port Giza, Alexandria, Cairo, (GMT+02:00)",
    utc: "+02:00",
    region: "Africa",
  },
  {
    label: "Africa/Gaborone (GMT+02:00)",
    tzCode: "Africa/Gaborone",
    name: "Maun Selebi-Phikwe, Molepolole, Francistown, Gaborone, (GMT+02:00)",
    utc: "+02:00",
    region: "Africa",
  },
  {
    label: "Africa/Harare (GMT+02:00)",
    tzCode: "Africa/Harare",
    name: "Gweru Mutare, Chitungwiza, Bulawayo, Harare, (GMT+02:00)",
    utc: "+02:00",
    region: "Africa",
  },
  {
    label: "Africa/Johannesburg (GMT+02:00)",
    tzCode: "Africa/Johannesburg",
    name: "Pretoria Soweto, Johannesburg, Durban, Town, Cape (GMT+02:00)",
    utc: "+02:00",
    region: "Africa",
  },
  {
    label: "Africa/Juba (GMT+02:00)",
    tzCode: "Africa/Juba",
    name: "Kuacjok Wau, Malakal, Winejok, Juba, (GMT+02:00)",
    utc: "+02:00",
    region: "Africa",
  },
  {
    label: "Africa/Khartoum (GMT+02:00)",
    tzCode: "Africa/Khartoum",
    name: "Kassala Sudan, Port Nyala, Omdurman, Khartoum, (GMT+02:00)",
    utc: "+02:00",
    region: "Africa",
  },
  {
    label: "Africa/Kigali (GMT+02:00)",
    tzCode: "Africa/Kigali",
    name: "Gisenyi Musanze, Gitarama, Butare, Kigali, (GMT+02:00)",
    utc: "+02:00",
    region: "Africa",
  },
  {
    label: "Africa/Lubumbashi (GMT+02:00)",
    tzCode: "Africa/Lubumbashi",
    name: "Likasi Kananga, Kisangani, Mbuji-Mayi, Lubumbashi, (GMT+02:00)",
    utc: "+02:00",
    region: "Africa",
  },
  {
    label: "Africa/Lusaka (GMT+02:00)",
    tzCode: "Africa/Lusaka",
    name: "Chingola Kabwe, Ndola, Kitwe, Lusaka, (GMT+02:00)",
    utc: "+02:00",
    region: "Africa",
  },
  {
    label: "Africa/Maputo (GMT+02:00)",
    tzCode: "Africa/Maputo",
    name: "Chimoio Nampula, Beira, Matola, Maputo, (GMT+02:00)",
    utc: "+02:00",
    region: "Africa",
  },
  {
    label: "Africa/Maseru (GMT+02:00)",
    tzCode: "Africa/Maseru",
    name: "Hoek Mohale’s Maputsoe, Leribe, Mafeteng, Maseru, (GMT+02:00)",
    utc: "+02:00",
    region: "Africa",
  },
  {
    label: "Africa/Mbabane (GMT+02:00)",
    tzCode: "Africa/Mbabane",
    name: "Nhlangano Malkerns, Bend, Big Mbabane, Manzini, (GMT+02:00)",
    utc: "+02:00",
    region: "Africa",
  },
  {
    label: "Africa/Tripoli (GMT+02:00)",
    tzCode: "Africa/Tripoli",
    name: "Khums Al Tarhuna, Mişrātah, Benghazi, Tripoli, (GMT+02:00)",
    utc: "+02:00",
    region: "Africa",
  },
  {
    label: "Asia/Amman (GMT+02:00)",
    tzCode: "Asia/Amman",
    name: "Sīr as Wādī Russeifa, Irbid, Zarqa, Amman, (GMT+02:00)",
    utc: "+02:00",
    region: "Asia",
  },
  {
    label: "Asia/Beirut (GMT+02:00)",
    tzCode: "Asia/Beirut",
    name: "Tyre Sidon, Tripoli, Bayrūt, Ra’s Beirut, (GMT+02:00)",
    utc: "+02:00",
    region: "Asia",
  },
  {
    label: "Asia/Damascus (GMT+02:00)",
    tzCode: "Asia/Damascus",
    name: "Latakia Ḩamāh, Homs, Damascus, Aleppo, (GMT+02:00)",
    utc: "+02:00",
    region: "Asia",
  },
  {
    label: "Asia/Famagusta (GMT+02:00)",
    tzCode: "Asia/Famagusta",
    name: "Lápithos Paralímni, Protaras, Kyrenia, Famagusta, (GMT+02:00)",
    utc: "+02:00",
    region: "Asia",
  },
  {
    label: "Asia/Gaza (GMT+02:00)",
    tzCode: "Asia/Gaza",
    name: "Balaḩ al Dayr Rafaḩ, Jabālyā, Yūnis, Khān Gaza, (GMT+02:00)",
    utc: "+02:00",
    region: "Asia",
  },
  {
    label: "Asia/Hebron (GMT+02:00)",
    tzCode: "Asia/Hebron",
    name: "Ţūlkarm Battir, Nablus, Hebron, Jerusalem, East (GMT+02:00)",
    utc: "+02:00",
    region: "Asia",
  },
  {
    label: "Asia/Jerusalem (GMT+02:00)",
    tzCode: "Asia/Jerusalem",
    name: "Ashdod Haifa, Jerusalem, West Aviv, Tel Jerusalem, (GMT+02:00)",
    utc: "+02:00",
    region: "Asia",
  },
  {
    label: "Asia/Nicosia (GMT+02:00)",
    tzCode: "Asia/Nicosia",
    name: "Paphos Stróvolos, Larnaca, Limassol, Nicosia, (GMT+02:00)",
    utc: "+02:00",
    region: "Asia",
  },
  {
    label: "Europe/Athens (GMT+02:00)",
    tzCode: "Europe/Athens",
    name: "Lárisa Piraeus, Pátra, Thessaloníki, Athens, (GMT+02:00)",
    utc: "+02:00",
    region: "Europe",
  },
  {
    label: "Europe/Bucharest (GMT+02:00)",
    tzCode: "Europe/Bucharest",
    name: "Iaşi 2, Sector 6, Sector 3, Sector Bucharest, (GMT+02:00)",
    utc: "+02:00",
    region: "Europe",
  },
  {
    label: "Europe/Chisinau (GMT+02:00)",
    tzCode: "Europe/Chisinau",
    name: "Rîbniţa Bender, Bălţi, Tiraspol, Chisinau, (GMT+02:00)",
    utc: "+02:00",
    region: "Europe",
  },
  {
    label: "Europe/Helsinki (GMT+02:00)",
    tzCode: "Europe/Helsinki",
    name: "Turku Vantaa, Tampere, Espoo, Helsinki, (GMT+02:00)",
    utc: "+02:00",
    region: "Europe",
  },
  {
    label: "Europe/Kaliningrad (GMT+02:00)",
    tzCode: "Europe/Kaliningrad",
    name: "Gusev Baltiysk, Sovetsk, Chernyakhovsk, Kaliningrad, (GMT+02:00)",
    utc: "+02:00",
    region: "Europe",
  },
  {
    label: "Europe/Kyiv (GMT+02:00)",
    tzCode: "Europe/Kyiv",
    name: "Dnipro Odesa, Donetsk, Kharkiv, Kyiv, (GMT+02:00)",
    utc: "+02:00",
    region: "Europe",
  },
  {
    label: "Europe/Mariehamn (GMT+02:00)",
    tzCode: "Europe/Mariehamn",
    name: "Mariehamn (GMT+02:00)",
    utc: "+02:00",
    region: "Europe",
  },
  {
    label: "Europe/Riga (GMT+02:00)",
    tzCode: "Europe/Riga",
    name: "Jūrmala Jelgava, Liepāja, Daugavpils, Riga, (GMT+02:00)",
    utc: "+02:00",
    region: "Europe",
  },
  {
    label: "Europe/Sofia (GMT+02:00)",
    tzCode: "Europe/Sofia",
    name: "Ruse Burgas, Varna, Plovdiv, Sofia, (GMT+02:00)",
    utc: "+02:00",
    region: "Europe",
  },
  {
    label: "Europe/Tallinn (GMT+02:00)",
    tzCode: "Europe/Tallinn",
    name: "Pärnu Kohtla-Järve, Narva, Tartu, Tallinn, (GMT+02:00)",
    utc: "+02:00",
    region: "Europe",
  },
  {
    label: "Europe/Uzhgorod (GMT+02:00)",
    tzCode: "Europe/Uzhgorod",
    name: "Tyachiv Berehove, Khust, Mukachevo, Uzhgorod, (GMT+02:00)",
    utc: "+02:00",
    region: "Europe",
  },
  {
    label: "Europe/Vilnius (GMT+02:00)",
    tzCode: "Europe/Vilnius",
    name: "Panevėžys Šiauliai, Klaipėda, Kaunas, Vilnius, (GMT+02:00)",
    utc: "+02:00",
    region: "Europe",
  },
  {
    label: "Europe/Zaporizhzhia (GMT+02:00)",
    tzCode: "Europe/Zaporizhzhia",
    name: "Lysychansk Alchevsk, Sievierodonetsk, Sevastopol, Luhansk, (GMT+02:00)",
    utc: "+02:00",
    region: "Europe",
  },
  {
    label: "Africa/Addis_Ababa (GMT+03:00)",
    tzCode: "Africa/Addis_Ababa",
    name: "Dar Bahir Nazrēt, Mek'ele, Dawa, Dire Ababa, Addis (GMT+03:00)",
    utc: "+03:00",
    region: "Africa",
  },
  {
    label: "Africa/Asmara (GMT+03:00)",
    tzCode: "Africa/Asmara",
    name: "Mendefera Assab, Massawa, Keren, Asmara, (GMT+03:00)",
    utc: "+03:00",
    region: "Africa",
  },
  {
    label: "Africa/Dar_es_Salaam (GMT+03:00)",
    tzCode: "Africa/Dar_es_Salaam",
    name: "Mbeya Arusha, Zanzibar, Mwanza, Salaam, es Dar (GMT+03:00)",
    utc: "+03:00",
    region: "Africa",
  },
  {
    label: "Africa/Djibouti (GMT+03:00)",
    tzCode: "Africa/Djibouti",
    name: "Dikhil Obock, Tadjourah, Sabieh, 'Ali Djibouti, (GMT+03:00)",
    utc: "+03:00",
    region: "Africa",
  },
  {
    label: "Africa/Kampala (GMT+03:00)",
    tzCode: "Africa/Kampala",
    name: "Jinja Mbarara, Lira, Gulu, Kampala, (GMT+03:00)",
    utc: "+03:00",
    region: "Africa",
  },
  {
    label: "Africa/Mogadishu (GMT+03:00)",
    tzCode: "Africa/Mogadishu",
    name: "Marka Kismayo, Berbera, Hargeysa, Mogadishu, (GMT+03:00)",
    utc: "+03:00",
    region: "Africa",
  },
  {
    label: "Africa/Nairobi (GMT+03:00)",
    tzCode: "Africa/Nairobi",
    name: "Kisumu Eldoret, Nakuru, Mombasa, Nairobi, (GMT+03:00)",
    utc: "+03:00",
    region: "Africa",
  },
  {
    label: "Antarctica/Syowa (GMT+03:00)",
    tzCode: "Antarctica/Syowa",
    name: "Syowa (GMT+03:00)",
    utc: "+03:00",
    region: "Antarctica",
  },
  {
    label: "Asia/Aden (GMT+03:00)",
    tzCode: "Asia/Aden",
    name: "Mukalla Aden, Taiz, Ḩudaydah, Al Sanaa, (GMT+03:00)",
    utc: "+03:00",
    region: "Asia",
  },
  {
    label: "Asia/Baghdad (GMT+03:00)",
    tzCode: "Asia/Baghdad",
    name: "Mosul Qadīmah, al Başrah Al Jadīdah, al Mawşil Al Basrah, Baghdad, (GMT+03:00)",
    utc: "+03:00",
    region: "Asia",
  },
  {
    label: "Asia/Bahrain (GMT+03:00)",
    tzCode: "Asia/Bahrain",
    name: "Ḩamad Madīnat Kulayb, Dār Rifā‘, Ar Muharraq, Al Manama, (GMT+03:00)",
    utc: "+03:00",
    region: "Asia",
  },
  {
    label: "Asia/Kuwait (GMT+03:00)",
    tzCode: "Asia/Kuwait",
    name: "Farwānīyah Al Sālim, as Şabāḩ Sālimīyah, As Ḩawallī, Aḩmadī, Al (GMT+03:00)",
    utc: "+03:00",
    region: "Asia",
  },
  {
    label: "Asia/Qatar (GMT+03:00)",
    tzCode: "Asia/Qatar",
    name: "Khawr Al Wakrah, Al Muḩammad, Şalāl Umm Rayyān, Ar Doha, (GMT+03:00)",
    utc: "+03:00",
    region: "Asia",
  },
  {
    label: "Asia/Riyadh (GMT+03:00)",
    tzCode: "Asia/Riyadh",
    name: "Sulţānah Medina, Mecca, Jeddah, Riyadh, (GMT+03:00)",
    utc: "+03:00",
    region: "Asia",
  },
  {
    label: "Europe/Istanbul (GMT+03:00)",
    tzCode: "Europe/Istanbul",
    name: "Adana Bursa, İzmir, Ankara, Istanbul, (GMT+03:00)",
    utc: "+03:00",
    region: "Europe",
  },
  {
    label: "Europe/Kirov (GMT+03:00)",
    tzCode: "Europe/Kirov",
    name: "Kotel’nich Slobodskoy, Polyany, Vyatskiye Kirovo-Chepetsk, Kirov, (GMT+03:00)",
    utc: "+03:00",
    region: "Europe",
  },
  {
    label: "Europe/Minsk (GMT+03:00)",
    tzCode: "Europe/Minsk",
    name: "Hrodna Vitebsk, Mahilyow, Homyel', Minsk, (GMT+03:00)",
    utc: "+03:00",
    region: "Europe",
  },
  {
    label: "Europe/Moscow (GMT+03:00)",
    tzCode: "Europe/Moscow",
    name: "Rostov-na-Donu Kazan, Novgorod, Nizhniy Petersburg, Saint Moscow, (GMT+03:00)",
    utc: "+03:00",
    region: "Europe",
  },
  {
    label: "Europe/Simferopol (GMT+03:00)",
    tzCode: "Europe/Simferopol",
    name: "Feodosiya Yalta, Yevpatoriya, Kerch, Simferopol, (GMT+03:00)",
    utc: "+03:00",
    region: "Europe",
  },
  {
    label: "Europe/Volgograd (GMT+03:00)",
    tzCode: "Europe/Volgograd",
    name: "Uryupinsk Mikhaylovka, Kamyshin, Volzhskiy, Volgograd, (GMT+03:00)",
    utc: "+03:00",
    region: "Europe",
  },
  {
    label: "Indian/Antananarivo (GMT+03:00)",
    tzCode: "Indian/Antananarivo",
    name: "Mahajanga Fianarantsoa, Antsirabe, Toamasina, Antananarivo, (GMT+03:00)",
    utc: "+03:00",
    region: "Indian",
  },
  {
    label: "Indian/Comoro (GMT+03:00)",
    tzCode: "Indian/Comoro",
    name: "Tsimbeo Domoni, Fomboni, Moutsamoudou, Moroni, (GMT+03:00)",
    utc: "+03:00",
    region: "Indian",
  },
  {
    label: "Indian/Mayotte (GMT+03:00)",
    tzCode: "Indian/Mayotte",
    name: "Sada Dembeni, Dzaoudzi, Koungou, Mamoudzou, (GMT+03:00)",
    utc: "+03:00",
    region: "Indian",
  },
  {
    label: "Asia/Tehran (GMT+03:30)",
    tzCode: "Asia/Tehran",
    name: "Tabriz Karaj, Isfahan, Mashhad, Tehran, (GMT+03:30)",
    utc: "+03:30",
    region: "Asia",
  },
  {
    label: "Asia/Baku (GMT+04:00)",
    tzCode: "Asia/Baku",
    name: "Yevlakh Lankaran, Sumqayıt, Ganja, Baku, (GMT+04:00)",
    utc: "+04:00",
    region: "Asia",
  },
  {
    label: "Asia/Dubai (GMT+04:00)",
    tzCode: "Asia/Dubai",
    name: "City Khaimah Al Ras City, Ajman Dhabi, Abu Sharjah, Dubai, (GMT+04:00)",
    utc: "+04:00",
    region: "Asia",
  },
  {
    label: "Asia/Muscat (GMT+04:00)",
    tzCode: "Asia/Muscat",
    name: "Sohar Bawshar, Şalālah, Seeb, Muscat, (GMT+04:00)",
    utc: "+04:00",
    region: "Asia",
  },
  {
    label: "Asia/Tbilisi (GMT+04:00)",
    tzCode: "Asia/Tbilisi",
    name: "Zugdidi Sokhumi, Batumi, Kutaisi, Tbilisi, (GMT+04:00)",
    utc: "+04:00",
    region: "Asia",
  },
  {
    label: "Asia/Yerevan (GMT+04:00)",
    tzCode: "Asia/Yerevan",
    name: "Hrazdan Vagharshapat, Vanadzor, Gyumri, Yerevan, (GMT+04:00)",
    utc: "+04:00",
    region: "Asia",
  },
  {
    label: "Europe/Astrakhan (GMT+04:00)",
    tzCode: "Europe/Astrakhan",
    name: "Kamyzyak Kharabali, Znamensk, Akhtubinsk, Astrakhan, (GMT+04:00)",
    utc: "+04:00",
    region: "Europe",
  },
  {
    label: "Europe/Samara (GMT+04:00)",
    tzCode: "Europe/Samara",
    name: "Novokuybyshevsk Syzran’, Izhevsk, Togliatti-on-the-Volga, Samara, (GMT+04:00)",
    utc: "+04:00",
    region: "Europe",
  },
  {
    label: "Europe/Saratov (GMT+04:00)",
    tzCode: "Europe/Saratov",
    name: "Vol’sk Balashov, Engel’s, Balakovo, Saratov, (GMT+04:00)",
    utc: "+04:00",
    region: "Europe",
  },
  {
    label: "Europe/Ulyanovsk (GMT+04:00)",
    tzCode: "Europe/Ulyanovsk",
    name: "Novoul’yanovsk Barysh, Inza, Dimitrovgrad, Ulyanovsk, (GMT+04:00)",
    utc: "+04:00",
    region: "Europe",
  },
  {
    label: "Indian/Mahe (GMT+04:00)",
    tzCode: "Indian/Mahe",
    name: "Cascade Vallon, Beau Ombre, Bel Boileau, Anse Victoria, (GMT+04:00)",
    utc: "+04:00",
    region: "Indian",
  },
  {
    label: "Indian/Mauritius (GMT+04:00)",
    tzCode: "Indian/Mauritius",
    name: "Bornes Quatre Curepipe, Vacoas, Hill, Bassin-Rose Beau Louis, Port (GMT+04:00)",
    utc: "+04:00",
    region: "Indian",
  },
  {
    label: "Indian/Reunion (GMT+04:00)",
    tzCode: "Indian/Reunion",
    name: "Saint-André Tampon, Le Saint-Pierre, Saint-Paul, Saint-Denis, (GMT+04:00)",
    utc: "+04:00",
    region: "Indian",
  },
  {
    label: "Asia/Kabul (GMT+04:30)",
    tzCode: "Asia/Kabul",
    name: "Jalālābād Herāt, Sharīf, Mazār-e Kandahār, Kabul, (GMT+04:30)",
    utc: "+04:30",
    region: "Asia",
  },
  {
    label: "Antarctica/Mawson (GMT+05:00)",
    tzCode: "Antarctica/Mawson",
    name: "Mawson (GMT+05:00)",
    utc: "+05:00",
    region: "Antarctica",
  },
  {
    label: "Asia/Aqtau (GMT+05:00)",
    tzCode: "Asia/Aqtau",
    name: "Yeraliyev Shetpe, Beyneu, Zhanaozen, Shevchenko, (GMT+05:00)",
    utc: "+05:00",
    region: "Asia",
  },
  {
    label: "Asia/Aqtobe (GMT+05:00)",
    tzCode: "Asia/Aqtobe",
    name: "Embi Khromtau, Shalqar, Kandyagash, Aktobe, (GMT+05:00)",
    utc: "+05:00",
    region: "Asia",
  },
  {
    label: "Asia/Ashgabat (GMT+05:00)",
    tzCode: "Asia/Ashgabat",
    name: "Balkanabat Mary, Daşoguz, Türkmenabat, Ashgabat, (GMT+05:00)",
    utc: "+05:00",
    region: "Asia",
  },
  {
    label: "Asia/Atyrau (GMT+05:00)",
    tzCode: "Asia/Atyrau",
    name: "Maqat Balykshi, Shalkar, Qulsary, Atyrau, (GMT+05:00)",
    utc: "+05:00",
    region: "Asia",
  },
  {
    label: "Asia/Dushanbe (GMT+05:00)",
    tzCode: "Asia/Dushanbe",
    name: "Istaravshan Bokhtar, Kŭlob, Khujand, Dushanbe, (GMT+05:00)",
    utc: "+05:00",
    region: "Asia",
  },
  {
    label: "Asia/Karachi (GMT+05:00)",
    tzCode: "Asia/Karachi",
    name: "Multan Rawalpindi, Faisalabad, Lahore, Karachi, (GMT+05:00)",
    utc: "+05:00",
    region: "Asia",
  },
  {
    label: "Asia/Oral (GMT+05:00)",
    tzCode: "Asia/Oral",
    name: "Zhumysker Tasqala, Zhänibek, Aqsay, Oral, (GMT+05:00)",
    utc: "+05:00",
    region: "Asia",
  },
  {
    label: "Asia/Qyzylorda (GMT+05:00)",
    tzCode: "Asia/Qyzylorda",
    name: "Chiili Aral, Novokazalinsk, Baikonur, Kyzylorda, (GMT+05:00)",
    utc: "+05:00",
    region: "Asia",
  },
  {
    label: "Asia/Samarkand (GMT+05:00)",
    tzCode: "Asia/Samarkand",
    name: "Jizzax Qarshi, Nukus, Bukhara, Samarkand, (GMT+05:00)",
    utc: "+05:00",
    region: "Asia",
  },
  {
    label: "Asia/Tashkent (GMT+05:00)",
    tzCode: "Asia/Tashkent",
    name: "Chirchiq Qo‘qon, Andijon, Namangan, Tashkent, (GMT+05:00)",
    utc: "+05:00",
    region: "Asia",
  },
  {
    label: "Asia/Yekaterinburg (GMT+05:00)",
    tzCode: "Asia/Yekaterinburg",
    name: "Orenburg Perm, Ufa, Chelyabinsk, Yekaterinburg, (GMT+05:00)",
    utc: "+05:00",
    region: "Asia",
  },
  {
    label: "Indian/Kerguelen (GMT+05:00)",
    tzCode: "Indian/Kerguelen",
    name: "Port-aux-Français (GMT+05:00)",
    utc: "+05:00",
    region: "Indian",
  },
  {
    label: "Indian/Maldives (GMT+05:00)",
    tzCode: "Indian/Maldives",
    name: "Thinadhoo Kulhudhuffushi, Hithadhoo, Fuvahmulah, Male, (GMT+05:00)",
    utc: "+05:00",
    region: "Indian",
  },
  {
    label: "Asia/Colombo (GMT+05:30)",
    tzCode: "Asia/Colombo",
    name: "Negombo Jaffna, Moratuwa, Lavinia, Dehiwala-Mount Colombo, (GMT+05:30)",
    utc: "+05:30",
    region: "Asia",
  },
  {
    label: "Asia/Kolkata (GMT+05:30)",
    tzCode: "Asia/Kolkata",
    name: "Chennai Kolkata, Bengaluru, Delhi, Mumbai, (GMT+05:30)",
    utc: "+05:30",
    region: "Asia",
  },
  {
    label: "Asia/Kathmandu (GMT+05:45)",
    tzCode: "Asia/Kathmandu",
    name: "Birgañj Biratnagar, Pātan, Pokhara, Kathmandu, (GMT+05:45)",
    utc: "+05:45",
    region: "Asia",
  },
  {
    label: "Antarctica/Vostok (GMT+06:00)",
    tzCode: "Antarctica/Vostok",
    name: "Vostok (GMT+06:00)",
    utc: "+06:00",
    region: "Antarctica",
  },
  {
    label: "Asia/Almaty (GMT+06:00)",
    tzCode: "Asia/Almaty",
    name: "Nur-Sultan Taraz, Shymkent, Karagandy, Almaty, (GMT+06:00)",
    utc: "+06:00",
    region: "Asia",
  },
  {
    label: "Asia/Bishkek (GMT+06:00)",
    tzCode: "Asia/Bishkek",
    name: "Tokmok Karakol, Jalal-Abad, Osh, Bishkek, (GMT+06:00)",
    utc: "+06:00",
    region: "Asia",
  },
  {
    label: "Asia/Dhaka (GMT+06:00)",
    tzCode: "Asia/Dhaka",
    name: "Comilla Rājshāhi, Khulna, Chattogram, Dhaka, (GMT+06:00)",
    utc: "+06:00",
    region: "Asia",
  },
  {
    label: "Asia/Omsk (GMT+06:00)",
    tzCode: "Asia/Omsk",
    name: "Tavricheskoye Znamenskoye, Kalachinsk, Tara, Omsk, (GMT+06:00)",
    utc: "+06:00",
    region: "Asia",
  },
  {
    label: "Asia/Qostanay (GMT+06:00)",
    tzCode: "Asia/Qostanay",
    name: "Lisakovsk Arkalyk, Dzhetygara, Rudnyy, Kostanay, (GMT+06:00)",
    utc: "+06:00",
    region: "Asia",
  },
  {
    label: "Asia/Thimphu (GMT+06:00)",
    tzCode: "Asia/Thimphu",
    name: "Pemagatshel Phuntsholing, Tsirang, Punākha, himphu, (GMT+06:00)",
    utc: "+06:00",
    region: "Asia",
  },
  {
    label: "Asia/Urumqi (GMT+06:00)",
    tzCode: "Asia/Urumqi",
    name: "Huocheng Shihezi, Zhanjiang, Ürümqi, Zhongshan, (GMT+06:00)",
    utc: "+06:00",
    region: "Asia",
  },
  {
    label: "Indian/Chagos (GMT+06:00)",
    tzCode: "Indian/Chagos",
    name: "Territory Ocean Indian British (GMT+06:00)",
    utc: "+06:00",
    region: "Indian",
  },
  {
    label: "Asia/Yangon (GMT+06:30)",
    tzCode: "Asia/Yangon",
    name: "Township Seikgyi Kyain Mawlamyine, Taw, Pyi Nay Mandalay, Yangon, (GMT+06:30)",
    utc: "+06:30",
    region: "Asia",
  },
  {
    label: "Indian/Cocos (GMT+06:30)",
    tzCode: "Indian/Cocos",
    name: "Island West (GMT+06:30)",
    utc: "+06:30",
    region: "Indian",
  },
  {
    label: "Antarctica/Davis (GMT+07:00)",
    tzCode: "Antarctica/Davis",
    name: "Davis (GMT+07:00)",
    utc: "+07:00",
    region: "Antarctica",
  },
  {
    label: "Asia/Bangkok (GMT+07:00)",
    tzCode: "Asia/Bangkok",
    name: "Nonthaburi Mueang Prakan, Samut Haiphong, Hanoi, Bangkok, (GMT+07:00)",
    utc: "+07:00",
    region: "Asia",
  },
  {
    label: "Asia/Barnaul (GMT+07:00)",
    tzCode: "Asia/Barnaul",
    name: "Gorno-Altaysk Novoaltaysk, Rubtsovsk, Biysk, Barnaul, (GMT+07:00)",
    utc: "+07:00",
    region: "Asia",
  },
  {
    label: "Asia/Hovd (GMT+07:00)",
    tzCode: "Asia/Hovd",
    name: "Altai Uliastay, Ulaangom, Ölgii, Khovd, (GMT+07:00)",
    utc: "+07:00",
    region: "Asia",
  },
  {
    label: "Asia/Ho_Chi_Minh (GMT+07:00)",
    tzCode: "Asia/Ho_Chi_Minh",
    name: "Thơ Cần Trang, Nha Hòa, Biên Nang, Da City, Minh Chi Ho (GMT+07:00)",
    utc: "+07:00",
    region: "Asia",
  },
  {
    label: "Asia/Jakarta (GMT+07:00)",
    tzCode: "Asia/Jakarta",
    name: "Bekasi Bandung, Medan, Surabaya, Jakarta, (GMT+07:00)",
    utc: "+07:00",
    region: "Asia",
  },
  {
    label: "Asia/Krasnoyarsk (GMT+07:00)",
    tzCode: "Asia/Krasnoyarsk",
    name: "Kyzyl Achinsk, Norilsk, Abakan, Krasnoyarsk, (GMT+07:00)",
    utc: "+07:00",
    region: "Asia",
  },
  {
    label: "Asia/Novokuznetsk (GMT+07:00)",
    tzCode: "Asia/Novokuznetsk",
    name: "Kiselëvsk Leninsk-Kuznetsky, Prokop’yevsk, Kemerovo, Novokuznetsk, (GMT+07:00)",
    utc: "+07:00",
    region: "Asia",
  },
  {
    label: "Asia/Novosibirsk (GMT+07:00)",
    tzCode: "Asia/Novosibirsk",
    name: "Kuybyshev Akademgorodok, Iskitim, Berdsk, Novosibirsk, (GMT+07:00)",
    utc: "+07:00",
    region: "Asia",
  },
  {
    label: "Asia/Phnom_Penh (GMT+07:00)",
    tzCode: "Asia/Phnom_Penh",
    name: "Reap Siem Battambang, Sihanoukville, Takeo, Penh, Phnom (GMT+07:00)",
    utc: "+07:00",
    region: "Asia",
  },
  {
    label: "Asia/Pontianak (GMT+07:00)",
    tzCode: "Asia/Pontianak",
    name: "Sampit Singkawang, Palangkaraya, Pinang, Tanjung Pontianak, (GMT+07:00)",
    utc: "+07:00",
    region: "Asia",
  },
  {
    label: "Asia/Tomsk (GMT+07:00)",
    tzCode: "Asia/Tomsk",
    name: "Asino Kolpashevo, Strezhevoy, Seversk, Tomsk, (GMT+07:00)",
    utc: "+07:00",
    region: "Asia",
  },
  {
    label: "Asia/Vientiane (GMT+07:00)",
    tzCode: "Asia/Vientiane",
    name: "Prabang Luang Savannakhet, Thakhèk, Pakse, Vientiane, (GMT+07:00)",
    utc: "+07:00",
    region: "Asia",
  },
  {
    label: "Indian/Christmas (GMT+07:00)",
    tzCode: "Indian/Christmas",
    name: "Cove Fish Flying (GMT+07:00)",
    utc: "+07:00",
    region: "Indian",
  },
  {
    label: "Asia/Brunei (GMT+08:00)",
    tzCode: "Asia/Brunei",
    name: "Bangar Tutong, Seria, Belait, Kuala Begawan, Seri Bandar (GMT+08:00)",
    utc: "+08:00",
    region: "Asia",
  },
  {
    label: "Asia/Choibalsan (GMT+08:00)",
    tzCode: "Asia/Choibalsan",
    name: "Choibalsan Baruun-Urt, (GMT+08:00)",
    utc: "+08:00",
    region: "Asia",
  },
  {
    label: "Asia/Hong_Kong (GMT+08:00)",
    tzCode: "Asia/Hong_Kong",
    name: "Chung Tung Hui, Kau Long Yuen Wan, Tsuen Kowloon, Kong, Hong (GMT+08:00)",
    utc: "+08:00",
    region: "Asia",
  },
  {
    label: "Asia/Irkutsk (GMT+08:00)",
    tzCode: "Asia/Irkutsk",
    name: "Ust’-Ilimsk Angarsk, Bratsk, Ulan-Ude, Irkutsk, (GMT+08:00)",
    utc: "+08:00",
    region: "Asia",
  },
  {
    label: "Asia/Kuala_Lumpur (GMT+08:00)",
    tzCode: "Asia/Kuala_Lumpur",
    name: "Bahru Johor Subang, Baru Kampung Klang, Lumpur, Kuala Bharu, Kota (GMT+08:00)",
    utc: "+08:00",
    region: "Asia",
  },
  {
    label: "Asia/Kuching (GMT+08:00)",
    tzCode: "Asia/Kuching",
    name: "Miri Tawau, Sandakan, Kinabalu, Kota Kuching, (GMT+08:00)",
    utc: "+08:00",
    region: "Asia",
  },
  {
    label: "Asia/Macau (GMT+08:00)",
    tzCode: "Asia/Macau",
    name: "Macau (GMT+08:00)",
    utc: "+08:00",
    region: "Asia",
  },
  {
    label: "Asia/Makassar (GMT+08:00)",
    tzCode: "Asia/Makassar",
    name: "Manado Banjarmasin, Balikpapan, of City Denpasar, Makassar, (GMT+08:00)",
    utc: "+08:00",
    region: "Asia",
  },
  {
    label: "Asia/Manila (GMT+08:00)",
    tzCode: "Asia/Manila",
    name: "Davao Budta, City, Caloocan Manila, City, Quezon (GMT+08:00)",
    utc: "+08:00",
    region: "Asia",
  },
  {
    label: "Asia/Shanghai (GMT+08:00)",
    tzCode: "Asia/Shanghai",
    name: "Shenzhen Guangzhou, Tianjin, Beijing, Shanghai, (GMT+08:00)",
    utc: "+08:00",
    region: "Asia",
  },
  {
    label: "Asia/Singapore (GMT+08:00)",
    tzCode: "Asia/Singapore",
    name: "Woodlands Singapore, (GMT+08:00)",
    utc: "+08:00",
    region: "Asia",
  },
  {
    label: "Asia/Taipei (GMT+08:00)",
    tzCode: "Asia/Taipei",
    name: "Banqiao Tainan, Taichung, Kaohsiung, Taipei, (GMT+08:00)",
    utc: "+08:00",
    region: "Asia",
  },
  {
    label: "Asia/Ulaanbaatar (GMT+08:00)",
    tzCode: "Asia/Ulaanbaatar",
    name: "Mörön Hovd, Darhan, Erdenet, Bator, Ulan (GMT+08:00)",
    utc: "+08:00",
    region: "Asia",
  },
  {
    label: "Australia/Perth (GMT+08:00)",
    tzCode: "Australia/Perth",
    name: "Albany Bunbury, Mandurah, Rockingham, Perth, (GMT+08:00)",
    utc: "+08:00",
    region: "Australia",
  },
  {
    label: "Australia/Eucla (GMT+08:45)",
    tzCode: "Australia/Eucla",
    name: "Eucla (GMT+08:45)",
    utc: "+08:45",
    region: "Australia",
  },
  {
    label: "Asia/Chita (GMT+09:00)",
    tzCode: "Asia/Chita",
    name: "Aginskoye Petrovsk-Zabaykal’skiy, Borzya, Krasnokamensk, Chita, (GMT+09:00)",
    utc: "+09:00",
    region: "Asia",
  },
  {
    label: "Asia/Dili (GMT+09:00)",
    tzCode: "Asia/Dili",
    name: "Aileu Likisá, Suai, Maliana, Dili, (GMT+09:00)",
    utc: "+09:00",
    region: "Asia",
  },
  {
    label: "Asia/Jayapura (GMT+09:00)",
    tzCode: "Asia/Jayapura",
    name: "Abepura Ternate, Sorong, Jayapura, Ambon, (GMT+09:00)",
    utc: "+09:00",
    region: "Asia",
  },
  {
    label: "Asia/Khandyga (GMT+09:00)",
    tzCode: "Asia/Khandyga",
    name: "Khandyga (GMT+09:00)",
    utc: "+09:00",
    region: "Asia",
  },
  {
    label: "Asia/Pyongyang (GMT+09:00)",
    tzCode: "Asia/Pyongyang",
    name: "Hŭngnam Sunch’ŏn, Namp’o, Hamhŭng, Pyongyang, (GMT+09:00)",
    utc: "+09:00",
    region: "Asia",
  },
  {
    label: "Asia/Seoul (GMT+09:00)",
    tzCode: "Asia/Seoul",
    name: "Daejeon Daegu, Incheon, Busan, Seoul, (GMT+09:00)",
    utc: "+09:00",
    region: "Asia",
  },
  {
    label: "Asia/Tokyo (GMT+09:00)",
    tzCode: "Asia/Tokyo",
    name: "Sapporo Nagoya, Osaka, Yokohama, Tokyo, (GMT+09:00)",
    utc: "+09:00",
    region: "Asia",
  },
  {
    label: "Asia/Yakutsk (GMT+09:00)",
    tzCode: "Asia/Yakutsk",
    name: "Svobodnyy Neryungri, Belogorsk, Blagoveshchensk, Yakutsk, (GMT+09:00)",
    utc: "+09:00",
    region: "Asia",
  },
  {
    label: "Pacific/Palau (GMT+09:00)",
    tzCode: "Pacific/Palau",
    name: "Mengellang Ulimang, Kloulklubed, Town, Koror Koror, (GMT+09:00)",
    utc: "+09:00",
    region: "Pacific",
  },
  {
    label: "Australia/Adelaide (GMT+09:30)",
    tzCode: "Australia/Adelaide",
    name: "Gawler Vale, Morphett Gambier, Mount Hills, Adelaide Adelaide, (GMT+09:30)",
    utc: "+09:30",
    region: "Australia",
  },
  {
    label: "Australia/Broken_Hill (GMT+09:30)",
    tzCode: "Australia/Broken_Hill",
    name: "Hill Broken (GMT+09:30)",
    utc: "+09:30",
    region: "Australia",
  },
  {
    label: "Australia/Darwin (GMT+09:30)",
    tzCode: "Australia/Darwin",
    name: "Springs Howard Palmerston, Springs, Alice Darwin, (GMT+09:30)",
    utc: "+09:30",
    region: "Australia",
  },
  {
    label: "Antarctica/DumontDUrville (GMT+10:00)",
    tzCode: "Antarctica/DumontDUrville",
    name: "DumontDUrville (GMT+10:00)",
    utc: "+10:00",
    region: "Antarctica",
  },
  {
    label: "Antarctica/Macquarie (GMT+10:00)",
    tzCode: "Antarctica/Macquarie",
    name: "Macquarie (GMT+10:00)",
    utc: "+10:00",
    region: "Antarctica",
  },
  {
    label: "Asia/Ust-Nera (GMT+10:00)",
    tzCode: "Asia/Ust-Nera",
    name: "Ust-Nera (GMT+10:00)",
    utc: "+10:00",
    region: "Asia",
  },
  {
    label: "Asia/Vladivostok (GMT+10:00)",
    tzCode: "Asia/Vladivostok",
    name: "Ussuriysk Komsomolsk-on-Amur, Vtoroy, Khabarovsk Khabarovsk, Vladivostok, (GMT+10:00)",
    utc: "+10:00",
    region: "Asia",
  },
  {
    label: "Australia/Brisbane (GMT+10:00)",
    tzCode: "Australia/Brisbane",
    name: "Cairns Townsville, City, Logan Coast, Gold Brisbane, (GMT+10:00)",
    utc: "+10:00",
    region: "Australia",
  },
  {
    label: "Australia/Currie (GMT+10:00)",
    tzCode: "Australia/Currie",
    name: "Currie (GMT+10:00)",
    utc: "+10:00",
    region: "Australia",
  },
  {
    label: "Australia/Hobart (GMT+10:00)",
    tzCode: "Australia/Hobart",
    name: "Bay Sandy Devonport, Burnie, Launceston, Hobart, (GMT+10:00)",
    utc: "+10:00",
    region: "Australia",
  },
  {
    label: "Australia/Lindeman (GMT+10:00)",
    tzCode: "Australia/Lindeman",
    name: "Lindeman (GMT+10:00)",
    utc: "+10:00",
    region: "Australia",
  },
  {
    label: "Australia/Melbourne (GMT+10:00)",
    tzCode: "Australia/Melbourne",
    name: "Centre City Melbourne Ballarat, Bendigo, Geelong, Melbourne, (GMT+10:00)",
    utc: "+10:00",
    region: "Australia",
  },
  {
    label: "Australia/Sydney (GMT+10:00)",
    tzCode: "Australia/Sydney",
    name: "Maitland Wollongong, Newcastle, Canberra, Sydney, (GMT+10:00)",
    utc: "+10:00",
    region: "Australia",
  },
  {
    label: "Pacific/Chuuk (GMT+10:00)",
    tzCode: "Pacific/Chuuk",
    name: "Colonia Weno, (GMT+10:00)",
    utc: "+10:00",
    region: "Pacific",
  },
  {
    label: "Pacific/Guam (GMT+10:00)",
    tzCode: "Pacific/Guam",
    name: "Village Mangilao Village, Tamuning-Tumon-Harmon Tamuning, Village, Yigo Village, Dededo (GMT+10:00)",
    utc: "+10:00",
    region: "Pacific",
  },
  {
    label: "Pacific/Port_Moresby (GMT+10:00)",
    tzCode: "Pacific/Port_Moresby",
    name: "Madang Popondetta, Hagen, Mount Lae, Moresby, Port (GMT+10:00)",
    utc: "+10:00",
    region: "Pacific",
  },
  {
    label: "Pacific/Saipan (GMT+10:00)",
    tzCode: "Pacific/Saipan",
    name: "Village Jose San Saipan, (GMT+10:00)",
    utc: "+10:00",
    region: "Pacific",
  },
  {
    label: "Australia/Lord_Howe (GMT+10:30)",
    tzCode: "Australia/Lord_Howe",
    name: "Howe Lord (GMT+10:30)",
    utc: "+10:30",
    region: "Australia",
  },
  {
    label: "Antarctica/Casey (GMT+11:00)",
    tzCode: "Antarctica/Casey",
    name: "Casey (GMT+11:00)",
    utc: "+11:00",
    region: "Antarctica",
  },
  {
    label: "Asia/Magadan (GMT+11:00)",
    tzCode: "Asia/Magadan",
    name: "Ola Susuman, Ust-Nera, Magadan, (GMT+11:00)",
    utc: "+11:00",
    region: "Asia",
  },
  {
    label: "Asia/Sakhalin (GMT+11:00)",
    tzCode: "Asia/Sakhalin",
    name: "Nevel’sk Okha, Kholmsk, Korsakov, Yuzhno-Sakhalinsk, (GMT+11:00)",
    utc: "+11:00",
    region: "Asia",
  },
  {
    label: "Asia/Srednekolymsk (GMT+11:00)",
    tzCode: "Asia/Srednekolymsk",
    name: "Srednekolymsk (GMT+11:00)",
    utc: "+11:00",
    region: "Asia",
  },
  {
    label: "Pacific/Bougainville (GMT+11:00)",
    tzCode: "Pacific/Bougainville",
    name: "Buka Arawa, (GMT+11:00)",
    utc: "+11:00",
    region: "Pacific",
  },
  {
    label: "Pacific/Efate (GMT+11:00)",
    tzCode: "Pacific/Efate",
    name: "Lakatoro Sola, Isangel, Luganville, Port-Vila, (GMT+11:00)",
    utc: "+11:00",
    region: "Pacific",
  },
  {
    label: "Pacific/Guadalcanal (GMT+11:00)",
    tzCode: "Pacific/Guadalcanal",
    name: "Buala Gizo, Auki, Malango, Honiara, (GMT+11:00)",
    utc: "+11:00",
    region: "Pacific",
  },
  {
    label: "Pacific/Kosrae (GMT+11:00)",
    tzCode: "Pacific/Kosrae",
    name: "Tofol (GMT+11:00)",
    utc: "+11:00",
    region: "Pacific",
  },
  {
    label: "Pacific/Norfolk (GMT+11:00)",
    tzCode: "Pacific/Norfolk",
    name: "Kingston (GMT+11:00)",
    utc: "+11:00",
    region: "Pacific",
  },
  {
    label: "Pacific/Noumea (GMT+11:00)",
    tzCode: "Pacific/Noumea",
    name: "Wé Païta, Dumbéa, Mont-Dore, Nouméa, (GMT+11:00)",
    utc: "+11:00",
    region: "Pacific",
  },
  {
    label: "Pacific/Pohnpei (GMT+11:00)",
    tzCode: "Pacific/Pohnpei",
    name: "Center Government National - Palikir Town, Kolonia Kolonia, (GMT+11:00)",
    utc: "+11:00",
    region: "Pacific",
  },
  {
    label: "Antarctica/McMurdo (GMT+12:00)",
    tzCode: "Antarctica/McMurdo",
    name: "McMurdo (GMT+12:00)",
    utc: "+12:00",
    region: "Antarctica",
  },
  {
    label: "Asia/Anadyr (GMT+12:00)",
    tzCode: "Asia/Anadyr",
    name: "Bilibino Anadyr, (GMT+12:00)",
    utc: "+12:00",
    region: "Asia",
  },
  {
    label: "Asia/Kamchatka (GMT+12:00)",
    tzCode: "Asia/Kamchatka",
    name: "Mil’kovo Klyuchi, Vilyuchinsk, Yelizovo, Petropavlovsk-Kamchatsky, (GMT+12:00)",
    utc: "+12:00",
    region: "Asia",
  },
  {
    label: "Pacific/Auckland (GMT+12:00)",
    tzCode: "Pacific/Auckland",
    name: "Shore North City, Manukau Christchurch, Wellington, Auckland, (GMT+12:00)",
    utc: "+12:00",
    region: "Pacific",
  },
  {
    label: "Pacific/Fiji (GMT+12:00)",
    tzCode: "Pacific/Fiji",
    name: "Ba Labasa, Nadi, Lautoka, Suva, (GMT+12:00)",
    utc: "+12:00",
    region: "Pacific",
  },
  {
    label: "Pacific/Funafuti (GMT+12:00)",
    tzCode: "Pacific/Funafuti",
    name: "Village Asau Village, Toga Village, Tanrake Village, Savave Funafuti, (GMT+12:00)",
    utc: "+12:00",
    region: "Pacific",
  },
  {
    label: "Pacific/Kwajalein (GMT+12:00)",
    tzCode: "Pacific/Kwajalein",
    name: "Jabat Ebaye, (GMT+12:00)",
    utc: "+12:00",
    region: "Pacific",
  },
  {
    label: "Pacific/Majuro (GMT+12:00)",
    tzCode: "Pacific/Majuro",
    name: "Mili Wotje, Jabor, Arno, Majuro, (GMT+12:00)",
    utc: "+12:00",
    region: "Pacific",
  },
  {
    label: "Pacific/Nauru (GMT+12:00)",
    tzCode: "Pacific/Nauru",
    name: "Ijuw Uaboe, Anabar, Baiti, Yaren, (GMT+12:00)",
    utc: "+12:00",
    region: "Pacific",
  },
  {
    label: "Pacific/Tarawa (GMT+12:00)",
    tzCode: "Pacific/Tarawa",
    name: "Village Bikenibeu Village, Betio Tarawa, (GMT+12:00)",
    utc: "+12:00",
    region: "Pacific",
  },
  {
    label: "Pacific/Wake (GMT+12:00)",
    tzCode: "Pacific/Wake",
    name: "Wake (GMT+12:00)",
    utc: "+12:00",
    region: "Pacific",
  },
  {
    label: "Pacific/Wallis (GMT+12:00)",
    tzCode: "Pacific/Wallis",
    name: "Alo Leava, Mata-Utu, (GMT+12:00)",
    utc: "+12:00",
    region: "Pacific",
  },
  {
    label: "Pacific/Chatham (GMT+12:45)",
    tzCode: "Pacific/Chatham",
    name: "Waitangi (GMT+12:45)",
    utc: "+12:45",
    region: "Pacific",
  },
  {
    label: "Pacific/Apia (GMT+13:00)",
    tzCode: "Pacific/Apia",
    name: "Leulumoega Afega, Mulifanua, Asau, Apia, (GMT+13:00)",
    utc: "+13:00",
    region: "Pacific",
  },
  {
    label: "Pacific/Enderbury (GMT+13:00)",
    tzCode: "Pacific/Enderbury",
    name: "Enderbury (GMT+13:00)",
    utc: "+13:00",
    region: "Pacific",
  },
  {
    label: "Pacific/Fakaofo (GMT+13:00)",
    tzCode: "Pacific/Fakaofo",
    name: "settlement old Fale Nukunonu, Village, Atafu (GMT+13:00)",
    utc: "+13:00",
    region: "Pacific",
  },
  {
    label: "Pacific/Tongatapu (GMT+13:00)",
    tzCode: "Pacific/Tongatapu",
    name: "‘Ohonua Pangai, Neiafu, Lapaha, Nuku‘alofa, (GMT+13:00)",
    utc: "+13:00",
    region: "Pacific",
  },
  {
    label: "Pacific/Kiritimati (GMT+14:00)",
    tzCode: "Pacific/Kiritimati",
    name: "Kiritimati (GMT+14:00)",
    utc: "+14:00",
    region: "Pacific",
  },
];
