import styled from "@emotion/styled";
import { forwardRef } from "react";

import { Icon, IconDetail } from "@smart/itops-icons-dom";

import { FontSizeKey, PartColors, Variant, variantStyle } from "../theme";

const PillWrapper = styled.span<{
  size?: FontSizeKey;
  partColors?: PartColors;
  variant?: Variant;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.6rem;
  min-width: min-content;

  border-radius: 100px;
  border: 0;
  padding: 0.8rem 1.6rem;
  font-size: ${(props) =>
    props.size ? props.theme.fontSize[props.size] : "inherit"};
  font-weight: 600;
  white-space: nowrap;
  ${variantStyle}

  transition:
    background-color 0.2s,
    border-color 0.2s,
    color 0.2s;
`;

export type PillProps = {
  icon?: IconDetail;
  text?: string;
  className?: string;
  size?: FontSizeKey;
  partColors?: PartColors;
  variant?: Variant;
};

export const Pill = forwardRef<HTMLDivElement, PillProps>(
  ({ icon, text, ...pillProps }, ref) => (
    <PillWrapper ref={ref} {...pillProps}>
      {text && <span className="pill-text">{text}</span>}
      {icon && <Icon className="pill-icon" {...icon} />}
    </PillWrapper>
  ),
);
