import styled from "@emotion/styled";
import { ReactNode } from "react";

import { EditorElement } from "@smart/itops-serialisation-basic";

const Link = styled.a`
  color: ${(props) => props.theme.palette.primary.base};
`;

export type DisplayLinkProps = {
  element: EditorElement<"link">;
  children: ReactNode;
};

export const DisplayLink = ({ children, element }: DisplayLinkProps) => (
  <Link href={element.url} target="_blank" rel="noreferrer">
    {children}
  </Link>
);
