import styled from "@emotion/styled";
import { position } from "polished";

import {
  buildDisplayName,
  buildInitials,
  specialChars,
} from "@smart/itops-utils-basic";

const AvatarWrapper = styled.div<{ size: number }>`
  margin: 0.6rem;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;

  background-color: ${(props) => props.theme.palette.background.base};
  border: 2px solid ${(props) => props.theme.palette.primary.base};
  border-radius: 10rem;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  .initials {
    font-size: ${(props) => props.theme.fontSize.small};
    font-weight: 800;
  }

  .image {
    ${position("absolute", 0)}
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
`;

type AvatarProps = {
  size?: number;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  initials?: string | null;
  picture?: string | null;
};

const defaultSize = 38;

const Avatar = ({ size, picture, ...props }: AvatarProps) => (
  <AvatarWrapper
    size={size || defaultSize}
    title={buildDisplayName(props, specialChars.enDash)}
  >
    <span className="initials">
      {buildInitials(props, specialChars.enDash)}
    </span>
    <div
      className="image"
      role="img"
      style={{ backgroundImage: picture ? `url(${picture})` : undefined }}
    />
  </AvatarWrapper>
);

export { AvatarProps, Avatar };
