import objectHash from "object-hash";
import { z } from "zod";

import { buildNamespace } from "@smart/itops-types-basic";

import { system } from "./base";

export const SubmissionLinkHashNS = buildNamespace({
  system,
  entity: "SubmissionLinkHash",
} as const);

export const SubmissionLinkHashSchema = z.object({
  uri: SubmissionLinkHashNS.schema,
  operationId: z.string(),

  teamUri: z.string(),
  slugUri: z.string(),
  formUri: z.string(),
  userUri: z.string(),
  notifiedUserUris: z.array(z.string()).optional(),
  analyticsTools: z
    .array(z.object({ type: z.string(), trackingId: z.string() }))
    .optional(),

  createdAt: z.string(),
  updatedAt: z.string().optional(),

  deleted: z.boolean().optional(),
  deletedAtSec: z.number().optional(),
});

export const CreateSubmissionLinkHashName = "create-submission-link";

export type AnalyticsTool = {
  type: "googleAnalytics";
  trackingId: string;
};

export const buildHash = <T extends {}>(name: string, data: T): string =>
  objectHash({ name, data });

export const buildCreateSubmissionLinkHash = (data: {
  teamUri: string;
  slugUri: string;
  formUri: string;
  userUri: string;
  notifiedUserUris?: string[];
}) => buildHash(CreateSubmissionLinkHashName, data);
