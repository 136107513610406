import { z } from "zod";

import { buildNamespace } from "@smart/itops-types-basic";

import { system } from "./base";
import { FormNS } from "./form";
import { fieldLinkSchema } from "./link";
import { SectionNS } from "./section";

export const GroupNS = buildNamespace({
  system,
  entity: "Group",
} as const);

export const groupTypeValue = [
  "layout",
  "layoutContact",
  "layoutRepeatable",
  "template",
  "custom",
] as const;

export type GroupType = (typeof groupTypeValue)[number];

export const groupTypeLabel: Record<GroupType, string> = {
  layout: "Layout",
  layoutContact: "Contact Layout",
  layoutRepeatable: "Repeatable Layout",
  template: "Template",
  custom: "Custom",
};
export const groupTypeSchema = z.enum(groupTypeValue);

export const groupTemplateTypeValue = ["address", "contact", "person"] as const;

export type GroupTemplateType = (typeof groupTemplateTypeValue)[number];

export const groupTemplateTypeLabel: Record<GroupTemplateType, string> = {
  address: "Address Details",
  contact: "Contact Details",
  person: "Person",
};
export const groupTemplateTypeSchema = z.enum(groupTemplateTypeValue);

export const GroupSchema = z.object({
  uri: GroupNS.schema,
  operationId: z.string(),

  formUri: FormNS.schema,
  sectionUri: SectionNS.schema,
  order: z.string().regex(/[a-z:]/),

  label: z.string().optional(),
  hint: z.string().optional(),
  description: z.string().optional(),
  type: groupTypeSchema,
  templateType: groupTemplateTypeSchema.optional(),
  links: z.array(fieldLinkSchema).optional(),

  layout: z
    .object({
      id: z.string(),
      providerId: z.string(),
      name: z.string(),
      parentId: z.string().optional(),
      parentName: z.string().optional(),
      parentProviderId: z.string().optional(),
    })
    .optional()
    .nullable(),

  field: z
    .object({
      name: z.string(),
      type: z.string(),
      possibleValues: z.array(z.string()).optional(),
    })
    .optional()
    .nullable(),

  repeatable: z.boolean().optional(),
  repeatPrompt: z.string().optional(),
  minRepeat: z.number().optional(),
  maxRepeat: z.number().optional(),
  allowedRepeatable: z.boolean().optional(),

  updatedAt: z.string(),

  deleted: z.boolean().optional(),
  deletedAtSec: z.number().optional(),
});
