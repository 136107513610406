import styled from "@emotion/styled";
import { position } from "polished";
import { ChangeEventHandler } from "react";
import { UseFormRegisterReturn } from "react-hook-form";

import { CheckboxItem } from "@smart/itops-components-dom";

const CheckBoxWrapper = styled(CheckboxItem)`
  .label {
    margin-left: 2.3rem;
  }

  .checkmark {
    ${position("absolute", "1.1rem", "0")}
    height: 1.7rem;
    width: 1.7rem;
    border-radius: 0.3rem;
    border-color: ${(props) => props.theme.palette.active.accent};

    &:after {
      content: "";
      ${position("absolute", "0rem", 0, 0, "0.45rem")}
      opacity: 0;
      width: 0.6rem;
      height: 1.3rem;
      border: solid ${(props) => props.theme.palette.primary.accent};
      border-width: 0 0.25rem 0.25rem 0;
      transform: rotate(45deg);
    }
  }

  input:checked ~ .checkmark,
  :hover input:checked ~ .checkmark,
  :hover input ~ .checkmark {
    background-color: white;
    border-color: black;
  }

  &:hover .label,
  input:checked ~ .label {
    text-decoration: none;
  }
`;

type CheckBoxProps = {
  onChange?: ChangeEventHandler<HTMLInputElement>;
  checked?: boolean;
  registered?: UseFormRegisterReturn;
  label?: string;
};

const CheckBox = ({ onChange, checked, registered, label }: CheckBoxProps) => {
  const inputProps = registered || { checked, onChange };

  return (
    <CheckBoxWrapper className="checkbox">
      <input type="checkbox" {...inputProps} />
      <div className="checkmark" />
      {label && <div className="label">{label}</div>}
    </CheckBoxWrapper>
  );
};
export { CheckBox };
