export const uploadFile = ({
  uploadUrl,
  file,
  onProgress,
}: {
  uploadUrl: string;
  file: File;
  onProgress: (percent: number) => void;
}) =>
  new Promise<void>((resolve, reject) => {
    const request = new XMLHttpRequest();
    request.open("PUT", uploadUrl);
    onProgress(0);
    if (request.upload && request.upload.addEventListener) {
      request.upload.addEventListener("progress", (e) => {
        onProgress((e.loaded / e.total) * 100);
      });
    }
    request.addEventListener("error", () => {
      reject(new Error("Upload Failed"));
    });
    request.addEventListener("load", () => {
      if (request.status === 200) {
        resolve();
      } else {
        reject(new Error(request.responseText));
      }
    });

    request.send(file);
  });
