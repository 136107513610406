import { z } from "zod";

import { buildNamespace } from "@smart/itops-types-basic";

import { system } from "./base";
import { activityEventDataTypeValue } from "./activity-event";

export const activityTypeValue = [
  "send-submission",
  "view-submission",
] as const;

export type ActivityType = (typeof activityTypeValue)[number];

export const UserActivityStateNS = buildNamespace({
  system,
  entity: "UserActivityState",
} as const);

const eventSchema = z.object({
  operationId: z.string(),
  type: z.enum(activityEventDataTypeValue),
  activity: z.enum(activityTypeValue),
  matterId: z.string(),
  formId: z.string().optional(),
  submissionId: z.string().optional(),
  fieldId: z.string().optional(),
  userId: z.string(),
  createdAt: z.string(),
});

const activitySchema = z.object({
  type: z.enum(activityTypeValue),
  matterId: z.string(),
  events: z.array(eventSchema),
});

export const UserActivityStateSchema = z.object({
  uri: z.string(),
  operationId: z.string(),

  currentActivity: activitySchema.nullable(),
  pausedActivities: z.array(activitySchema),

  updatedAt: z.string(),
});
