import { Editor, createEditor } from "slate";
import { withHistory } from "slate-history";
import { withReact } from "slate-react";

import {
  EditorElement,
  EditorElementType,
} from "@smart/itops-serialisation-basic";

const INLINE_ELEMENT_TYPES: EditorElementType[] = ["link", "placeholder"];
const VOID_ELEMENT_TYPES: EditorElementType[] = ["placeholder"];

export const withCustomCheckers = (editor: Editor) => {
  const { isInline, isVoid } = editor;

  editor.isInline = (element: EditorElement) =>
    INLINE_ELEMENT_TYPES.includes(element.type) ? true : isInline(element);

  editor.isVoid = (element: EditorElement) =>
    VOID_ELEMENT_TYPES.includes(element.type) ? true : isVoid(element);

  return editor;
};

export const createCustomEditor = () =>
  withReact(withHistory(withCustomCheckers(createEditor())));
