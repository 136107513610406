import { TimeZone, timeZones } from "./list";
import { partition } from "../array";

type BuildListOption = {
  noDefault?: boolean;
};

const getRegion = (timezone: TimeZone) =>
  timezone.tzCode.split("/").reverse().pop();

export const localTimeZoneCode =
  Intl.DateTimeFormat().resolvedOptions().timeZone;

export const buildTimeZoneList = (option?: BuildListOption): TimeZone[] => {
  const sortedTimeZones = [...timeZones].sort((a, b) =>
    a.label > b.label ? 1 : -1,
  );
  if (option?.noDefault) return sortedTimeZones;

  const localTzCode = localTimeZoneCode;
  const local = sortedTimeZones.find((item) => item.tzCode === localTzCode);
  if (!local) return sortedTimeZones;

  // Move the local time-zone and region to the top of the list
  const [localRegion, otherRegions] = partition(
    sortedTimeZones,
    (item) => getRegion(item) === getRegion(local),
  );
  const [localTimeZone, otherTimeZones] = partition(
    localRegion,
    (item) => item.tzCode === localTzCode,
  );

  return [...localTimeZone, ...otherTimeZones, ...otherRegions];
};
