/* eslint-disable react/no-array-index-key */
import styled from "@emotion/styled";
import { position, transparentize } from "polished";
import { Fragment, useCallback, useState } from "react";

import { useGlobalKeyboardShortcut } from "@smart/itops-hooks-dom";
import { Icon, IconName } from "@smart/itops-icons-dom";

import { IconButton } from "./buttons";
import { Image } from "./image";
import { Rule } from "./rule";

const HamburgerButton = styled(IconButton)`
  ${position("absolute", "0.8rem", "auto", "auto", 0)}
  margin: 1rem;

  @media (min-width: ${(props) => props.theme.breakPoints.medium}px) {
    display: none;
  }
`;

const Separator = styled(Rule)`
  margin: 1.6rem 1rem;
`;

const SideNavWrapper = styled.div<{ expanded: boolean }>`
  ${position("absolute", 0)};
  height: 100%;
  width: ${(props) => (props.expanded ? "100%" : "0rem")};
  background: ${(props) =>
    transparentize(0.5, props.theme.palette.foreground.base)};
  z-index: 5;
  overflow: hidden;

  display: flex;
  flex-flow: column no-wrap;
  justify-content: stretch;

  --background: ${(props) => props.theme.palette.foreground.highlight};
  --foreground: ${(props) => props.theme.palette.background.base};
  --highlight: ${(props) => props.theme.palette.foreground.accent};

  .nav {
    width: 30rem;

    background: var(--background);
    color: var(--foreground);

    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: none;

    h2.title {
      margin: 2rem 1.6rem;
      font-weight: bold;
      font-size: ${(props) => props.theme.fontSize.subHeading};
      line-height: 1.4;
      letter-spacing: 0.4px;
    }
  }

  .overlay {
    flex: 1;

    .close-menu {
      background: var(--background);
      color: ${(props) => props.theme.palette.background.base};
      margin: 1.5rem 1rem;
    }
  }

  @media (min-width: ${(props) => props.theme.breakPoints.medium}px) {
    position: relative;
    width: 45rem;
    background: transparent;

    --background: transparent;
    --foreground: ${(props) => props.theme.palette.foreground.base};
    --highlight: ${(props) => props.theme.palette.background.accent};

    .nav {
      h2.title {
        display: none;
      }
      width: 100%;
      padding: 1rem 1rem;
      border-right: 1px dotted ${(props) => props.theme.palette.disabled.base};
    }

    .overlay {
      display: none;
    }
  }
`;

const TeamInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  .logo {
    width: 5rem;
    height: 5rem;
    margin-left: 0.5rem;
    object-fit: contain;
    @media (min-width: ${(props) => props.theme.breakPoints.medium}px) {
      margin-left: 0;
    }
  }

  .name {
    margin-left: 1rem;
    font-size: ${(props) => props.theme.fontSize.base};
    font-weight: 500;
  }
`;

export const NavButton = styled.button<{ selected?: boolean; child?: boolean }>`
  cursor: pointer;
  display: flex;
  width: 100%;
  align-items: center;
  padding: 1.4rem 1.6rem;
  margin: 0;
  background: none;
  border: 0;
  background: ${(props) =>
    props.selected ? "var(--highlight)" : "transparent"};

  p {
    margin: 0;
    margin-left: ${(props) => (props.child ? "4rem" : 0)};
    font-size: ${(props) =>
      props.child ? props.theme.fontSize.small : props.theme.fontSize.base};
    font-weight: 500;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &:hover p {
    text-decoration: underline dotted;
  }

  .error {
    display: block;
    width: 1rem;
    height: 1rem;
    border-radius: 1rem;
    background: ${(props) => props.theme.palette.danger.accent};
    margin-left: 1rem;
  }

  .open-sub-menu {
    margin: 0 1rem 0 -2rem;
  }
`;

export const NavGroup = styled.div<{ visible: boolean }>`
  display: ${(props) => (props.visible ? "block" : "none")};
`;

type SideNavLink = {
  label: string;
  value?: string;
  selected?: boolean;
  icon?: IconName;
  hidden?: boolean;
  error?: boolean;
  separator?: boolean;
};

type SideNavLinkWithChildren = SideNavLink & {
  childLinks?: SideNavLink[];
};

type SideNavProps = {
  className?: string;
  teamName?: string;
  teamLogo?: string;
  editable?: boolean;
  title?: string;
  onOpen?: (index: number, subIndex?: number) => void;
  links: SideNavLinkWithChildren[];
};

const SideNav = ({
  className,
  teamName,
  teamLogo,
  editable = true,
  title,
  links,
  onOpen,
}: SideNavProps) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [visibleGroup, setVisibleGroup] = useState<number>();

  useGlobalKeyboardShortcut({
    key: "Escape",
    fn: useCallback(() => {
      setVisibleGroup(undefined);
      setExpanded(false);
    }, []),
  });

  return (
    <>
      {!expanded && (
        <HamburgerButton
          name="menu"
          className={`open-menu ${className || ""}`}
          aria-label="Open Form Menu"
          onClick={() => setExpanded(true)}
          dot={links.find((l) => l.error) ? "danger" : undefined}
        />
      )}
      <SideNavWrapper
        role="navigation"
        aria-label="Form Menu"
        expanded={expanded}
      >
        <div className="nav">
          {title && <h2 className="title">{title}</h2>}
          <TeamInfoWrapper>
            <Image src={teamLogo} ariaLabel="Team Logo" className="logo" />
            <h3 className="name">{teamName}</h3>
          </TeamInfoWrapper>
          {links.map(
            (
              { label, icon, selected, hidden, childLinks, error, separator },
              index,
            ) =>
              hidden ? null : (
                <Fragment key={index}>
                  {separator && <Separator />}
                  <NavButton
                    selected={selected}
                    onClick={() => {
                      setExpanded(false);
                      if (onOpen) onOpen(index);
                    }}
                    type="button"
                    role="link"
                    title={label}
                  >
                    {childLinks && childLinks.length > 0 && (
                      <IconButton
                        name="angleRight"
                        className="open-sub-menu"
                        palette="background"
                        rotate={visibleGroup === index ? 90 : 0}
                        size={10}
                        onClick={(e) => {
                          e.stopPropagation();
                          setVisibleGroup(
                            visibleGroup === index ? undefined : index,
                          );
                        }}
                      />
                    )}
                    {icon && <Icon name={icon} />}
                    <p>{label}</p>
                    {editable && error && <span className="error" />}
                  </NavButton>
                  {childLinks && childLinks.length && (
                    <NavGroup visible={visibleGroup === index}>
                      {childLinks.map((child, cIndex) => (
                        <NavButton
                          key={cIndex}
                          child
                          onClick={() => {
                            setExpanded(false);
                            if (onOpen) onOpen(index, cIndex);
                          }}
                          type="button"
                          role="link"
                        >
                          {child.icon && <Icon name={child.icon} />}
                          <p>{child.label}</p>
                        </NavButton>
                      ))}
                    </NavGroup>
                  )}
                </Fragment>
              ),
          )}
        </div>
        <div
          className="overlay"
          role="presentation"
          onClick={() => setExpanded(false)}
        >
          {expanded && (
            <IconButton
              name="cancel"
              className="close-menu"
              size={14}
              aria-label="Close Form Menu"
            />
          )}
        </div>
      </SideNavWrapper>
    </>
  );
};

export { SideNav, SideNavLink, SideNavLinkWithChildren, SideNavProps };
