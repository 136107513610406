import { z } from "zod";

import { buildNamespace } from "@smart/itops-types-basic";

import { system } from "./base";
import { FormNS } from "./form";
import { TeamNS } from "./team";
import { UserNS } from "./user";

export const FormMatterTypeNS = buildNamespace({
  system,
  entity: "FormMatterType",
} as const);

export const FormMatterTypeSchema = z.object({
  uri: FormMatterTypeNS.schema,
  operationId: z.string(),

  formUri: FormNS.schema,
  teamUri: TeamNS.schema,

  updatedBy: UserNS.schema,
  updatedAt: z.string(),

  id: z.string(),
  source: z.string(),
  name: z.string(),
  category: z.string(),
  location: z.string(),
  representativeOptions: z.array(z.string()).optional(),

  deleted: z.boolean().optional(),
  deletedAtSec: z.number().optional(),
});
