export class FieldError extends Error {
  public fieldName: string;

  public fieldError: string;

  constructor(fieldName: string, fieldError: string) {
    super(`${fieldName}: ${fieldError}`);

    this.fieldName = fieldName;
    this.fieldError = fieldError;
  }
}
