import {
  useState,
  useEffect,
  createContext,
  ReactNode,
  useContext,
} from "react";

const loadHash = () => {
  const newHash = window.location.hash.substring(1);
  if (newHash) {
    window.history.replaceState(null, "", window.location.pathname);
  }
  return newHash;
};

export const processHash = () =>
  Object.fromEntries(new URLSearchParams(loadHash()));

export type FragmentContextValue = {
  hash: Record<string, string>;
  resetHash: () => void;
};

export const FragmentContext = createContext<FragmentContextValue>({
  hash: {},
  resetHash: () => {},
});

export const setupFragment = (): FragmentContextValue => {
  const [hash, setHash] = useState(() => processHash());

  useEffect(() => {
    const handleChange = () => {
      if (window.location.hash) {
        setHash(processHash());
      }
    };

    window.addEventListener("popstate", handleChange);
    return () => window.removeEventListener("popstate", handleChange);
  }, []);

  const resetHash = () => {
    window.history.replaceState(null, "", window.location.pathname);
    setHash({});
  };

  return { hash, resetHash };
};

export type FragmentProviderProps = {
  children: ReactNode;
};

export const FragmentProvider = ({ children }: FragmentProviderProps) => {
  const value = setupFragment();

  return (
    <FragmentContext.Provider value={value}>
      {children}
    </FragmentContext.Provider>
  );
};

export const processFragment = <K extends string>(
  hash: Record<string, string>,
  keys: K[],
) => {
  const fragment: Partial<Record<K, string>> = {};
  let hasValues = false;
  for (const key of keys) {
    const value = hash[key] || undefined;
    if (value) hasValues = true;
    fragment[key] = value;
  }

  return { fragment, hasValues };
};

export const useFragment = <K extends string>(keys: K[]) => {
  const { hash, resetHash } = useContext(FragmentContext);
  const [fragment, setFragment] = useState<Partial<Record<K, string>>>(
    () => processFragment(hash, keys).fragment,
  );

  useEffect(() => {
    const newFrag = processFragment(hash, keys);
    if (newFrag.hasValues) setFragment(newFrag.fragment);
  }, [hash]);

  return [fragment, resetHash] as const;
};
