import styled from "@emotion/styled";
import { ForwardedRef, ReactNode, forwardRef } from "react";

import { Input, InputWrapper } from "./wrapper";
import { FontSizeKey } from "../../theme";

const Radios = styled.div<{
  direction?: "row" | "column";
  align?: string;
  size?: FontSizeKey;
}>`
  display: flex;
  flex-direction: ${(props) => props.direction || "column"};
  justify-content: ${(props) => props.align || "center"};
  gap: 0.8rem;

  font-size: ${(props) =>
    props.size ? props.theme.fontSize[props.size] : "inherit"};
`;

const RadioItem = styled.label<{
  readOnly?: boolean;
}>`
  position: relative;
  cursor: ${(props) => (props.readOnly ? "not-allowed" : "pointer")};
  display: flex;
  flex-flow: row;

  margin: 0;
  padding: 0.6rem 0;
  padding-right: 3rem;

  input {
    appearance: none;
    outline: none;
  }

  .dot {
    position: relative;
    background: var(--background);
    border: 3px solid var(--background);
    box-shadow: 0 0 0 1px ${(props) => props.theme.scheme.grey.r35};
    border-radius: 1.6rem;
    height: 1.6rem;
    width: 1.6rem;
    margin-right: 1rem;
    flex: 0 0 1.6rem;
  }

  .label {
    position: relative;
    font-size: inherit;

    .label-title {
      font-size: 1em;
      font-weight: 350;
    }

    .label-subtitle {
      font-size: 0.8em;
    }
  }

  .option-detail {
  }

  &:hover,
  &:focus-within {
    .dot {
      box-shadow: 0 0 0 1px ${(props) => props.theme.scheme.blue.r60};
    }
  }

  &:focus-within {
    .dot {
      box-shadow:
        0 0 0 1px ${(props) => props.theme.scheme.blue.r60},
        0 0 0 3px ${(props) => props.theme.scheme.blue.r10};
    }
  }

  &[aria-disabled="true"] {
    cursor: not-allowed;

    .dot {
      background: ${(props) => props.theme.scheme.grey.r10};
      border-color: ${(props) => props.theme.scheme.grey.r10};
      box-shadow: 0 0 0 1px ${(props) => props.theme.scheme.grey.r30};
    }
  }

  &:has(:checked) {
    .dot {
      background: ${(props) => props.theme.scheme.blue.r80};
    }
  }

  &.box {
    --background: ${(props) => props.theme.scheme.grey.r0};
    background: var(--background);
    border: 1px solid ${(props) => props.theme.scheme.grey.r35};
    border-radius: 0.8rem;
    padding: 0.8rem 0;
    flex: 1;
    align-items: center;
    min-height: 4.5rem;

    .dot {
      margin: 0.1rem 1rem 0.1rem 0;
    }

    .label {
      flex: 1;

      .label-title {
        font-size: 0.9em;
        font-weight: 600;
      }
    }

    .option-detail {
      margin: 0 0.8rem;
    }

    &:hover,
    &:focus-within {
      border-color: ${(props) => props.theme.scheme.blue.r60};
    }

    &:has(:checked) {
      --background: ${(props) => props.theme.scheme.blue.r5};
    }
  }
`;

const CustomInputWrapper = styled(InputWrapper)`
  margin-left: 3.5rem;
  padding: 0;
`;

export type RadioButtonOptionItem = {
  label: string;
  subtitle?: string;
  detail?: ReactNode;
  value: string;
};

type CustomResponseItemProps = {
  id?: string;
  name?: string;
  options: RadioButtonOptionItem[];
  value?: string;
  onChange: (value: string) => void;
  onBlur: () => void;
  error?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  box?: boolean;
};

const CustomResponseItem = ({
  id,
  name,
  options,
  value,
  onChange,
  onBlur,
  error,
  disabled,
  readOnly,
  box,
}: CustomResponseItemProps) => {
  const isCustomResponse =
    value !== undefined && !options.find((option) => option.value === value);

  return (
    <>
      <RadioItem
        tabIndex={0}
        aria-disabled={disabled}
        readOnly={readOnly}
        className={box ? "box" : "open"}
      >
        <input
          id={id && `${id}-custom`}
          name={name}
          type="radio"
          value="Other"
          checked={isCustomResponse}
          onChange={() => !isCustomResponse && !readOnly && onChange("")}
          disabled={disabled}
        />
        <div className="dot" />
        <div className="label">Other</div>
      </RadioItem>

      {isCustomResponse && (
        <CustomInputWrapper>
          <Input
            id={id && `${id}-custom-value`}
            name={name}
            aria-label="Other Value"
            aria-invalid={error}
            aria-errormessage={error ? `${id}-error` : undefined}
            placeholder="Please specify"
            type="text"
            value={value || ""}
            onChange={(e) => onChange(e.currentTarget.value)}
            onBlur={onBlur}
            disabled={disabled}
            readOnly={readOnly}
          />
        </CustomInputWrapper>
      )}
    </>
  );
};

export type RadioButtonsProps = {
  className?: string;
  options: RadioButtonOptionItem[];
  value: string | undefined;
  onChange: (value: string) => void;
  onBlur?: () => void;
  allowCustomResponse?: boolean | null;
  size?: FontSizeKey;
  id?: string;
  name?: string;
  disabled?: boolean;
  error?: boolean;
  readOnly?: boolean;
  box?: boolean;
  direction?: "row" | "column";
  align?: string;
};

const BaseRadioButtons = (
  {
    className,
    options,
    value,
    onChange,
    onBlur,
    allowCustomResponse,
    size,
    id,
    name,
    error,
    disabled,
    readOnly,
    box,
    direction,
    align,
  }: RadioButtonsProps,
  ref: ForwardedRef<HTMLInputElement>,
) => (
  <Radios
    className={className}
    id={id}
    align={align}
    direction={direction}
    size={size}
  >
    {options.map((o, idx) => (
      <RadioItem
        key={o.value}
        tabIndex={-1}
        aria-disabled={disabled}
        readOnly={readOnly}
        className={box ? "box" : "open"}
      >
        <input
          id={id && `${id}-${o.value}`}
          ref={idx === 0 ? ref : undefined}
          name={name}
          type="radio"
          value={o.value}
          checked={o.value === value}
          onChange={(e) => !readOnly && onChange(e.currentTarget.value)}
          disabled={disabled}
        />
        <div className="dot" />
        <div className="label">
          <div className="label-title">{o.label}</div>
          {o.subtitle && <div className="label-subtitle">{o.subtitle}</div>}
        </div>
        {o.detail && <div className="option-detail">{o.detail}</div>}
      </RadioItem>
    ))}
    {allowCustomResponse && (
      <CustomResponseItem
        id={id}
        name={name}
        error={error}
        options={options}
        value={value}
        onChange={onChange}
        onBlur={() => onBlur && onBlur()}
        disabled={disabled}
        readOnly={readOnly}
        box={box}
      />
    )}
  </Radios>
);

export const RadioButtons = forwardRef(BaseRadioButtons);
