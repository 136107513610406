import { useCallback, useRef, useState } from "react";

import { Guard, logError, buildErrorCode } from "@smart/itops-utils-basic";

import { FetchProps, jsonFetchHelper } from "./fetch";
import { useIsMounted } from "./mounted";
import { useStatus, Status } from "./status";

export type PostOptions<A, I, O> = {
  authenticated: A;
  method: "POST" | "PUT";
  input: Guard<I>;
  output: Guard<O>;
};

export type PostEvents<I, O> = {
  onResult?: (result: {
    url: string;
    method: "POST" | "PUT";
    input: I;
    output: O;
  }) => void;
  onError?: (error: {
    url: string;
    method: "POST" | "PUT";
    input: I;
    error: unknown;
  }) => void;
};

export type PostFn<A, I, O> = (
  props: FetchProps<A, I> & {
    query?: Record<string, string>;
    events?: PostEvents<I, O>;
  },
) => Promise<O | undefined>;

export type PostHook<A, I, O> = () => {
  status: Status;
  result?: O;
  error?: string;
  post: PostFn<A, I, O>;
};

export const usePost =
  <A, I, O>(
    postUrl: string,
    { authenticated, method, input, output }: PostOptions<A, I, O>,
  ): PostHook<A, I, O> =>
  () => {
    const { isMounted } = useIsMounted();
    const postId = useRef(0);
    const [status, setStatus] = useStatus();
    const [errorCode, setErrorCode] = useState<string>();
    const [result, setResult] = useState<O>();

    const post = useCallback<PostFn<A, I, O>>(
      async (props) => {
        const myPostId = Date.now();
        postId.current = myPostId;

        let url = postUrl;
        if (props.query)
          url = `${url}?${new URLSearchParams(props.query).toString()}`;
        try {
          if (myPostId === postId.current && isMounted()) setStatus("loading");
          const fetched = await jsonFetchHelper(
            { authenticated, method, input, output },
            url,
            props,
          );
          if (props.events?.onResult) {
            props.events.onResult({
              url,
              method,
              input: props.body,
              output: fetched,
            });
          }

          if (myPostId === postId.current && isMounted()) {
            setStatus("success");
            setResult(fetched);
          }

          return fetched;
        } catch (e) {
          console.warn("Error occurred while posting:", url);
          logError(e);

          if (props.events?.onError) {
            props.events.onError({
              url,
              method,
              input: props.body,
              error: e,
            });
          }

          if (myPostId === postId.current && isMounted()) {
            setStatus("error");
            setErrorCode(buildErrorCode(e));
          }
        }

        return undefined;
      },
      [postUrl, method, input, output],
    );

    return {
      status,
      post,
      result,
      errorCode,
    };
  };
