import isHotkey from "is-hotkey";
import { useMemo, KeyboardEvent } from "react";
import { Descendant } from "slate";

import { serialize, deserialize, Mark } from "@smart/itops-serialisation-basic";

import { Editor, ExtendedAction } from "./types";
import { toggleMark } from "./utils";

export const HOTKEYS: Record<string, Mark> = {
  "mod+b": "bold",
  "mod+i": "italic",
};

export const useParsed = (value?: string) =>
  useMemo(() => (value ? deserialize(value) : null), [value]);

export const useInitialValue = (initialValue?: string) =>
  useMemo(() => deserialize(initialValue), []);

export const useEditorActions = ({
  editor,
  extendedActions,
  disabledHotkeys,
  onChange,
}: {
  editor: Editor;
  extendedActions?: ExtendedAction[];
  disabledHotkeys?: string[];
  onChange?: (v: string) => void;
}) =>
  useMemo(
    () => ({
      onChange: (v: Descendant[]) => {
        const isAstChange = editor.operations.some(
          (op) => op.type !== "set_selection",
        );
        if (isAstChange && onChange) onChange(serialize(v));
      },
      onKeyDown: (e: KeyboardEvent<HTMLDivElement>) => {
        for (const hotkey in HOTKEYS) {
          if (isHotkey(hotkey, e)) {
            e.preventDefault();
            toggleMark(editor, HOTKEYS[hotkey]);
          }
        }

        if (extendedActions) {
          for (const action of extendedActions) {
            if (action.hotkey && isHotkey(action.hotkey, e)) {
              e.preventDefault();
              action.onClick();
            }
          }
        }

        if (disabledHotkeys?.some((hotkey) => isHotkey(hotkey, e)))
          e.preventDefault();
      },
    }),
    [editor],
  );
