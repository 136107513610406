import { GraphQLFormattedError } from "graphql";

export class GQLError extends Error {
  gqlErrors: GraphQLFormattedError[];

  statusCode: number;

  constructor(errors: readonly GraphQLFormattedError[]) {
    super(errors.length === 1 ? errors[0].message : "Multiple GraphQL Errors");

    this.statusCode = 500;
    this.gqlErrors = [...errors];
  }
}
