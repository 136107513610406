import styled from "@emotion/styled";
import {
  arrow,
  autoUpdate,
  flip,
  FloatingArrow,
  offset,
  shift,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
} from "@floating-ui/react";
import { useRef, useState } from "react";

import { TooltipContent } from "./tooltip";
import { skeleton } from "../utils";

export const MultipleTextCount = styled.span`
  margin-left: 0.5rem;
  padding: 0.2rem 0.5rem 0.1rem;
  border: 1px solid ${(props) => props.theme.scheme.grey.r50};
  border-radius: 4px;
  font-size: 0.95em;
  line-height: 1.2;
  font-weight: 500;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const MultipleTextLabelWrapper = styled.span`
  display: flex;
  align-items: center;

  .visible-items {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

const MultipleTextLoading = styled(MultipleTextLabelWrapper)`
  border-radius: 0.8rem;
  ${skeleton(3, 0)}
`;

export type MultipleTextLabelProps = {
  texts: string[];
  loading?: boolean;
  placeholder?: string;
  maxVisibleItems?: number;
};

export const MultipleTextLabel = ({
  texts,
  loading,
  placeholder,
  maxVisibleItems = 1,
}: MultipleTextLabelProps) => {
  const sorted = [...new Set(texts)].sort();
  const tooltip = sorted.join(", ");
  const visibleItems = sorted.splice(0, maxVisibleItems);

  const arrowRef = useRef(null);
  const [open, onOpenChange] = useState(false);
  const { refs, floatingStyles, context } = useFloating({
    open,
    onOpenChange,
    placement: "bottom",
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(6),
      shift({ padding: 5 }),
      flip({ fallbackPlacements: ["right", "left", "top"] }),
      arrow({ element: arrowRef }),
    ],
  });

  const hover = useHover(context, { move: false });
  const focus = useFocus(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: "tooltip" });
  const { getFloatingProps, getReferenceProps } = useInteractions([
    hover,
    focus,
    dismiss,
    role,
  ]);

  return loading ? (
    <MultipleTextLoading>
      <span className="visible-items">Loading {placeholder || "items"}...</span>
    </MultipleTextLoading>
  ) : (
    <>
      <MultipleTextLabelWrapper
        ref={refs.setReference}
        {...getReferenceProps()}
      >
        <span className="visible-items">{visibleItems.join(", ")}</span>
        {sorted.length ? (
          <MultipleTextCount>+{sorted.length}</MultipleTextCount>
        ) : null}
      </MultipleTextLabelWrapper>
      {open && !!sorted.length && (
        <TooltipContent
          ref={refs.setFloating}
          style={floatingStyles}
          {...getFloatingProps()}
        >
          <FloatingArrow ref={arrowRef} context={context} tipRadius={2} />
          <p>{tooltip}</p>
        </TooltipContent>
      )}
    </>
  );
};
