/* eslint-disable no-underscore-dangle */
import { codes } from "micromark-util-symbol";
import { Code, Effects, State } from "micromark-util-types";

import {
  CASING_TOKEN_TYPE,
  CHUNK_STRING_TOKEN_TYPE,
  DISPLAY_TEXT_MARKER_TOKEN_TYPE,
  DISPLAY_TEXT_TOKEN_TYPE,
  IS_LINK_MARKER_TOKEN_TYPE,
  PLACEHOLDER_MARKER_TOKEN_TYPE,
  PLACEHOLDER_NAME_TOKEN_TYPE,
  PLACEHOLDER_TOKEN_TYPE,
} from "./constants";

/**
 * This will go through the placeholder characters one by one and
 * turn them into tokens, starting from left curly brace (the triggerring character).
 */
const tokenize = (effects: Effects, ok: State, nok: State) => {
  let casing: "lowercase" | undefined;
  let isLink = false;
  const nameChars: string[] = [];
  const displayTextChars: string[] = [];

  const exitPlaceholder = (code: Code) => {
    effects.enter(PLACEHOLDER_MARKER_TOKEN_TYPE);
    effects.consume(code);
    effects.exit(PLACEHOLDER_MARKER_TOKEN_TYPE);
    const token = effects.exit(PLACEHOLDER_TOKEN_TYPE);
    token._casing = casing;
    token._isLink = isLink;
    token._placeholderName = nameChars.join("");
    token._displayText = displayTextChars.join("");
  };

  const displayText = (code: Code) => {
    if (code === null) return nok(code);

    if (code === codes.rightCurlyBrace) {
      effects.exit(CHUNK_STRING_TOKEN_TYPE);
      effects.exit(DISPLAY_TEXT_TOKEN_TYPE);
      exitPlaceholder(code);

      return ok;
    }

    effects.consume(code);
    displayTextChars.push(String.fromCharCode(code));

    return displayText;
  };

  const checkLink = (code: Code) => {
    if (code !== codes.greaterThan) {
      effects.enter(DISPLAY_TEXT_TOKEN_TYPE);
      effects.enter(CHUNK_STRING_TOKEN_TYPE, { contentType: "string" });
      return displayText(code);
    }

    isLink = true;
    effects.enter(IS_LINK_MARKER_TOKEN_TYPE);
    effects.consume(code);
    effects.exit(IS_LINK_MARKER_TOKEN_TYPE);

    effects.enter(DISPLAY_TEXT_TOKEN_TYPE);
    effects.enter(CHUNK_STRING_TOKEN_TYPE, { contentType: "string" });
    return displayText;
  };

  const name = (code: Code) => {
    if (code === null) return nok(code);

    if (code === codes.verticalBar) {
      effects.exit(CHUNK_STRING_TOKEN_TYPE);
      effects.exit(PLACEHOLDER_NAME_TOKEN_TYPE);
      effects.enter(DISPLAY_TEXT_MARKER_TOKEN_TYPE);
      effects.consume(code);
      effects.exit(DISPLAY_TEXT_MARKER_TOKEN_TYPE);

      return checkLink;
    }

    if (code === codes.rightCurlyBrace) {
      effects.exit(CHUNK_STRING_TOKEN_TYPE);
      effects.exit(PLACEHOLDER_NAME_TOKEN_TYPE);
      exitPlaceholder(code);

      return ok;
    }

    effects.consume(code);
    nameChars.push(String.fromCharCode(code));

    return name;
  };

  const checkCasing = (code: Code) => {
    const enterPlaceholderNameToken = () => {
      effects.enter(PLACEHOLDER_NAME_TOKEN_TYPE);
      effects.enter(CHUNK_STRING_TOKEN_TYPE, { contentType: "string" });
    };

    if (code !== codes.underscore) {
      enterPlaceholderNameToken();

      return name(code);
    }

    casing = "lowercase";
    effects.enter(CASING_TOKEN_TYPE);
    effects.enter(CHUNK_STRING_TOKEN_TYPE, { contentType: "string" });
    effects.consume(code);
    effects.exit(CHUNK_STRING_TOKEN_TYPE);
    effects.exit(CASING_TOKEN_TYPE);

    enterPlaceholderNameToken();

    return name;
  };

  const start = (code: Code) =>
    code === codes.rightCurlyBrace ? nok(code) : checkCasing(code);

  const init = (code: Code) => {
    if (code === codes.leftCurlyBrace) {
      effects.enter(PLACEHOLDER_TOKEN_TYPE);
      effects.enter(PLACEHOLDER_MARKER_TOKEN_TYPE);
      effects.consume(code);
      effects.exit(PLACEHOLDER_MARKER_TOKEN_TYPE);
      return start;
    }

    return nok(code);
  };

  return init;
};

export const placeholderSyntaxExtension = {
  text: { [codes.leftCurlyBrace]: { tokenize } },
};
