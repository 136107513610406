import {
  ForwardedRef,
  TextareaHTMLAttributes,
  ReactNode,
  forwardRef,
} from "react";

import { Area, InputKind, InputWrapper } from "./wrapper";
import { FontSizeKey } from "../../theme";

export type TextAreaProps = {
  size?: FontSizeKey;
  kind?: InputKind;
  right?: ReactNode;
  error?: boolean;
  className?: string;
  minHeight?: number;
} & Pick<
  TextareaHTMLAttributes<HTMLTextAreaElement>,
  | "id"
  | "disabled"
  | "name"
  | "title"
  | "placeholder"
  | "rows"
  | "value"
  | "defaultValue"
  | "onChange"
  | "onBlur"
>;

const BaseTextArea = (
  {
    id,
    size,
    kind,
    right,
    error,
    disabled,
    className,
    minHeight,
    ...inputProps
  }: TextAreaProps,
  ref: ForwardedRef<HTMLTextAreaElement>,
) => (
  <InputWrapper
    kind={kind}
    size={size}
    className={className}
    aria-disabled={disabled}
  >
    <Area
      id={id}
      disabled={disabled}
      ref={ref}
      aria-invalid={error}
      aria-errormessage={error ? `${id}-error` : undefined}
      minHeight={minHeight}
      {...inputProps}
    />
    {right}
  </InputWrapper>
);

export const TextArea = forwardRef(BaseTextArea);
