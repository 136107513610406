import styled from "@emotion/styled";
import { position, transparentize } from "polished";

import { Button, ButtonProps } from "../button";
import { Progress } from "../progress";

const ModalFooterWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.6rem 2rem;
  border-top: 1px solid ${(props) => props.theme.scheme.grey.r25};
  font-size: ${(props) => props.theme.fontSize.base};
  gap: 1rem;

  .stretch {
    flex: 1;
  }

  .modal-footer-loading {
    ${position("absolute", 0)}
    cursor: wait;
    display: flex;
    align-items: flex-end;
    background: ${(props) => transparentize(0.4, props.theme.scheme.grey.r5)};
  }
`;

export type ModalFooterProps = {
  loading?: boolean;
  left?: (ButtonProps & { key?: string })[];
  right?: (ButtonProps & { key?: string })[];
};

export const ModalFooter = ({ loading, left, right }: ModalFooterProps) => (
  <ModalFooterWrapper>
    {left?.map((buttonProps) => (
      <Button key={buttonProps.key || buttonProps.text} {...buttonProps} />
    ))}
    <div className="stretch" />
    {right?.map((buttonProps) => (
      <Button key={buttonProps.key || buttonProps.text} {...buttonProps} />
    ))}
    {loading && (
      <div className="modal-footer-loading">
        <Progress indeterminate size={0.6} />
      </div>
    )}
  </ModalFooterWrapper>
);
