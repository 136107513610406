import type { SmartTheme } from "@smart/itops-components-dom";
import { systemStack } from "@smart/itops-fonts-dom";
import { scheme } from "@smart/itops-ui-dom";

export const specificSmokeballColours = {
  matter: "#4990e2",
  lead: "#30cba8",
};

export const specificIntakeColours = {
  submissionStatus: {
    active: {
      background: "#008929",
      border: "#008929",
      color: "#FFFFFF",
    },
    inactive: {
      background: "#FFFFFF",
      border: "#757575",
      color: "#757575",
    },
  },
};

const fg = "#222222";
const bg = "#FEFEFE";

export const smokeballTheme: SmartTheme = {
  baseUnit: 1,
  baseFontSize: "62.5%",
  fonts: {
    title: systemStack,
    body: systemStack,
    small: systemStack,
  },
  fontSize: {
    heading: "2.2rem",
    subHeading: "1.8rem",
    emphasis: "1.6rem",
    base: "1.4rem",
    small: "1.2rem",
    xSmall: "1rem",
  },
  palette: {
    background: {
      base: bg,
      accent: "#F6F6F6",
      highlight: "#EAEAEA",
      overlay: fg,
    },
    foreground: {
      base: fg,
      accent: "#848484",
      highlight: "#B4B5B6",
      overlay: bg,
    },
    brand: {
      base: "#F2600C",
      accent: "#F2600C",
      highlight: "#F2600C",
      overlay: bg,
    },
    primary: {
      base: "#4990E2",
      accent: "#1E75DE",
      highlight: "#1E75DE",
      overlay: bg,
    },
    secondary: {
      base: "#415871",
      accent: "#D8E8F6",
      highlight: "#E0EBF3",
      overlay: bg,
    },
    active: {
      base: "#007082",
      accent: "#8CA2AF",
      highlight: "#B9C9DA",
      overlay: bg,
    },
    danger: {
      base: "#C26574",
      accent: "#C26574",
      highlight: "#C26574",
      overlay: bg,
    },
    warning: {
      base: "#BC8D00",
      accent: "#FDCD54",
      highlight: "#F7E4C6",
      overlay: bg,
    },
    success: {
      base: "#B7E3B6",
      accent: "#B7E3B6",
      highlight: "#5BD380",
      overlay: bg,
    },
    default: {
      base: "#DBDBDB",
      accent: "#B2B4B4",
      highlight: "#A2ACB3",
      overlay: fg,
    },
    disabled: {
      base: "#C6C8C7",
      accent: "#EDEDED",
      highlight: "#EDEDED",
      overlay: bg,
    },
  },
  scheme,
  breakPoints: {
    mobile: 400,
    tablet: 680,
    readable: 800,
    medium: 900,
    maximum: 1400,
  },
};
