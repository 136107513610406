import { keyframes, useTheme } from "@emotion/react";
import styled from "@emotion/styled";

import { BaseIconProps, defaultSize } from "./types";
import { IconName } from "../generated/icons";
import * as icons from "../generated/paths";
import { units } from "../generated/setup";

const spinKeyframes = {
  cw: keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  `,
  ccw: keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(-360deg); }
  `,
};

const SvgWrapper = styled.svg<{
  rotate?: number;
  spin?: boolean;
  spinDirection?: "cw" | "ccw";
}>`
  ${(props) => (props.width ? `min-width: ${props.width}px;` : "")}
  ${(props) => (props.height ? `min-height: ${props.height}px;` : "")}
  transform: rotate(${(props) => props.rotate || 0}deg);
  transition: transform 0.2s ease;
  animation: ${(props) =>
      props.spin ? spinKeyframes[props.spinDirection || "cw"] : "none"}
    1.1s infinite linear;
`;

export type UnnamedIconProps = BaseIconProps & { path: string };

export const UnnamedIcon = ({
  path,
  size,
  rotate,
  className,
  spin,
  spinDirection,
  transformSVG,
  color,
  ref,
  onClick,
  dataTestId,
}: UnnamedIconProps) => {
  const theme = useTheme() as { baseUnit?: number };
  const finalSize = (size || defaultSize) * (theme?.baseUnit || 1);

  return (
    <SvgWrapper
      data-testid={dataTestId}
      className={className}
      rotate={rotate}
      spin={spin}
      spinDirection={spinDirection}
      height={finalSize}
      width={finalSize}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      viewBox={`0 0 ${units} ${units}`}
      onClick={onClick}
      ref={ref}
    >
      <path
        transform={transformSVG || `scale(1,-1) translate(0, -${units})`}
        d={path}
        fill={color || "currentColor"}
      />
    </SvgWrapper>
  );
};

export type FontasticIconName = IconName;

export type FontasticIconProps = BaseIconProps & { name: FontasticIconName };

export const FontasticIcon = ({ name, ...rest }: FontasticIconProps) => (
  <UnnamedIcon path={icons[name]} {...rest} />
);
