import { FC } from "react";
import { RenderElementProps } from "slate-react";

import { EditorElementType } from "@smart/itops-serialisation-basic";

import { Link } from "./link";
import { ElementRendererProps, Paragraph } from "./paragraph";
import { Placeholder } from "./placeholder";

const byElementType = {
  link: Link,
  placeholder: Placeholder,
  paragraph: Paragraph,
};

export const Block = (
  props: RenderElementProps & { readOnly?: boolean; isLinkEnabled?: boolean },
) => {
  const { element } = props;
  const Element = byElementType[element.type] as FC<
    ElementRendererProps<EditorElementType>
  >;
  return <Element {...props} />;
};
