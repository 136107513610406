import styled from "@emotion/styled";
import { position } from "polished";
import { ButtonHTMLAttributes, ReactNode } from "react";

import {
  BreakPointKey,
  ColourKey,
  IconButton,
  Loading,
} from "@smart/itops-components-dom";
import { useGlobalKeyboardShortcut } from "@smart/itops-hooks-dom";
import { Icon, IconName } from "@smart/itops-icons-dom";

import { Button } from "./button";
import { useProviderInfo } from "./provider-info";

type ModalProps = {
  open: [boolean, (v: false) => void];
  icon?: IconName;
  title?: string;
  subtitle?: string;
  subtitleIcon?: IconName;
  additionalInfo?: ReactNode;
  children?: ReactNode;
  className?: string;
  buttons: {
    text: string;
    palette?: ColourKey;
    type?: ButtonHTMLAttributes<HTMLButtonElement>["type"];
    disabled?: boolean;
    hidden?: boolean;
    onClick?: () => void;
    eventCaptureAttributes?: Record<string, string | boolean | undefined>;
  }[];
  onSubmit?: () => void;
  onReset?: () => void;
  size?: BreakPointKey;
  transparent?: boolean;
  loading?: boolean;
  error?: ReactNode;
  isForm?: boolean;
  closeOptions?: {
    closeButton?: boolean;
    escapeKey?: boolean;
    clickOutside?: boolean;
  };
  loadingIconSize?: number;
};

const ModalWrapper = styled.div<{
  size?: BreakPointKey;
  transparent?: boolean;
}>`
  ${position("fixed", 0)}
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;

  .backdrop {
    ${position("absolute", 0)};
    height: 100%;
    width: 100%;

    margin: 0;
    padding: 0;
    border: 0;
    background: ${(props) => props.theme.palette.background.highlight};
    opacity: 0.9;
  }

  .modal {
    position: relative;
    ${(props) =>
      props.size
        ? `
      max-height: ${props.theme.breakPoints[props.size]}px;   
      max-width: ${props.theme.breakPoints[props.size]}px;
      height: 95%;
      width: 95%;
    `
        : `
      max-height: 98%;
      max-width: 98%;
      width: 50rem;
      height: auto;
      border-radius: 4px;
    `}

    background: ${(props) => props.theme.palette.background.base};
    border: 1px solid ${(props) => props.theme.palette.background.highlight};

    form,
    .form-alternative {
      display: flex;
      flex-direction: column;
      height: 100%;
      max-height: 98vh;
    }

    .header {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      min-height: 3rem;

      .title-icon {
        color: ${(props) => props.theme.palette.primary.base};
        margin: 0 0 0 1.4rem;
      }

      .provider-icon {
        width: 1.5rem;
        margin: 0 0 0 0.5rem;
      }

      h2 {
        flex: 1;
        font-size: ${(props) => props.theme.fontSize.base};
        font-weight: normal;
        margin: 0 0.5rem;
      }

      button {
        margin: 0;
        opacity: 0.6;
      }
    }

    .subtitle {
      display: flex;
      margin: 0.5rem 1.4rem 1rem;

      .subtitle-icon {
        margin-right: 0.5rem;
        color: ${(props) => props.theme.palette.primary.base};
      }

      h3 {
        margin: 0;
        font-size: ${(props) => props.theme.fontSize.emphasis};
        font-weight: 600;
      }

      .additional-info {
        font-size: ${(props) => props.theme.fontSize.base};

        .note {
          font-size: ${(props) => props.theme.fontSize.small};
        }
      }
    }

    .content {
      position: relative;
      background: ${(props) =>
        props.transparent
          ? "transparent"
          : props.theme.palette.background.accent};
      border: 1px solid
        ${(props) =>
          props.transparent
            ? "transparent"
            : props.theme.palette.background.highlight};
      border-radius: 1px;

      margin: 0.4rem 1.4rem;
      padding: ${(props) => (props.transparent ? 0 : "1.2rem")};
      flex: 1;
      height: 0;
      overflow: auto;

      p {
        font-size: ${(props) => props.theme.fontSize.base};
        line-height: 1.6;
        margin: 0 0 1rem;
      }
    }

    .error-message {
      margin: 0.4rem 1.4rem 0;
    }

    .footer {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-end;
      margin: 1.2rem 1.4rem;
      gap: 0.8rem;
    }
  }
`;

// When having nested modals, we can't use the form implementation because html doesn't allow nested forms
const Container = ({
  onSubmit,
  onReset,
  isForm,
  children,
}: {
  onSubmit?: () => void;
  onReset?: () => void;
  isForm: boolean;
  children: ReactNode;
}) =>
  isForm ? (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (onSubmit) onSubmit();
      }}
      onReset={(e) => {
        e.preventDefault();
        if (onReset) onReset();
      }}
    >
      {children}
    </form>
  ) : (
    <div className="form-alternative">{children}</div>
  );

const Modal = ({
  open: [open, setOpen],
  icon,
  title,
  subtitle,
  subtitleIcon,
  additionalInfo,
  children,
  className,
  buttons,
  onSubmit,
  onReset,
  size,
  transparent,
  loading,
  error,
  isForm = true,
  closeOptions = {},
  loadingIconSize,
}: ModalProps) => {
  const closeOptionsToUse = {
    closeButton: true,
    escapeKey: true,
    clickOutside: true,
    ...closeOptions,
  };

  const onClose = () => {
    if (onReset) onReset();
    setOpen(false);
  };
  const providerInfo = useProviderInfo();
  useGlobalKeyboardShortcut({
    key: "Escape",
    fn: closeOptionsToUse.escapeKey ? onClose : undefined,
  });

  if (!open) return null;

  return (
    <ModalWrapper
      className={`modal-wrapper ${className || ""}`}
      size={size}
      transparent={transparent}
    >
      <button
        type="button"
        aria-label="Close Modal - Outside"
        className="backdrop"
        onClick={closeOptionsToUse.clickOutside ? onClose : undefined}
      />
      <div className="modal">
        <Container onSubmit={onSubmit} onReset={onClose} isForm={isForm}>
          {title && (
            <div className="header">
              {icon ? (
                <Icon className="title-icon" name={icon} size={15} />
              ) : (
                providerInfo?.icon && (
                  <img
                    className="provider-icon"
                    src={providerInfo.icon}
                    alt="Provider Icon"
                  />
                )
              )}
              <h2>{title}</h2>
              {closeOptionsToUse.closeButton && (
                <IconButton
                  name="cancel"
                  palette="foreground"
                  size={10}
                  onClick={onClose}
                  aria-label="Close Modal - X"
                />
              )}
            </div>
          )}
          {(subtitleIcon || subtitle) && (
            <div className="subtitle">
              {subtitleIcon && (
                <Icon className="subtitle-icon" name={subtitleIcon} size={25} />
              )}
              {subtitle && (
                <div>
                  <h3>{subtitle}</h3>
                  <div className="additional-info">{additionalInfo}</div>
                </div>
              )}
            </div>
          )}

          {!!children && (
            <div className="content">
              {loading && <Loading size={loadingIconSize} />}
              {children}
            </div>
          )}
          {error && <div className="error-message">{error}</div>}
          <div className="footer">
            {buttons.map((button) =>
              button.hidden ? null : (
                <Button
                  key={button.text}
                  {...button}
                  disabled={loading || button.disabled}
                  {...button.eventCaptureAttributes}
                />
              ),
            )}
          </div>
        </Container>
      </div>
    </ModalWrapper>
  );
};

export { ModalProps, Modal };
