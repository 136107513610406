import { isNullOrUndefined } from "./guard";

export const getNodeEnv = () => {
  switch (process.env.NODE_ENV) {
    case "test":
    case "development":
      return process.env.NODE_ENV;
    case "live":
    case "production":
    default:
      return "live";
  }
};

export type NodeEnv = ReturnType<typeof getNodeEnv>;

export const isTestEnv = () => getNodeEnv() === "test";

export const isTestComponent = () =>
  getNodeEnv() === "test" && process.env.RENDER_TEST_COMPONENT === "true";

export const selectByEnv = <R extends Record<NodeEnv, any>>(
  things: R,
): Exclude<R[NodeEnv], undefined | null> => {
  const env = getNodeEnv();
  const thing = things[env];
  if (isNullOrUndefined(thing)) throw new Error(`Not available in ${env}`);

  return thing;
};

export const getLocalDBLocation = (file: string) =>
  [process.env.SMART_HOME || process.env.HOME || "", file].join("/");

export const shouldLog = () => process.env.DEBUG || getNodeEnv() !== "test";

export const loadFromEnv = (key: string, localDefault: string) => () => {
  if (getNodeEnv() === "live") {
    const value = process.env[key];
    if (!value) throw new Error(`${key} not set in env`);

    return value;
  }

  return process.env[key] || localDefault;
};

export const loadFromEnvKey =
  <K extends string>(localValues: Record<K, string>) =>
  (key: K) =>
    loadFromEnv(key, localValues[key])();

export const envs = ["dev", "staging", "live"] as const;

export type Env = (typeof envs)[number] | "local";

export const envIcons: Record<Env, string> = {
  dev: ":playground-slide:",
  staging: ":computer:",
  live: ":tada:",
  local: ":house:",
};

export const regions = ["aus", "nova", "lon"] as const;

export type Region = (typeof regions)[number];

export const regionIcons: Record<Region, string> = {
  aus: ":flag-au:",
  nova: ":flag-us:",
  lon: ":flag-gb:",
};

export type InfraInfo = { env: Env; region: Region };

export type AWSInfo = { accountId: string; region: string };

export type InfoKeys<I, E> =
  I extends Record<infer K, string> ? Record<K, E> : never;
