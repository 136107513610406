import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";

const inwards = keyframes`
  0% {
    transform: translate(300%, -50%) scale(60%);
    opacity: 0;
  }

  100% {
    transform: translate(-50%, -50%) scale(100%);
    opacity: 1;
  }
`;

const IllustrationsWrapper = styled.div`
  position: relative;
  margin: 1rem;
  height: 12rem;
  width: 12rem;
  flex: 0 0 12rem;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 12rem;

    transform: translate(-300%, -50%) scale(60%);
    opacity: 0;

    transition:
      transform 0.6s,
      opacity 0.6s;

    &[aria-selected="true"] {
      animation: ${inwards} 0.8s;
      transform: translate(-50%, -50%) scale(100%);
      opacity: 1;
    }
  }
`;

export type IllustrationsProps = {
  illustrations: string[];
  active?: number;
};

export const Illustrations = ({ illustrations, active }: IllustrationsProps) =>
  active === undefined ? null : (
    <IllustrationsWrapper>
      {illustrations.map((src, index) => (
        <img
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          src={src}
          aria-selected={index === active}
          alt=""
        />
      ))}
    </IllustrationsWrapper>
  );
