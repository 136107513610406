import styled from "@emotion/styled";

import { Icon } from "@smart/itops-icons-dom";

const FailureMessageWrapper = styled.p`
  display: flex;
  align-items: center;
  white-space: break-spaces;
  color: ${(props) => props.theme.scheme.red.r100};

  .exclamation {
    align-self: flex-start;
    margin-right: 1rem;
    margin-top: 0.3rem;
  }
`;

type FailureMessageProps = {
  action: string;
  className?: string;
  textOverride?: string;
  contactName?: string;
  contactLink?: string;
  title?: string;
};

export const ContactDisplay = ({
  text,
  link,
}: {
  text?: string;
  link?: string;
}) => (link ? <a href={link}>contact {text}</a> : <span>contact {text}</span>);

const FailureMessage = ({
  action,
  className,
  textOverride,
  contactName,
  contactLink,
  title,
}: FailureMessageProps) => (
  <FailureMessageWrapper
    className={className}
    title={title}
    data-testid="failure-message"
  >
    <Icon className="exclamation" library="lucide" name="AlertCircle" />
    <span>
      <span>{textOverride || `Could not ${action}.`}</span>{" "}
      {(contactName || !textOverride) && (
        <p>
          Please try again or{" "}
          <ContactDisplay text={contactName || "support"} link={contactLink} />.
        </p>
      )}
    </span>
  </FailureMessageWrapper>
);

export { FailureMessage, FailureMessageProps };
