import { css, Theme } from "@emotion/react";
import styled from "@emotion/styled";
import {
  autoUpdate,
  flip,
  offset,
  Placement,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
} from "@floating-ui/react";
import { position, transparentize } from "polished";
import { createContext, ReactNode, useContext, useMemo, useState } from "react";

import {
  Icon,
  IconLibrary,
  IconLibraryName,
  IconName,
} from "@smart/itops-icons-dom";

import { IconButton } from "./buttons";

type Divider = "top" | "bottom";

const dividerStyle = (props: { theme: Theme; divider?: Divider }) => css`
  border-top: ${props.divider === "top"
    ? `1px solid ${props.theme.palette.disabled.highlight}`
    : "none"};
  border-bottom: ${props.divider === "bottom"
    ? `1px solid ${props.theme.palette.disabled.highlight}`
    : "none"};
`;

const MenuButtonWrapper = styled.button<{
  divider?: Divider;
  category?: ItemCategory;
}>`
  display: flex;
  flex-flow: row;
  align-items: center;
  gap: 0.3rem;

  background: none;
  border: 0;
  width: 100%;
  cursor: pointer;
  text-align: left;
  padding: 0.8rem 1.2rem;
  color: ${(props) => (props.category === "warning" ? "red" : "default")};

  ${dividerStyle}

  .label {
    font-size: ${(props) => props.theme.fontSize.small};
    margin: 0.2rem;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .icon + .label {
    margin-left: 0.4rem;
  }

  &:hover:not(:disabled) {
    background-color: ${(props) =>
      transparentize(0.1, props.theme.palette.background.accent)};
  }

  &:disabled {
    color: ${(props) => props.theme.palette.disabled.base};
  }

  &:hover:disabled {
    cursor: not-allowed;
  }
`;

const MenuTitleWrapper = styled.p<{ divider?: Divider }>`
  ${dividerStyle}
  font-size: ${(props) => props.theme.fontSize.small};
  color: ${(props) => props.theme.palette.foreground.accent};
  padding: 0.8rem 1.2rem;
  cursor: default;
  margin: 0;
`;

const MenuContentWrapper = styled.div<{ divider?: Divider }>`
  ${dividerStyle}
  font-size: ${(props) => props.theme.fontSize.small};
  padding: 0.8rem 1.4rem;
`;

const CheckboxMenuItemWrapper = styled.label`
  position: relative;
  cursor: pointer;
  display: flex;
  flex-flow: row;
  align-items: center;
  width: 100%;
  padding: 0.8rem 1.4rem 0.8rem 0;

  input {
    display: none;
  }

  &:hover:not(:disabled) {
    background-color: ${(props) =>
      transparentize(0.1, props.theme.palette.background.accent)};
  }

  &:disabled {
    color: ${(props) => props.theme.palette.disabled.base};
  }

  &:hover:disabled {
    cursor: not-allowed;
  }

  .label {
    font-size: ${(props) => props.theme.fontSize.small};
    margin: 0.4rem 0 0 3.6rem;
    white-space: nowrap;
  }

  .checkmark {
    ${position("absolute", "1rem", "1.2rem")}
    height: 1.6rem;
    width: 1.6rem;
    background-color: ${(props) => props.theme.palette.background.base};
    border: 1px solid ${(props) => props.theme.palette.foreground.base};
    border-radius: 4px;

    &:after {
      content: "";
      ${position("absolute", "0.1rem", 0, 0, "0.4rem")}
      opacity: 0;
      width: 0.6rem;
      height: 1rem;
      border: solid white;
      border-width: 0 0.25rem 0.25rem 0;
      transform: rotate(45deg);
    }
  }

  input:checked ~ .checkmark {
    background-color: ${(props) => props.theme.palette.primary.accent};
    border-color: ${(props) => props.theme.palette.primary.accent};

    &:after {
      opacity: 1;
    }
  }
`;

type ItemType = "action" | "checkbox";
type ItemCategory = "warning" | "normal";

type ContextMenuItem = {
  label: string;
  value: string;
  onClick?: () => void;
  icon?: IconName | ReactNode;
  iconColor?: string;
  iconSize?: number;
  divider?: Divider;
  disabled?: boolean;
  hidden?: boolean;
  isTitle?: boolean;
  content?: ReactNode;
  type?: ItemType;
  checked?: boolean;
  onChange?: (checked: boolean) => void;
  category?: ItemCategory;
};

type ContextMenuProps<L extends IconLibrary> = {
  id?: string;
  items: ContextMenuItem[];
  arrow?: boolean;
  className?: string;
  placement?: Placement;
  offsetOptions?: {
    mainAxis: number;
    crossAxis: number;
  };
  library?: L;
  icon?: IconLibraryName<L>;
  title?: string;
  iconSize?: number;
};

const ContextMenuWrapper = styled.span`
  display: inline-block;
`;

const ContextMenuIcon = styled(IconButton)`
  &:active,
  &:focus {
    outline: none;
    background: none;
  }
`;

const ContentWrapper = styled.div`
  background-color: var(--background);
  z-index: 1;
  border: solid ${(props) => props.theme.palette.disabled.base} 1px;
  border-radius: 0.5rem;
  overflow: hidden;
`;

const CloseMenuContext = createContext<{ close: () => void }>({
  close: () => {},
});

const useCloseMenu = () => useContext(CloseMenuContext);

type CheckboxMenuItemProps = {
  label: string;
  value: string;
  checked?: boolean;
  onChange: (checked: boolean) => void;
  close: () => void;
};

const CheckboxMenuItem = ({
  label,
  value,
  checked,
  onChange,
  close,
}: CheckboxMenuItemProps) => (
  <CheckboxMenuItemWrapper onClick={(e) => e.stopPropagation()}>
    <input
      name={label}
      type="checkbox"
      value={value}
      checked={checked}
      onChange={(e) => {
        onChange(e.target.checked);
        close();
      }}
    />
    <div className="checkmark" />
    <span className="label">{label}</span>
  </CheckboxMenuItemWrapper>
);

const ContextMenu = <L extends IconLibrary = "fontastic">({
  id,
  items,
  className,
  placement = "bottom-end",
  offsetOptions = { mainAxis: -4, crossAxis: -8 },
  icon,
  library,
  arrow,
  title,
  iconSize,
}: ContextMenuProps<L>) => {
  const [open, setOpen] = useState(false);
  const { x, y, refs, strategy, context } = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    middleware: [
      offset(offsetOptions),
      shift({ padding: 10 }),
      flip({ fallbackPlacements: ["bottom", "top"] }),
    ],
    whileElementsMounted: autoUpdate,
  });
  const { getReferenceProps, getFloatingProps } = useInteractions([
    useDismiss(context),
    useClick(context),
  ]);
  const closeMenuContextValue = useMemo(
    () => ({ close: () => setOpen(false) }),
    [],
  );

  return (
    <ContextMenuWrapper
      data-testid={id ? `${id}-context-menu` : "context-menu"}
      className={className}
      ref={refs.setReference}
      title={title}
      {...getReferenceProps({
        onClick(event) {
          event.stopPropagation();
        },
      })}
    >
      <CloseMenuContext.Provider value={closeMenuContextValue}>
        <ContextMenuIcon
          className="context-menu-icon"
          palette="foreground"
          size={iconSize || 16}
          library={library}
          name={icon || "contextMenu"}
          rightIcon={arrow ? { name: "triangleDown", size: 6 } : undefined}
        />
        {open && (
          <ContentWrapper
            ref={refs.setFloating}
            {...getFloatingProps({
              style: {
                position: strategy,
                top: y ?? 0,
                left: x ?? 0,
              },
            })}
          >
            {items.map((item) => {
              if (item.hidden) {
                return null;
              }

              if (item.isTitle) {
                return (
                  <MenuTitleWrapper key={item.value} divider={item.divider}>
                    {item.label}
                  </MenuTitleWrapper>
                );
              }

              if (item.content) {
                return (
                  <MenuContentWrapper
                    key={item.value}
                    divider={item.divider}
                    // Custom content item won't close the menu on click
                    onClick={(event) => event.stopPropagation()}
                  >
                    {item.content}
                  </MenuContentWrapper>
                );
              }

              switch (item.type) {
                case "checkbox":
                  return (
                    <CheckboxMenuItem
                      key={item.value}
                      label={item.label}
                      value={item.value}
                      checked={item.checked}
                      onChange={(checked) => item.onChange?.(checked)}
                      close={() => setOpen(false)}
                    />
                  );
                default:
                  return (
                    <MenuButtonWrapper
                      key={item.value}
                      divider={item.divider}
                      disabled={item.disabled}
                      onClick={item.onClick}
                      className="context-menu-item"
                      category={item.category}
                    >
                      {item.icon &&
                        (typeof item.icon === "string" ? (
                          <Icon
                            name={item.icon as IconName}
                            color={item.iconColor}
                            className="icon"
                            size={item.iconSize || 15}
                          />
                        ) : (
                          item.icon
                        ))}
                      <div className="label">{item.label}</div>
                    </MenuButtonWrapper>
                  );
              }
            })}
          </ContentWrapper>
        )}
      </CloseMenuContext.Provider>
    </ContextMenuWrapper>
  );
};

export { ContextMenu, ContextMenuProps, useCloseMenu };
