import { z } from "zod";

import { buildNamespace, extractId } from "@smart/itops-types-basic";

import { system } from "./base";

export const TeamNS = buildNamespace({
  system,
  entity: "Team",
} as const);

export const TeamSchema = z.object({
  uri: TeamNS.schema,
  operationId: z.string(),

  source: z.string(),
  name: z.string(),
  email: z.string().optional(),
  phone: z.string(),
  address: z.string(),
  picture: z.string().optional(),

  updatedAt: z.string(),
});

export const teamPrefix = {
  sb: "sb",
};

export const extractPrefixedTeamId = (
  uri: string,
  prefix: keyof typeof teamPrefix,
) => {
  const id = extractId(uri);
  const testString = `${prefix}-`;
  const prefixed = id.startsWith(testString);

  return {
    prefixed,
    id: prefixed ? id.slice(testString.length) : id,
  };
};

export const globalTeamUri = "smart:team:sb-smokeball-global";
