import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { transparentize } from "polished";

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const Spinner = styled.div<{ size: number }>`
  width: ${(props) => props.size}rem;
  height: ${(props) => props.size}rem;
  min-width: ${(props) => props.size}rem;
  min-height: ${(props) => props.size}rem;

  border: ${(props) => Math.ceil(props.size / 2)}px solid
    ${(props) => transparentize(0.6, props.theme.scheme.grey.r35)};
  border-top-color: ${(props) => props.theme.scheme.blue.r80};
  border-radius: 100%;

  animation: ${spin} 1.1s infinite linear;
`;
