import { useTheme } from "@emotion/react";

import { BaseIconProps, defaultSize } from "./types";
import * as icons from "../lucide/paths";

export type LucideIconName = keyof typeof icons;

export type LucideIconProps = BaseIconProps & {
  name: LucideIconName;
};
export const LucideIcon = ({
  name,
  color,
  className,
  size,
  stroke,
  rotate,
  dataTestId,
}: LucideIconProps) => {
  const Icon = icons[name];
  const theme = useTheme() as { baseUnit?: number };
  const finalSize = (size || defaultSize) * (theme?.baseUnit || 1);

  return (
    <Icon
      color={color}
      className={className}
      size={finalSize}
      transform={rotate ? `rotate(${rotate})` : undefined}
      strokeWidth={stroke}
      data-testid={dataTestId}
    />
  );
};
