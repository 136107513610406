import { z } from "zod";

import { buildNamespace } from "@smart/itops-types-basic";
import { wrapSchema } from "@smart/itops-utils-basic";

import { system } from "./base";
import { FormNS } from "./form";
import { fieldFieldSchema, fieldLayoutSchema, fieldTypeSchema } from "./field";
import { SubmissionNS } from "./submission";

export const AIUserFeedbackNS = buildNamespace({
  system,
  entity: "AIUserFeedback",
} as const);

export const AIUserFeedbackStatusSchema = z.enum([
  "generating",
  "generated",
  "completed",
  "reviewed",
]);

export const AIUserFeedbackSchema = z.object({
  uri: AIUserFeedbackNS.schema,
  operationId: z.string(),

  type: z.string(),
  model: z.string().optional(),
  input: z.string().optional(),
  output: z.string().optional(),
  status: AIUserFeedbackStatusSchema,
  statusText: z.string().optional(),
  formUri: FormNS.schema.optional(),
  submissionUri: SubmissionNS.schema.optional(),

  metrics: z
    .object({
      items: z.array(z.string()),
    })
    .optional(),
  feedback: z.string().optional(),
  rating: z.number().optional(),
  fileCategory: z.string().optional(),
  sourceFileIds: z.array(z.string()).optional(),
  updatedAt: z.string().optional(),
  userUri: z.string().optional(),

  deletedAtSec: z.number().optional(),
});

export const GeneratedFormSchema = z.discriminatedUnion("version", [
  z.object({
    version: z.literal("1"),
    sections: z.array(
      z.object({
        uri: z.string(),
        title: z.string(),
        displayTitle: z.string().optional(),
        index: z.number(),
        fixed: z.boolean().optional(),
        questions: z
          .array(
            z.object({
              uri: z.string(),
              label: z.string(),
              index: z.number(),
              type: fieldTypeSchema,
              options: z.array(z.string()).optional(),
              layout: fieldLayoutSchema.optional(),
              field: fieldFieldSchema.optional(),
            }),
          )
          .optional(),
      }),
    ),
  }),
]);

export type GeneratedForm = z.infer<typeof GeneratedFormSchema>;

export const generatedFormGuard = wrapSchema(GeneratedFormSchema);

export const GeneratedResponsesSchema = z.object({
  responses: z.array(
    z.object({
      fieldUri: z.string(),
      response: z
        .string()
        .describe("For text, multilineText, email, date and choice type")
        .or(z.array(z.string()).describe("For checkbox type"))
        .or(z.number().describe("For number type"))
        .or(z.string().describe("For phoneNumber type"))
        .or(z.string().describe("For address type")),
    }),
  ),
});

export type GeneratedResponses = z.infer<typeof GeneratedResponsesSchema>;

export const GeneratedQueriesSchema = z.object({
  queries: z
    .array(z.string())
    .describe("The queries to help answer the questions in the form"),
});

export type GeneratedQueries = z.infer<typeof GeneratedQueriesSchema>;
