import { v4 } from "@smart/itops-utils-basic";
import { z } from "zod";

export type Namespace<S, E> = {
  system: S;
  entity: E;
  namespace: string;
  generateUri: (...ids: string[]) => string;
  isUriMatch: (uri: string) => boolean;
  uriRegex: RegExp;
  schema: z.ZodString;
};

export const buildNamespace = <S, E>(
  parts: Pick<Namespace<S, E>, "system" | "entity">,
): Namespace<S, E> => {
  const namespace = `${parts.system}:${parts.entity}`.toLocaleLowerCase();
  const uriRegex = new RegExp(`${namespace}:[a-z0-9-]+`);
  return {
    ...parts,
    namespace,
    uriRegex,
    generateUri: (...ids) =>
      `${namespace}:${ids.length ? ids.join("-") : v4()}`,
    isUriMatch: (uri: string) => uriRegex.test(uri),
    schema: z.string().regex(uriRegex, `should match ${namespace}`),
  };
};

export const namespaceParts = (uri: string) => {
  const [system, entity, id] = uri.split(":");
  return { system, entity, id };
};

export const extractId = (uri: string) => namespaceParts(uri).id;

export const namespacedList = <K extends string, L extends { [i in K]: any }>(
  items: { [i in K]: { namespace: Namespace<string, i> } } & L,
): L => items;
