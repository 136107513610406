import { z } from "zod";

import { domainSchema } from "./domains";

export const planInfoSchema = z.object({
  createdAt: z.string(),
  attemptNumber: z.number(),
  teamUri: z.string(),
  userUri: z.string(),
  source: z.string(),
  email: z.string(),
  roles: z.string().optional(),
});

export type PlanInfo = z.infer<typeof planInfoSchema>;

export const planRoutingSchema = z.object({
  operationId: z.string(),
  domain: domainSchema,
  shardKey: z.string(),
});
