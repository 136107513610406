import styled from "@emotion/styled";
import { position, transparentize } from "polished";
import { ReactNode, useState } from "react";
import { NavLink } from "react-router-dom";

import { Icon, IconName } from "@smart/itops-icons-dom";

import { IconButton } from "./buttons";

const width = 25;

const SidebarWrapper = styled.div<{ expanded: boolean }>`
  display: grid;
  grid-template-columns: ${(props) => (props.expanded ? width : 6)}rem auto;
  grid-template-rows: 100%;
  grid-template-areas: "sidebar display";

  transition: grid-template-columns 0.2s ease;

  .display {
    grid-area: display;
    position: relative;
    overflow: hidden;
    min-height: 98vh;
    max-height: 100%;
  }

  .sidebar {
    ${position("absolute", 0)}
    width: ${(props) => (props.expanded ? width : 6)}rem;
    height: 100%;

    --background: ${(props) => props.theme.palette.background.accent};

    grid-area: sidebar;
    background: var(--background);
    box-shadow: inset -9px 0px 8px -8px rgba(0, 0, 0, 0.2);

    display: flex;
    flex-flow: column;
    align-items: flex-start;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: none;

    .menu {
      align-self: stretch;
      justify-content: flex-end;
    }

    .nav {
      flex: 1;
      width: ${width}rem;

      a {
        display: flex;
        flex-flow: row;
        align-items: center;
        padding: 0.5rem 2rem;
        margin: 0.2rem 0;

        color: ${(props) => props.theme.palette.primary.base};
        text-decoration: none;

        &.active {
          background: ${(props) =>
            transparentize(0.8, props.theme.palette.primary.accent)};
        }

        p {
          margin: 0.8rem 0 0.8rem 2rem;
          font-size: ${(props) => props.theme.fontSize.base};
          font-weight: 500;
        }
      }
    }

    .footer {
      width: ${width}rem;
    }
  }
`;

type SidebarLink = { to: string; label: string; icon: IconName };
type SidebarFooterProps = { setExpanded: (expanded: boolean) => void };

type SidebarProps = {
  children: ReactNode;
  links: SidebarLink[];
  footer?: (props: SidebarFooterProps) => JSX.Element;
};

const Sidebar = ({ children, links, footer: Footer }: SidebarProps) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <SidebarWrapper expanded={expanded}>
      <div className="sidebar">
        <IconButton
          name="menu"
          className="menu"
          onClick={() => setExpanded(!expanded)}
        />
        <div className="nav" role="navigation" aria-label="Sidebar">
          {links.map(({ to, label, icon }) => (
            <NavLink key={to} to={to}>
              <Icon name={icon} />
              <p>{label}</p>
            </NavLink>
          ))}
        </div>
        {Footer && (
          <div className="footer">
            <Footer setExpanded={setExpanded} />
          </div>
        )}
      </div>
      <div className="display">{children}</div>
    </SidebarWrapper>
  );
};

export { Sidebar, SidebarLink, SidebarProps };
