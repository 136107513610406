import styled from "@emotion/styled";
import { useState } from "react";
import { Editor, Range, Transforms } from "slate";
import { ReactEditor, useSlate } from "slate-react";

import {
  EditorElement,
  isEditorElementType,
} from "@smart/itops-serialisation-basic";

import { Buttons, Field } from "./field";
import { Button } from "../../button";
import { RadioButtons } from "../../fields";
import { useEditorContext } from "../hooks";

const Radios = styled(RadioButtons)`
  input {
    display: none;
  }
`;

export const PlaceholderPopup = ({ element }: { element?: EditorElement }) => {
  const editor = useSlate();
  const { setModal, placeholders } = useEditorContext();
  const [displayText, setDisplayText] = useState(() => {
    if (isEditorElementType("placeholder")(element)) return element.displayText;
    if (editor.selection) return Editor.string(editor, editor.selection);
    return "";
  });
  const [casing, setCasing] = useState<"titleCase" | "lowercase">("titleCase");
  const [placeholder, setPlaceholder] = useState(() => {
    if (isEditorElementType("placeholder")(element)) return element.name;
    return placeholders?.keys[0];
  });

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (!placeholder || !placeholders) return;

        const details = placeholders.details[placeholder];
        if (!details) return;

        const updated: EditorElement<"placeholder"> = {
          type: "placeholder",
          name: placeholder,
          isLink: details.isLink,
          displayText: details.isLink ? displayText : details.label,
          casing: casing === "lowercase" ? "lowercase" : undefined,
          children: [{ text: "" }],
        };

        if (element && isEditorElementType("placeholder")(element)) {
          Transforms.setNodes(editor, updated, {
            match: (n) => n === element,
          });
        } else {
          if (editor.selection && Range.isExpanded(editor.selection)) {
            Transforms.delete(editor, {
              at: editor.selection,
            });
          }

          Transforms.insertNodes(editor, updated);
        }

        setModal(undefined);
        ReactEditor.focus(editor);
      }}
    >
      <Field>
        <label>Type</label>
        <select
          value={placeholder}
          onChange={(e) => {
            const { value } = e.currentTarget;
            setPlaceholder(value);
            if (placeholders?.details[value].isLink && !displayText) {
              setDisplayText(placeholders.details[value].defaultValue || "");
            }
          }}
        >
          {placeholders?.keys.map((key) => (
            <option key={key} value={key}>
              {placeholders.details[key].label}
            </option>
          ))}
        </select>
      </Field>
      {placeholder && placeholders?.details[placeholder]?.isLink && (
        <Field>
          <label>Display Text</label>
          <input
            type="text"
            onChange={(e) => setDisplayText(e.currentTarget.value)}
            value={displayText}
          />
        </Field>
      )}
      {placeholder && placeholders?.details[placeholder]?.lowerable && (
        <Field>
          <Radios
            direction="row"
            align="left"
            size="base"
            options={[
              { label: "Title Case", value: "titleCase" },
              { label: "Lowercase", value: "lowercase" },
            ]}
            value={casing}
            onChange={(value) => setCasing(value as "titleCase" | "lowercase")}
          />
        </Field>
      )}
      <Buttons>
        <Button
          size="small"
          text="Cancel"
          variant="cancel"
          onClick={() => {
            setModal(undefined);
            ReactEditor.focus(editor);
          }}
        />
        <Button
          size="small"
          text={element ? "Update" : "Add"}
          variant="action"
          type="submit"
        />
      </Buttons>
    </form>
  );
};
