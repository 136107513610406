import excel from "./excel.png";
import file from "./file.png";
import image from "./image.png";
import outlook from "./outlook.png";
import pdf from "./pdf.png";
import powerpoint from "./powerpoint.png";
import word from "./word.png";

export const fileTypes = {
  pdf,
  txt: file,
  jpg: image,
  png: image,
  svg: image,
  bmp: image,
  csv: excel,
  xls: excel,
  xlsx: excel,
  msg: outlook,
  ppt: powerpoint,
  pptx: powerpoint,
  doc: word,
  docx: word,
};
