export class LocalAWSError extends Error {
  public type: string;

  public statusCode: number;

  public displayCode?: string;

  constructor(
    type: string,
    message: string,
    statusCode: number,
    displayCode?: string,
  ) {
    super(message);
    this.type = type;
    this.statusCode = statusCode;
    this.displayCode = displayCode;
  }
}
