import { MouseEvent, createContext, useContext } from "react";

import { EditorElement, ParagraphTag } from "@smart/itops-serialisation-basic";

export type DisplayerPlaceholders<P extends string = string> = {
  keys: P[];
  data: Record<P, string>;
};

export type DisplayerContextValue = {
  paragraphTag?: ParagraphTag;
  placeholders?: DisplayerPlaceholders;
  onLinkClick?: (
    e: MouseEvent<HTMLAnchorElement>,
    element: EditorElement<"link">,
  ) => void;
  onPlaceholderClick?: (
    e: MouseEvent<HTMLAnchorElement>,
    element: EditorElement<"placeholder">,
    data: string,
  ) => void;
};

const displayerContext = createContext<DisplayerContextValue>({
  paragraphTag: "p",
});

export const DisplayerContextProvider = displayerContext.Provider;

export const useDisplayerContext = () => useContext(displayerContext);
