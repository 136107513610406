export const chunk = <I>(items: I[], size: number): I[][] =>
  Array.from({ length: Math.ceil(items.length / size) }, (v, i) =>
    items.slice(i * size, i * size + size),
  );

export const isElementOf =
  <T>(things: ReadonlyArray<T>) =>
  (e: any): e is T =>
    things.indexOf(e) !== -1;

export const keysOf = <T extends string | number>(things: Record<T, any>) =>
  Object.keys(things) as T[];

export const isKeyOf = <T extends string | number>(things: Record<T, any>) =>
  isElementOf(keysOf(things));

export const entriesOf = <K extends string | number, V>(things: Record<K, V>) =>
  Object.entries(things) as [K, V][];

export const fromEntries = <K extends string | number, V>(things: [K, V][]) =>
  Object.fromEntries(things) as Record<K, V>;

export const mapEntries = <M, T extends {}>(
  things: T,
  fn: (value: T[keyof T], key: keyof T) => M[keyof M],
) => {
  const entries = Object.entries(things);
  const mapped = entries.map(([key, value]) => [
    key,
    fn(value as T[keyof T], key as keyof T),
  ]);
  return Object.fromEntries(mapped) as M;
};

export const partition = <I>(
  items: I[],
  predicate: (item: I) => boolean,
): [I[], I[]] =>
  items.reduce(
    ([passed, failed], item) =>
      predicate(item)
        ? [[...passed, item], failed]
        : [passed, [...failed, item]],
    [[], []] as [I[], I[]],
  );

export const mapOrEmpty = <I, O, E>(
  items: I[] | undefined | null,
  map: (item: I, i: number, items: I[]) => O,
  empty: E,
) => (items?.length ? items.map(map) : empty);

export const hasDuplicate = <I>(items: I[], key: keyof I): boolean => {
  const values = items.map((i) => i[key]);
  return values.length !== new Set(values).size;
};

export function findLastIndex<I>(
  items: I[],
  predicate: (item: I, index: number, array: I[]) => boolean,
): number {
  let i = items.length;
  while (i > 0) {
    i -= 1;
    if (predicate(items[i], i, items)) return i;
  }
  return -1;
}

export const pick = <V extends {}, K extends keyof V>(obj: V, args: K[]) =>
  ({
    ...args.reduce((res, key) => ({ ...res, [key]: obj[key] }), {}),
  }) as Pick<V, K>;

export const indexBy = <T extends {}>(
  indexKey: keyof T,
  list: T[],
): Record<string | number, T> =>
  list.reduce(
    (map, item) => ({
      ...map,
      [item[indexKey] as unknown as string | number]: item,
    }),
    {},
  );

export const replace = <T>(
  list: T[],
  shouldReplace: (item: T) => boolean,
  replacement: T | ((item: T) => T),
): T[] =>
  list.map((item) => {
    if (shouldReplace(item)) {
      return typeof replacement === "function"
        ? (replacement as (item: T) => T)(item)
        : replacement;
    }
    return item;
  });

export const zip = <A, B>(listA: A[], listB: B[]): [A, B][] =>
  listA.map((value, idx) => [value, listB[idx]]);

export const groupBy = <I>(items: I[], getGroupingKey: (item: I) => string) =>
  items.reduce<Record<string, I[]>>((grouped, item) => {
    const key = getGroupingKey(item);

    return {
      ...grouped,
      [key]: grouped[key] ? [...grouped[key], item] : [item],
    };
  }, {});
