import { unknownUserErrorCode } from "./user";
import { ValidationError } from "./validation";

export class ProcessorError extends Error {
  public step: string;

  public args: any;

  public processError?: ValidationError;

  constructor(error: unknown, step: string, args: any) {
    super(`Error ${step}`);
    if (error instanceof ValidationError) {
      this.stack = error.stack;
      this.message = error.message;
      this.processError = error;
    } else if (error instanceof Error) {
      this.stack = error.stack;
      this.message = `${error.name} ${step}`;
      this.processError = new ValidationError(
        error,
        unknownUserErrorCode,
        step,
        args,
      );
    } else {
      this.processError = new ValidationError(
        error,
        unknownUserErrorCode,
        step,
        args,
      );
    }

    this.step = step;
    this.args = args;
  }
}
