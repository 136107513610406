export type FetchRequestDetails = {
  action: string;
};

export type RequestError = {
  code: string;
  message: string;
  request?: { requestUrl: string };
  options: { body: string };
  response?: { statusCode: number; body: string };
};

export const isRequestError = (e: any): e is RequestError =>
  typeof e === "object" && !!e.code;

export class FetchError extends Error {
  public action: string;

  public statusCode?: number;

  public url?: string | URL;

  public input?: string;

  public body?: string;

  constructor(error: RequestError | unknown, { action }: FetchRequestDetails) {
    let stack;
    let code;
    let message;
    if (isRequestError(error)) {
      code = error.code;
      message = error.message;
    } else if (error instanceof TypeError) {
      code = "TypeError";
      message = `${error.message} ${error.cause}`;
      stack = error.stack;
    } else if (error instanceof Error) {
      code = error.name;
      message = error.message;
      stack = error.stack;
    } else {
      code = `${error}`;
      message = `${error}`;
    }

    super(`${code} in ${action}: ${message}`);

    this.action = action;
    if (stack) this.stack = stack;

    if (isRequestError(error)) {
      this.url = error.request?.requestUrl;
      this.input = error.options?.body?.toString();
      this.statusCode = error.response?.statusCode;
      this.body = `${error.response?.body}`;
    }
  }
}
