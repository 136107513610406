import { z } from "zod";

import { buildNamespace } from "@smart/itops-types-basic";

import { system } from "./base";

export const SettingNS = buildNamespace({
  system,
  entity: "Setting",
} as const);

export const SettingSchema = z.object({
  uri: SettingNS.schema,
  operationId: z.string(),

  teamUri: z.string(),
  defaultClientMessage: z.object({
    matter: z.string().optional(),
    lead: z.string().optional(),
    reminder: z.string().optional(),
    confirmation: z.string().optional(),
  }),
  locations: z.array(z.string()).optional(),
  areasOfLaw: z.array(z.string()).optional(),

  updatedAt: z.string(),
});
