import styled from "@emotion/styled";
import { position, transparentize } from "polished";
import { ReactNode } from "react";

import { useGlobalKeyboardShortcut } from "@smart/itops-hooks-dom";

import { ModalFooter, ModalFooterProps } from "./footer";
import { ModalHeader, ModalHeaderProps } from "./header";
import { BreakPointKey } from "../../theme";

type ModalSize = {
  height?: BreakPointKey;
  width?: BreakPointKey;
};

const ModalWrapper = styled.div<{ size?: ModalSize }>`
  ${position("fixed", 0)}
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;

  .backdrop {
    ${position("absolute", 0)}
    height: 100%;
    width: 100%;

    margin: 0;
    padding: 0;
    border: 0;
    background: rgba(0, 0, 0, 0.3);
  }

  .modal {
    position: relative;
    --background: white;
    background: var(--background);
    border-radius: 0.8rem;
    box-shadow: 0px 4px 84px 0px rgba(0, 0, 0, 0.25);
    overflow: hidden;

    display: flex;
    flex-flow: column nowrap;

    height: ${(props) => (props.size?.height ? "95%" : "auto")};
    max-height: ${(props) =>
      props.size?.height
        ? `${props.theme.breakPoints[props.size.height]}px`
        : "95%"};
    width: ${(props) => (props.size?.width ? "95%" : "56rem")};
    max-width: ${(props) =>
      props.size?.width
        ? `${props.theme.breakPoints[props.size.width]}px`
        : "95%"};
  }

  .modal-content {
    flex: 1;
    overflow: auto;
    padding: 2rem 2rem;
    position: relative;
    max-height: 100%;

    .modal-content-loading {
      ${position("absolute", 0)}
      cursor: wait;
      background: ${(props) => transparentize(0.4, props.theme.scheme.grey.r5)};
    }
  }
`;

export type ModalProps = {
  children?: ReactNode;
  className?: string;
  closeOptions?: {
    escapeKey?: boolean;
    clickOutside?: boolean;
  };
  footer?: ModalFooterProps;
  header?: ModalHeaderProps;
  loading?: boolean;
  open: boolean;
  onClose?: () => void;
  size?: ModalSize;
  dataTestId?: string;
};

export const Modal = ({
  children,
  className,
  closeOptions,
  footer,
  header,
  loading,
  open,
  onClose,
  size,
  dataTestId,
}: ModalProps) => {
  const closeOptionsToUse = {
    escapeKey: true,
    clickOutside: true,
    ...closeOptions,
  };
  useGlobalKeyboardShortcut({
    key: "Escape",
    fn: closeOptionsToUse.escapeKey && onClose ? () => onClose() : undefined,
  });

  if (!open) return null;

  return (
    <ModalWrapper
      className={`modal-wrapper ${className || ""}`}
      size={size}
      data-testid={dataTestId}
    >
      <button
        type="button"
        aria-label="Close Modal - Outside"
        className="backdrop"
        onClick={() =>
          closeOptionsToUse.clickOutside && onClose ? onClose() : undefined
        }
      />
      <div className="modal">
        {header && (
          <ModalHeader {...header} onClose={header.onClose || onClose} />
        )}
        <div className="modal-content">
          {children}
          {loading && <div className="modal-content-loading" />}
        </div>
        {footer && <ModalFooter {...footer} loading={loading} />}
      </div>
    </ModalWrapper>
  );
};

export { ModalHeader, ModalFooter };
