import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { transparentize } from "polished";

export const appear = keyframes({
  "0%": {
    transform: "translateZ(0) scale(0, 0)",
    marginBottom: "-10px",
    opacity: 0,
  },
  "50%": {
    transform: "translateZ(0) scale(1.0, 1.0)",
    marginBottom: "0",
  },
  "100%": { opacity: 1 },
});

const expand = keyframes({
  "10%, 70%": { transform: "translateZ(0) scale(1.0, 1.0)", opacity: 0.2 },
  "40%": { transform: "translateZ(0) scale(1.4, 1.4)", opacity: 0.6 },
});

const IndicatorWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin: 0;
  padding: 1.2rem 1.2rem;
  border-radius: 1.8rem;

  background-color: ${(props) =>
    transparentize(0.6, props.theme.palette.foreground.accent)};

  overflow: hidden;
  animation: ${appear} 0.4s ease-in-out;
`;

const Dot = styled.div<{ index: number }>`
  position: relative;

  width: 0.7rem;
  height: 0.7rem;
  margin: 0 0.25rem;

  &::after {
    content: "";

    position: absolute;
    width: 100%;
    height: 100%;

    background-color: ${(props) => props.theme.palette.foreground.accent};
    border-radius: 100px;
    transform: "translateZ(0) scale(1.0, 1.0)";
    opacity: 0.2;

    animation: ${expand} 2.2s ease-in-out infinite;
    animation-delay: ${(props) => props.index * 0.4}s;
  }
`;

const dots = new Array(3).fill(0);

const Indicator = () => (
  <IndicatorWrapper className="indicator">
    {dots.map((_, i) => (
      // eslint-disable-next-line react/no-array-index-key
      <Dot key={i} index={i} />
    ))}
  </IndicatorWrapper>
);

export { Indicator };
